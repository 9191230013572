import React from "react";

import { formatUIValue } from "$utils/number-helper";

const LargeNumber = ({ value, label, isHealthy }) => {
    const healthClass = isHealthy === false ? "txt-unhealthy" : isHealthy ? "txt-healthy" : "txt -potential";
    return (
        <div className="large-number">
            <h3 className={healthClass}>{formatUIValue(value)}</h3>
            {label && <h5>{label}</h5>}
        </div>
    );
};

export default LargeNumber;
