import React from "react";

export const BarBubbleTooltip = ({ x, y, width, value }) => {
    const radius = 15;

    return (
        <g>
            <circle cx={x + width / 2} cy={y} r={radius} fill="#de653a" />
            <text className="txt -condensed -bold -small" x={x + width / 2} y={y} fill="#03202d" textAnchor="middle" dominantBaseline="middle">
                {value}
            </text>
        </g>
    );
};
