import React from "react";
import classNames from "classnames";
import { getKeyMetaData } from "../lib/helpers";

const ChartTemplates = ({ activeIndex, answer, options, questionIndex, handleSelect, title }) => {
    const hasFollowUp = options && options[0].hasOwnProperty("followUp");
    return (
        <div
            className={classNames("wizard-widget -template", {
                "-selected": answer,
                "-active": questionIndex === activeIndex || (!hasFollowUp && questionIndex === activeIndex - 1),
                "-previous": activeIndex >= questionIndex && hasFollowUp,
                "-skip": hasFollowUp && options.length === 1,
            })}
        >
            <h3>{title}</h3>
            <div className="wizard-options">
                {options.map((o, i) => {
                    return (
                        <div
                            key={`option_${questionIndex}_${i}}`}
                            className={classNames("option txt -center", { "-selected": answer?.id === o.id })}
                            onClick={() => {
                                handleSelect(o, questionIndex);
                            }}
                        >
                            <h4 dangerouslySetInnerHTML={{ __html: o.text }}></h4>
                            <p dangerouslySetInnerHTML={{ __html: o.description }}></p>
                            {o.metrics.length > 0 && (
                                <div className="metrics">
                                    {o.metrics.map((m, i) => (
                                        <h4 key={`metric_${i}`}>
                                            <span className="memo">{getKeyMetaData(m)?.text}</span>
                                        </h4>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ChartTemplates;
