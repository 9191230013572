import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Formik, Form, Field } from "formik";

import NotificationBar from "$components/notification-bar";

import { SalesforceStore, submitOpportunityToSalesforce, fetchOpportunityFormSchema } from "$stores/salesforce";
import { extractValue } from "$components/form-inputs/libs/helpers";
import { toast } from "react-toastify";
import { getFieldType } from "$components/form-inputs/libs/fields";
import { mapByKey } from "$utils/mapping";

import "./salesforce.scss";

const formatValues = (fieldsByKey, values) => {
    return Object.keys(values).reduce((obj, key) => {
        if (fieldsByKey[key]?.fieldType === "boolean") {
            obj[key] = values[key] ? "true" : "false";
        } else {
            obj[key] = values[key] || "";
        }
        return obj;
    }, {});
};

const SalesforceOpportunityForm = ({ clientId, projectKey, currentProject, afterSubmit }) => {
    const { opportunityFormSchema, prePopulateMap, defaultValues, opportunityFormLoadingError } = SalesforceStore.useState((s) => s);

    const [initialValues, setInitialValues] = useState({});
    const [isLoadingForm, setIsLoadingForm] = useState(true);

    useEffect(() => {
        if (!opportunityFormSchema && isLoadingForm) {
            fetchOpportunityFormSchema();
        }
    }, [opportunityFormSchema, isLoadingForm]);

    useEffect(() => {
        if (opportunityFormLoadingError) {
            setIsLoadingForm(false);
        }
    }, [opportunityFormLoadingError]);

    useEffect(() => {
        if (prePopulateMap && defaultValues) {
            const maps = Object.keys(prePopulateMap).reduce((acc, key) => {
                acc[key] = extractValue(currentProject, prePopulateMap[key]).toString();
                return acc;
            }, {});
            const defaults = Object.keys(defaultValues).reduce((acc, key) => {
                acc[key] = defaultValues[key];
                return acc;
            }, {});
            setInitialValues({ ...maps, ...defaults });
            setIsLoadingForm(false);
        }
    }, [prePopulateMap, defaultValues, currentProject]);

    const handleSubmit = async (values) => {
        const response = await submitOpportunityToSalesforce(clientId, projectKey, values);
        if (response) {
            toast("Sent to Salesforce Successfully");
            if (afterSubmit) afterSubmit();
        }
    };

    if (isLoadingForm) {
        return <div>Loading ...</div>;
    }

    if (opportunityFormSchema?.fields && opportunityFormSchema?.groups) {
        return (
            <div className="salesforce-form">
                <h1>
                    <em>Salesforce</em> Export
                </h1>

                <Formik
                    initialValues={initialValues}
                    validate={(values) => {
                        const errors = {};
                        opportunityFormSchema.fields.forEach((f) => {
                            if (f.isRequired && !values[f.key]) {
                                errors[f.key] = `${f.label} is required`;
                            }
                        });
                        return errors;
                    }}
                    onSubmit={async (values, { isSubmitting, setSubmitting }) => {
                        if (isSubmitting) {
                            return;
                        }
                        await handleSubmit(formatValues(mapByKey(opportunityFormSchema.fields, "key"), values));
                        setSubmitting(false);
                    }}
                >
                    {({ errors, touched, submitCount, isSubmitting, handleChange, setFieldValue, values }) => (
                        <Form>
                            {!_.isEmpty(errors) && submitCount > 0 && (
                                <NotificationBar type="error">
                                    <ul>
                                        {Object.keys(errors).map((k) => {
                                            return <li key={`error_${k}`}>{errors[k]}</li>;
                                        })}
                                    </ul>
                                </NotificationBar>
                            )}
                            {Object.keys(opportunityFormSchema.groups).map((section, i) => {
                                return (
                                    <div key={`section_${i}`} className="groups">
                                        {opportunityFormSchema.groups[section][0]?.sectionHeader && <h4>{opportunityFormSchema.groups[section][0]?.sectionHeader}</h4>}
                                        <div className="fields">
                                            {opportunityFormSchema.groups[section].map((f) => {
                                                const fieldType = getFieldType(f.fieldType);
                                                const requiredLabel = f.isRequired ? "*" : "";

                                                return (
                                                    <div className={`form-group ${f.isHidden ? "hide" : ""}`} key={f.key}>
                                                        <Field
                                                            type={fieldType.type}
                                                            id={f.key}
                                                            name={f.key}
                                                            label={`${requiredLabel}${f.label}`}
                                                            options={f.options}
                                                            component={fieldType.Component}
                                                            onChange={handleChange}
                                                            updateOnChange={(name, value) => {
                                                                setFieldValue(name, extractValue(value));
                                                            }}
                                                            isRequired={f.isRequired}
                                                            invalid={!!errors[f.key] && (touched[f.key] || submitCount > 0)}
                                                            defaultValue={values[f.key]}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="button-group -right">
                                <button type="submit" className="button -primary" disabled={isSubmitting}>
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    } else {
        return <h3>Service Currently Unavailable</h3>;
    }
};

export default SalesforceOpportunityForm;
