import { closeModal } from "$stores/modal";
import { useInput } from "$utils/hooks/use-input";
import classNames from "classnames";

const { Input } = require("$components/form-inputs/inputs");

const EditOptionDialog = ({ message, data, resolve }) => {
    const inputTitle = useInput(data.text);
    const onUpdate = () => {
        resolve({
            action: "update",
            text: inputTitle.value,
        });
        closeModal();
    };

    const onDelete = () => {
        resolve({
            action: "delete",
        });
        closeModal();
    };

    const onCancel = () => {
        resolve(false);
        closeModal();
    };

    return (
        <div className="edit-option">
            <h4 className="txt -center">Filter Edit</h4>
            <div className="input-group">
                <Input name="Name" label="Filter Name" {...inputTitle.bind} />
                <button className={classNames("button -secondary -small", { "-disabled": inputTitle.value === message })} onClick={onUpdate}>
                    Save
                </button>
            </div>
            <br />
            <br />
            <div className="background-line -center -gray -darker">
                <span className="txt -compressed -uppercase">or</span>
            </div>
            <br />
            <div className="button-group -center">
                <button className="button -text" onClick={onDelete}>
                    Delete filter
                </button>
            </div>
            <div id="action-buttons" className="button-group -center">
                <button className="button -small -center -border" onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default EditOptionDialog;
