import React, { useEffect, useState } from "react";

import ProfileIcon from "$components/profile-icon";
import StatWithChange from "$components/dashboard/components/stat";
import ScoreBubble from "$components/score-bubble";

import { checkIsHealthy } from "$utils/health-helper";
import { getTerm } from "$stores/dictionary";
import { capitalizeFirstLetter } from "$utils/text-helpers";

import "./team-dashboard.scss";

const TeamSharedDashboard = ({ employee }) => {
    return (
        <div className="team-dashboard">
            <div className="client-logo">
                <ProfileIcon imageUrl={null} isActive={employee.isActive} name={employee?.name} modifierClassNames={["-client", "-medium"]} />
                <h2>
                    {employee?.name}
                    <span>{employee?.title}</span>
                </h2>
            </div>
            <div className="divide"></div>
            {!!employee?.clientGroupCount && (
                <>
                    <div>
                        <StatWithChange
                            label={`${getTerm("parentClients")} as ${getTerm("parentCrm")}`}
                            tooltip={`${getTerm("parentClients")} where the ${getTerm("teamMember").toLowerCase()} is the ${getTerm("parentCrm")}`}
                            preSup=""
                            value={employee.clientGroupCount}
                        />
                    </div>
                    <div className="divide"></div>
                </>
            )}
            {!!employee?.clientManagerCount && (
                <>
                    <div>
                        <StatWithChange
                            label={`${getTerm("parentClients")} as ${getTerm("cm") || "Client Manager"}`}
                            tooltip={`${getTerm("parentClients")} where the ${getTerm("teamMember").toLowerCase()} is a ${getTerm("cm")}`}
                            preSup=""
                            value={employee.clientManagerCount}
                        />
                    </div>
                    <div className="divide"></div>
                </>
            )}
            {!!employee?.clientCount && (
                <>
                    <div>
                        <StatWithChange
                            label={`${getTerm("subClients")} as ${getTerm("subCrm")}`}
                            tooltip={`${getTerm("subClients")} where the ${getTerm("teamMember").toLowerCase()} is the ${getTerm("subCrm")}`}
                            preSup=""
                            value={employee.clientCount}
                        />
                    </div>
                    <div className="divide"></div>
                </>
            )}
            {!!employee?.projectCount && (
                <>
                    <div>
                        <StatWithChange
                            label={`${getTerm("existingProjects")} as ${getTerm("projectPIC")}`}
                            tooltip={`${getTerm("existingProjects")} where the ${getTerm("teamMember").toLowerCase()} is the ${getTerm("projectPIC")}`}
                            preSup=""
                            value={employee.projectCount}
                        />
                    </div>
                    <div className="divide"></div>
                </>
            )}
            <div className="stat-group">
                <div>
                    <ScoreBubble
                        graph={true}
                        value={employee?.averageClientScore || "N/A"}
                        healthy={checkIsHealthy(employee?.averageClientScore)}
                        percent={(employee?.averageClientScore / 700) * 100}
                        modifierClassNames={["-small"]}
                    />
                    <h4 className="txt -center">Average {capitalizeFirstLetter(getTerm("client"))} Health</h4>
                </div>
            </div>
        </div>
    );
};

export default TeamSharedDashboard;
