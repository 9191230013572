import React, { useEffect, useState } from "react";

import Flags from "$components/flags";
import ProfileIcon from "$components/profile-icon";
import ProbabilityBubble from "$components/probability";
import StatWithChange from "$components/dashboard/components/stat";
import ScoreBubble from "$components/score-bubble";

import { AppContextStore } from "$stores/app-context";
import { formatDate, SHORT_DATE_FORMAT } from "$utils/date-helpers";
import { checkIsHealthy } from "$utils/health-helper";

import "./client-dashboard.scss";
import { getTerm } from "$stores/dictionary";

const ClientSharedDashboard = ({ client }) => {
    const lastDataUpdate = AppContextStore.useState((f) => f.versions.lastModelRun);

    return (
        <div className="client-dashboard">
            <Flags data={[{ classnames: { "-independence": client?.requiresIndependence }, label: "Check Independence" }]} />
            <div className="client-logo">
                <ProfileIcon imageUrl={null} name={client?.name} modifierClassNames={["-client", "-medium"]} />
                <h2>
                    {client?.name}
                    <span>Updated {formatDate(lastDataUpdate, SHORT_DATE_FORMAT)}</span>
                </h2>
            </div>
            <div className="divide"></div>
            {!isNaN(client?.twelveMonthRevenue) && (
                <StatWithChange label={getTerm("t12Revenue")} value={client.twelveMonthRevenue} increase={Math.round(client.twelveMonthRevenueChangePercent)} tooltip={getTerm("tooltip12MonthRevenue")} isHealthy={true} />
            )}
            <div className="divide"></div>
            {!isNaN(client?.growthPotential) && (
                <StatWithChange label={getTerm("growthPotential")} value={client.growthPotential} increase={Math.round(client.growthPotentialChangePercent)} tooltip={getTerm("tooltipGrowthPotential")} />
            )}
            <div className="divide"></div>
            {!isNaN(client.revenueAtRisk) && <StatWithChange label={getTerm("revenueAtRisk")} value={client.revenueAtRisk} increase={undefined} />}
            <div className="divide"></div>
            <div className="stat-group">
                <div>
                    <ScoreBubble graph={true} value={client?.healthScore} healthy={checkIsHealthy(client?.healthScore)} percent={(client?.healthScore / 700) * 100} modifierClassNames={["-small"]} />
                    <h4 className="txt -center">Health</h4>
                </div>
                &nbsp; &nbsp;
                <div>
                    <ProbabilityBubble value={client.sviScore * 10} cssClasses={["-importance"]} />
                    <h4 className="txt -center">SVI</h4>
                </div>
            </div>
        </div>
    );
};

export default ClientSharedDashboard;
