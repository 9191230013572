import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

const MainNavItem = ({ closeDropdown, hasActivePath, label, path, trackEvent }) => {
    return (
        <li className={classnames({ "-selected": hasActivePath })}>
            <Link
                to={path}
                onClick={() => {
                    if (closeDropdown) {
                        closeDropdown();
                    }
                    if (trackEvent) {
                        trackEvent(label);
                    }
                }}
            >
                {label}
            </Link>
        </li>
    );
};

export default MainNavItem;
