import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import _ from "lodash";

import Button from "$components/button";
import DisplayItem from "./components/display-item";
import Input from "../inputs/input";
import SelectItem from "./components/select-item";
import MoreMessage from "./components/more-message";

import { checkMultiMatch } from "$utils/check-match";
import { mapByKey } from "$utils/mapping";
import { KEYCODE } from "$utils/keycodes";
import { extractValue } from "../libs/helpers";

import "./typeahead.scss";

const DEFAULT_OPTION_DATA = {
    options: [],
    optionsByKey: {},
};

const Typeahead = ({ apiLoading, cssClass, label, name = "", value, options, selectedOptions = [], updateOnChange, optionLookup, placeholder, showSelectAndDeselectAll = true }) => {
    var hideTimer = null;
    const appInsights = useAppInsightsContext();
    const inputRef = useRef();
    const dropdownRef = useRef();
    const [optionData, setOptionData] = useState(DEFAULT_OPTION_DATA);
    const [values, setValues] = useState(value || []);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([options]);
    const [activeIndex, setActiveIndex] = useState(-1);

    useEffect(() => {
        const isMatch = checkMultiMatch(value, values);
        if (!isMatch) {
            setValues(value || []);
        }
    }, [value]);

    useEffect(() => {
        if (options === null) {
            setOptionData(DEFAULT_OPTION_DATA);
            //setIsLoading(false);
        } else {
            setOptionData({
                options: options,
                optionsByKey: mapByKey(options, "value"),
            });
            //setIsLoading(false);
        }
    }, [options]);

    /*useEffect(() => {
        if (!isOpen) {
            setInputValue("");
            if (optionLookup) {
                optionLookup("");
            }
        }
    }, [isOpen, optionLookup]);*/

    useEffect(() => {
        if (typeof apiLoading === "boolean" && apiLoading != isLoading) {
            setIsLoading(apiLoading);
        }
    }, [apiLoading]);

    useEffect(() => {
        setIsOpen(inputValue.length > 0);
    }, [inputValue]);

    useEffect(() => {
        setFilteredOptions(optionData.options.filter((o) => o.text.toLowerCase().includes(inputValue.toLocaleLowerCase())));
        /*if (!optionLookup) {
            setIsLoading(false);
        }*/
    }, [inputValue, optionData]);

    const updateValue = (item, isAdd) => {
        var newValues = isAdd ? [...values, { ...item }] : values.filter((v) => extractValue(v) !== extractValue(item));
        setValues(newValues);
        if (updateOnChange) {
            updateOnChange(name, newValues);
        }
    };

    const mouseEnter = (e) => {
        clearTimeout(hideTimer);
    };

    const mouseLeave = (e) => {
        clearTimeout(hideTimer);
        if (!isLoading) {
            hideTimer = setTimeout(() => {
                setIsOpen(false);
            }, 400);
        }
    };

    const onChange = async (e) => {
        const value = e.currentTarget.value;
        setIsLoading(true);
        if (optionLookup) {
            optionLookup(value);
        }
        setInputValue(value);
    };

    const handleKeyDown = useCallback(
        (event) => {
            switch (event.keyCode) {
                case KEYCODE.TAB:
                    setIsOpen(false);
                    break;
                case KEYCODE.RETURN:
                    const option = filteredOptions[activeIndex];
                    if (option && dropdownRef && dropdownRef.current) {
                        var isSelected = values.some((v) => extractValue(v) === extractValue(option));
                        if (option.options) {
                            option.options.forEach((subItem) => {
                                if (values.some((v) => extractValue(v) === extractValue(subItem))) {
                                    isSelected = true;
                                }
                            });
                        }
                        updateValue(option, !isSelected);
                    }
                    break;
                case KEYCODE.ESC:
                    setIsOpen(false);
                    setActiveIndex(-1);
                    break;
                case KEYCODE.UP:
                    setIsOpen(true);
                    if (activeIndex > 0) {
                        setActiveIndex(activeIndex - 1);
                    } else {
                        setActiveIndex(filteredOptions.length - 1);
                    }
                    break;
                case KEYCODE.DOWN:
                    setIsOpen(true);

                    if (activeIndex < filteredOptions.length - 1) {
                        setActiveIndex(activeIndex + 1);
                    } else {
                        setActiveIndex(0);
                    }
                    break;
                default:
                    break;
            }
        },
        [values, options, activeIndex]
    );

    const arrowClick = () => {
        if (inputValue) {
            setInputValue("");
        }
        if (inputRef) {
            inputRef.current.querySelector("input").focus();
        }
        setIsOpen(!isOpen);
    };

    const selectAll = () => {
        const useOptions = filteredOptions ? filteredOptions : optionData.options;
        const newValues = _.uniqBy(
            useOptions.reduce(
                (arr, o) => {
                    if (o.options) {
                        const subOptions = o.options.map((so) => so.value);
                        return [...arr, ...subOptions];
                    } else {
                        arr.push({
                            value: o.value,
                            text: o.text,
                        });
                        return arr;
                    }
                },
                [...values]
            ),
            "value"
        );
        setValues(newValues);
        if (updateOnChange) {
            updateOnChange(name, newValues);
        }
        if (appInsights) {
            appInsights.trackEvent(
                { name: "Picklist" },
                {
                    label: label,
                    action: "Select All",
                }
            );
        }
    };

    const deselectAll = () => {
        setValues([]);

        if (updateOnChange) {
            updateOnChange(name, []);
        }

        if (appInsights) {
            appInsights.trackEvent(
                { name: "Picklist" },
                {
                    label: label,
                    action: "Deselect All",
                }
            );
        }
    };

    const items = filteredOptions
        ? filteredOptions.map((item, index) => {
              const isActiveIndex = activeIndex === index ? true : false;
              return <SelectItem key={`options_${item?.value}`} item={item} isActiveIndex={isActiveIndex} values={values} updateValues={updateValue} />;
          })
        : null;
    const selectedItems = values
        ? values.map((item, index) => {
              return <DisplayItem key={`selected_${item?.value}`} item={item} updateValues={updateValue} />;
          })
        : [];
    const icon = inputValue ? faTimes : faSearch;

    return (
        <div className={classnames("typeahead", { [cssClass]: cssClass })} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            <div className="input" ref={inputRef}>
                {label ? (
                    <label htmlFor={`typeahead_${name}`}>{label}:</label>
                ) : (
                    <label htmlFor={`typeahead_${name}`}>
                        <FontAwesomeIcon icon={faSearch} />
                    </label>
                )}
                <Input
                    id={`typeahead_${name}`}
                    autoComplete="off"
                    onChange={onChange}
                    value={inputValue}
                    onClick={() => {
                        if (!isOpen) {
                            setIsOpen(selectedItems.length > 0 || selectedOptions.length > 0 || inputValue.length > 0);
                        }
                    }}
                    tabIndex="0"
                    onKeyDown={handleKeyDown}
                    onFocus={() => {
                        setIsOpen(selectedItems.length > 0 || selectedOptions.length > 0 || values.length > 0 || (!optionLookup && filteredOptions.length > 0));
                    }}
                    placeholder={values.length > 0 ? `${values.length} Selected` : placeholder || "Search..."}
                />
                <div className="arrow" onClick={arrowClick}>
                    <FontAwesomeIcon icon={icon} />
                </div>
            </div>
            <div ref={dropdownRef} className={classnames("dropdown", { "-open": isOpen })}>
                {isLoading && <div className="typeahead-message">Searching...</div>}
                {!isLoading && items.length === 0 && inputValue.length > 0 && <div className="typeahead-message">No Results</div>}
                {items.length > 0 && <ul>{items}</ul>}
                {filteredOptions.length > 0 && filteredOptions[0]?.count > filteredOptions.length && <MoreMessage moreCount={filteredOptions[0]?.count - filteredOptions.length} />}
                {selectedItems.length > 0 && items.length > 0 && (
                    <div className="background-line -darker">
                        <span>Selected:</span>
                    </div>
                )}
                {selectedItems.length > 0 && <ul>{selectedItems}</ul>}
                {showSelectAndDeselectAll && (selectedItems.length > 0 || optionData.options.length > 0 || values.length > 0) && (
                    <div className="button-group">
                        <Button className="button -small -dark" onClick={selectAll}>
                            Select All
                        </Button>
                        <Button className="button -small -dark" onClick={deselectAll}>
                            Deselect All
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Typeahead;
