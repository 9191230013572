import React from "react";
import { getTerm } from "$stores/dictionary";

const HelpTeamExperience = () => {
    return (
        <div className="help-section">
            <p>
                The Experience tab is a list of all the {getTerm("potentialProjects")} where the individual has been identified by Orgaimi as an experienced {getTerm("teamMember")} to staff said{" "}
                {getTerm("project").toLowerCase()}, allowing someone to see the places where Orgaimi has suggested them.
            </p>
        </div>
    );
};

export default HelpTeamExperience;
