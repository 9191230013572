import { useDocumentTitle } from "$utils/document-title";
import React from "react";

const AdminIndex = () => {
    useDocumentTitle("Admin");
    return (
        <div>
            <h1>Admin Coming Soon</h1>
        </div>
    );
};

export default AdminIndex;
