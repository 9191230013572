import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { dictionary, initDictionary, resetDictionary, updateDictionary } from "$stores/dictionary";

import DictionaryItem from "./components/dictionary-item";

import "./dictionary.scss";
import classNames from "classnames";

const DictionaryAdmin = () => {
    const [newDictionary, setNewDictionary] = React.useState([]);
    const [changes, setChanges] = React.useState([]);
    const [isSaving, setIsSaving] = React.useState(false);
    const [showChanges, setShowChanges] = React.useState(true);

    useEffect(() => {
        if (dictionary) {
            setNewDictionary(Object.keys(dictionary).map((key) => ({ key: key, value: dictionary[key] })));
        }
    }, [dictionary]);

    const save = async () => {
        setIsSaving(true);
        const changesToSave = changes.reduce((acc, change) => ({ ...acc, [change.key]: { term: change.value } }), {});
        await updateDictionary(changesToSave);
        await resetDictionary();
        await initDictionary();
        setChanges([]);
        setIsSaving(false);
    };

    return (
        <div>
            <h1>Dictionary Admin</h1>
            <div className="dictionary-edit">
                <div>
                    {newDictionary.map((item) => {
                        return (
                            <DictionaryItem
                                key={item.key}
                                item={item}
                                onChange={(key, value) => {
                                    setNewDictionary(
                                        newDictionary.map((item) => {
                                            if (item.key === key) {
                                                return { key: key, value: value };
                                            } else {
                                                return item;
                                            }
                                        })
                                    );
                                    const change = changes.find((c) => c.key === key);
                                    if (change) {
                                        change.value = value;
                                    } else {
                                        setChanges([...changes, { key: key, value: value, originalValue: dictionary[key] }]);
                                    }
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <article id="dictionary-changes" className={classNames({ "-open": showChanges })}>
                {changes.length > 0 && (
                    <>
                        <div className="flex-header">
                            <button
                                className="button -text"
                                onClick={() => {
                                    setShowChanges(!showChanges);
                                }}
                            >
                                <FontAwesomeIcon icon={faChevronDown} />
                            </button>
                            <h3>Changes {changes.length > 0 ? `(${changes.length})` : ""}</h3>
                            <button className="button -secondary -small" onClick={save} disabled={isSaving}>
                                Save
                            </button>
                        </div>

                        <div className="change-wrapper">
                            <div className="change-list">
                                {changes.map((change) => {
                                    return (
                                        <div key={`change_${change.key}`} className="change-item">
                                            <b>{change.key}</b>:<br />
                                            {change.originalValue || "::blank::"} <span>--&gt;</span> {change.value}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                )}
            </article>
        </div>
    );
};

export default DictionaryAdmin;
