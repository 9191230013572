import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";

import Select from "$components/form-inputs/select";

import { getTerm } from "$stores/dictionary";
import { Textarea } from "$components/form-inputs/inputs";
import { useInput } from "$utils/hooks/use-input";
import { getFeebackOptions } from "$components/feedback/lib/data";
import { closeModal } from "$stores/modal";

const FEEDBACK_CHARACTER_LIMIT = 250;

const FeebackDialog = ({ message, resolve, reject }) => {
    const [feedbackOptions, setFeedbackOptions] = useState([]);
    const reasonSelect = useInput("");
    const additionalInput = useInput("");

    useEffect(() => {
        const init = async () => {
            const results = await getFeebackOptions();
            setFeedbackOptions(results || []);
        };

        init();
    }, []);

    const sendFeedback = async () => {
        if (!reasonSelect.value) {
            toast("Please Select A Reason");
            return false;
        }
        if (additionalInput.value.length > FEEDBACK_CHARACTER_LIMIT) {
            toast("Additional Information Is too Long");
            return false;
        }
        const payload = {
            reason: reasonSelect.value.text,
            feedback: additionalInput.value,
        };

        resolve(payload);
        closeModal();
    };

    const onCancel = () => {
        resolve(false);
        closeModal();
    };

    const characterCount = additionalInput.value.length;
    const charactersLeft = FEEDBACK_CHARACTER_LIMIT - characterCount;

    return (
        <div className="feedback">
            <h5>Feedback</h5>
            <p>Please let us know the issue or concern with these {getTerm("potentialProjects").toLowerCase()}.</p>
            <div>
                <Select label="Reason*" options={feedbackOptions} {...reasonSelect.bind} defaultValue={reasonSelect.value} />
            </div>

            <Textarea placeholder="Additional Information" {...additionalInput.bind}></Textarea>
            <div className="button-group -right">
                <div>
                    <span className="txt -small -detail-dark">* Required</span>
                    {characterCount >= FEEDBACK_CHARACTER_LIMIT - 100 && (
                        <div className={classNames("txt -small", { "-error": charactersLeft < 0 })}>{`${FEEDBACK_CHARACTER_LIMIT - additionalInput.value.length} characters remaining`}</div>
                    )}
                </div>
                <button className="button -small" onClick={onCancel}>
                    Cancel
                </button>
                <button className="button -primary -small" onClick={sendFeedback}>
                    Send
                </button>
            </div>
        </div>
    );
};

export default FeebackDialog;
