import { sortByKey } from "$utils/mapping";
import { Store } from "pullstate";
import { dictionary } from "./dictionary";
import { toast } from "react-toastify";

const initialState = {
    pageCount: 0,
    pageIndex: 0,
    perPage: 10,
    selectedSubClient: null,
    subClients: [],
    sortAsc: false,
    sortBy: { text: "Portion of Revenue", value: "portionOfRevenue", isAsc: false },
};

export const SubclientStore = new Store(initialState);

export const sortSubClients = (subClients) => {
    SubclientStore.update((s) => {
        s.subClients = [...subClients].sort((a, b) => sortByKey(a, b, s.sortBy?.value, s.sortAsc));
        s.pageCount = Math.ceil(subClients.length / s.perPage);
        return s;
    });
};

export const setSelectedSubclient = (subClientId, label) => {
    return SubclientStore.update((s) => {
        // Find Page, Set Page
        const scIndex = s.subClients.map((s) => s.id).indexOf(subClientId);
        if (scIndex < 0) {
            toast(`${label ? label : dictionary["subClient"]} not found`);
        } else {
            s.pageIndex = Math.floor((scIndex + 2) / s.perPage);
            s.selectedSubClient = subClientId;
            return s;
        }
    });
};

export const setPageIndex = (p) => {
    SubclientStore.update((s) => {
        s.pageIndex = p;
        return s;
    });
};

export const setSortBy = (sort, asc) => {
    SubclientStore.update((s) => {
        s.sortBy = sort;
        s.sortAsc = asc;
        s.subClients = [...s.subClients].sort((a, b) => sortByKey(a, b, sort?.value, asc));
        return s;
    });
};

export const setPerPage = (pp) => {
    SubclientStore.update((s) => {
        s.perPage = pp;
        s.pageCount = Math.ceil(s.subClients.length / pp);
        return s;
    });
};
