import React, { useEffect, useState } from "react";
import { AppContextStore, initSiteSettings } from "../../../stores/app-context";

import { clearValue, hardPresistValue, retrieveValue } from "$services/local";
import { DICTIONARY_DATA_KEY } from "$stores/dictionary";

const AppVersion = () => {
    const [_version, setVersion] = useState("");
    const version = AppContextStore.useState((v) => v.versions);

    useEffect(() => {
        const getVersion = async () => {
            const REQUIRE_TERMINATOR = ""; // Confuses Webpack when using `require` on a file that may not be there so it doesn't throw an error
            let versionJson;
            try {
                versionJson = require(`../../../_version.json${REQUIRE_TERMINATOR}`);
            } catch (e) {
                // Dev Environment Defaults
                versionJson = {
                    CloudBuildNumber: "0.0.0-alpha",
                };
            }
            setVersion(versionJson);
        };
        getVersion();
    }, []);

    useEffect(() => {
        const checkVersion = async () => {
            if (version && _version && version.hasOwnProperty("apiVersion")) {
                const storedVersion = await retrieveValue("version");
                // Update dictionary if new build exists
                const currentVersion = version?.apiVersion + version?.lastETLRun?.toString() + _version?.CloudBuildNumber;
                if (storedVersion !== currentVersion) {
                    clearValue(DICTIONARY_DATA_KEY);
                    //await initSiteSettings();

                    hardPresistValue("version", currentVersion);
                    location.reload();
                }
            }
        };
        checkVersion();
    }, [version, _version]);

    if (!_version) {
        return null;
    }

    const appVersion = `v${_version.CloudBuildNumber}`;

    return (
        <div className="build-number">
            APP: {appVersion} | API: {version.apiVersion}
        </div>
    );
};

export default AppVersion;
