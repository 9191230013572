import { Store } from "pullstate";
import debounce from "debounce";
import api from "../services/api";
import { buildFilters } from "../utils/api-helpers";
import { updateGlobalError } from "./app-context";
import logger from "../services/logger";
import { buildTop5Titles } from "src/pages/segments/lib/constants";
import { getTerm } from "./dictionary";

const initialState = {
    segments: [],
    isLoading: false,
    segmentGroups: [],
    chart: [],
    top5: {
        ...Object.keys(buildTop5Titles()).reduce((obj, t) => {
            obj[t] = t.data;
            return obj;
        }, {}),
    },
};

let queryFilters = {};
let cancelRequest = null;

export const SegmentStore = new Store(initialState);

export const fetchSegmentGroups = async () => {
    return SegmentStore.update((s) => {
        s.segmentGroups = [
            { key: "1", label: getTerm("industry") },
            { key: "2", label: getTerm("serviceLine") },
            //{ key: "5", label: "Region" },
            //{ key: "3", label: "Office" },
        ];
        return s;
    });
};

// TODO: This is Deprecated
export const fetchSegments = async (metaDataType, filters = {}) => {
    //const res = api.get(`/segments/metaDataType?${buildFilters(filters)}`);

    queryFilters = filters;
    debouncedSearchApi({ ...queryFilters });
    SegmentStore.update((s) => {
        if (!s.isLoading) {
            s.isLoading = true;
        }
        return s;
    });
};

const formatTop5Data = (data) => {
    const segmentSummary = data?.segmentSummary || [];
    return segmentSummary.reduce(
        (memo, s) => {
            memo[s.summaryKey] = s.data;
            return memo;
        },
        { ...initialState.top5 }
    );
};

const apiSegmentChart = async (segmentGroup, filters, apiSettings) => {
    try {
        const res = await api.get(`/api/segments/v2/${segmentGroup}?${buildFilters(filters)}`, null, apiSettings);
        apiFetchTop5(segmentGroup, "", filters, apiSettings);
        const minMax = res.chart.reduce(
            (obj, c) => {
                if (obj.min > c.revenueAtRisk) {
                    obj.min = c.revenueAtRisk;
                }
                if (obj.max < c.growth) {
                    obj.max = c.growth;
                }
                return obj;
            },
            { min: 0, max: 100 }
        );
        SegmentStore.update((s) => {
            s.chart = res.chart;
            s.chartYDomain = [minMax.min, minMax.max];
            return s;
        });
    } catch (e) {
        logger.error("Unable to fetch segment chart", e);
    }
};

export function fetchSegmentChart(segmentGroup, filters = {}) {
    const abortController = new AbortController();
    apiSegmentChart(segmentGroup, filters, { signal: abortController.signal });
    return abortController;
}

const apiFetchTop5 = async (segmentGroup, segment = "", filters = {}, apiSettings) => {
    try {
        const res = await api.get(`/api/segments/v2/${segmentGroup || 1}/top/${segment}?${buildFilters(filters)}`, null, apiSettings);
        const formattedData = formatTop5Data(res);
        SegmentStore.update((s) => {
            s.top5 = Object.keys(initialState.top5).reduce((obj, t) => {
                obj[t] = formattedData[t] || [];
                return obj;
            }, {});
            return s;
        });
    } catch (error) {
        logger.error(error);
    }
};

export const fetchTop5 = (segmentGroup, segment = "", filters = {}) => {
    const abortController = new AbortController();
    apiFetchTop5(segmentGroup, segment, filters, { signal: abortController.signal });
    return abortController;
};

export const resetTop5 = () => {
    SegmentStore.update((s) => {
        return {
            ...s,
            top5: { ...initialState.top5 },
        };
    });
};

export const setLoading = () => {
    SegmentStore.update((s) => {
        s.isLoading = true;
        return s;
    });
};

export const resetSegments = () => {
    SegmentStore.update((s) => {
        return initialState;
    });
};

const fetchSegmentsCall = async (filters) => {
    try {
        const result = await api.getFromCache(`/api/segments?${buildFilters(filters)}`);
        SegmentStore.update((s) => {
            s.segments = result.segments;
            s.isLoading = false;
            return s;
        });
    } catch (e) {
        if (api.requestWasCanceled(e)) {
            return;
        }
        updateGlobalError("Unable to fetch segments");
        logger.error("Unable to fetch segments", e);
    }
};

const debouncedSearchApi = debounce(fetchSegmentsCall, 1000);
