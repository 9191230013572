import React from "react";

const DisplayItem = ({ item, updateValues }) => {
    return (
        <li>
            <a
                href="#deselectItem"
                className="-active"
                tabIndex="-1"
                //aria-selected={isActiveIndex}
                role="option"
                onClick={() => {
                    updateValues(item, false);
                }}
            >
                <span className="checkmark"></span>
                {item.text}
            </a>
        </li>
    );
};

export default DisplayItem;
