import React, { useEffect, useState } from "react";

import { UserStore } from "$stores/user";
import { buildQuestions } from "src/pages/dashboard/lib/wizards";

/*
type: null,
        primaryDisplay: null, // Sort Metric
        displayMetrics: [],
        template: null,
        sortAsc: true,
        filters: [],
*/

import "./widget-wizard.scss";

const Wizard = ({ isOpen, onComplete, ReviewComp }) => {
    const [filters, setFilters] = useState({});
    //const [sort, setSort] = useState({});
    //const [dispalyMetrics, setDisplayMetrics] = useState([]);
    const [finalQuestion, setFinalQuestion] = useState(false);
    const [questionHistory, setQuestionsHistory] = useState(buildQuestions());
    const [answers, setAnswers] = useState([]);
    const user = UserStore.useState((s) => s);

    useEffect(() => {
        if (!isOpen) {
            setFinalQuestion(false);
            setAnswers([]);
            setFilters([]);
            setQuestionsHistory(buildQuestions());
        }
    }, [isOpen]);

    useEffect(() => {
        // Hande auto-select of next answer if only one option
        const lastAnswer = answers.length - 1;
        if (answers[lastAnswer]?.followUp && answers[lastAnswer].followUp.options.length === 1) {
            handleSelect(answers[lastAnswer].followUp.options[0], questionHistory.length);
        }
    }, [answers]);

    const handleSelect = (answer, questionIndex) => {
        if (answers.length - 1 === questionIndex) {
            const newAnswers = [...answers];
            newAnswers.splice(questionIndex, 1, answer);
            setAnswers(newAnswers);
        } else {
            const newAnswers = [...answers];
            newAnswers.splice(questionIndex);
            newAnswers.push(answer);
            setAnswers(newAnswers);
        }

        if (answer && answer.hasOwnProperty("followUp")) {
            const newQuestions = [...questionHistory];
            newQuestions.splice(questionIndex + 1);
            setQuestionsHistory([...newQuestions, answer.followUp]);
        }

        if (answer && !answer.hasOwnProperty("followUp")) {
            // End
            setFinalQuestion(true);
        }
    };

    const onFilterUpdate = (key, value) => {
        const newFilters = { ...filters };
        if (value) {
            newFilters[key] = value;
        } else {
            delete newFilters[key];
        }

        setFilters(newFilters);
    };

    return (
        <div className="wizard">
            {questionHistory.map((a, i) => {
                const Comp = questionHistory[i].Comp;
                return (
                    <Comp
                        key={`question_${i}`}
                        title={a.title}
                        activeIndex={answers.length}
                        finalQuestion={finalQuestion}
                        questionIndex={i}
                        options={questionHistory[i].options}
                        answer={answers[i]}
                        handleSelect={handleSelect}
                    />
                );
            })}
            {finalQuestion && <ReviewComp answers={answers} onComplete={onComplete} updateFilters={onFilterUpdate} filters={filters} />}
        </div>
    );
};

export default Wizard;
