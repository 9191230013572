import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import debounce from "debounce";
import _ from "lodash";

import Breadcrumb from "$components/breadcrumb";
import NoData from "$components/charts/no-content";
import ShadowTable from "$components/loaders/shadow-table";
import TeamNav from "./components/team-nav";
import TeamSharedDashboard from "./components/shared-dashboard";

import { EmployeeDetailsStore, fetchEmployee, resetEmployee } from "$stores/employee-details";
import { fetchChartsStore, resetCharts } from "$stores/charts";
import { handleAbort } from "$services/api";
import { teamDetailCharts } from "../clients/lib/chart-helper";
import { fetchClientsManaged, reset as resetClients } from "$stores/managed-clients-list";
import { fetchClientGroupsManaged, reset as resetClientGroups } from "$stores/managed-client-groups-list";
import { fetchProjectsManaged, reset as resetProjects } from "$stores/managed-projects-list";
import { fetchClientGroupsCMManaged, reset as resetClientGroupsCM } from "$stores/managed-client-groups-cm-list.js";

import "./team.scss";

const debounceFetch = debounce((crmFilter) => {
    // TODO: This could be moved to the managed page and will need to be if we have filters on that page -CT 3/19/2024
    fetchClientsManaged(crmFilter);
    fetchClientGroupsManaged(crmFilter);
    fetchProjectsManaged({ projectPerson: crmFilter.crm });
    //fetchClientGroupsCMManaged({ clientManager: crmFilter.crm });
}, 500);

const TeamDetailLayout = () => {
    const { employeeId } = useParams();
    const employeeDetails = EmployeeDetailsStore.useState((s) => s.employeeDetails);
    const breadcrumbLinks = [
        { text: "Team", url: "/team" },
        { text: employeeDetails?.name, url: `/team/${employeeId}` },
    ];

    useEffect(() => {
        return () => {
            resetEmployee();
            resetCharts();
            resetClientGroups();
            resetClientGroupsCM();
            resetClients();
            resetProjects();
        };
    }, []);

    useEffect(() => {
        const controllerEmployee = fetchEmployee(employeeId);
        const controllerCharts = fetchChartsStore({ employee: employeeId }, teamDetailCharts);
        const crmFilter = { crm: employeeId };
        debounceFetch(crmFilter);

        return () => {
            handleAbort([controllerEmployee, controllerCharts]);
        };
    }, [employeeId]);

    return (
        <div className="page-container">
            <div id="team" className="page">
                <Breadcrumb links={breadcrumbLinks} />
                <div className="main">
                    {_.isNull(employeeDetails) && <NoData message="No User Available" />}
                    {_.isArray(employeeDetails) && employeeDetails.length <= 0 && <ShadowTable />}
                    {employeeDetails?.name && (
                        <main>
                            <div className="main-wrapper">
                                <div className="page-content -shadow">
                                    <div className="panel-content">
                                        <TeamNav employeeId={employeeId} />
                                        <TeamSharedDashboard employee={employeeDetails} />
                                        <div className="outlet">
                                            <Outlet />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TeamDetailLayout;
