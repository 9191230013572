import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList, ResponsiveContainer } from "recharts";

import { BarBubbleTooltip } from "../../../components/charts/tooltips/bar-bubble";
import { GRID_STROKE } from "$components/charts/svg-definitions";

const PotentialProjects = ({ data }) => {
    return (
        <ResponsiveContainer width="99%" height="100%">
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 5,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke={GRID_STROKE} vertical={false} />
                <XAxis dataKey="name" />
                <YAxis
                    domain={[0, 15]}
                    allowDataOverflow={true}
                    tickCount={5}
                    label={{ value: "Project Count", angle: -90, fill: "#f3f3f3", position: "insideLeft", offset: 16, style: { textAnchor: "middle" } }}
                    style={{ fill: "#f3f3f3" }}
                />
                <Bar dataKey="count" fill="#de653a" minPointSize={5} barSize={10}>
                    <LabelList dataKey="count" content={BarBubbleTooltip} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default PotentialProjects;
