import PaginatedStore from "./base/PaginatedStore";

export const DEFAULT_SORT = { name: "asc" };
const EMPLOYEE_STORAGE_KEY = "/api/projects/existing";

const { store, fetchList, setFilters, setLoading, reset, setPageIndex, setPerPage, setSort, watchPagination } = PaginatedStore({
    key: "employeeExistingProjects",
    label: "Employee Existing Projects",
    apiListUrl: EMPLOYEE_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    storageKey: "employee_" + EMPLOYEE_STORAGE_KEY,
});

export const EmployeeExistingProjectsStore = store;

export const fetchEmployeeExistingProjects = fetchList;

export { setLoading, reset, setFilters, setPageIndex, setPerPage, setSort, watchPagination };
