import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Breadcrumb from "../../components/breadcrumb";
import ExistingProject from "./existing/detail";
import PotentialProject from "./potential/detail";
import ShadowTable from "../../components/loaders/shadow-table";

import { ProjectDetailsStore, fetchProject, reset as resetCurrentProject } from "../../stores/projectDetails";
import { handleAbort } from "$services/api";
import { scrollToPos } from "$utils/scroll-to";
import { getTerm } from "$stores/dictionary";

import "./project.scss";

const ProjectDetail = () => {
    const { projectId, projectType } = useParams();
    const { currentProject, isLoading } = ProjectDetailsStore.useState((s) => {
        return { currentProject: s.projectDetails, isLoading: s.isLoading };
    });

    useEffect(() => {
        scrollToPos(0);

        return () => {
            resetCurrentProject();
        };
    }, []);

    useEffect(() => {
        const controllerProject = fetchProject(projectId, projectType);

        return () => {
            handleAbort([controllerProject]);
        };
    }, [projectId, projectType]);

    // Unneeded if we aren't showing the filter stats/overview
    /*useEffect(() => {
        const clientId = currentProject?.clientId;
        if (clientId) {
            fetchOverview({ client: [clientId] });
        }
    }, [currentProject]);*/

    const breadcrumbLinks = [
        { text: getTerm("projects"), url: currentProject?.type ? `/projects/${currentProject?.type}` : "/projects" },
        { text: currentProject?.name, url: `/project/${projectId}/${currentProject?.type}` },
    ];

    return (
        <div className="page-container">
            <div id="project" className="page">
                <Breadcrumb links={breadcrumbLinks} />
                <div className="main">
                    {isLoading && <ShadowTable />}
                    {!isLoading && (
                        <main>
                            {currentProject.type !== "potential" && <ExistingProject />}
                            {currentProject.type === "potential" && <PotentialProject />}
                        </main>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectDetail;
