import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { debounce } from "lodash";

import TableLoader from "$components/table/table-loader.js";

import { buildClientGroupsManagedColumns } from "./lib/columns.js";
import { mapToTableData } from "src/pages/projects/existing/list.js";
import { getTerm } from "$stores/dictionary.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";
import { ClientGroupsCMManagedStore, fetchClientGroupsCMManaged, setFilters, setPageIndex, setPerPage, setSort } from "$stores/managed-client-groups-cm-list.js";

const debouncedFetchClientGroupsCM = debounce(async (updatedFilters, pagingInfo) => {
    fetchClientGroupsCMManaged(updatedFilters, { keyPrefix: "tableFilter.", ...pagingInfo });
}, 800);

const CMManagedClientGroups = () => {
    const { employeeId } = useParams();
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const filterMenu = AppContextStore.useState((f) => mapByKey(f.filterMenu, "apiKey") || {});
    const { clientGroups, filters, globalFilterRanges, pageIndex, pageSize, pageCount, isLoading, sort, isSortAsc } = ClientGroupsCMManagedStore.useState((c) => {
        return {
            clientGroups: c.clientGroupsManaged || [],
            filters: c.filters,
            globalFilterRanges: c.globalFilterRanges,
            pageSize: c.pageSize,
            pageIndex: c.pageIndex,
            pageCount: c.pageCount,
            isLoading: c.isLoading,
            sort: c.sort,
            isSortAsc: c.isSortAsc,
        };
    });
    const [clientGroupsManagedColumns, setClientGroupsManagedColumns] = useState([]);

    useEffect(() => {
        setClientGroupsManagedColumns(buildClientGroupsManagedColumns({ filterOptions: filterMenu, globalFilterRanges }));
    }, [filterMenu, globalFilterRanges]);

    useEffect(() => {
        debouncedFetchClientGroupsCM({ clientManager: employeeId }, { filters: filters, page: pageIndex, limit: pageSize, sort: sort, isSortAsc: isSortAsc });
    }, [filters, sort, pageIndex, isSortAsc, pageSize]);

    const rowClick = (row) => {
        navigate(`/client/${row.id}`);
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: "CM Client Groups Managed",
                section: "CM Client Groups Managing",
                projectId: row.id,
            }
        );
    };

    const data = clientGroups.map(mapToTableData);

    return (
        <div>
            <h1>
                <span data-tooltip={`${getTerm("parentClients")} where the ${getTerm("teamMember").toLowerCase()} is a ${getTerm("cm")}`}>
                    <em>{getTerm("parentClients")}</em> as {getTerm("cm") || "Client Manager"}
                </span>
            </h1>
            <TableLoader
                allowFilter={true}
                columns={clientGroupsManagedColumns}
                containerClass="table-client-groups-managed"
                data={data}
                filters={filters}
                isLoading={isLoading}
                isSortAsc={isSortAsc}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageCount={pageCount}
                setFilters={setFilters}
                setPageIndex={setPageIndex}
                setPerPage={setPerPage}
                setSort={setSort}
                sort={sort}
                onRowClick={rowClick}
            />
            <br />
        </div>
    );
};

export default CMManagedClientGroups;
