function rad2degr(rad) {
    return (rad * 180) / Math.PI;
}
function degr2rad(degr) {
    return (degr * Math.PI) / 180;
}

/**
 * @param latLngInDeg array of arrays with latitude and longtitude
 *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
 *   [longtitude2] ...]
 *
 * @return array with the center latitude longtitude pairs in
 *   degrees.
 */
export function getLatLngCenter(latLngInDegr, zoomLevel) {
    if (zoomLevel === 1) {
        return [25, -35]; // Shows the whole world centered
    }

    var LATIDX = 0;
    var LNGIDX = 1;
    var sumX = 0;
    var sumY = 0;
    var sumZ = 0;

    for (var i = 0; i < latLngInDegr.length; i++) {
        var lat1 = degr2rad(latLngInDegr[i][LATIDX]);
        var lng1 = degr2rad(latLngInDegr[i][LNGIDX]);
        // sum of cartesian coordinates
        sumX += Math.cos(lat1) * Math.cos(lng1);
        sumY += Math.cos(lat1) * Math.sin(lng1);
        sumZ += Math.sin(lat1);
    }

    var avgX = sumX / latLngInDegr.length;
    var avgY = sumY / latLngInDegr.length;
    var avgZ = sumZ / latLngInDegr.length;
    // convert average x, y, z coordinate to latitude and longtitude
    var lng = Math.atan2(avgY, avgX);
    var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    var lat = Math.atan2(avgZ, hyp);

    return [rad2degr(lat), rad2degr(lng)];
}

export function getLatLngZoom(latLongs) {
    let zoom = 3; // Default Zoom
    const lats = latLongs.map((l) => l[0]).sort();
    const latDifference = Math.abs(lats[lats.length - 1] - lats[0]);
    const lngs = latLongs.map((l) => l[1]).sort();
    const lngDifference = Math.abs(lngs[lngs.length - 1] - lngs[0]);
    if (lngDifference > 40 || latDifference > 40) {
        return 1;
    } else if (lngDifference > 15 || latDifference > 15) {
        return 2;
    }

    return zoom;
}
