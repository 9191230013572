import React from "react";
import { Area, ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

import { useChartColor, useChartUv, GRID_STROKE } from "./svg-definitions";
import { DateCurrencyTooltip } from "./tooltips/currency";
import { formatDate, SHORT_MONTH_FORMAT } from "$utils/date-helpers";
import { abbrNum } from "$utils/number-helper";

const MoneyLineChart = ({ revenueHistory, isHealthy }) => {
    const chartColor = useChartColor(isHealthy);
    const chartUv = useChartUv(isHealthy);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                width={500}
                height={300}
                data={revenueHistory}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke={GRID_STROKE} />
                <XAxis axisLine={false} dataKey="periodDate" tickFormatter={(label) => formatDate(label, SHORT_MONTH_FORMAT)} allowDuplicatedCategory={false} />
                <YAxis axisLine={false} tickFormatter={(label) => `$${abbrNum(label)}`} />
                <Tooltip content={<DateCurrencyTooltip xKey="periodDate" />} />
                <Line type="monotone" dataKey="revenue" stroke={chartColor} dot={false} strokeLinecap="round" strokeWidth={2} />
                <Area type="monotone" dataKey="revenue" stroke="false" strokeWidth={2} fillOpacity={1} fill={chartUv} tooltipType="none" />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default MoneyLineChart;
