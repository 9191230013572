const AVAILABLE_ENDPOINT_VERSIONS = {
    offices_financials: "REACT_APP_ENDPOINT_OFFICE_FINANCIALS",
    offices_summary: "REACT_APP_ENDPOINT_OFFICE_SUMMARY",
    offices_insights: "REACT_APP_ENDPOINT_OFFICE_INSIGHTS",
    offices_offices: "REACT_APP_ENDPOINT_OFFICE_OFFICES",
    clients_groups_list: "REACT_APP_ENDPOINT_CLIENTS_GROUPS_LIST",
    teams_list: "REACT_APP_ENDPOINT_TEAMS_LIST",
};

const formatEndpoint = (endpoint) => {
    if (!endpoint) {
        return "/";
    }

    return `${!endpoint.startsWith("/") ? "/" : ""}${endpoint}${!endpoint.endsWith("/") ? "/" : ""}`;
};

export const endpoints = (availableEndpointVersions) => {
    const endpoints = {};
    Object.keys(availableEndpointVersions).forEach((key) => {
        endpoints[key] = {};
        if (typeof availableEndpointVersions[key] === "string") {
            endpoints[key] = formatEndpoint(process.env[availableEndpointVersions[key]]);
        } else {
            const keys = Object.keys(availableEndpointVersions[key]);
            if (keys.length > 0) {
                keys.forEach((subKey) => {
                    endpoints[key][subKey] = formatEndpoint(process.env[availableEndpointVersions[key][subKey]]);
                });
            }
        }
    });
    return endpoints;
};

const builtEndpoints = endpoints(AVAILABLE_ENDPOINT_VERSIONS);
const endpointVersionRetrieval = (key) => {
    if (builtEndpoints[key]) {
        return builtEndpoints[key];
    }
    return "";
};

export default endpointVersionRetrieval;
