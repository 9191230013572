import React, { useContext, useState } from "react";
import classnames from "classnames";

import MultiRangeSliderDropdown from "$components/form-inputs/multi-range-slider/dropdown";
import MultiSelect from "$components/form-inputs/select/multi";
import SavedFilters from "$components/filter-menu/components/saved-filters";
import Typeahead from "$components/form-inputs/typeahead";

import { AppContextStore, bulkUpdateCurrentFilters, updateCurrentFilters } from "$stores/app-context";
import { CrmStore, search } from "$stores/crm-filter";
import { ClientFilterStore, search as clientSearch } from "$stores/client-filter";
import { ProjectPicFilterStore, search as projectPicSearch } from "$stores/project-pic-filter";
import { PanelContext } from "$contexts/panels";
import { getTerm } from "$stores/dictionary";
import { getKeyMetaData } from "src/pages/dashboard/lib/helpers";

import "./filter-menu.scss";

const FilterMenu = ({ isActive }) => {
    const [pauseClose, setPauseClose] = useState(false);
    const { closePanel, clearTimer } = useContext(PanelContext);
    const filters = AppContextStore.useState((f) => f.filterMenu.filter((f) => !f.hide) || {}); // TODO: Turn on when we work through Migrate Site Wide Filter to Client Level.
    const currentFilters = AppContextStore.useState((f) => f.currentFilters || {});
    const { crmOptions, crmLoading } = CrmStore.useState((f) => {
        return { crmOptions: f.results, crmLoading: f.isLoading };
    });

    const { clientOptions, clientLoading } = ClientFilterStore.useState((f) => {
        return { clientOptions: f.results, clientLoading: f.isLoading };
    });

    const { projectPicOptions, projectPicLoading } = ProjectPicFilterStore.useState((f) => {
        return { projectPicOptions: f.results, projectPicLoading: f.isLoading };
    });

    const cancelClose = () => {
        setPauseClose(true);
    };

    return (
        <div
            className={classnames("filter-menu", { "-active": isActive })}
            onMouseLeave={() => {
                if (!pauseClose) {
                    closePanel("filters");
                }
            }}
            onMouseEnter={() => {
                setPauseClose(false);
                clearTimer();
            }}
        >
            <div className="input-group">
                <h2>
                    <em>{getTerm("parentClient")}</em> Filters
                </h2>
                <Typeahead
                    name="filterMenuParentClient"
                    placeholder="Search..."
                    label={getTerm("parentClient")}
                    options={clientOptions}
                    value={currentFilters.client}
                    optionLookup={clientSearch}
                    apiLoading={clientLoading}
                    updateOnChange={(name, newValues) => {
                        updateCurrentFilters("client", newValues);
                    }}
                />
                <Typeahead
                    name="filterMenuCRM"
                    placeholder="Search..."
                    label={getKeyMetaData("clientPerson")?.text}
                    options={crmOptions}
                    apiLoading={crmLoading}
                    value={currentFilters.clientPerson}
                    optionLookup={search}
                    updateOnChange={(name, newValues) => {
                        updateCurrentFilters("clientPerson", newValues);
                    }}
                />
                <Typeahead
                    name="filterMenuprojectPIC"
                    placeholder="Search..."
                    label={getTerm("projectPIC")}
                    options={projectPicOptions}
                    apiLoading={projectPicLoading}
                    value={currentFilters.projectPic}
                    optionLookup={projectPicSearch}
                    updateOnChange={(name, newValues) => {
                        updateCurrentFilters("projectPic", newValues);
                    }}
                />
                {filters.map((filterItem) => {
                    return (
                        <MultiSelect
                            key={`filtermenu_${filterItem.key}`}
                            name={filterItem.key}
                            multiple={false}
                            label={filterItem.label}
                            options={[...(filterItem.values || [])]}
                            updateOnChange={((key) => (name, value) => {
                                updateCurrentFilters(key, value);
                            })(filterItem.key)}
                            value={currentFilters[filterItem.key]}
                        />
                    );
                })}
                <MultiRangeSliderDropdown
                    name="filterMenuSVIRange"
                    label="SVI Range"
                    min={1}
                    max={10}
                    defaultValue={{ min: currentFilters["sviRangeMin"] || "", max: currentFilters["sviRangeMax"] || "" }}
                    updateOnChange={(name, newValues) => {
                        if (newValues.min || newValues.max) {
                            bulkUpdateCurrentFilters({
                                sviRangeMin: newValues.min ? newValues.min.toString() : "",
                                sviRangeMax: newValues.max ? newValues.max.toString() : "",
                            });
                        } else if (!newValues) {
                            bulkUpdateCurrentFilters({
                                sviRangeMin: "",
                                sviRangeMax: "",
                            });
                        }
                    }}
                />
                <SavedFilters pauseClose={cancelClose} />
            </div>
        </div>
    );
};

export default FilterMenu;
