import React from "react";
import classnames from "classnames";

export const WithLabel = (WrappedInput) => (props) => {
    const cssClass = classnames("form-field", {
        [props.className]: !!props.className,
        "-error": !!props.invalid,
    });
    const label = props.label ? <label htmlFor={props.name || props.id}>{props.label}:</label> : null;

    return (
        <div className={cssClass}>
            {label}
            <WrappedInput {...props} />
        </div>
    );
};
