import React from "react";

const FractionValue = ({ value, valueOf }) => {
    return (
        <div>
            {value}
            <span className="txt-white">/{valueOf}</span>
        </div>
    );
};

export default FractionValue;
