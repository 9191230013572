import React from "react";
import { format } from "date-fns";
import { buildDateFromString, SLASH_DATE_FORMAT } from "$utils/date-helpers";

const DateInfoCell = ({ value, label, noValue = "-" }) => {
    const useValue = value ? format(buildDateFromString(value), SLASH_DATE_FORMAT) : noValue;
    return (
        <>
            <h5>{label}</h5>
            <h4>{useValue}</h4>
        </>
    );
};

export default DateInfoCell;
