import React from "react";
import ShadowTable from "../shadow-table";
import "./app-loader.scss";

const AppLoader = () => {
    return (
        <div className="app-loader">
            <ShadowTable />
        </div>
    );
};

export default AppLoader;
