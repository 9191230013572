import React from "react";

import { createRipple } from "./lib/helper";

const Button = ({ className, onClick, onMouseDown, children, title }) => {
    const handleMouseDown = (e) => {
        createRipple(e);
        if (onMouseDown) {
            onMouseDown(e);
        }
    };

    return (
        <button className={className} onClick={onClick} onMouseDown={handleMouseDown} title={title}>
            {children}
        </button>
    );
};

export default Button;
