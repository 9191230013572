import api from "$services/api";

const LIMIT = 50;

export const fetchProjectSnapshots = async () => {
    const response = await api.get("/api/admin/snapshots?limit=" + LIMIT);
    const badSnapshots = response.filter((s) => {
        const projectJson = JSON.parse(s.projectJson);
        return projectJson?.ProjectKey?.toString() !== s.projectKey.toString();
    });
    return badSnapshots;
};

export const repairSnapshot = async (projectKey) => {
    const response = await api.put(`/api/admin/${projectKey}/snapshot`);
    return response;
};
