import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpClientRecommendations = () => {
    return (
        <div className="help-section">
            <p>
                The Recommendations View provides three {getTerm("parentClient").toLowerCase()} trends that can be optimized to drive further growth and prevent {getTerm("parentClient").toLowerCase()} churn. These trends
                have been selected based on similar {getTerm("parentClients").toLowerCase()} to the selected {getTerm("parentClient").toLowerCase()} who have improved their growth. Each Recommendation card provides the
                following information:
            </p>
            <ul>
                <li data-number="1">{getTerm("parentClient")} management trend that should be increased, decreased, or maintained to improve likelihood of growth</li>
                <li data-number="2">Justification for why Orgaimi is recommending a certain {getTerm("parentClient").toLowerCase()} management behavior change</li>
                <li data-number="3">
                    Statistics on this {getTerm("parentClient").toLowerCase()}'s trends vs. {getTerm("parentClients").toLowerCase()} like them who have improved their growth
                </li>
            </ul>
        </div>
    );
};

export default HelpClientRecommendations;
