import React from "react";
import "./critical-error.scss";

const HaltAndCatchFire = () => {
    return (
        <div className="critical-error">
            <img src="/images/icon-ai.png" alt="Orgaimi AI" height="150" width="150" />
            <h1>An error has occurred</h1>
            <p>Please try again in a few minutes.</p>
        </div>
    );
};

export default HaltAndCatchFire;
