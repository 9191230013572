import React from "react";

import TeamMember from "$components/profile-icon/team-member";
import { getTerm } from "$stores/dictionary";
import NoData from "$components/charts/no-content";

const TeamInfoCell = ({ value, label, crmName, crmEmail, crmId, crmIsActive = true, projectId, projectKey, allowFeedback }) => {
    const teamInitials = value.slice(0, 3).map((m, i) => {
        return <TeamMember key={`member-${i}`} data={m} projectId={projectId} projectKey={projectKey} showExpertIn={true} showFeedback={allowFeedback} />;
    });

    return (
        <div className="focus-info">
            {crmName && (
                <div>
                    <h5>{getTerm("parentCrm")}</h5>
                    <div className="team-members">
                        <TeamMember data={{ id: crmId, name: crmName, email: crmEmail, isActive: crmIsActive }} />
                    </div>
                </div>
            )}
            <h5>{label}</h5>
            <div className="team-members">{teamInitials.length > 0 ? teamInitials : <NoData />}</div>
        </div>
    );
};

export default TeamInfoCell;
