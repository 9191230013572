import React, { useEffect, useState } from "react";
import debounce from "debounce";
import { useNavigate } from "react-router-dom";

import TableLoader from "$components/table/table-loader";

import { ClientStore, setFilters, setPageIndex, setPagingInfo, setPerPage, setSort } from "$stores/clients";
import { buildDefaultColumns } from "./columns";
import { mapClientToTableData } from "./mapper";
import { AppContextStore } from "$stores/app-context";
import { mapByKey } from "$utils/mapping";

const ClientList = () => {
    const navigate = useNavigate();
    const { filterMenu } = AppContextStore.useState((a) => {
        return {
            filterMenu: mapByKey(a.filterMenu, "apiKey") || {},
        };
    });
    const { clients, filters, globalFilterRanges, pageIndex, pageSize, pageCount, isLoading, sort, isSortAsc } = ClientStore.useState((c) => {
        return {
            clients: c.clients,
            filters: c.filters,
            globalFilterRanges: c.globalFilterRanges,
            pageSize: c.pageSize,
            pageIndex: c.pageIndex,
            pageCount: c.pageCount,
            isLoading: c.isLoading,
            sort: c.sort,
            isSortAsc: c.isSortAsc,
        };
    });
    const [defaultColumns, setDefaultColumns] = useState(buildDefaultColumns({ globalFilterRanges: globalFilterRanges, filterOptions: filterMenu }));

    useEffect(() => {
        setDefaultColumns(buildDefaultColumns({ globalFilterRanges: globalFilterRanges, filterOptions: filterMenu }));
    }, [filterMenu, globalFilterRanges]);

    const rowClick = (row) => {
        navigate(`/client/${row.key}`);
    };

    const data = clients.map(mapClientToTableData);

    return (
        <TableLoader
            allowFilter={true}
            columns={defaultColumns}
            containerClass="client-list"
            data={data}
            filters={filters}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPagingInfo={setPagingInfo}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default ClientList;
