import { useDocumentTitle } from "$utils/document-title";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logout } from "../../stores/user";
import { buildRedirectWithPath } from "$utils/api-helpers";

const resetAction = async (path) => {
    await logout();
    const redirect = buildRedirectWithPath("/", path);
    window.location = redirect;
};

/**
 * @description This component runs after receiving a successful logout from azure
 *
 **/
const Reset = () => {
    const location = useLocation();
    const search = location.search;
    let path = "";
    if (search) {
        const params = new URLSearchParams(search);
        path = params.get("path");
    }
    useDocumentTitle("Reset");
    useEffect(() => {
        resetAction(path);
    }, []);
    return null;
};

export default Reset;
