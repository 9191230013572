import { closeModal } from "$stores/modal";
import React from "react";

const DiscardDialog = ({ message = "Are you sure?", resolve }) => {
    const onConfirm = () => {
        resolve(true);
        closeModal();
    };

    const onCancel = () => {
        resolve(false);
        closeModal();
    };

    return (
        <div>
            <h4 className="txt-center">{message}</h4>
            <div className="button-group -center">
                <button className="button -small" onClick={onCancel}>
                    Go Back
                </button>
                <button className="button -primary -small" onClick={onConfirm}>
                    Discard Changes
                </button>
            </div>
        </div>
    );
};

export default DiscardDialog;
