import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpClientExistingProjects = () => {
    return (
        <div className="help-section">
            <p>
                The {getTerm("existingProjects")} page provides additional information about the {getTerm("parentClient").toLowerCase()}'s current and recent {getTerm("existingProjects").toLowerCase()} portfolio to put
                Orgaimi's {getTerm("potentialProjects")} and recommendations into context.
            </p>
            <p>Chart changes based on toggle options</p>
            <ul>
                <li data-number="1">
                    Last {getTerm("t12Revenue")} by {getTerm("serviceLine")}
                </li>
                <li data-number="2">
                    Last {getTerm("t12Revenue")} of {getTerm("parentClient")} vs. Like {getTerm("parentClients")}
                </li>
            </ul>

            <p>You can also see the distribution by Project Type as well as a list of all Existing Projects should you want to dive deeper.</p>
        </div>
    );
};

export default HelpClientExistingProjects;
