import React, { useState } from "react";
import { Radio } from "../inputs";

export const RadioGroup = ({ defaultValue, id, name, options = [], updateOnChange }) => {
    const [value, setValue] = useState(defaultValue || "");

    const handleOnChange = (n, v) => {
        const newValue = value === v ? "" : v;
        setValue(newValue);
        if (updateOnChange) {
            updateOnChange(id || name, newValue);
        }
    };

    return (
        <div className="radio-group">
            {options.map((o) => {
                return <Radio key={`radio_${o.value}`} id={`${id || name || ""}`} value={o.value} label={o.text} defaultValue={o.value === value} updateOnChange={handleOnChange} />;
            })}
        </div>
    );
};
