import React from "react";

import { formatDate, SHORT_DATE_FORMAT } from "$utils/date-helpers";
import { abbrNum } from "$utils/number-helper";
import classNames from "classnames";

const SinglePayload = ({ data, preSup = "", postSup = "", modifyFn = (value) => abbrNum(value) }) => {
    if (!data) {
        return <span>No Data</span>;
    }
    const colorClass = data?.value < 0 ? "-unhealthy" : "-healthy";
    return (
        <span className={classNames("txt -center -bold -condensed -healthy -xxlarge", colorClass)} style={{ color: data.stroke }}>
            {preSup && <sup>{preSup}</sup>}
            {modifyFn(data.value)}
            {postSup && <sup>{postSup}</sup>}
        </span>
    );
};

const MultiPayload = ({ data, keysToLabel = {}, preSup = "", postSup = "", modifyFn = (value) => abbrNum(value) }) => {
    return (
        <div className="list">
            <span style={{ color: data.stroke }}>{keysToLabel[data.name] || data.name}: </span>
            <SinglePayload data={data} preSup={preSup} postSup={postSup} modifyFn={modifyFn} />
        </div>
    );
};

export const DateCurrencyTooltip = ({ active, payload, xKey = "date", yKey = "value" }) => {
    if (payload && active) {
        const filteredPayload = payload.filter((p) => p.type !== "none");
        const isMultiPayload = filteredPayload.length > 1;
        return (
            <div className="custom-tooltip">
                <h5 className="txt -center">{payload[0] ? formatDate(payload[0].payload[xKey], SHORT_DATE_FORMAT) : "-"}</h5>
                {!isMultiPayload && <SinglePayload data={payload[0]} key={yKey} preSup="$" />}
                {isMultiPayload && payload.map((p, i) => <MultiPayload key={`date-currency-tooltip_${i}`} data={p} preSup="$" />)}
            </div>
        );
    }

    return null;
};

export const LabelCurrencyTooltip = ({ active, payload, yKey = "value", keysToLabel = {} }) => {
    if (payload && active) {
        const filteredPayload = payload.filter((p) => p.type !== "none");
        const activePayload = filteredPayload[0] || {};
        const isMultiPayload = filteredPayload.length > 1;
        return (
            <div className="chart-tooltip -dark -potential">
                {!isMultiPayload && <h5 className="txt -center">{activePayload.label || keysToLabel[activePayload.name] || activePayload.name}</h5>}
                {!isMultiPayload && <SinglePayload data={payload[0]} key={yKey} preSup="$" />}
                {isMultiPayload && payload.map((p, i) => <MultiPayload key={`date-currency-tooltip_${i}`} data={p} keysToLabel={keysToLabel} preSup="$" />)}
            </div>
        );
    }
    return null;
};

export const LabelPercentTooltip = ({ active, payload, yKey = "value", keysToLabel = {} }) => {
    if (payload && active) {
        const filteredPayload = payload.filter((p) => p.type !== "none");
        const activePayload = filteredPayload[0] || {};
        const isMultiPayload = filteredPayload.length > 1;
        return (
            <div className="chart-tooltip -dark -potential">
                {!isMultiPayload && <h5 className="txt -center">{activePayload.label || keysToLabel[activePayload.name] || activePayload.name}</h5>}
                {!isMultiPayload && <SinglePayload data={payload[0]} key={yKey} postSup="%" modifyFn={(value) => value.toFixed(2)} />}
                {isMultiPayload && payload.map((p, i) => <MultiPayload key={`date-percent-tooltip_${i}`} data={p} keysToLabel={keysToLabel} postSup="%" modifyFn={(value) => value.toFixed(2)} />)}
            </div>
        );
    }
    return null;
};

export const HoverTooltip = ({ active, payload, yKey = "value", keysToLabel = {} }) => {
    if (payload && active) {
        return <div></div>;
    }
    return null;
};
