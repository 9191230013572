import React, { useState } from "react";

import FilterCount from "./filter-count";
import FilterSummary from "src/pages/admin/pages/filter-lists/components/filter-summary";

import { AppContextStore } from "../../../stores/app-context";
import classNames from "classnames";

var filtersTimeout = null;

const ViewingFor = ({ label, hideFilters }) => {
    const { currentFilters, filterCount } = AppContextStore.useState((f) => {
        return { currentFilters: f.currentFilters || {}, filterCount: f.currentFiltersCount };
    });
    const [showFilterSummary, setShowFilterSummary] = useState(false);

    const updateFilterSummary = (bool = false) => {
        clearTimeout(filtersTimeout);
        filtersTimeout = setTimeout(
            () => {
                setShowFilterSummary(bool);
            },
            bool ? 80 : 250
        );
    };

    return (
        <>
            <div className="viewing-for">
                <p className="txt -condensed -detail-dark -bold -uppercase">
                    Viewing For: <span className="txt -light">{label}</span>
                </p>

                {!hideFilters && <FilterCount updateFilterSummary={updateFilterSummary} />}
            </div>

            {filterCount > 0 && (
                <div
                    className={classNames("filter-summary-wrapper", { "-active": showFilterSummary })}
                    onMouseLeave={() => {
                        updateFilterSummary(false);
                    }}
                    onMouseEnter={() => {
                        updateFilterSummary(true);
                    }}
                >
                    <FilterSummary
                        TitleComp={() => (
                            <h3>
                                <em>Active</em> Filters
                            </h3>
                        )}
                        filters={currentFilters}
                        modifierClassNames={["-light"]}
                    />
                </div>
            )}
        </>
    );
};

export default ViewingFor;
