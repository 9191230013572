import React from "react";
import _ from "lodash";

import FilterSummary from "./components/filter-summary";

import { AppContextStore } from "$stores/app-context";
import { useDocumentTitle } from "$utils/document-title";

import "./filter-lists.scss";

const FilterListIndex = () => {
    useDocumentTitle("Saved Filters - Admin");
    const savedFilters = AppContextStore.useState((f) => f.savedFilters || {});

    return (
        <div>
            <h1>
                <em>Favorite</em> Filters
            </h1>

            <div className="filter-list">
                {Object.keys(savedFilters).map((key) => {
                    const filters = savedFilters[key]?.filters ? JSON.parse(savedFilters[key]?.filters) : {};
                    return <FilterSummary key={key} id={savedFilters[key].value} name={savedFilters[key]?.text} filters={filters} />;
                })}
            </div>
        </div>
    );
};

export default FilterListIndex;
