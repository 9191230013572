import React from "react";

import { getTerm } from "$stores/dictionary";
import { emifyIt } from "$utils/text-helpers";

const HelpDashboard = () => {
    return (
        <div>
            <div className="help-section">
                <div className="row">
                    <div className="column -span12">
                        <p>Your Dashboard is a collection of cards that you can add, customize and re-arrange to provide you with a personalize view of the things that matter to you.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="column -span4">
                        <img src="/images/help/help-half-moon-chart.png" alt="Chart" />
                    </div>
                    <div className="column -span8">
                        <h3>
                            <em>Manage</em> Cards
                        </h3>
                        <p>To add a card to your Dashboard, just click the Add Card button and follow the steps.</p>
                        <p>You can click and drag to rearrange your cards, and dragging a card on top of another card of the same size will swap their positions.</p>
                        <p>To edit an existing card, click the edit icon in the top right corner of the card. Different cards have different options. </p>
                        <p>To remove a card from your dashboard, edit the card and click the Garbage Can icon in the upper left.</p>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="column -span12">
                        <h3>
                            <img alt="Priorities Option" src="/images/icon-priorities.svg" className="svg -white" /> <em>{getTerm("growthPotential")}</em> Panel
                        </h3>
                        <p>Your Dashboard also shows your Growth Potential panel. This can be accessed at anytime in the upper right corner.</p>
                        <ul>
                            <li data-number="1">
                                "{`${getTerm("parentCrm")}${getTerm("cm") ? `/${getTerm("cm")}` : ""}`}" to see the top 5 clients by {getTerm("growthPotential")} in which you are a{" "}
                                {getTerm("parentCustomerRelationsManager")} {getTerm("clientManager") ? ` or ${getTerm("clientManager")}` : ""}
                            </li>
                            <li data-number="2">
                                "{getTerm("myProjects")}" to see the top 5 clients by {getTerm("growthPotential")} where you are the {getTerm("projectPersonInCharge")}
                            </li>
                            <li data-number="3">
                                "{getTerm("allFirm")}" to see the top 5 clients by {getTerm("growthPotential")} for the firm
                            </li>
                            <li data-number="4">"Expert" to see the top 5 clients by {getTerm("growthPotential")} where you are experienced</li>
                            <li data-number="4">"Search" to find a different {getTerm("teamMember")}'s client accounts</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpDashboard;
