import React from "react";

import { abbrNum, buildPredefinedRange } from "$utils/number-helper";

const PredefinedNumberRange = ({ label, value, isHealthy }) => {
    const healthClass = isHealthy === false ? "txt-unhealthy" : isHealthy ? "txt-healthy" : "txt -potential";
    var range = typeof value === "string" ? value.toUpperCase() : buildPredefinedRange(value);

    return (
        <div className="large-number">
            <div>
                <h3 className={healthClass}>
                    <sup>$</sup>
                    {range}
                </h3>
            </div>
            {label && <h5>{label}</h5>}
        </div>
    );
};

export default PredefinedNumberRange;
