import React from "react";

import CollapsingPills from "$components/table/cells/collapsing-pills";
import LargeNumber from "$components/info-list/content/large-number";
import LogoCell from "$components/table/cells/logo";
import RevenueCell from "src/pages/clients/list/cells/Revenue";
import ScoreBubble from "$components/score-bubble";

import { abbrNum } from "$utils/number-helper";
import { getTerm } from "$stores/dictionary";
import { checkIsHealthy } from "$utils/health-helper";
import { capitalizeFirstLetter } from "$utils/text-helpers";
import { MultiRangeFilter, SelectColumnFilter } from "$components/table/components/filters/filter";

export const buildClientsManagedColumns = ({ filterOptions, globalFilterRanges }) => {
    return [
        {
            Header: () => getTerm("parentClient"),
            accessorKey: "clientGroupName",
            id: "clientGroupName",
            Cell: ({ data, value }) => <LogoCell value={value} logoUrl={""} />, // TODO: LogoUrl
        },
        {
            Header: () => getTerm("subClient"),
            accessorKey: "name",
            id: "name",
            className: "",
        },
        {
            Header: () => getTerm("industry"),
            accessorKey: "industries",
            size: 120,
            enableSorting: false,
            Cell: ({ data, value }) => {
                const tierOnes = value ? value.filter((v) => v.tier === 1) : [];
                return <CollapsingPills data={tierOnes} maxVisible={2} keyPrefix="industry" />;
            },
        },
        {
            Header: () => getTerm("serviceLine"),
            accessorKey: "serviceLines",
            size: 120,
            enableSorting: false,
            Filter: SelectColumnFilter,
            filterOptions: { options: filterOptions?.practice?.values || [] },
            Cell: ({ data, value }) => <CollapsingPills data={value} maxVisible={2} keyPrefix="practice" />,
        },
        {
            Header: () => "Health Score",
            accessorKey: "healthScore",
            sortDescFirst: true,
            size: 100,
            Filter: MultiRangeFilter,
            filters: {
                min: 1,
                max: 700,
                format: { isCurrency: false },
            },
            Cell: ({ data, value }) => <LargeNumber value={`${value}`} isHealthy={checkIsHealthy(value)} />,
        },
        {
            Header: () => "SVI Score",
            accessorKey: "sviScore",
            sortDescFirst: true,
            size: 75,
            Filter: MultiRangeFilter,
            filters: {
                min: 1,
                max: 10,
                format: { isCurrency: false },
            },
            Cell: ({ data, value }) => {
                return (
                    <div style={{ alignSelf: "center" }}>
                        <ScoreBubble value={value} percent={(value / 10) * 100} postSup={""} graph={true} modifierClassNames={["-small", "-svi"]} />
                    </div>
                );
            },
        },
        {
            Header: () => {
                return <span data-tooltip={getTerm("tooltip12MonthRevenue")}>{getTerm("t12Revenue")}</span>;
            },
            accessorKey: "twelveMonthRevenue",
            id: "twelveMonthRevenue",
            sortDescFirst: true,
            size: 170,
            Filter: MultiRangeFilter,
            filters: {
                min: globalFilterRanges?.twelveMonthRevenueMin ? Math.floor(globalFilterRanges?.twelveMonthRevenueMin) : "",
                max: globalFilterRanges?.twelveMonthRevenueMax ? Math.ceil(globalFilterRanges?.twelveMonthRevenueMax) : "",
                format: { isCurrency: true },
            },
            Cell: ({ data, value }) => <LargeNumber value={`$${abbrNum(value)}`} isHealthy={true} />,
        },
        {
            Header: () => getTerm("revenueAtRisk"),
            accessorKey: "revenueAtRisk",
            id: "revenueAtRisk",
            sortDescFirst: true,
            size: 170,
            Filter: MultiRangeFilter,
            filters: {
                min: globalFilterRanges?.revenueAtRiskMin ? Math.floor(globalFilterRanges?.revenueAtRiskMin) : "",
                max: globalFilterRanges?.revenueAtRiskMax ? Math.ceil(globalFilterRanges?.revenueAtRiskMax) : "",
                format: { isCurrency: true },
            },
            Cell: ({ data, value }) => <LargeNumber value={`$${abbrNum(value)}`} isHealthy={false} />,
        },
        {
            Header: () => `Portion of ${capitalizeFirstLetter(getTerm("revenue"))}`,
            accessorKey: "portionOfRevenue",
            id: "portionOfRevenue",
            sortDescFirst: true,
            size: 170,
            enableColumnFilter: false,
            Cell: ({ data, value }) => {
                return (
                    <div style={{ alignSelf: "center" }}>
                        <ScoreBubble value={value >= 10 ? Math.round(value) : Math.round(value * 10) / 10} percent={value} postSup={"%"} graph={true} modifierClassNames={["-small"]} />
                    </div>
                );
            },
        },
    ];
};
