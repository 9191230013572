import PaginatedStore from "./base/PaginatedStore";
import endpoints from "../services/endpoint";

export const DEFAULT_SORT = { name: "asc" };
//TODO: Endpoint
const CLIENTS_MANAGED_STORAGE_KEY = `/api/client-groups/clients${endpoints("managed_list")}`;

const { store, fetchList, reset, setFilters, setLoading, setPageIndex, setPerPage, setSort, watchPagination } = PaginatedStore({
    key: "clientsManaged",
    label: "Clients Managed",
    apiListUrl: CLIENTS_MANAGED_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    persistPagingInfo: false,
    storageKey: CLIENTS_MANAGED_STORAGE_KEY,
});

export const ClientsManagedStore = store;

export const fetchClientsManaged = fetchList;

export { reset, setFilters, setLoading, setPageIndex, setPerPage, setSort, watchPagination };
