export const REACT_APP_MSAL_CLIENTID = getEnvVar("{REACT_APP_MSAL_CLIENTID}");
export const REACT_APP_MSAL_AUTHORITY = getEnvVar("{REACT_APP_MSAL_AUTHORITY}");
export const REACT_APP_MSAL_REDIRECTURI = getEnvVar("{REACT_APP_MSAL_REDIRECTURI}");
export const REACT_APP_MSAL_USER_ACCESS_PERMISSION = getEnvVar("{REACT_APP_MSAL_USER_ACCESS_PERMISSION}");
export const REACT_APP_AMCHART_KEY = getEnvVar("{REACT_APP_AMCHART_KEY}");
export const REACT_APP_API_URL = getEnvVar("{REACT_APP_API_URL}");
export const REACT_APP_DOMAIN_URL = getEnvVar("{REACT_APP_DOMAIN_URL}");
export const REACT_APP_APPINSIGHTS_CONNECTIONSTRING = getEnvVar("{REACT_APP_APPINSIGHTS_CONNECTIONSTRING}");
export const REACT_APP_APPINSIGHTS_KEY = getEnvVar("{REACT_APP_APPINSIGHTS_KEY}");
export const REACT_APP_DISABLE_TELEMETRY = getEnvVar("{REACT_APP_DISABLE_TELEMETRY}")?.toString().toLowerCase() === "true" || false;

// TODO: Temporary Feature Flags
export const REACT_APP_FEATURE_TEAMS = getEnvVar("{REACT_APP_FEATURE_TEAMS}")?.toString().toLowerCase() === "true" || false;

export const IS_PROD = process.env.NODE_ENV === "production";

/** When in develop mode, read env vars normally, when in production output a
 * special string that will be replaced by script
 * @param {string} envVar the env var name
 * @return {string} the env var value
 */
function getEnvVar(envVarStr) {
    // is true when running: npm run build
    const isProd = process.env.NODE_ENV === "production";
    const envVar = envVarStr.replace(/[{}]/g, "");
    return isProd ? envVarStr : process.env[envVar];
}
