import React from "react";
import { Outlet } from "react-router-dom";

import AdminNavigation from "./components/navigation";

import "./admin.scss";

const AdminLayout = () => {
    return (
        <div id="admin" className="page">
            <div className="main -spacer">
                <aside>
                    <AdminNavigation />
                </aside>
                <main>
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default AdminLayout;
