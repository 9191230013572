import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpClientSubclients = () => {
    return (
        <div className="help-section">
            <p>
                All {getTerm("subClients")} section provides a list of all {getTerm("subClients")} underneath the {getTerm("parentClient")} account. For each {getTerm("subClient")}, the list provides information,
                including:
            </p>

            <ul>
                <li data-number="1">{getTerm("subClient")} Name</li>
                <li data-number="2">{getTerm("revenueAtRisk")}</li>
                <li data-number="3">{getTerm("parentClient")} Health Score</li>
                <li data-number="4">Strategic Value Index</li>
                <li data-number="5">
                    Portion of Revenue represented by the {getTerm("subClient")} within the {getTerm("parentClient")}
                </li>
                <li data-number="6">
                    The list can also be sorted by Portion of Revenue, {getTerm("subClient")} Name, {getTerm("parentClient")} Health Score, or {getTerm("revenueAtRisk")}
                </li>
            </ul>
        </div>
    );
};

export default HelpClientSubclients;
