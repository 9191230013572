import React, { useEffect, useState } from "react";
import classNames from "classnames";

import EngineLoader from "$components/loaders/engine-loader";
import NoData from "../no-content";

import { abbrNum, formatUIValue } from "$utils/number-helper";
import { CHART_DEFAULT_COLORS } from "../lib/constants";

import "./bar-comparison.scss";

export const Bar = ({ id, text, labelId, value, width, color, preSup, postSup, modifyFn = (value) => abbrNum(value), onClick }) => {
    const isLink = !!onClick && !!text && !text.toLowerCase().startsWith("other");
    return (
        <div className="bar-container">
            <div
                className={classNames({ "txt -cursor": isLink })}
                onClick={
                    isLink
                        ? () => {
                              onClick({ text: text, id: id, value: labelId || id });
                          }
                        : null
                }
            >
                <h4>{text}</h4>
                <div className="bar-bg">
                    <div className="bar" style={{ width: `${width}%`, backgroundColor: color }}></div>
                </div>
            </div>
            {value && (
                <div>
                    {preSup && <sup>{preSup}</sup>}
                    {formatUIValue(modifyFn(value))}
                    {postSup && <sup>{postSup}</sup>}
                </div>
            )}
        </div>
    );
};

const BarComparison = ({ colors = CHART_DEFAULT_COLORS, data, preSup = "", postSup = "", onClick, modifyFn = (value) => abbrNum(value) }) => {
    const [widths, setWidths] = useState([0, 0]);

    useEffect(() => {
        if (data) {
            const total = data.reduce((num, d) => {
                num = num + d.value;
                return num;
            }, 0);

            setWidths(
                data.reduce((arr, d) => {
                    arr.push((d.value / total) * 100);
                    return arr;
                }, [])
            );
        }
    }, [data]);

    if (!data) {
        return <EngineLoader />;
    }
    if (data.length === 0) {
        return <NoData message="No Parsable Data Available" />;
    }

    return (
        <div className="bar-comparison">
            {data.map((d, i) => {
                return <Bar key={`bar_${i}`} width={widths[i]} {...d} color={d.color || colors[i]} preSup={d?.preSup || preSup} postSup={postSup} modifyFn={modifyFn} onClick={onClick} />;
            })}
        </div>
    );
};

export default BarComparison;
