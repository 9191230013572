import React from "react";

import ManagerBox from "$components/filter-stats/components/manager-box";
import TeamMember from "$components/profile-icon/team-member";

import { getTerm } from "$stores/dictionary";

const TeamHierarchy = ({ crms, crmTitle, clientManagers, cmTitle }) => {
    return (
        <div>
            {crms.length === 1 ? (
                <ManagerBox name={crms[0].fullName} isActive={crms[0].isActive} employeeId={crms[0].id} title={crmTitle || getTerm("parentCustomerRelationsManager")} />
            ) : (
                <div>
                    <h4 className="txt -center">{crms[0]?.personType}</h4>
                    <div className="team-members">
                        {crms.map((m) => {
                            return <TeamMember key={`team-member_${m.id}`} data={{ name: m.fullName, email: m.email, isActive: m.isActive }} />;
                        })}
                    </div>
                </div>
            )}

            {clientManagers.length > 0 && (
                <div>
                    <h5 className="txt -center">{cmTitle || clientManagers[0]?.personType}</h5>
                    <div className="team-members">
                        {clientManagers.map((m) => {
                            return <TeamMember key={`team-member_${m.id}`} data={{ id: m.id, name: m.fullName, email: m.email, isActive: m.isActive }} />;
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TeamHierarchy;
