import React, { useEffect, useState } from "react";
import api from "../services/api";

import { REACT_APP_DOMAIN_URL } from "../services/env";
import { useDocumentTitle } from "$utils/document-title";
import { useParams } from "react-router-dom";
import { stringTemplateReplace } from "$utils/template-replace";

const EmailIndex = () => {
    useDocumentTitle("Email");
    const { email } = useParams();
    const urlParams = new URLSearchParams(window.location.search);

    const [emailTemplate, setEmailTemplate] = useState(null);

    const params = Object.fromEntries(Array(...urlParams.entries()));
    useEffect(() => {
        const fetchEmail = async () => {
            const template = await api.get(`/emails/${email}`);
            if (!template) {
                return;
            }
            setEmailTemplate(stringTemplateReplace(template, { ...params }).replaceAll("$__SITEURL", REACT_APP_DOMAIN_URL));
        };

        fetchEmail();
    }, [email]);

    return <div dangerouslySetInnerHTML={{ __html: emailTemplate }}></div>;
};

export default EmailIndex;
