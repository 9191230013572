import React, { useEffect, useState } from "react";

import ThemeScore from "./theme-score";
import { ScoreCardStore, fetchScoreCardScores } from "$stores/scorecard";
import { getTerm } from "$stores/dictionary";

import Pagination from "$components/table/components/pagination";

import "./theme-score.scss";
import AppLoader from "$components/loaders/app-loader";
import { ClientDetailsStore } from "$stores/client-details";

const ThemesScores = () => {
    const topClients = ScoreCardStore.useState((s) => s.topClients);
    const { hasSubClients, clientId } = ClientDetailsStore.useState((s) => {
        return { hasSubClients: s.hasSubClients, clientId: s.clientDetails.id };
    });
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [paginatedClients, setPaginatedClients] = useState(false);

    const setPageIndex = (p) => {
        setPaginatedClients(false);
        fetchScoreCardScores({ client: clientId, page: p });
        setPage(p + 1);
    };

    useEffect(() => {
        if (topClients !== false) {
            setPaginatedClients([...topClients]);
        }
    }, [topClients]);

    useEffect(() => {
        if (topClients && topClients.length > 0) {
            const total = topClients[0].totalRecordCount;
            setPages(Math.ceil(total / 10));
        }
    }, [topClients]);

    return (
        <div>
            <h3>{hasSubClients && <em>{getTerm("subClient")}&nbsp;</em>}Themes & Scores</h3>
            <div className="subclient-themes">
                {paginatedClients === false && <AppLoader />}
                {paginatedClients &&
                    paginatedClients.map((subClient, i) => {
                        return <ThemeScore key={`subclient_themes_${i}`} subClient={subClient} hasSubClients={hasSubClients} />;
                    })}
                {paginatedClients && (
                    <Pagination pageIndex={page - 1} pageCount={pages} setPageIndex={setPageIndex} canPreviousPage={page > 1} canNextPage={page < pages} pageOptions={Array.from({ length: pages }, (x, i) => i)} />
                )}
            </div>
        </div>
    );
};

export default ThemesScores;
