import React from "react";

const Progress = ({ percent }) => {
    return (
        <svg viewBox="0 0 36 36" className="circular-chart">
            <path
                className="circle"
                strokeDasharray={`${percent || 0}, 100`}
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
        </svg>
    );
};

export default Progress;
