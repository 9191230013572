import { getTerm } from "$stores/dictionary";

export const buildTop5Titles = (siteKey) => {
    return {
        ClientGroupsTotalGrowth: {
            header: () => `${getTerm("parentClients")} by<br /><em>Total ${getTerm("growthPotential")}</em>`,
            type: "currency",
            linkTemplate: "/client/#{labelId}/growth-potential",
            metrics: ["growthPotential"],
        },
        /*ClientGroupsSVIScore: {
        header: () => `${getTerm("parentClients")} by<br /><em>SVI Score</em>`,
        type: "number",
        linkTemplate: "/client/#{labelId}",
    },*/
        /*ClientGroupsRevenueRisk: {
        header: () => `${getTerm("parentClients")} by<br /><em>${getTerm("revenueAtRisk")}</em>`,
        type: "currency",
        linkTemplate: "/client/#{labelId}",
    },*/
        ProjectTypesTotalGrowth: {
            header: () => `${getTerm("projectTypes")} by<br /><em>Total ${getTerm("growthPotential")}</em>`,
            type: "currency",
            linkTemplate: `/projects/potential?filter=${JSON.stringify({ name: "#{name}" })}&sort=${JSON.stringify({ averageRevenue: "desc" })}`,
            metrics: ["growthPotential"],
        },
        ProjectTypesAverageGrowth: {
            header: () => `${getTerm("projectTypes")} by<br /><em>Average ${getTerm("growthPotential")}</em>`,
            type: "currency",
            linkTemplate: `/projects/potential?filter=${JSON.stringify({ name: "#{name}" })}&sort=${JSON.stringify({ averageRevenue: "desc" })}`,
            metrics: ["growthPotential"],
        },
        PotentialProjectsGrowth: {
            header: () => `${getTerm("potentialProjects")} by<br /><em>${getTerm("growthPotential")}</em>`,
            type: "currency",
            linkTemplate: "/project/#{labelId}/potential",
            metrics: ["growthPotential"],
        },
        ...(siteKey !== "faegredrinker"
            ? {
                  PartnersTotalGrowth: {
                      header: () => `${getTerm("parentCustomerRelationsManagers")} by<br /><em>Total ${getTerm("growthPotential")}</em>`,
                      type: "currency",
                      linkTemplate: "/team/#{labelId}",
                      metrics: ["growthPotential"],
                      //LabelComponent: ProfileLabel,
                  },
              }
            : {}),
        /*PartnersSVIScore: {
        header: () => `Partners by<br /><em>SVI Score</em>`,
        type: "number",
        linkTemplate: "/team/#{labelId}",
    },*/
        /*PartnersRevenueRisk: {
        header: () => `Partners by<br /><em>{getTerm("revenueAtRisk")}</em>`,
        type: "currency",
        linkTemplate: "/team/#{labelId}",
    },*/
    };
};
