import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import LargeNumber from "../../info-list/content/large-number";

import { useWindowSize } from "../../../utils/hooks/use-window-size";

import "./temperature-bar.scss";

const TemperatureBar = ({ children, hasDot = false, isHealthy = null, percent = 0, score = null, cssClass = [] }) => {
    const $bar = useRef();
    const size = useWindowSize();
    const [pos, setPos] = useState(0);
    const healthClass = isHealthy === false ? "-unhealthy" : isHealthy ? "-healthy" : "";

    useEffect(() => {
        // Slight delay waiting for render
        setTimeout(() => {
            setPos($bar.current ? $bar.current.offsetWidth * (percent / 100) : 0);
        }, 500);
    }, [$bar, percent, size]);

    return (
        <div className="temperature-bar-wrapper">
            <div className={classnames("temperature-bar", healthClass, cssClass)}>
                <div className="bar" ref={$bar}>
                    {!hasDot && <div className="line" style={{ width: `${percent}%` }}></div>}
                    {hasDot && <div className="dot" style={{ left: `${pos - 2}px` }}></div>}
                </div>
                {children && <div className="label">{children}</div>}
            </div>
            {score && <LargeNumber value={score} isHealthy={isHealthy} />}
        </div>
    );
};

export default TemperatureBar;
