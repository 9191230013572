import React, { useEffect, useState } from "react";
import debounce from "debounce";
import { useSearchParams } from "react-router-dom";

import PotentialProjectList from "./list.js";

import { PotentialProjectsStore, fetchPotentialProjects, setLoading, reset, setPagingInfo } from "$stores/potentialProjects.js";
import { AppContextStore } from "$stores/app-context";
import { useDocumentTitle } from "$utils/document-title.js";
import { useEffectOnce } from "$utils/hooks/useEffectOnce.js";

const debouncedFetchProjects = debounce(async (updatedFilters, pagingInfo) => {
    fetchPotentialProjects(updatedFilters, { keyPrefix: "tableFilter.", ...pagingInfo });
}, 800);

const PotentialProjects = ({ overrideFilters = {}, overrideGlobalFilters = {}, suppressGlobalFilters = false, buildColumns, columns, settings = {} }) => {
    useDocumentTitle("Potential Project List");
    const [searchParams] = useSearchParams();
    const [initialLoad, setInitialLoad] = useState(true);

    const { hasLoadedFilters, globalFilters, siteConfig } = AppContextStore.useState((f) => {
        return { hasLoadedFilters: f.hasLoadedFilters, globalFilters: suppressGlobalFilters ? {} : f.currentFilters || {}, siteConfig: f.siteConfig };
    });

    const pagingInfo = PotentialProjectsStore.useState((f) => {
        return { page: f.pageIndex, limit: f.pageSize, filters: f.filters, sort: f.sort, isSortAsc: f.isSortAsc };
    });

    useEffectOnce(() => {
        return () => {
            // Resets Pagination
            reset();
        };
    }, []);

    useEffect(() => {
        if (hasLoadedFilters) {
            const updatables = {};
            const sort = JSON.parse(searchParams.get("sort"));
            const filter = JSON.parse(searchParams.get("filter"));
            const page = searchParams.get("pageIndex");
            if (sort) updatables.sort = sort;
            if (filter) updatables.filter = filter;
            if (page) updatables.pageIndex = page;

            if (!_.isEmpty(updatables)) {
                setPagingInfo(updatables);
            }
            setInitialLoad(false);
        }
    }, [hasLoadedFilters]);

    useEffect(() => {
        if (!initialLoad && hasLoadedFilters) {
            setLoading();
            debouncedFetchProjects({ ...globalFilters, ...overrideGlobalFilters }, { ...pagingInfo, filters: { ...(pagingInfo.filters || {}), ...overrideFilters } });
        }
    }, [initialLoad, hasLoadedFilters, globalFilters, pagingInfo, overrideFilters, overrideGlobalFilters]);

    return (
        <div>
            <PotentialProjectList /*initialFilters={filtersToArray(globalFilters)}*/ settings={{ visibleColumns: siteConfig.potentialProjectListColumns, ...settings }} buildColumns={buildColumns} columns={columns} />
        </div>
    );
};

export default PotentialProjects;
