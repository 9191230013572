import React, { useEffect } from "react";
import loaderImg from "./loader.svg";

import "./engine-loader.scss";

const EngineLoader = ({ text, rotatingText, rotationTimer = 2000 }) => {
    const [displayText, setDisplayText] = React.useState(text);

    useEffect(() => {
        let interval;
        if (rotatingText && rotatingText.length > 0) {
            let index = 0;
            setDisplayText(rotatingText[index]);
            interval = setInterval(() => {
                index = index + 1;
                if (index > rotatingText.length - 1) {
                    index = 0;
                }

                setDisplayText(rotatingText[index]);
            }, rotationTimer);
        } else {
            if (interval) {
                clearInterval(interval);
            }
            setDisplayText(text);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [text, rotatingText, rotationTimer]);

    return (
        <div className="engine-loader">
            <img src={loaderImg} alt="Loading" />
            <p>{displayText}</p>
        </div>
    );
};

export default EngineLoader;
