import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpClientGrowthPotential = () => {
    return (
        <div className="help-section">
            <p>
                {getTerm("growthPotential")} page provides information on the future {getTerm("growthPotential").toLowerCase()} of the {getTerm("parentClient").toLowerCase()} account
            </p>
            <b>
                {getTerm("growthPotential")} by {getTerm("industry")} &amp; {getTerm("subClient")}
            </b>
            <ul>
                <li data-number="1">Inner pie chart: {getTerm("industry")} categories</li>
                <li data-number="2">Outer pie chart: {getTerm("subClient")} categories</li>
            </ul>
            <p>
                A {getTerm("parentClient").toLowerCase()} may have multiple {getTerm("subClient")} categories within a single {getTerm("industry")} category
            </p>
            <b>
                {getTerm("growthPotential")} by {getTerm("serviceLine")} &amp; {getTerm("projectType")}
            </b>
            <ul>
                <li data-number="3">Inner pie chart: {getTerm("serviceLine")}</li>
                <li data-number="4">Outer pie chart: {getTerm("projectType")}</li>
            </ul>
            <p>
                A {getTerm("parentClient").toLowerCase()} may have multiple {getTerm("projectTypes")} within a single {getTerm("serviceLine")} category
            </p>
            <h4>Top 3 Potential Projects</h4>
            Top 3 Potential Projects section provides detailed cards for the top 3 potential expansion opportunities based on the average revenue range of the potential project type OR the expansion probability.
        </div>
    );
};

export default HelpClientGrowthPotential;
