import { getTerm } from "$stores/dictionary";

export const buildTop5SubclientTitles = () => {
    return {
        ClientsSVIScore: {
            header: () => <h4 className="txt -center -white">SVI Score</h4>,
            type: "number",
            metrics: ["sviScore"],
        },
        ClientsRevenueAtRisk: {
            header: () => <h4 className="txt -center -white">{getTerm("revenueAtRisk")}</h4>,
            type: "currency",
            metrics: ["revenueAtRisk"],
        },
    };
};
