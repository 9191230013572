import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpOffices = () => {
    return (
        <div className="help-section">
            <p>
                The Offices view provides a summary of {getTerm("parentClient")} Health and {getTerm("growthPotential")} for Offices. {getTerm("parentClients")} are allocated to Offices based on the GL location of the{" "}
                {getTerm("parentClient")} Partner. This view helps you identify "hotspots" of offices where you may want to focus on for growth or risk mitigation.
            </p>
            <ul>
                <li data-number="1">
                    Each bubble represents one of the firm's offices
                    <ul>
                        <li data-number="2">The size of the bubble represents the Total {getTerm("growthPotential")} per Office</li>
                        <li data-number="2">
                            The color of the bubble represents the average {getTerm("parentClient")} Health Score, where light blue indicates Excellent or Good {getTerm("parentClient")} Health and bright gold indicate{" "}
                            {getTerm("parentClient")} Health to Review
                        </li>
                    </ul>
                </li>
                <li>
                    Hover over one of the offices to see summary statistics for the office
                    <ul>
                        <li data-number="3">Average Health Score for the Office</li>
                        <li data-number="4">Percentage change in Health Score over the past month</li>
                        <li data-number="5">Office name and Office Managing Partner</li>
                        <li data-number="6">Link to Detailed View</li>
                    </ul>
                </li>
            </ul>

            <h4>Detail View</h4>
            <p>
                The Offices Detail view provides detailed statistics on the Office for <b>Scores</b>, <b>Financials</b>, and <b>Insights</b>
            </p>
        </div>
    );
};

export default HelpOffices;
