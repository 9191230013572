import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpSegments = () => {
    return (
        <div className="help-section">
            <h4>Chart</h4>
            <p>
                The Segment Chart helps you understand the distribution of your {getTerm("growthPotential")} and {getTerm("revenueAtRisk")} across different <b>{getTerm("industry")}</b> or <b>{getTerm("serviceLine")}</b>{" "}
                as well as shows you Top 5 cards for various criteria. These charts are impacted by your Filter choices, so you can dive deep into the data.
            </p>
            <p>You can also click on a single bar of the Segments Chart to focus only on that item. Click on the selected item a second time to return to the default state.</p>

            <h4>Top 5 Cards</h4>
            <p>The bottom of the page is a series of Top 5 cards that indicate the top performing categories to act as a "leaderboard".</p>
            <h4>Additional Filtering</h4>
            <p>
                Use the global <img alt="Filter Option" src="/images/icon-filter.svg" className="svg -white" align="center" /> Client Filters functionality to filter the chart further to drive more specificity in the{" "}
                {getTerm("industry")}/{getTerm("serviceLine")} charts
            </p>
        </div>
    );
};

export default HelpSegments;
