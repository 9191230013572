import { Store } from "pullstate";
import api from "../services/api";
import _ from "lodash";
import logger from "../services/logger";
import { updateGlobalError } from "./app-context";

const initialState = {
    employeeDetails: {},
};

export const EmployeeDetailsStore = new Store(initialState);

const apiEmployee = async (employeeId, apiSettings) => {
    try {
        // TODO: Endpoint
        const response = await api.get(`/api/teams?employee=${employeeId}&page=0&limit=1`, null, apiSettings);

        const employeeData = response.data && response.data.length > 0 ? response.data[0] : null;

        logger.debug("EmployeeDetailsStore: fetchClient", employeeData);

        EmployeeDetailsStore.update((s) => {
            s.employeeDetails = employeeData;
        });
    } catch (e) {
        if (api.requestWasCanceled(e)) {
            return;
        }
        updateGlobalError("Unable to fetch employee");
        logger.error("Unable to fetch employee", e);
    }
};

export const fetchEmployee = (employeeId) => {
    const abortController = new AbortController();
    apiEmployee(employeeId, { signal: abortController.signal });
    return abortController;
};

export const resetEmployee = () => {
    EmployeeDetailsStore.update((s) => {
        return initialState;
    });
};
