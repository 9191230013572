export function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// TODO: unit testing
export function emifyIt(string) {
    const stringArr = string.split(" ");
    return (
        <span data-testid="emified">
            <em data-testid="em">{stringArr[0]}</em>
            {stringArr.length > 1 ? " " : ""}
            {stringArr.slice(1).join(" ")}
        </span>
    );
}
