export const DEFAULT_PROBABILITY_MATRIX = [0, 33, 66, 100];

export const dealProbabilityText = (percent, probabilityMatrix = DEFAULT_PROBABILITY_MATRIX) => {
    if (isNaN(percent)) {
        return "N/A";
    } else if (percent <= probabilityMatrix[1]) {
        return "Low";
    } else if (percent <= probabilityMatrix[2]) {
        return "Medium";
    } else {
        return "High";
    }
};

export const getProbabilityRanges = (probabilityMatrix = DEFAULT_PROBABILITY_MATRIX, accuracy = 0.01) => {
    return {
        low: { min: probabilityMatrix[0], max: probabilityMatrix[1] },
        medium: { min: probabilityMatrix[1] + accuracy, max: probabilityMatrix[2] },
        high: { min: probabilityMatrix[2] + accuracy, max: probabilityMatrix[3] },
    };
};
