import classNames from "classnames";
import React from "react";

const ThemeDefinitions = ({ cssClasses, data }) => {
    return (
        <div className={classNames("theme-definitions", cssClasses)}>
            {data.map((d, i) => {
                return (
                    <div key={`definition${i}`}>
                        <h4>{d.name}</h4>
                        <p>{d.text}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default ThemeDefinitions;
