import React from "react";

import EngineLoader from "$components/loaders/engine-loader";
import InfoList from "../../info-list";

import { OpportunityStore } from "../../../stores/opportunities";
import { buildProjectContent } from "../lib/helpers";

const MyProjectsPanel = () => {
    const revenueData = OpportunityStore.useState((s) => s.myProjects);
    if (!revenueData) {
        return <EngineLoader />;
    }

    return (
        <InfoList
            data={revenueData.map((s) => {
                return buildProjectContent(s);
            })}
        />
    );
};

export default MyProjectsPanel;
