import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

const getNextSortValue = (value, sortDescFirst) => {
    if (sortDescFirst) {
        return !value ? "desc" : value === "desc" ? "asc" : null;
    } else {
        return !value ? "asc" : value === "asc" ? "desc" : null;
    }
};

const SortButton = ({ id, sortDescFirst, updateSort, value }) => {
    return (
        <span
            className="sort"
            onClick={(e) => {
                const persist = e.nativeEvent.shiftKey ? true : false;
                updateSort(id, getNextSortValue(value, sortDescFirst), persist);
            }}
        >
            <FontAwesomeIcon icon={faSort} />
            {{
                asc: (
                    <div className="asc">
                        <FontAwesomeIcon icon={faSortUp} />
                    </div>
                ),
                desc: (
                    <div className="desc">
                        <FontAwesomeIcon icon={faSortDown} />
                    </div>
                ),
            }[value] ?? null}
        </span>
    );
};

export default SortButton;
