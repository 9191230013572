import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag, faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";

import CollapsingPills from "$components/table/cells/collapsing-pills";
import Flags from "$components/flags";
import LargeNumberRange from "$components/table/cells/large-number-range";
import PredefinedNumberRange from "$components/table/cells/predefined-number-range";
import ProbabilityBubble from "$components/probability";

import { Checkbox } from "$components/form-inputs/plain-inputs";
import { DefaultFilterForColumn, MultiRangeFilter, ProbabilityRange, SelectColumnFilter } from "$components/table/components/filters/filter";
import { getTerm } from "$stores/dictionary";

export const buildClientProjectsColumns = ({ settings, selectedList = [], updateSelectedList, updateAll, filterOptions, sendFlags }) => {
    var columns = [
        {
            Header: ({ data }) => {
                const value = data.every((p) => selectedList.includes(p.key));
                return (
                    <div>
                        {selectedList.length > 0 ? (
                            <div>
                                <button className={classNames("button -text", { "-disabled": !selectedList.length })} title="Flag Selected" onClick={sendFlags}>
                                    Flag {!!selectedList.length && selectedList.length}
                                </button>
                            </div>
                        ) : (
                            <>&nbsp;</>
                        )}
                        <div className="controls">
                            <div className="button-group -center">
                                <Checkbox
                                    name="selected-state"
                                    value={value}
                                    onChange={(e) => {
                                        updateAll(!value);
                                        //setAllUpcs(value ? [] : props.data.map((d) => d));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );
            },
            accessorKey: "key", // accessor is the "key" in the data
            id: "key", // id is used as a sort key (i.e., actual api name)
            enableSorting: false,
            enableColumnFilter: false,
            Cell: ({ data, value, rowIndex }) => {
                if (data.userFlagged) {
                    return <FontAwesomeIcon icon={faFlag} />;
                }
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Flags data={[{ classnames: { "-independence": data.requiresIndependence }, label: "Check Independence" }]} />
                        <Checkbox
                            name={`select_${data.key}_${rowIndex}`}
                            value={selectedList.includes(data.key)}
                            onChange={(e) => {
                                updateSelectedList(data, selectedList.includes(data.key) ? "remove" : "add");
                            }}
                        />
                    </div>
                );
            },
            size: 50,
            className: "-center",
        },
        {
            Header: () => getTerm("projectType"),
            accessorKey: "name", // accessor is the "key" in the data
            id: "name", // id is used as a sort key (i.e., actual api name)
            size: 120,
            Filter: DefaultFilterForColumn,
            Cell: ({ data, value }) => <div>{value}</div>,
        },
        {
            Header: () => getTerm("projectTypeGroup"),
            accessorKey: "projectTypeGroup", // accessor is the "key" in the data
            id: "projectTypeGroup", // id is used as a sort key (i.e., actual api name)
        },
        {
            Header: () => getTerm("serviceLine"),
            accessorKey: "serviceLine",
            Filter: SelectColumnFilter,
            filterOptions: { options: filterOptions?.practice?.values || [] },
            Cell: ({ data, value }) => {
                return <CollapsingPills data={[{ id: value, text: value }]} maxVisible={2} keyPrefix="serviceLine" />;
            },
        },
        {
            Header: () => "Flags",
            accessorKey: "flags",
            size: 120,
            Filter: SelectColumnFilter,
            filterOptions: {
                options: [
                    { value: "requiresIndependence", text: "Check Independence" },
                    { value: "isCrossSell", text: "Cross-Sell" },
                ],
            },
            enableSorting: false,
            Cell: ({ data, value }) => {
                const flags = [];
                if (data.requiresIndependence) {
                    flags.push({ id: "independence", text: "Check Independence", modifierClassNames: ["-independence"] });
                }
                if (data.isCrossSell) {
                    flags.push({ id: "cross-sell", text: "Cross-Sell", icon: faHandHoldingDollar, modifierClassNames: ["-importance"] });
                }

                return <CollapsingPills data={flags} maxVisible={2} keyPrefix="serviceLine" />;
            },
        },
        {
            Header: () => `${getTerm("subClient")} Size`,
            accessorKey: "clientSize",
            id: "clientSize",
            sortDescFirst: true,
            Filter: SelectColumnFilter,
            filterOptions: { options: filterOptions?.clientSize?.values },
            filters: {
                min: 0,
                max: 1000000,
                format: { isCurrency: true },
            },
            Cell: ({ data, value }) => {
                return <PredefinedNumberRange value={value} isHealthy={true} />;
            },
        },
        {
            Header: () => getTerm("averageRevenue"),
            accessorKey: "averageRevenue",
            id: "averageRevenue",
            sortDescFirst: true,
            Filter: MultiRangeFilter,
            filters: {
                min: 0,
                max: 50000000,
                format: { isCurrency: true },
            },
            Cell: ({ data, value }) => {
                return <LargeNumberRange value={value} />;
            },
        },
        {
            Header: () => "Probability",
            accessorKey: "probability",
            id: "probability",
            sortDescFirst: true,
            size: 100,
            Filter: ProbabilityRange,
            filters: {
                min: 0,
                max: 100,
                format: { isCurrency: false },
            },
            Cell: ({ data, value }) => <ProbabilityBubble value={value} />,
        },
    ];

    if (settings.visibleColumns && settings.visibleColumns.length > 0) {
        columns = columns.filter((c) => {
            return settings.visibleColumns.includes(c.accessorKey);
        });
    }

    return columns;
};

export const buildSubclientProjectsColumns = ({ settings, selectedList = [], updateSelectedList, updateAll, filterOptions, sendFlags }) => {
    var subclientProjectsColumns = [...buildClientProjectsColumns({ settings, selectedList, updateSelectedList, updateAll, filterOptions, sendFlags })];
    subclientProjectsColumns.splice(3, 0, {
        Header: () => getTerm("subClient"),
        accessorKey: "clientName",
        id: "clientName",
        className: "",
    });
    subclientProjectsColumns.splice(4, 0, {
        Header: () => getTerm("industry"),
        accessorKey: "industry",
        id: "industry",
        className: "",
    });

    return subclientProjectsColumns;
};
