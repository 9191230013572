import React from "react";
import CountUp from "../components/countup";

const NUMBER_SETTINGS = {
    showSuffix: true,
    decimalPrecision: null,
};

export const getNumberUnitPlace = (inputNumber, settings = {}) => {
    const numberSettings = { ...NUMBER_SETTINGS, ...settings };
    let decPlaces = numberSettings.decimalPrecision || 0;
    let number = parseInt(inputNumber) || 0;
    let absNumber = Math.abs(number);

    // OR-396: Decimal work to keep margin of error under 1% while visually rounding
    if (Object.is(numberSettings.decimalPrecision, null)) {
        // 1 decimals (5% margin of rounding) // Maggie doesn't want 2 decimals here, hard to scan
        if ((absNumber >= 1000 && absNumber < 10000) || (absNumber >= 1000000 && absNumber < 10000000) || (absNumber >= 1000000000 && absNumber < 10000000000)) {
            decPlaces = 1;
        }

        // 1 Decimal (under 1% margin rounding)
        if ((absNumber >= 10000 && absNumber < 100000) || (absNumber >= 10000000 && absNumber < 100000000) || (absNumber >= 10000000000 && absNumber < 100000000000)) {
            decPlaces = 1;
        }
    }
    const poweredPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    var abbrev = ["K", "M", "B", "T"];
    var unitPlace = "";

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3);
        // If the number is bigger or equal do the abbreviation
        if (size <= absNumber) {
            // Here, we multiply by poweredPlaces, round, and then divide by poweredPlaces.
            // This gives us nice rounding to a particular decimal place.
            number = (Math.round((inputNumber * poweredPlaces) / size) / poweredPlaces).toFixed(decPlaces);
            unitPlace = abbrev[i];
            // We are done... stop
            break;
        }
    }

    return {
        number,
        numberString: number.toString(),
        unitPlace,
    };
};

export const abbrNum = (inputNumber, settings = {}) => {
    if (inputNumber !== 0 && !inputNumber) {
        return "";
    }
    const numberSettings = { ...NUMBER_SETTINGS, ...settings };
    const numberObj = getNumberUnitPlace(inputNumber, numberSettings);

    return (numberSettings?.showSuffix ? numberObj.numberString + numberObj.unitPlace : numberObj.numberString) || "";
};

const abbrSmallNumber = (number) => {
    let decPlaces = 0;
    if (number > 11) {
        decPlaces = 1;
    }
    var multiplier = Math.pow(10, decPlaces || 0);
    return (Math.round(number * multiplier) / multiplier).toString();
};

export const formatCurrency = (number) => {
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    return formatter.format(number).replace("$", "");
};

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export const formatPercentage = (per) => {
    return abbrSmallNumber(per).toString();
};

export const formatPoints = (per) => {
    return Math.abs(per);
};

export const formatNumberWithCommas = (x) => {
    return x ? x.toLocaleString("en-US", { maximumFractionDigits: 2 }) : "";
};

export const formatUIValue = (value) => {
    let newValue = value ? value.toString() : "";
    const format = {
        isCurrency: false,
        isPercent: false,
        isNegative: false,
    };

    if (newValue.includes("$")) {
        newValue = newValue.replace("$", "");
        format.isCurrency = true;
    }
    if (newValue.includes("%")) {
        newValue = newValue.replace("%", "");
        format.isPercent = true;
    }
    if (newValue.includes("-")) {
        newValue = newValue.replace("-", "");
        format.isNegative = true;
    }

    return (
        <span>
            {format.isNegative && "-"}
            {format.isCurrency && <sup>$</sup>}
            <CountUp num={newValue} />
            {format.isPercent && <sup>%</sup>}
        </span>
    );
};

export const NUMBER_VARIANCE = 10;

export const getNumberRange = (value, variance = NUMBER_VARIANCE) => {
    return {
        low: Math.round(value * ((100 - variance) / 100)),
        high: Math.round(value * ((100 + variance) / 100)),
    };
};

const RANGES = [0, 10000, 100000, 1000000, 10000000];

export const buildPredefinedRange = (value) => {
    const newRanges = [...RANGES, value].sort((a, b) => {
        return a - b;
    });
    const valueIndex = newRanges.indexOf(value);
    return valueIndex === 0 ? "–" : valueIndex === newRanges.length - 1 ? `${abbrNum(newRanges[valueIndex - 1])}+` : `${abbrNum(newRanges[valueIndex - 1])}–${abbrNum(newRanges[valueIndex + 1])}`;
};

// If over 100, drop the decimals
export const conditionalRound = (num) => {
    if (num > 100) {
        return Math.round(num);
    }
    return num;
};

export const ordinalSuffixOf = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return "st";
    }
    if (j == 2 && k != 12) {
        return "nd";
    }
    if (j == 3 && k != 13) {
        return "rd";
    }
    return "th";
};

// Round to whole number unless under 0
export const roundAboveOne = (num) => {
    if (num < 10) {
        return Math.round(num * 10) / 10;
    } else {
        return Math.round(num);
    }
};
