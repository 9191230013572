import React, { useEffect, useState } from "react";
import { AppContextStore, replaceCurrentFilters, saveFilter } from "$stores/app-context";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import _ from "lodash";

import FilterSummary from "./components/filter-summary";

import { Input } from "$components/form-inputs/inputs";
import { useInput } from "$utils/hooks/use-input";
import { useDocumentTitle } from "$utils/document-title";
import { stringMatch } from "$utils/check-match";

import NotificationBar from "$components/notification-bar";
import BrowseSharedFilters from "./components/browse-shared-filters";

import "./shared-filters.scss";

const SharedFiltersIndex = () => {
    const title = useDocumentTitle("Shared Filters");
    const { currentFilters, selectedSavedFilter, previousFilters, hasLoadedFilters } = AppContextStore.useState((f) => {
        return { currentFilters: f.currentFilters || {}, previousFilters: f.previousFilters || {}, selectedSavedFilter: f.selectedSavedFilter, hasLoadedFilters: f.hasLoadedFilters };
    });
    const [sharedFilters, setSharedFilters] = useState({});
    const unsavedFilterName = useInput("");
    const inputFilterName = useInput("");
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const sharedFilters = searchParams.get("sharedFilters");
        if (sharedFilters) {
            const filterString = atob(decodeURIComponent(sharedFilters).replace("==", ""));
            setSharedFilters(JSON.parse(filterString), "");
        }
        //if (hasLoadedFilters && sharedFilters) {
        //if (!stringMatch(currentFilters, JSON.parse(filterString))) {
        //setSharedFilters(JSON.parse(filterString), "");
        //}
        //}
    }, []);

    const handleUnsaved = async () => {
        if (_.isEmpty(currentFilters)) {
            setError("No filters are currently set");
            return false;
        }

        if (unsavedFilterName.value) {
            // If input value, save new
            const newOption = { text: unsavedFilterName.value };
            saveFilter(
                {
                    ...newOption,
                },
                currentFilters
            );

            unsavedFilterName.reset();
            // TODO: selectFilter.setValue(newOption);
        } else if (!unsavedFilterName.value) {
            // TODO: setError("Filter name required to save!");
        }
    };

    const handleSave = async () => {
        if (_.isEmpty(sharedFilters)) {
            setError("No filters are currently set");
            return false;
        }

        if (inputFilterName.value) {
            // If input value, save new
            const newOption = { text: inputFilterName.value };
            saveFilter(
                {
                    ...newOption,
                },
                sharedFilters
            );

            replaceCurrentFilters(sharedFilters, inputFilterName.value);

            inputFilterName.reset();
        } else if (!inputFilterName.value) {
            // TODO: setError("Filter name required to save!");
        }
    };

    const applyFilters = () => {
        if (!_.isEmpty(sharedFilters)) {
            // Import Filter
            replaceCurrentFilters(sharedFilters, "");
            inputFilterName.reset();
            return false;
        }
    };

    return (
        <div id="shared-filters" className="page">
            <div className="main -spacer">
                <main>
                    {stringMatch(currentFilters, sharedFilters) && <BrowseSharedFilters sharedFilters={sharedFilters} />}

                    {!_.isEmpty(currentFilters) && !stringMatch(currentFilters, sharedFilters) && (
                        <NotificationBar type="warning" isClosable={true}>
                            <div className="unsaved-filters">
                                <h3>
                                    <em>Your Unsaved</em> Filters
                                </h3>
                                <p>We noticed you may have been in the middle of other unsaved filters. Would you like to save them now?</p>
                                <br />
                                <div className="saved-filters">
                                    <div className="input-group -full -light">
                                        <Input placeholder="New Filter Name" {...unsavedFilterName.bind} />
                                        <button className={classNames("button -secondary -small -right", { "-secondary": unsavedFilterName.value.startsWith("==") })} onClick={handleUnsaved}>
                                            {unsavedFilterName.value.startsWith("==") ? "Import" : "Save"}
                                        </button>
                                    </div>
                                </div>
                                <br />
                                <div className="filter-list">
                                    <FilterSummary filters={currentFilters} cssClass="-light" />
                                </div>
                            </div>
                        </NotificationBar>
                    )}

                    {!stringMatch(currentFilters, sharedFilters) && (
                        <>
                            <h1>
                                <em>Shared</em> Filters
                            </h1>
                            {!_.isEmpty(sharedFilters) && (
                                <>
                                    <p>
                                        These are the filters <b>{decodeURIComponent(searchParams.get("fromUser"))}</b> would like to share with you.
                                    </p>
                                    <div className="button-group">
                                        <button className="button -primary -small" onClick={applyFilters}>
                                            Apply Filters
                                        </button>
                                        <div className="input-group -full">
                                            <Input placeholder="New Filter Name" {...inputFilterName.bind} />
                                            <button className={classNames("button -secondary -small -right")} onClick={handleSave}>
                                                {inputFilterName.value.startsWith("==") ? "Import" : "Save"}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="filter-list">
                                        <FilterSummary filters={sharedFilters} />
                                    </div>
                                </>
                            )}
                            {_.isEmpty(sharedFilters) && <NotificationBar type="error" message="There are no shared filters attached to this page." />}
                        </>
                    )}
                </main>
            </div>
        </div>
    );
};

export default SharedFiltersIndex;
