const computedStyles = getComputedStyle(document.documentElement);

export const COLOR_BASE = computedStyles.getPropertyValue("--orgaimi-color-bg").trim(); //"#1C3642";
export const COLOR_BASE_DARK = computedStyles.getPropertyValue("--orgaimi-color-bg2").trim(); //"#03202D";
export const COLOR_FONT = computedStyles.getPropertyValue("--orgaimi-color-font").trim(); //"#f3f3f3";
export const COLOR_HEADER_BG = computedStyles.getPropertyValue("--orgaimi-color-header-bg").trim(); //"#03202D";
export const COLOR_HEALTHY = "#18b2dc";
export const COLOR_UNHEALTHY = "#fd9332";
export const COLOR_POTENTIAL = "#de653a";
export const COLOR_GRAY = "rgba(243,243,243, 0.5)";
