import debounce from "lodash.debounce";
import { Store } from "pullstate";
import api from "../../services/api";
import { persistValue, retrieveValue } from "../../services/local";

const TypeaheadStore = ({ searchApiEndpoint, key, responseHandler }) => {
    const initialState = {
        isLoading: false,
        results: [],
        searchTerm: "",
        selectedResults: [],
    };

    const FILTER_DATA_KEY = `${key}-filter-data`;

    const SearchStore = new Store(initialState);

    (async () => {
        SearchStore.update((s) => {
            s.isLoading = true;
        });
        const initialFilterData = await retrieveValue(FILTER_DATA_KEY);
        if (initialFilterData) {
            updateSelectedResults(initialFilterData);
        } else {
            SearchStore.update((s) => {
                s.isLoading = false;
            });
        }
    })();

    const searchApi = async (term) => {
        if (!term) {
            return;
        }

        SearchStore.update((s) => {
            s.isLoading = true;
        });
        const response = await api.get(`${searchApiEndpoint}?query=${term}`);

        SearchStore.update((s) => {
            s.results = responseHandler(response);
            s.isLoading = false;
        });
    };

    const debouncedSearchApi = debounce(searchApi, 1000);

    SearchStore.createReaction(
        (s) => s.searchTerm,
        (searchTerm, draft) => {
            debouncedSearchApi(searchTerm);
        }
    );

    const search = async (term) => {
        SearchStore.update((s) => {
            s.searchTerm = term;
            s.results = [];
        });
    };

    const updateSelectedResults = async (selected) => {
        persistValue(FILTER_DATA_KEY, selected);
        SearchStore.update((s) => {
            s.selectedResults = selected;
            s.isLoading = false;
        });
    };

    return {
        store: SearchStore,
        search,
        updateSelectedResults,
    };
};

export default TypeaheadStore;
