import TypeaheadStore from "./base/TypeaheadStore";

const { store, search, updateSelectedResults } = TypeaheadStore({
    key: "crm",
    searchApiEndpoint: "/api/employees/crm",
    responseHandler: (response) => {
        return response && response["employees"] && response["employees"].length > 0
            ? response["employees"].map((e) => {
                  return {
                      value: e.employeeId,
                      text: e.fullName,
                  };
              })
            : null;
    },
});

const CrmStore = store;

export { CrmStore, search, updateSelectedResults };
