import { Store } from "pullstate";
import api from "../services/api";
import logger from "$services/logger";

import { buildFilters } from "$utils/api-helpers";

const initialState = {
    recommendations: null,
    scores: null,
    summary: null,
    topClients: false,
    trends: null,
};

export const ScoreCardStore = new Store(initialState);

const apiScoreCardScores = async ({ client, industry, practice, office, geography, page = 0, limit = 10 }, apiSettings) => {
    try {
        const result = await api.get(`/api/score-cards/scores?${buildFilters({ Client: client, Industry: industry, Practice: practice, Office: office, Geography: geography, page, limit })}`, null, apiSettings);
        ScoreCardStore.update((s) => {
            s.summary = result;
            s.topClients = result.scores.map((s) => {
                return {
                    ...s,
                    valueByThemeId: s.themes.reduce((obj, t, i) => {
                        obj[t.id] = t.value;
                        return obj;
                    }, {}),
                };
            });
            return s;
        });
    } catch (e) {
        logger.error("Unable to fetch scorecard scores", e);
    }
};

export const fetchScoreCardScores = (params) => {
    const abortController = new AbortController();
    apiScoreCardScores(params, { signal: abortController.signal });
    return abortController;
};

// Unused
/*export const fetchScoreCardTrends = async () => {
    const result = await api.get("/api/score-cards/trends");
    ScoreCardStore.update((s) => {
        s.trends = result.trends;
        return s;
    });
};*/

const apiRecommendations = async ({ client, industry, scoreRange, geography, practice, office }, apiSettings) => {
    try {
        const result = await api.get(`/api/recommendations?${buildFilters({ Client: client, Industry: industry, ScoreRange: scoreRange, Geography: geography, Practice: practice, Office: office })}`, null, apiSettings);
        ScoreCardStore.update((s) => {
            s.recommendations = result.recommendations || [];
            return s;
        });
    } catch (e) {
        logger.error("Unable to fetch recommendations", e);
    }
};

export const fetchRecommendations = (params) => {
    const abortController = new AbortController();
    apiRecommendations(params, { signal: abortController.signal });
    return abortController;
};

export const resetScoreCard = () => {
    ScoreCardStore.update((s) => {
        return initialState;
    });
};
