import { format, formatDistanceToNow, differenceInSeconds } from "date-fns";

export const SHORT_DATE_FORMAT = "MMMM yyyy";
export const STANDARD_DATE_FORMAT = "MMMM dd, yyyy";
export const SLASH_DATE_FORMAT = "M/dd/yy";
export const SHORT_MONTH_FORMAT = "MMM";
export const DATE_TIME_FORMAT = "MMM d - h:mm aaa";

const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
};

export const buildDateFromString = (str) => {
    if (!str) {
        return null;
    }
    try {
        const date = new Date(str);
        return isValidDate(date) ? date : null;
    } catch {
        return null;
    }
};

export const formatDate = (date, pattern = STANDARD_DATE_FORMAT) => {
    const stringDate = buildDateFromString(date);
    return stringDate ? format(stringDate, pattern) : "";
};

export const formatRecentDate = (date) => {
    const stringDate = buildDateFromString(date);
    return stringDate ? formatDistanceToNow(stringDate) : "";
};

export const getTTLFromExpTimestamp = (expTimestamp) => {
    if (!expTimestamp) {
        return 0;
    }
    try {
        const now = new Date();
        const futureDate = new Date(expTimestamp * 1000);
        const seconds = differenceInSeconds(now, futureDate) * -1;
        return seconds;
    } catch {
        return 0;
    }
};
