import { getTerm } from "$stores/dictionary";

export const ADD_DEFAULT_CARDS = {
    cardId: "add_default_cards",
    title: "Welcome",
    type: "addDefaultCards",
    template: "templateDefaultCards",
    columnPosition: 4,
    rowPosition: 1,
    columnSize: 2,
    rowSize: 2,
    metrics: "[]",
    filters: "{}",
    sort: "{}",
    rootEndpoint: "/api/dashboard",
    isFakeCard: true,
};

export const buildDefaultCards = () => {
    return [
        {
            cardId: `initial_my_contact_card_${new Date().getTime()}`,
            title: "My Contact Card",
            type: "contact",
            template: "templateContactCard",
            columnPosition: 1,
            rowPosition: 1,
            columnSize: 1,
            rowSize: 1,
            metrics: ["crm"],
            filters: {},
            sort: { crm: "asc" },
            rootEndpoint: "/api/employees/all",
        },
        {
            cardId: `initial_data_refresh_${new Date().getTime()}`,
            title: "Data Refresh",
            type: "refresh",
            template: "templateDataRefresh",
            columnPosition: 1,
            rowPosition: 2,
            columnSize: 1,
            rowSize: 1,
            metrics: [],
            filters: {},
            sort: {},
            rootEndpoint: "/api/dashboard",
        },
        {
            cardId: `initial_firm_growth_half_moon_${new Date().getTime()}`,
            title: `${getTerm("firmGrowthPotential")} by ${getTerm("serviceLine")}`,
            type: "halfCircle",
            template: "templateGrowthPotentialByServiceLine",
            columnPosition: 2,
            rowPosition: 1,
            columnSize: 2,
            rowSize: 2,
            metrics: ["growthPotential", "serviceLine"],
            filters: {},
            sort: { probability: "desc" },
            rootEndpoint: "/api/dashboard",
        },
        {
            cardId: `initial_firm_t12Revenue_${new Date().getTime()}`,
            title: getTerm("t12Revenue"),
            type: "stats",
            template: "templateRevenue",
            columnPosition: 2,
            rowPosition: 3,
            columnSize: 1,
            rowSize: 1,
            metrics: ["t12Revenue"],
            filters: {},
            sort: {},
            rootEndpoint: "/api/dashboard",
        },
        {
            cardId: `initial_firm_growth_potential_${new Date().getTime()}`,
            title: getTerm("firmGrowthPotential"),
            type: "stats",
            template: "templateGrowthPotential",
            columnPosition: 3,
            rowPosition: 3,
            columnSize: 1,
            rowSize: 1,
            metrics: ["growthPotential"],
            filters: {},
            sort: {},
            rootEndpoint: "/api/dashboard",
        },
    ];
};

export const buildCrmCards = (user) => {
    return [
        {
            cardId: `initial_my_growth_potential_half_moon_${new Date().getTime()}`,
            title: `${getTerm("growthPotential")} By ${getTerm("serviceLine")} For ${getTerm("parentClients")} Where I Am The ${getTerm("parentCrm")}`,
            type: "halfCircle",
            template: "templateGrowthPotentialByServiceLine",
            columnPosition: 4,
            rowPosition: 2,
            columnSize: 2,
            rowSize: 2,
            metrics: ["growthPotential", "serviceLine"],
            filters: { crm: { value: user.id, text: user.displayName } },
            sort: { probability: "desc" },
            rootEndpoint: "/api/dashboard",
        },
        {
            cardId: `initial_my_t12Revenue_${new Date().getTime()}`,
            title: `${getTerm("t12Revenue")} For ${getTerm("parentClients")} Where I Am The ${getTerm("parentCrm")}`,
            type: "stats",
            template: "templateRevenue",
            columnPosition: 4,
            rowPosition: 1,
            columnSize: 1,
            rowSize: 1,
            metrics: ["t12Revenue"],
            filters: { crm: { value: user.id, text: user.displayName } },
            sort: {},
            rootEndpoint: "/api/dashboard",
        },
        {
            cardId: `initial_my_growth_potential_${new Date().getTime()}`,
            title: `${getTerm("growthPotential")} For ${getTerm("parentClients")} Where I Am The ${getTerm("parentCrm")}`,
            type: "stats",
            template: "templateGrowthPotential",
            columnPosition: 5,
            rowPosition: 1,
            columnSize: 1,
            rowSize: 1,
            metrics: ["growthPotential"],
            filters: { crm: { value: user.id, text: user.displayName } },
            sort: {},
            rootEndpoint: "/api/dashboard",
        },
        {
            cardId: `initial_my_top_clients_${new Date().getTime()}`,
            title: `Top ${getTerm("parentClients")} By ${getTerm("growthPotential")} For ${getTerm("parentClients")} Where I Am The ${getTerm("parentCrm")}`,
            type: "top5",
            template: "templateClientsByGrowthPotential",
            columnPosition: 6,
            rowPosition: 1,
            columnSize: 2,
            rowSize: 4,
            metrics: ["name", "growthPotential"],
            filters: { crm: { value: user.id, text: user.displayName } },
            sort: { growthPotential: "desc" },
            rootEndpoint: "/api/client-groups/?page=0&limit=5",
        },
    ];
};
