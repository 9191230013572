import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { Checkbox } from "$components/form-inputs/plain-inputs";
import { useInput } from "$utils/hooks/use-input";
import CustomFilters from "./custom-filters";
import { UserStore } from "$stores/user";
import { getFiltersByTemplate, getPersonalizedFilterByTemplate } from "../lib/helpers";
import { AppContextStore } from "$stores/app-context";
import { mapByKey } from "$utils/mapping";

const WizardReview = ({ answers, onComplete, updateFilters, filters }) => {
    const filterMenu = AppContextStore.useState((f) => mapByKey(f.filterMenu, "apiKey") || {});
    const [showFilters, setShowFilters] = useState(false);
    const personalizedFilterSelect = useInput(false);
    const personalFilter = getPersonalizedFilterByTemplate(answers[answers.length - 1]?.template);
    const [customFilters, setCustomFilters] = useState([]);
    const user = UserStore.useState((s) => s);
    useEffect(() => {
        if (personalizedFilterSelect.value === true) {
            updateFilters(personalFilter.apiKey, { value: user.id, text: user?.displayName });
        } else {
            updateFilters(personalFilter.apiKey, "");
        }
    }, [personalizedFilterSelect.value]);

    useEffect(() => {
        setCustomFilters(getFiltersByTemplate(answers[answers.length - 1]?.template, filterMenu));
    }, [answers, filterMenu]);

    return (
        <div>
            <div className="finalize">
                <div className="button-group">
                    <div>
                        {personalFilter?.text && personalFilter.employeeTypes && personalFilter.employeeTypes.includes(user.employeeType) && (
                            <div className="button-group -left">
                                <Checkbox name="add-widget-personalize" label={personalFilter?.text} {...personalizedFilterSelect.bind} />
                            </div>
                        )}
                        {customFilters && (
                            <button
                                className={classNames("button -small -left", { "-disabled": personalFilter?.disableCustomFilters && personalizedFilterSelect.value })}
                                onClick={() => {
                                    if (personalFilter?.disableCustomFilters && personalizedFilterSelect.value) {
                                        return false;
                                    }
                                    setShowFilters(true);
                                }}
                            >
                                Add Custom Filters
                            </button>
                        )}
                    </div>

                    <button
                        className="button -small -secondary -right"
                        onClick={() => {
                            if (onComplete) {
                                onComplete(answers, filters);
                            }
                        }}
                    >
                        Create Card
                    </button>
                </div>
            </div>
            <div className={classNames("custom-filters-wrapper", { "-active": showFilters })}>
                <CustomFilters
                    closeFilters={() => {
                        setShowFilters(false);
                    }}
                    filterableOptions={customFilters || []}
                    filters={filters}
                    updateFilters={updateFilters}
                />
            </div>
        </div>
    );
};

export default WizardReview;
