import React, { useState } from "react";
import classnames from "classnames";

import ProfileIcon from "../../../profile-icon";

import { useNavigate } from "react-router-dom";
import { scrollToPos } from "$utils/scroll-to";

import "./name.scss";

const FULL_NAME_BREAKPOINT = 0;

const InitialsCell = ({ url, isActive = true, value, totalWidth }) => {
    const navigate = useNavigate();
    const showInitials = totalWidth <= FULL_NAME_BREAKPOINT;
    const [hover, setHover] = useState(false);

    if (!value) {
        return "-";
    }

    const onHover = () => {
        if (showInitials) {
            setHover(true);
        }
    };

    const onLeave = () => {
        setHover(false);
    };

    const handleClick = () => {
        if (url) {
            scrollToPos(0);
            navigate(url);
        }
    };
    const crm = Array.isArray(value) ? value[0] || {} : value.hasOwnProperty("fullName") ? value : { fullName: value };

    return (
        <div onMouseEnter={onHover} onMouseLeave={onLeave} onClick={handleClick} className={classnames("name-container", { "-hover": hover, "-anchor": !!url })}>
            <ProfileIcon name={crm.fullName} isActive={isActive} modifierClassNames={["-small"]} />
            {!showInitials && <p>{crm.fullName}</p>}
            <div className="tooltip">{crm.fullName}</div>
        </div>
    );
};

export default InitialsCell;
