export const TOOLTIP_THEMES = {
    DELIVERY_PROCESS: "tooltipDeliveryProcess",
    TEAM_COMPOSITION: "tooltipTeamComposition",
    CROSS_COLLABORATION: "tooltipCrossCollaboration",
    CLIENT_DEMOGRAPHICS: "tooltipClientDemographics",
    EXTERNAL_MARKET: "tooltipExternalMarket",
    BUSINESS_ADMINISTRATION: "tooltipBusinessAdministration",
    COMMUNICATIONS: "tooltipCommunications",
    FEEDBACK: "tooltipFeedback",
    CRM: "tooltipCrm",
};

export const getThemeTooltipDictId = (theme) => {
    return TOOLTIP_THEMES[theme.toUpperCase().replace(/\s|-/g, "_")] || null;
};
