import { getTerm } from "$stores/dictionary";

import HelpClientDashboard from "../components/client-dashboard";
import HelpDashboard from "../components/dashboard";
import HelpSegments from "../components/segments";
import HelpOffices from "../components/offices";
import HelpPotentialProjects from "../components/potential-projects";
import HelpExistingProjects from "../components/existing-projects";
import HelpClientList from "../components/client-list";
import HelpClientSubclients from "../components/client-subclients";
import HelpClientGrowthPotential from "../components/client-growth-potential";
import HelpClientRecommendations from "../components/client-recommendations";
import HelpClientScores from "../components/client-scores";
import HelpClientExistingProjects from "../components/client-existing-projects";
import HelpPotentialProjectsDetail from "../components/potential-projects-detail";
import HelpExistingProjectDetail from "../components/existing-projects-detail";
import HelpTeamList from "../components/team-list";
import HelpTeamDashboard from "../components/team-dashboard";
import HelpTeamManaging from "../components/team-managing";
import HelpTeamPastWork from "../components/team-past-work";
import HelpTeamExperience from "../components/team-experience";

// Dashboard
export const HELP_DASHBOARD = () => [{ title: "Dashboard", Content: HelpDashboard }];

// Segments
export const HELP_SEGMENTS = () => [{ title: "Segments", Content: HelpSegments }];

// Offices
export const HELP_OFFICES = () => [{ title: "Offices", Content: HelpOffices }];

// Clients
export const HELP_CLIENT_LIST = () => [{ title: "Client List", Content: HelpClientList }];
export const HELP_CLIENT_DASHBOARD = () => [{ title: `${getTerm("parentClient")} Dashboard`, Content: HelpClientDashboard }];
export const HELP_CLIENT_SUBCLIENTS = () => [
    {
        title: (
            <>
                <em>All</em> {getTerm("subClients")}
            </>
        ),
        Content: HelpClientSubclients,
    },
];
export const HELP_CLIENT_GROWTH_POTENTIAL = () => [
    {
        title: getTerm("growthPotential"),
        Content: HelpClientGrowthPotential,
    },
];
/* Here */
export const HELP_CLIENT_RECOMMENDATIONS = () => [
    {
        title: "Recommendations",
        Content: HelpClientRecommendations,
    },
];
export const HELP_CLIENT_SCORES = () => [
    {
        title: "Scores",
        Content: HelpClientScores,
    },
];
export const HELP_CLIENT_EXISTING_PROJECTS = () => [
    {
        title: getTerm("existingProjects"),
        Content: HelpClientExistingProjects,
    },
];

// Potential Projects
export const HELP_POTENTIAL_PROJECT_LIST = () => [{ title: getTerm("potentialProjects"), Content: HelpPotentialProjects }];
export const HELP_POTENTIAL_PROJECT_DETAIL = () => [
    {
        title: `${getTerm("potentialProjects")} Detail`,
        Content: HelpPotentialProjectsDetail,
    },
];

// Existing Projects
export const HELP_EXISTING_PROJECT_LIST = () => [{ title: getTerm("existingProjects"), Content: HelpExistingProjects }];
export const HELP_EXISTING_PROJECT_DETAIL = () => [
    {
        title: `${getTerm("existingProjects")} Detail`,
        Content: HelpExistingProjectDetail,
    },
];

// Team
export const HELP_TEAM_LIST = () => [
    {
        title: "Team",
        Content: HelpTeamList,
    },
];
export const HELP_TEAM_DASHBOARD = () => [
    {
        title: "Dashboard",
        Content: HelpTeamDashboard,
    },
];
export const HELP_TEAM_MANAGING = () => [
    {
        title: "Managing",
        Content: HelpTeamManaging,
    },
];
export const HELP_TEAM_PAST_WORK = () => [
    {
        title: "Past Work",
        Content: HelpTeamPastWork,
    },
];
export const HELP_TEAM_EXPERIENCE = () => [
    {
        title: "Experience",
        Content: HelpTeamExperience,
    },
];
