import React, { useContext } from "react";
import classnames from "classnames";

import FilterMenu from "../../filter-menu";
import MainNav from "./main-nav";
import NotificationPanel from "$components/notification-panel";
import OfficePanel from "../../map/office-panel";

import { PanelContext } from "../../../contexts/panels";
import { AppContextStore } from "../../../stores/app-context";
import { NOTIFICATION_STATES, NotificationStore } from "$stores/notifications";
import { HelpStore } from "$stores/help";
import { initiateModal } from "$stores/modal";
import GrowthPotentialPanel from "../../growth-potential-panel";

const UserNav = () => {
    const { panels, togglePanel } = useContext(PanelContext);
    const messages = NotificationStore.useState((n) => n.active.filter((m) => NOTIFICATION_STATES["unread"].includes(m.status)) || []);
    const showFilter = AppContextStore.useState((s) => s.showFilter);
    const filterCount = AppContextStore.useState((s) => s.currentFiltersCount);
    const hasNotificationsEnabled = AppContextStore.useState((s) => s.settings?.sections?.appNotifications || false);

    const { Component, helpData } = HelpStore.useState((s) => {
        return { Component: s.Content, helpData: s.data };
    });

    const potentialCount = 0;

    return (
        <>
            <MainNav />
            <div className="utility-nav">
                <ul>
                    <li className={classnames({ "-selected": window.location.pathname.startsWith("/help") })}>
                        <a
                            href="/help"
                            onClick={(e) => {
                                e.preventDefault();
                                initiateModal(Component, { data: helpData });
                            }}
                        >
                            <img alt="Help" src="/images/icon-info.svg" />
                        </a>
                    </li>
                    <li className={classnames({ "-selected": panels.growthPotential })}>
                        <a
                            href="#growthPotential"
                            onClick={(e) => {
                                e.preventDefault();
                                togglePanel("growthPotential");
                            }}
                        >
                            <img alt="Priorities Option" src="/images/icon-priorities.svg" />
                            {potentialCount > 0 && <div className="pill-count">{potentialCount}</div>}
                        </a>
                    </li>
                    {hasNotificationsEnabled && (
                        <li className={classnames({ "-selected": panels.notifications })}>
                            <a
                                href="#notifications"
                                onClick={(e) => {
                                    e.preventDefault();
                                    togglePanel("notifications");
                                }}
                            >
                                <img alt="Priorities Option" src="/images/icon-notifications.svg" />
                                {messages.length > 0 && <div className="pill-count">{messages.length}</div>}
                            </a>
                            <NotificationPanel isActive={panels.notifications} />
                        </li>
                    )}
                    {showFilter && (
                        <li className={classnames({ "-selected": panels.filters })}>
                            <a
                                href="#filter-menu"
                                onClick={(e) => {
                                    e.preventDefault();
                                    togglePanel("filters");
                                }}
                            >
                                <img alt="Filter Option" src="/images/icon-filter.svg" />
                                {filterCount > 0 && <div className="pill-count">{filterCount}</div>}
                            </a>
                            <FilterMenu isActive={panels.filters} />
                        </li>
                    )}
                    {/*<li>
                        <a href="#layer-menu">
                            <img alt="Layers Option" src="/images/layers-not-selected.svg" />
                        </a>
                    </li>*/}
                </ul>
            </div>
            <GrowthPotentialPanel />
            <OfficePanel />
        </>
    );
};

export default UserNav;
