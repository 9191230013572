import { Store } from "pullstate";
import api from "../services/api";
import { buildFilters } from "../utils/api-helpers";
import { abbrNum } from "../utils/number-helper";
import { updateGlobalError } from "./app-context";
import logger from "../services/logger";
import endpoints from "../services/endpoint";
import { getTerm } from "./dictionary";

const initialState = {
    summary: null,
    isLoading: true,
    offices: [],
    panels: {
        financials: null,
        insights: null,
        scores: null,
        isLoading: true,
    },
};

export const OfficeStore = new Store(initialState);

export const fetchOfficeSummary = async (filters) => {
    const result = await api.get(`/api/offices/summary?${buildFilters(filters)}`);
    OfficeStore.update((s) => {
        s.summary = result.summary;
        return s;
    });
};

export const setOfficesLoading = async (isLoading) => {
    OfficeStore.update((s) => {
        s.isLoading = isLoading;
        return s;
    });
};

const apiOffices = async (filters, apiSettings) => {
    setOfficesLoading(true);

    try {
        const result = await api.getFromCache(`/api/offices${endpoints("offices_offices")}?${buildFilters(filters)}`, null, apiSettings);
        OfficeStore.update((s) => {
            s.offices = result.offices.map((o) => {
                return {
                    id: o.officeId,
                    officeName: o.officeName,
                    officeManagingPartner: o.officeManagingPartner,
                    officeManagingPartnerId: o.officeManagingPartnerId,
                    officeManagingPartnerIsActive: o.officeManagingPartnerIsActive,
                    crmId: o.crmEmployeeId,
                    ...o.location,
                    ...o.scores,
                    financials: o.financials,
                    insights: o.insights,
                    scores: o.scores,
                };
            });
            s.isLoading = false;
            return s;
        });
    } catch (e) {
        if (api.requestWasCanceled(e)) {
            return;
        }
        updateGlobalError(`Unable to fetch ${getTerm("geographies")}`);
        logger.error(`Unable to fetch ${getTerm("geographies")}`, e);
    }
};

export const fetchOffices = (filters) => {
    const abortController = new AbortController();
    apiOffices(filters, { signal: abortController.signal });
    return abortController;
};

const fetchFinancePanelData = async (id, filters) => {
    let financePanelData = {};
    try {
        const financials = await api.get(`/api/offices${endpoints("offices_financials")}${id}/financials?${buildFilters(filters)}`);
        if (!financials || financials?.problemDetails?.status) {
            throw new Error("No financial data available");
        }
        financePanelData = mapFinancialPanelData(financials);
        OfficeStore.update((s) => {
            s.panels = {
                ...s.panels,
                financials: financePanelData,
                isLoading_financials: false,
            };
            return s;
        });
    } catch (e) {
        logger.error("Unable to fetch finance panel data", e);
        OfficeStore.update((s) => {
            s.panels = {
                ...s.panels,
                financials: [],
                isLoading_financials: false,
            };
            return s;
        });
    }
};

const fetchInsightPanelData = async (id, filters) => {
    let insightPanelData = {};
    try {
        const insights = await api.get(`/api/offices${endpoints("offices_insights")}${id}/insights?${buildFilters(filters)}`);
        insightPanelData = mapInsightsPanelData(insights);

        OfficeStore.update((s) => {
            s.panels = {
                ...s.panels,
                insights: insightPanelData,
                isLoading_insights: false,
            };
            return s;
        });
    } catch (e) {
        logger.error("Unable to fetch insight panel data", e);
    }
};

const fetchScorePanelData = async (id, officesLength, filters) => {
    let scorePanelData = {};
    try {
        const scores = await api.get(`/api/offices${endpoints("offices_summary")}${id}/summary?${buildFilters(filters)}`);
        scorePanelData = mapScorePanelData(scores, officesLength);
    } catch (e) {
        logger.error("Unable to fetch score panel data", e);
    }
    OfficeStore.update((s) => {
        s.panels = {
            ...s.panels,
            scores: scorePanelData,
            isLoading_scores: false,
        };
        return s;
    });
};

export const fetchPanelData = async (id, offices, filters) => {
    if (!offices || offices.length === 0) {
        return;
    }
    const office = offices.find((o) => o.id.toString() === id.toString());
    if (!office) {
        return;
    }

    fetchFinancePanelData(id, filters);

    fetchInsightPanelData(id, filters);

    fetchScorePanelData(id, offices.length, filters);

    OfficeStore.update((s) => {
        s.panels = {
            office: office,
            isLoading: false,
            isLoading_insights: true,
            isLoading_financials: true,
            isLoading_scores: true,
        };
        return s;
    });
};

const mapFinancialPanelData = (office) => {
    const { financials } = office;
    const { selectedOffice, officeAverage } = financials;
    return {
        officeId: selectedOffice.officeId,
        officeName: selectedOffice.officeName,
        officeManagingPartner: selectedOffice.officeManagingPartner,
        crmName: selectedOffice.crmName,
        crmId: selectedOffice.crmId,
        crmCount: selectedOffice.crmCount,
        clientCount: selectedOffice.clientCount,
        revenueHistory: selectedOffice.revenueHistory,
        charts: [
            {
                id: "totalRevenuePerOffice",
                title: "Total Revenue of Office",
                office: { label: `$${abbrNum(selectedOffice.totalRevenue)}`, value: selectedOffice.totalRevenue },
                average: { label: `$${abbrNum(officeAverage.totalRevenue)}`, value: officeAverage.totalRevenue },
            },
            {
                id: "revenuePerClient",
                title: "Revenue Per Client ({0} Total)",
                titleData: ["clientCount"],
                office: { label: `$${abbrNum(selectedOffice.averageRevenuePerClient)}`, value: selectedOffice.averageRevenuePerClient },
                average: { label: `$${abbrNum(officeAverage.averageRevenuePerClient)}`, value: officeAverage.averageRevenuePerClient },
            },
            {
                id: "revenuePerPartner",
                title: "Revenue Per Partner ({0} Total)",
                titleData: ["crmCount"],
                office: { label: `$${abbrNum(selectedOffice.averageRevenuePerCrm)}`, value: selectedOffice.averageRevenuePerCrm },
                average: { label: `$${abbrNum(officeAverage.averageRevenuePerCrm)}`, value: officeAverage.averageRevenuePerCrm },
            },
        ],
    };
};

const mapScorePanelData = (office, officesTotal) => {
    const { summary } = office;
    const { scores } = summary;
    return {
        officeId: summary.officeId,
        officeName: summary.officeName,
        officeManagingPartner: summary.officeManagingPartner,
        crmName: summary.crmName,
        crmId: summary.crmId,
        officeRanking: scores.relativeFilterRanking,
        officesTotal: officesTotal,
        averageHealthScore: scores.currentAverage,
        averageHealthScoreChange: scores.averageChange,
    };
};

const mapInsightsPanelData = (office) => {
    const { insights } = office;
    return {
        officeId: insights.officeId,
        officeName: insights.officeName,
        officeManagingPartner: insights.officeManagingPartner,
        officeManagingPartnerId: insights.officeManagingPartnerId,
        crmId: insights.crmId,
        crmName: insights.crmName,
        chart: insights.selectedOfficeScores.map((officeScore) => {
            const average = insights.averageOfficeScores.find((average) => average.themeId === officeScore.themeId);
            return {
                id: `${officeScore.themeId}`,
                officeValue: officeScore.themeScore,
                averageValue: average ? average.themeScore : 0,
                label: officeScore.themeName,
            };
        }),
    };
};

export const resetOffices = () => {
    OfficeStore.update((s) => {
        return initialState;
    });
};
