import React from "react";
import classNames from "classnames";

const CustomFilters = ({ closeFilters, filters, filterableOptions = [], updateFilters }) => {
    if (filterableOptions.length <= 0) {
        return "";
    }

    return (
        <div className="custom-filters">
            <h3>
                Custom <em>Filters</em>
            </h3>
            <div>
                {filterableOptions.map((filterItem, i) => {
                    const Comp = filterItem.Component || null;
                    return (
                        <div className="form-field -full" key={`filtermenu_${filterItem.key}_${i}`}>
                            <Comp
                                name={filterItem.key}
                                label={filterItem.label}
                                updateOnChange={((key) => (name, value) => {
                                    updateFilters(key, value);
                                })(filterItem.key)}
                                value={filters.hasOwnProperty(filterItem.key) ? filters[filterItem.key] : filterItem.defaultValue}
                                defaultValue={filters.hasOwnProperty(filterItem.key) ? filters[filterItem.key] : filterItem.defaultValue}
                                {...filterItem.filterSettings}
                            />
                        </div>
                    );
                })}
            </div>
            {closeFilters && (
                <div className="button-group">
                    <button
                        className="button -left -small"
                        onClick={() => {
                            if (closeFilters) {
                                closeFilters();
                            }
                        }}
                    >
                        Back
                    </button>
                </div>
            )}
        </div>
    );
};

export default CustomFilters;
