import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { debounce } from "lodash";

import TableLoader from "$components/table/table-loader.js";

import { buildProjectsManagedColumns } from "./lib/columns.js";
import { mapToTableData } from "src/pages/projects/existing/list.js";
import { ProjectsManagedStore, fetchProjectsManaged, setFilters, setPageIndex, setPerPage, setSort } from "$stores/managed-projects-list.js";
import { getTerm } from "$stores/dictionary.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";

const debouncedFetchProjects = debounce(async (updatedFilters, pagingInfo) => {
    fetchProjectsManaged(updatedFilters, { keyPrefix: "tableFilter.", ...pagingInfo });
}, 800);

const ManagedProjects = () => {
    const { employeeId } = useParams();
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const filterMenu = AppContextStore.useState((f) => mapByKey(f.filterMenu, "apiKey") || {});
    const { filters, globalFilterRanges, isLoading, isSortAsc, pageCount, pageIndex, pageSize, projects, sort } = ProjectsManagedStore.useState((c) => {
        return {
            filters: c.filters,
            globalFilterRanges: c.globalFilterRanges,
            isLoading: c.isLoading,
            isSortAsc: c.isSortAsc,
            pageSize: c.pageSize,
            pageIndex: c.pageIndex,
            pageCount: c.pageCount,
            projects: c.projectsManaged || [],
            sort: c.sort,
        };
    });
    const [projectsManagedColumns, setProjectsManagedColumns] = useState([]);

    useEffect(() => {
        setProjectsManagedColumns(buildProjectsManagedColumns({ globalFilterRanges, filterOptions: filterMenu }));
    }, [filterMenu, globalFilterRanges]);

    useEffect(() => {
        debouncedFetchProjects({ crm: employeeId }, { filters: filters, page: pageIndex, limit: pageSize, sort: sort, isSortAsc: isSortAsc });
    }, [filters, sort, pageIndex, isSortAsc, pageSize]);

    const rowClick = (row) => {
        navigate(`/project/${row.id}/existing`);
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: "Projects Managed",
                section: "Projects Managing",
                projectId: row.id,
            }
        );
    };

    const data = projects.map(mapToTableData);

    return (
        <div>
            <h1>
                <span data-tooltip={`${getTerm("existingProjects")} where the ${getTerm("teamMember").toLowerCase()} is the ${getTerm("projectPIC")}`}>
                    <em>{getTerm("existingProjects")}</em> as {getTerm("projectPIC")}
                </span>
            </h1>
            <TableLoader
                allowFilter={true}
                columns={projectsManagedColumns}
                containerClass="table-projects-managed"
                data={data}
                filters={filters}
                isLoading={isLoading}
                isSortAsc={isSortAsc}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageCount={pageCount}
                setFilters={setFilters}
                setPageIndex={setPageIndex}
                setPerPage={setPerPage}
                setSort={setSort}
                sort={sort}
                onRowClick={rowClick}
            />
            <br />
        </div>
    );
};

export default ManagedProjects;
