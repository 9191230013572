export const scrollToName = (name, body) => {
    const el = document.getElementsByName(name)[0];
    const container = body || document.getElementsByClassName("page-container")[0];
    if (el) {
        container.scrollTo({
            top: el.offsetTop || 0,
            behavior: "smooth",
        });
    }
};

export const scrollToPos = (num) => {
    const container = document.getElementsByClassName("page-container")[0] || document.body;
    container.scrollTo({
        top: num || 0,
        behavior: "smooth",
    });
};
