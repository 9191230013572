import React from "react";
import classNames from "classnames";

import "./flags.scss";

const Flags = ({ data }) => {
    return (
        <div className="flags">
            {data.map((f, i) => (
                <div key={`flag_${i}`} className={classNames("flag", f.classnames)} data-tooltip={f.label} />
            ))}
        </div>
    );
};

export default Flags;
