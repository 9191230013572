import React from "react";

import { abbrNum } from "$utils/number-helper";

const GenericLegend = ({ data, color }) => {
    const showValue = data.value ? (
        <span>
            :&nbsp;<span className="txt -gray">${abbrNum(data.value)}</span>
        </span>
    ) : null;
    return (
        <div className="external-label">
            <span className="color-swatch" style={{ backgroundColor: color }}></span> {data.text}
            {showValue}
        </div>
    );
};

export default GenericLegend;
