import React from "react";
import classnames from "classnames";

import Progress from "./components/progress";
import HealthArrow from "../health-arrow";

import { checkIsHealthyByChange } from "$utils/health-helper";

import "./score-bubble.scss";

const ScoreBubble = ({ label, healthy = null, arrow = undefined, isIncrease = null, graph = false, preSup = null, postSup = null, percent = null, value = "", modifierClassNames = [] }) => {
    const healthClass = typeof healthy === "boolean" ? (healthy ? "-healthy" : "-unhealthy") : "";
    const arrowIncrease = typeof isIncrease == "boolean" ? isIncrease : checkIsHealthyByChange(value);

    const generatedClassNames = {
        "-arrow": arrow,
    };
    return (
        <div className="score-bubble-wrapper">
            <div className={classnames("score-bubble", generatedClassNames, healthClass, modifierClassNames)}>
                {arrow && value.toString() !== "0" && <HealthArrow isHealthy={healthy} isIncrease={arrowIncrease} />}
                {preSup && <sup>{preSup}</sup>}
                {isNaN(value) ? value : Math.abs(value)}
                {postSup && <sup>{postSup}</sup>}
                {graph && <Progress percent={percent ? Math.abs(percent) : Math.abs(value)} />}
            </div>
            {label && <h5 className="txt -center">{label}</h5>}
        </div>
    );
};

export default ScoreBubble;
