export const PLACEHOLDER_TEXT = "::PLACEHOLDER::";
export const getDigitsAndTemplate = (value) => {
    const numbers = value.toString().replace(/[^\d.-]/g, "");
    return {
        num: Math.abs(numbers),
        template: value.toString().replace(Math.abs(numbers), PLACEHOLDER_TEXT),
    };
};

export const runTemplate = (num, template) => {
    return template.replace(PLACEHOLDER_TEXT, num);
};
