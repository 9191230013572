import React from "react";
import classNames from "classnames";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DateInput = ({ defaultValue, id, name, label, updateOnChange, invalid, popperModifiers }) => {
    const htmlLabel = label ? <label htmlFor={name || id}>{label}:</label> : null;

    const handleChange = (date) => {
        if (updateOnChange) {
            updateOnChange(id || name, date);
        }
    };

    return (
        <div className={classNames("form-field", { "-error": !!invalid })}>
            {htmlLabel}
            <DatePicker id={id} name={name} selected={defaultValue && new Date(defaultValue)} onChange={handleChange} isClearable={!!defaultValue} autoComplete="off" popperModifiers={popperModifiers || []} />
        </div>
    );
};

export default DateInput;
