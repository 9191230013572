import React from "react";

import BarComparison from "$components/charts/bar-comparison";

import { getKeyMetaData } from "../lib/helpers";
import { useNavigate } from "react-router-dom";
import { encodeStringTemplateReplace } from "$utils/template-replace";

const BarCard = ({ id, data, title, metrics, filters, sort, linkTemplate, rootEndpoint }) => {
    const navigate = useNavigate();
    const handleClick = (item) => {
        navigate(encodeStringTemplateReplace(linkTemplate, item));
    };
    return (
        <div className="top5">
            {title && (
                <header>
                    <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
                    {filters && (
                        <h4 className="txt -no-margin filter-display txt -gray">
                            {Object.keys(filters).map((k, i) => {
                                const keyMeta = getKeyMetaData(k);
                                return (
                                    <span key={`filter_${i}`}>
                                        <h5 className="txt -no-margin">{keyMeta?.text}:</h5>
                                        {keyMeta.renderData(filters[k])}
                                    </span>
                                );
                            })}
                        </h4>
                    )}
                </header>
            )}
            <div className="bar-comparison-card">
                <BarComparison data={data} onClick={handleClick} />
            </div>
        </div>
    );
};

export default BarCard;
