import React from "react";
import CountUp from "../../../components/countup";

const FilterItem = ({ count, item }) => {
    return (
        <div className="filter-item">
            <img src={`/images/icon-${item.key}.svg`} alt={item.label} />
            <b>
                <CountUp num={count} />
            </b>
            <span>{count === 1 ? item.singular : item.label}</span>
        </div>
    );
};

export default FilterItem;
