import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ChartBarBubble from "$components/charts/bar-bubble";
import ExistingProjects from "./components/existing-projects";
import TwelveMonthRevenue from "./components/twelve-month-revenue";

import { ClientExistingProjectsStore, fetchClientExistingProjects, setLoading, reset, setFilters } from "$stores/clientExistingProjects";
import { ClientDetailsStore } from "$stores/client-details";
import { useDocumentTitle } from "$utils/document-title";
import { ChartsStore } from "$stores/charts";
import { getTerm } from "$stores/dictionary";
import { setHelpContent } from "$stores/help";
import { HELP_CLIENT_EXISTING_PROJECTS } from "../help/lib/help-contents";
import { scrollToName } from "$utils/scroll-to";
import { useEffectOnce } from "$utils/hooks/useEffectOnce";

const ClientExistingProjects = () => {
    const { clientId } = useParams();
    useDocumentTitle("Client Existing Projects");
    const clientDetails = ClientDetailsStore.useState((s) => s.clientDetails);
    const { existingProjectCountByProjectType } = ChartsStore.useState((c) => c.charts);

    const pagingInfo = ClientExistingProjectsStore.useState((f) => {
        return { filters: f.filters, isSortAsc: f.isSortAsc, limit: f.pageSize, page: f.pageIndex, sort: f.sort };
    });

    useEffect(() => {
        setHelpContent(HELP_CLIENT_EXISTING_PROJECTS);

        const hash = window.location.hash;
        if (hash) {
            scrollToName(hash.replace("#", ""));
        }
    }, []);

    useEffectOnce(() => {
        return () => {
            // Resets Pagination
            reset();
        };
    }, []);

    useEffect(() => {
        if (pagingInfo) {
            setLoading();
            fetchClientExistingProjects({ client: [clientId] }, { keyPrefix: "tableFilter.", ...pagingInfo });
        }
    }, [pagingInfo]);

    const handleBarClick = (data, e) => {
        if (data && data.value) {
            setFilters({ projectType: data.value });
            scrollToName("existing-projects");
        }
    };

    const setExistingProjectsFilter = (key, value) => {
        setFilters([{ [key]: value }]);
        scrollToName("existing-projects");
    };

    return (
        <div>
            <h2>
                <em>{getTerm("parentClient")}</em> Detail Information
            </h2>
            <TwelveMonthRevenue clientId={clientDetails.id} handleClick={setExistingProjectsFilter} />
            <h3>
                <em>{getTerm("existingProject")} Counts</em> By {getTerm("projectType")}
            </h3>
            <ChartBarBubble key="existingProjectCountByProjectType" data={existingProjectCountByProjectType} itemClick={handleBarClick} />
            <h3>{getTerm("existingProjects")}</h3>
            <div name="existing-projects">
                <ExistingProjects />
            </div>
        </div>
    );
};

export default ClientExistingProjects;
