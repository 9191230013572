export const scrollIntoViewIfNeeded = (node) => {
    // Webkit browsers
    if (Element.prototype.scrollIntoViewIfNeeded) {
        node.scrollIntoViewIfNeeded();
        return;
    }

    // FF, IE, etc.
    const rect = node.getBoundingClientRect();
    const parent = node.parentNode;
    const parentRect = parent.getBoundingClientRect();

    const parentComputedStyle = window.getComputedStyle(parent, null);
    const parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue("border-top-width"), 10);

    if (rect.top < parentRect.top || rect.bottom > parentRect.bottom) {
        parent.scrollTop = node.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + node.clientHeight / 2;
    }
};
