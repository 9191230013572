import { Store } from "pullstate";

const initialState = {
    isLeftPanelStowed: false,
};

export const toggleStowLeftPanel = async () => {
    LayoutStore.update((s) => {
        s.isLeftPanelStowed = !s.isLeftPanelStowed;
        return s;
    });
};

export const LayoutStore = new Store(initialState);
