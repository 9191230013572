import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpPotentialProjects = () => {
    return (
        <div className="help-section">
            <p>
                Toggle between {getTerm("potential")} and {getTerm("existingProjects")} Lists to display data about {getTerm("potentialProjects").toLowerCase()} and {getTerm("existingProjects").toLowerCase()} (both
                closed and active projects) to provide a more tabular view of identifying project opportunities.
            </p>
            <p>The list is impacted by Filtering, and you can also filter within the table using the tools in the table header row.</p>
        </div>
    );
};

export default HelpPotentialProjects;
