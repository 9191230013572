import React, { useEffect } from "react";

import ManagedClientGroups from "./components/client-groups-managed";
import ManagedClients from "./components/clients-managed";
import ManagedProjects from "./components/projects-managed";

import { useDocumentTitle } from "$utils/document-title";
import { setHelpContent } from "$stores/help";
import { HELP_TEAM_MANAGING } from "../help/lib/help-contents";
import { EmployeeDetailsStore } from "$stores/employee-details";
import { scrollToName } from "$utils/scroll-to";
import CMManagedClientGroups from "./components/cm-client-groups-managed";

const TeamManaging = () => {
    useDocumentTitle("Employee Managing");
    const { clientGroupCount, clientManagerCount, clientCount, projectCount } = EmployeeDetailsStore.useState((s) => s.employeeDetails);

    useEffect(() => {
        setHelpContent(HELP_TEAM_MANAGING);

        const hash = window.location.hash;
        if (hash) {
            scrollToName(hash.replace("#", ""));
        }
    }, []);

    return (
        <div>
            {clientGroupCount > 0 && <ManagedClientGroups />}

            {clientManagerCount > 0 && <CMManagedClientGroups />}

            {clientCount > 0 && <ManagedClients />}

            {projectCount > 0 && <ManagedProjects />}
        </div>
    );
};

export default TeamManaging;
