import { Store } from "pullstate";

import logger from "$services/logger";
import api from "../services/api";

const initialState = {
    isLoading: false,
    comments: [],
};

export const postComment = async (projectKey, commentText) => {
    const response = await api.put(`/api/project-snapshots/${projectKey}/comments`, { commentText: commentText });
    return true;
};

export const CreateProjectCommentsStore = (projectKey) => {
    const ProjectCommentsStore = new Store(initialState);

    const setIsLoading = (isLoading) => {
        ProjectCommentsStore.update((s) => {
            s.isLoading = isLoading;
            return s;
        });
    };

    const apiComments = async (apiSettings) => {
        try {
            setIsLoading(true);

            const response = await api.get(`/api/project-snapshots/${projectKey}/comments`, null, apiSettings);
            ProjectCommentsStore.update((s) => {
                s.comments = response.comments;
                s.isLoading = false;
                return s;
            });
        } catch (e) {
            logger.error("Unable to fetch comments", e);
        }
    };

    const fetchComments = () => {
        const abortController = new AbortController();
        apiComments({ signal: abortController.signal });
        return abortController;
    };

    return {
        ProjectCommentsStore,
        setIsLoading,
        fetchComments,
    };
};
