import React from "react";
import api from "$services/api";
import { toast } from "react-toastify";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { getTerm } from "$stores/dictionary";

const FeedbackUndo = ({ id, projectKey, onSubmit }) => {
    const appInsights = useAppInsightsContext();

    const undoUserFlag = async () => {
        const res = await api.delete(`/api/projects/potential/${id}/user-flag/${projectKey}`);

        if (res) {
            toast("Concern Retracted");
            if (onSubmit) {
                onSubmit();
            }
        }

        appInsights.trackEvent(
            { name: "User Feedback" },
            {
                label: getTerm("potentialProject"),
                action: "Undo",
            }
        );
    };

    return (
        <div>
            This Project Was Flagged By You As A Concern.{" "}
            <button className="button -text" onClick={undoUserFlag}>
                Click to undo
            </button>
        </div>
    );
};

export default FeedbackUndo;
