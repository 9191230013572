import React from "react";
import { UserStore } from "$stores/user";
import ProfileIcon from "$components/profile-icon";
import TeamMember from "$components/profile-icon/team-member";

const ContactCard = ({ data, filters }) => {
    const user = UserStore.useState((s) => s);

    const overrideFilters = { crm: { text: user.displayName, value: user.id } };
    return (
        <div className="dashboard-info-card">
            {overrideFilters?.crm?.value === user.id ? (
                <div>
                    <div className="user-info">
                        <ProfileIcon imageUrl={user.headshotUri} isActive={user.isActive} name={user.displayName} modifierClassNames={["-medium", "-center"]} />
                    </div>
                    <br />
                    <div className="user-data">
                        <div className="txt -large">{user.displayName}</div>
                        <div className="txt -condensed -healthy -darker">{user.jobTitle}</div>
                    </div>
                    {!!user.lastLogin && (
                        <div className="background-line -darker -center -gray">
                            <span>Last Login: {formatDistance(new Date(user.lastLogin), new Date())} ago</span>
                        </div>
                    )}
                </div>
            ) : (
                <div className="team-members -large">
                    <TeamMember data={{ id: user.id, isActive: user.isActive, name: user.displayName, email: user.email }} modifierClassNames={["-medium"]} />
                </div>
            )}
        </div>
    );
};

export default ContactCard;
