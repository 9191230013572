import React, { useEffect } from "react";

import HealthHistory from "./components/health-history";
import RadarPolarChart from "$components/charts/radar-chart";
import ThemesTrends from "./components/themes-scores";

import { useDocumentTitle } from "$utils/document-title";
import { COLOR_FONT } from "$utils/colors";

import { ChartsStore } from "$stores/charts";
import { getTerm } from "$stores/dictionary";
import { setHelpContent } from "$stores/help";
import { HELP_CLIENT_SCORES } from "../help/lib/help-contents";

const ClientScores = () => {
    useDocumentTitle(`${getTerm("parentClient")} Scores`);

    const { clientGroupThemeScore } = ChartsStore.useState((c) => {
        return {
            clientGroupThemeScore: c.charts["clientGroupThemeScore"],
        };
    });

    useEffect(() => {
        setHelpContent(HELP_CLIENT_SCORES);
    }, []);

    return (
        <div>
            <h2>
                <em>{getTerm("parentClient")}</em> <span data-tooltip={getTerm("tooltipHealthScore")}>Health Scores</span>
            </h2>
            <div className="score-theme-charts">
                <div className="client-scores">
                    <HealthHistory />
                </div>
                <div>
                    <h3 className="txt -center">
                        <em>Overall</em> Theme Performance
                    </h3>
                    <div className="chart-theme-performance">
                        <RadarPolarChart data={clientGroupThemeScore} radarKeys={[{ name: "Theme Scores", dataKey: "value", color: COLOR_FONT }]} />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <ThemesTrends />
        </div>
    );
};

export default ClientScores;
