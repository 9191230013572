import _ from "lodash";

const recursiveParentMap = (list) => (p) => {
    const output = { ...p, value: p.id };
    var children = _.filter(list, (f) => f.parentMetadataId === p.id && f.id !== p.id).map(recursiveParentMap(list));

    output.options = children;
    return output;
};

export const mapParentTree = (list) => {
    let parents = _.filter(list, (f) => f.id === f.parentMetadataId);
    parents = parents.map(recursiveParentMap(list));
    return parents;
};

export const flattenTree = (data) => {
    const result = [];
    if (!data.options.constructor === Array) return;

    data.options.forEach((a) => {
        if (a.options && a.options.constructor === Array && a.options.length > 0) {
            result.push(...flattenTree(a));
        }
        result.push(a);
    });
    return result;
};
