const DEFAULT_STRING_REPLACE_SETTINGS = {
    pre: "#{",
    post: "}",
};

export const displayClassMap = {
    accent: "txt -bold -potential",
    bold: "txt -bold",
    importance: "txt -bold -importance",
};

export const generateDisplayWrappedData = (data) => {
    return data.reduce((obj, d) => {
        const displayClass = d.displayClass ? displayClassMap[d.displayClass.toLowerCase()] || "" : "";
        obj[d.key] = `<span class="${displayClass}">${d.value}</span>`;
        return obj;
    }, {});
};

export const stringTemplateReplace = (message, data, settings = DEFAULT_STRING_REPLACE_SETTINGS) => {
    try {
        var newString = message;
        Object.keys(data).forEach((key) => {
            const replaceKey = `${settings.pre}${key}${settings.post}`;
            newString = newString.replaceAll(replaceKey, data[key] === 0 || data[key] ? data[key] : "");
        });
        return newString;
    } catch {
        return message;
    }
};

export const encodeStringTemplateReplace = (message, data, settings = DEFAULT_STRING_REPLACE_SETTINGS) => {
    try {
        var newString = message;
        Object.keys(data).forEach((key) => {
            const replaceKey = `${settings.pre}${key}${settings.post}`;
            newString = newString.replaceAll(replaceKey, encodeURIComponent(data[key]));
        });
        return newString;
    } catch {
        return message;
    }
};

export const buildObjectTemplateReplace = (valueMap = {}, data) => {
    return Object.keys(valueMap).reduce((obj, key) => {
        obj[key] = typeof valueMap[key] === "string" ? stringTemplateReplace(valueMap[key], data) : data[key] || valueMap[key];
        return obj;
    }, {});
};
