import React, { useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PotentialProjects from "../projects/potential";

import { EmployeeDetailsStore } from "$stores/employee-details";
import { useDocumentTitle } from "$utils/document-title";
import { getTerm } from "$stores/dictionary";
import { getExpertInIcon } from "$components/profile-icon/lib/constants";
import { AppContextStore } from "$stores/app-context";
import { setHelpContent } from "$stores/help";
import { HELP_TEAM_EXPERIENCE } from "../help/lib/help-contents";

const TeamExperience = () => {
    useDocumentTitle("Employee Experience");
    const expertTypes = AppContextStore.useState((f) => f.filters?.expertType || []);
    const { employeeId } = EmployeeDetailsStore.useState((s) => {
        return { employeeId: s.employeeDetails?.id };
    });

    useEffect(() => {
        setHelpContent(HELP_TEAM_EXPERIENCE);
    }, []);

    return (
        <div>
            <h1>
                <em>Experience</em>
            </h1>
            <p>
                These are the {getTerm("potentialProjects")} that this person has been identified as an Experienced {getTerm("teamMember")} on.
            </p>
            <PotentialProjects
                settings={{
                    additionalColumns: [
                        {
                            Header: () => "Experience",
                            accessorKey: "team",
                            enableColumnFilter: false,
                            enableSorting: false,
                            id: "team",
                            sortDescFirst: true,
                            size: 100,
                            position: 4,
                            Cell: (data) => {
                                const team = data.value || [];
                                const value = team.reduce((exp, t) => {
                                    exp.push(...t.expertType);
                                    return exp;
                                }, []);
                                return (
                                    <div className="expert-in">
                                        {expertTypes.map((t) => {
                                            const isExpert = value.some((v) => v.id.toString() === t.id.toString());
                                            return (
                                                <div key={`expertIn${t.id}`} className={classNames({ "-active": isExpert })} data-tooltip={t.text}>
                                                    <span>
                                                        <FontAwesomeIcon icon={getExpertInIcon(t.id).icon} />
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );

                                //<CollapsingPills data={data.value} maxVisible={2} keyPrefix="experience" />;
                            },
                        },
                    ],
                }}
                overrideFilters={{ projectPerson: employeeId }}
                suppressGlobalFilters={true}
            />
        </div>
    );
};

export default TeamExperience;
