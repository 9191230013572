import ChartTypes from "src/pages/dashboard/wizard/1-chart-types";
import ChartTemplates from "src/pages/dashboard/wizard/3-chart-templates";
import PillOptions from "$components/wizard/questions/pill-options";

import { getTerm } from "$stores/dictionary";

/*
public enum EmployeeType
{
    Standard = 0,
    CRM = 1,
    ManagingPartner = 2,
    ProjectPIC = 3
}*/

const buildClientTemplates = (type) => {
    return {
        Comp: ChartTemplates,
        title: "Select Template",
        options: [
            {
                id: 1,
                text: `Top ${getTerm("parentClients")} By ${getTerm("growthPotential")}`,
                description: `Top 5 ${getTerm("parentClients")} By <em>${getTerm("growthPotential")}</em>`,
                metrics: ["name", "growthPotential"],
                filters: {},
                sort: { growthPotential: "desc" },
                defaultValue: [],
                template: "templateClientsByGrowthPotential",
                type: type,
                columnSize: 2,
                rowSize: 4,
            },
            {
                id: 2,
                text: `Top ${getTerm("parentClients")} By ${getTerm("t12Revenue")}`,
                description: `Top 5 ${getTerm("parentClients")} By <em>${getTerm("t12Revenue")}</em>`,
                metrics: ["name", "revenueHistoryTotal"],
                filters: {},
                sort: { revenueHistoryTotal: "desc" },
                defaultValue: [],
                template: "templateClientsByRevenue",
                type: type,
                columnSize: 2,
                rowSize: 4,
            },
            {
                id: 3,
                text: `${getTerm("parentClients")} At Risk`,
                description: `The 5 ${getTerm("parentClients")} with the <em>Lowest Health Score</em>`,
                metrics: ["name", "healthScore", "sviScore"],
                filters: {},
                sort: { healthScore: "asc" },
                defaultValue: [],
                template: "templateClientsAtRisk",
                type: type,
                columnSize: 2,
                rowSize: 4,
            },
        ],
    };
};

const buildPPTemplates = (type) => {
    return {
        Comp: ChartTemplates,
        title: "Select Template",
        options: [
            {
                id: 1,
                text: `Highest Probability ${getTerm("potentialProjects")}`,
                description: `Top 5 ${getTerm("potentialProjects")} by <em>Deal Probability</em>`,
                metrics: ["name", "averageRevenue", "probability", "clientGroupName"],
                filters: {},
                sort: { probability: "desc" },
                defaultValue: [],
                template: "templateHighestProbabilityProjects",
                type: type,
                columnSize: 2,
                rowSize: 4,
            },
            {
                id: 2,
                text: `Highest ${getTerm("growthPotential")} ${getTerm("potentialProjects")}`,
                description: `Top 5 ${getTerm("potentialProjects")} by <em>${getTerm("growthPotential")}</em>`,
                metrics: ["name", "averageRevenue", "probability", "clientGroupName"],
                filters: {},
                sort: { averageRevenue: "desc" },
                defaultValue: [],
                template: "templateMoneyMakerProbabilityProjects",
                type: type,
                columnSize: 2,
                rowSize: 4,
            },
            /*{
                id: 3,
                text: `${getTerm("potentialProjects")} By ${getTerm("growthPotential")}`,
                description: `Top 5 ${getTerm("potentialProjects")} By <em>${getTerm("growthPotential")}</em>`,
                metrics: ["name", "averageRevenue", "clientGroupName"],
                filters: {},
                sort: { averageRevenue: "desc" },
                defaultValue: [],
                template: "templateProjectsByGrowth",
                type: type,
                columnSize: 2,
                rowSize: 4,
            },*/
        ],
    };
};

export const buildQuestions = (filterMenu) => {
    return [
        {
            Comp: ChartTypes,
            title: "Select a Chart",
            options: [
                {
                    id: 1,
                    text: "Top 5",
                    value: "top5",
                    url: "/images/help/help-top-5-chart.png",
                    followUp: {
                        Comp: PillOptions,
                        title: "Select Display List",
                        options: [
                            { text: getTerm("parentClient"), value: "clientGroup", followUp: buildClientTemplates("top5", filterMenu), rootEndpoint: "/api/client-groups/?page=0&limit=5" },
                            { text: getTerm("potentialProjects"), value: "potentialProjects", followUp: buildPPTemplates("top5", filterMenu), rootEndpoint: "/api/projects/potential?page=0&limit=5" },
                            /*{ text: getTerm("existingProjects"), value: "existingProjects" },
                            { text: getTerm("projectType"), value: "projectType" },*/
                        ],
                    },
                },
                /*{
                    id: 2,
                    text: "Bar Comparison",
                    value: "barComparison",
                    url: "/images/help/help-bar-chart.png",
                    followUp: {
                        Comp: PillOptions,
                        title: "Select Display List",
                        options: [
                            {
                                text: getTerm("parentClient"),
                                value: "clientGroup",
                                followUp: {
                                    Comp: ChartTemplates,
                                    title: "Select Template",
                                    options: [
                                        {
                                            id: 1,
                                            text: `Top ${getTerm("parentClients")} By ${getTerm("growthPotential")}`,
                                            description: `Top 5 ${getTerm("parentClients")} By <em>${getTerm("growthPotential")}</em>`,
                                            metrics: ["name", "growthPotential"],
                                            filters: {},
                                            sort: { growthPotential: "desc" },
                                            defaultValue: [],
                                            template: "templateBarClientsByGrowthPotential",
                                            type: "barComparison",
                                            columnSize: 2,
                                            rowSize: 4,
                                        },
                                        {
                                            id: 2,
                                            text: `Top ${getTerm("parentClients")} By ${getTerm("t12Revenue")}`,
                                            description: `Top 5 ${getTerm("parentClient")} By <em>${getTerm("t12Revenue")}</em>`,
                                            metrics: ["name", "revenueHistoryTotal"],
                                            filters: {},
                                            sort: { revenueHistoryTotal: "desc" },
                                            defaultValue: [],
                                            template: "templateBarClientsByRevenue",
                                            type: "barComparison",
                                            columnSize: 2,
                                            rowSize: 4,
                                        },
                                    ],
                                },
                                rootEndpoint: "/api/client-groups/?page=0&limit=5",
                            },
                            {
                                text: getTerm("potentialProjects"),
                                value: "potentialProjects",
                                followUp: {
                                    Comp: ChartTemplates,
                                    title: "Select Template",
                                    options: [
                                        {
                                            id: 1,
                                            text: `Highest Probability ${getTerm("potentialProjects")}`,
                                            description: `Top 5 ${getTerm("potentialProjects")} by <em>Deal Probability</em>`,
                                            metrics: ["name", "averageRevenue", "probability", "clientName"],
                                            filters: {},
                                            sort: { probability: "desc" },
                                            defaultValue: [],
                                            template: "templateBarHighestProbabilityProjects",
                                            type: "barComparison",
                                            columnSize: 2,
                                            rowSize: 4,
                                        },
                                        {
                                            id: 2,
                                            text: `Highest ${getTerm("growthPotential")} ${getTerm("potentialProjects")}`,
                                            description: `Top 5 ${getTerm("potentialProjects")} by <em>${getTerm("growthPotential")}</em>`,
                                            metrics: ["name", "averageRevenue", "probability", "clientName"],
                                            filters: {},
                                            sort: { averageRevenue: "desc" },
                                            defaultValue: [],
                                            template: "templateBarMoneyMakerProbabilityProjects",
                                            type: "barComparison",
                                            columnSize: 2,
                                            rowSize: 4,
                                        },
                                        {
                                            id: 3,
                                            text: `${getTerm("potentialProjects")} By ${getTerm("growthPotential")}`,
                                            description: `Top 5 ${getTerm("potentialProjects")} By <em>${getTerm("growthPotential")}</em>`,
                                            metrics: ["name", "averageRevenue", "probability", "clientName"],
                                            filters: {},
                                            sort: { averageRevenue: "desc" },
                                            defaultValue: [],
                                            template: "templateBarProjectsByGrowth",
                                            type: "barComparison",
                                            columnSize: 2,
                                            rowSize: 4,
                                        },
                                    ],
                                },
                                rootEndpoint: "/api/projects/potential?page=0&limit=5",
                            },
                            { text: getTerm("existingProjects"), value: "existingProjects" },
                            { text: getTerm("projectType"), value: "projectType" },
                        ],
                    },
                },*/
                {
                    id: 3,
                    text: "Half Moon",
                    value: "halfCircle",
                    url: "/images/help/help-half-moon-chart.png",
                    followUp: {
                        Comp: PillOptions,
                        title: "Select Display List",
                        options: [
                            {
                                text: getTerm("serviceLine"),
                                value: "serviceLine",
                                rootEndpoint: "/api/dashboard",
                                followUp: {
                                    Comp: ChartTemplates,
                                    title: "Select Template",
                                    options: [
                                        {
                                            id: 1,
                                            text: `${getTerm("growthPotential")} by ${getTerm("serviceLine")}`,
                                            description: `${getTerm("growthPotential")} by <em>${getTerm("serviceLine")}</em>`,
                                            metrics: ["growthPotential", "serviceLine"],
                                            filters: { crm: 0 },
                                            sort: { probability: "desc" },
                                            defaultValue: {},
                                            template: "templateGrowthPotentialByServiceLine",
                                            type: "halfCircle",
                                            columnSize: 2,
                                            rowSize: 2,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
                {
                    id: 5,
                    text: "Info",
                    value: "info",
                    url: "/images/help/info-card.png",
                    followUp: {
                        Comp: PillOptions,
                        title: "Select Display Type",
                        options: [
                            {
                                text: "Data Refresh",
                                value: "refresh",
                                rootEndpoint: "/api/dashboard",
                                followUp: {
                                    Comp: ChartTemplates,
                                    title: "Select Template",
                                    options: [
                                        {
                                            id: 1,
                                            text: "Data Refresh",
                                            description: "Last data update",
                                            metrics: [],
                                            filters: {},
                                            sort: {},
                                            defaultValue: true,
                                            template: "templateDataRefresh",
                                            type: "refresh",
                                            columnSize: 1,
                                            rowSize: 1,
                                        },
                                    ],
                                },
                            },
                            {
                                text: "My Contact Card",
                                value: "contact",
                                rootEndpoint: "/api/employees/all",
                                followUp: {
                                    Comp: ChartTemplates,
                                    title: "Select Template",
                                    options: [
                                        {
                                            id: 1,
                                            text: "My Contact Card",
                                            description: `A quick link to your ${getTerm("team")} detail page.`,
                                            metrics: ["crm"],
                                            filters: {},
                                            sort: { crm: "asc" },
                                            defaultValue: {},
                                            template: "templateContactCard",
                                            type: "contact",
                                            columnSize: 1,
                                            rowSize: 1,
                                        },
                                    ],
                                },
                            },
                            {
                                text: "Single Stats",
                                value: "stats",
                                rootEndpoint: "/api/dashboard",
                                followUp: {
                                    Comp: PillOptions,
                                    title: "Select Template",
                                    options: [
                                        {
                                            id: 1,
                                            text: `${getTerm("t12Revenue")}`,
                                            description: `Total ${getTerm("t12Revenue")}`,
                                            metrics: ["t12Revenue"],
                                            filters: {},
                                            sort: {},
                                            defaultValue: {},
                                            template: "templateRevenue",
                                            type: "stats",
                                            columnSize: 1,
                                            rowSize: 1,
                                        },
                                        {
                                            id: 2,
                                            text: `${getTerm("growthPotential")}`,
                                            description: `Total ${getTerm("growthPotential")}`,
                                            metrics: ["growthPotential"],
                                            filters: {},
                                            sort: {},
                                            defaultValue: {},
                                            template: "templateGrowthPotential",
                                            type: "stats",
                                            columnSize: 1,
                                            rowSize: 1,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },
    ];
};
