import React from "react";
import { getTerm } from "$stores/dictionary";

const HelpTeamDashboard = () => {
    return (
        <div className="help-section">
            <p>
                The Team Detail view provides additional information about a {getTerm("teamMember")}'s specific experience across {getTerm("industries")}, {getTerm("serviceLines")}, {getTerm("projectTypes")} and{" "}
                {getTerm("parentClients")} as well as means to contact them..
            </p>
        </div>
    );
};

export default HelpTeamDashboard;
