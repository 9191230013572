import React, { useEffect, useState } from "react";

import FilterItem from "./components/filter-item";

import { AppContextStore, buildFilterResultSet } from "../../stores/app-context";
import "./filter-box.scss";

const FilterBox = () => {
    const { overview, sections } = AppContextStore.useState((a) => {
        return { sections: a.settings?.sections || {}, overview: a.overview };
    });
    const [filterData, setFilterData] = useState({});
    const [filterResultSet, setFilterResultSet] = useState(buildFilterResultSet({ geography: sections.hasOwnProperty("geography") ? sections["geography"] : false }));

    useEffect(() => {
        if (overview && overview.counts) {
            setFilterData({
                client: overview.counts.clientCount,
                clientPerson: overview.counts.crmCount,
                office: overview.counts.officeCount,
                geography: overview.counts.geographyCount,
            });
        }
    }, [overview]);

    const filterCounts = filterResultSet.map((f) => {
        return <FilterItem key={`filter_${f.key}`} item={f} count={filterData[f.key]} />;
    });

    return <div className="filter-box">{filterCounts}</div>;
};

export default FilterBox;
