import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { REACT_APP_DOMAIN_URL } from "../../services/env";
import classNames from "classnames";

import Button from "../button";
import EmployeeLookup from "./employee-lookup";
import NotificationBar from "$components/notification-bar";

import { UserStore } from "../../stores/user";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { closeModal } from "$stores/modal";
import { useEffectOnce } from "$utils/hooks/useEffectOnce";

const COMMENT_CHARACTER_LIMIT = 500;

const SendNotification = async (recipients, message, type, notificationUrl) => {
    const body = {
        recipients: [...recipients.map((r) => r.email)], // We can also send emails to other people here
        notificationUrl: notificationUrl || window.location.pathname,
        message: message,
    };

    try {
        await api.post(`/api/share/notification/${type}`, body);
        return { isSuccess: true };
    } catch (err) {
        return { isSuccess: false, error: err };
    }
};

const sendEmail = async (recipients, message, userName, pageName) => {
    const body = {
        emailRecipients: [...recipients.map((r) => r.email)], // We can also send emails to other people here
        data: {
            MC_PREVIEW_TEXT: `Orgaimi ${pageName}`,
            EMAIL_SUBJECT: `Orgaimi ${pageName}`,
            SHARE_CRITERIA: "",
            SHARE_DATE: "",
            USER_NAME: userName,
            DOMAIN_URL: REACT_APP_DOMAIN_URL,
            EMAIL_LINK: window.location.pathname,
            USER_MESSAGE: message,
        },
    };

    try {
        await api.post("/api/share", body);
        return { isSuccess: true };
    } catch (err) {
        return { isSuccess: false, error: err };
    }
};

const ShareModal = ({ pageName = document.title.split(" - ")[0], notificationUrl, type = false, PreviewComp = null, isOpen }) => {
    const appInsights = useAppInsightsContext();
    const [sent, setSent] = useState(false);
    const [message, setMessage] = useState("");
    const [recipients, setRecipients] = useState([]);
    const [errors, setErrors] = useState([]);
    const user = UserStore.useState((s) => s);

    useEffectOnce(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);

    const reset = () => {
        setSent(false);
        setMessage("");
        setErrors([]);
        setRecipients([]);
    };

    const trackSuccess = () => {
        appInsights.trackEvent(
            { name: "Share" },
            {
                label: pageName,
            }
        );
    };

    const onShare = async () => {
        const validationErrors = [];
        setErrors([]);

        if (_.isEmpty(recipients)) {
            validationErrors.push("You must select at least 1 recipient to share.");
        }
        if (message.length > COMMENT_CHARACTER_LIMIT) {
            validationErrors.push("The Comment Is Too Long");
        }

        if (validationErrors.length > 0) {
            setErrors([...validationErrors]);
            return false;
        }

        const result = !!type ? await SendNotification(recipients, message, type, notificationUrl) : await sendEmail(recipients, message, user.displayName, pageName);
        if (result.isSuccess) {
            trackSuccess();
            setSent(true);
        } else {
            setErrors(["Unable to share! Please try again later."]);
        }
    };

    const characterCount = message.length;
    const charactersLeft = COMMENT_CHARACTER_LIMIT - characterCount;
    return (
        <div className="share-modal txt -center">
            <h2 className="txt-uppercase">
                <em>Share</em> {pageName}
            </h2>
            {!!PreviewComp && <PreviewComp />}
            {!sent && (
                <>
                    {errors.length > 0 && (
                        <NotificationBar type="error" message={errors} cssClass="-border">
                            <ul>
                                {Object.keys(errors).map((k) => {
                                    return (
                                        <li key={`error_${k}`} className="txt -left">
                                            {errors[k]}
                                        </li>
                                    );
                                })}
                            </ul>
                        </NotificationBar>
                    )}
                    <h4 className="txt-uppercase">Share with:</h4>
                    <EmployeeLookup
                        onUpdate={(values) => {
                            setRecipients(values);
                        }}
                    />
                    <br />
                    <h4 className="txt-uppercase">Add Message</h4>
                    <div className="form-field -border -full">
                        <textarea
                            name="message"
                            value={message}
                            onChange={(e) => {
                                setMessage(e.currentTarget.value);
                            }}
                        ></textarea>
                    </div>
                    {characterCount >= COMMENT_CHARACTER_LIMIT - 100 && <h6 className={classNames("txt -small", { "-error": charactersLeft < 0 })}>{`${charactersLeft} characters remaining`}</h6>}
                    <div className="button-group -center">
                        <Button className="button -primary" onClick={onShare}>
                            Share
                        </Button>
                    </div>
                </>
            )}
            {sent && (
                <div>
                    <h3 className="txt-uppercase">Sent!</h3>
                    <p className="txt-small">
                        <a
                            href="/sent"
                            onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}
                        >
                            Close
                        </a>
                    </p>
                </div>
            )}
        </div>
    );
};

export default ShareModal;
