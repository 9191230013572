import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpClientList = () => {
    return (
        <div className="help-section">
            <p>
                The {getTerm("parentClient")} List provides a tabular view of all existing {getTerm("parentClient").toLowerCase()} accounts where there has been billing activity in the last 3 years. This list is impacted
                by your Filter choices. The sums at the top of the table represent the total of all the {getTerm("parentClients").toLowerCase()} in the selection.
            </p>
            <p>You can sort by click on the column headers as well as clicking on a single row to view that {getTerm("parentClient").toLowerCase()} in depth.</p>
        </div>
    );
};

export default HelpClientList;
