import React from "react";

import Top5 from "$components/top5";

const Top5Card = ({ id, data, linkTemplate, title, metrics, filters, sort, rootEndpoint }) => {
    return (
        <div>
            <Top5 data={data} filters={filters} linkTemplate={linkTemplate} title={title} type="currency" metrics={metrics} />
        </div>
    );
};

export default Top5Card;
