export const getColumnInfo = (currentColumn, span, colCount, isLast) => {
    const style = {};
    var nextColumn = 0;
    if (isLast) {
        const lastSpan = colCount - currentColumn;
        style.gridColumn = `span ${lastSpan}`;
    } else {
        if (span && currentColumn + span <= colCount) {
            style.gridColumn = `span ${span}`;
            nextColumn = currentColumn + span < colCount ? currentColumn + span : 0;
        } else {
            nextColumn = currentColumn + 1 < colCount ? currentColumn + 1 : 0;
        }
    }

    return {
        nextColumn,
        style,
    };
};
