import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

import { CHART_DEFAULT_COLORS } from "$components/charts/lib/constants";
import { abbrNum } from "$utils/number-helper";
import { formatDate, SHORT_MONTH_FORMAT } from "$utils/date-helpers";
import { DateCurrencyTooltip } from "$components/charts/tooltips/currency";
import { COLOR_BASE } from "$utils/colors";
import { GRID_STROKE } from "$components/charts/svg-definitions";

import "./project-billing.scss";

const ProjectBilling = ({ data }) => {
    return (
        <div className="project-billing">
            <ResponsiveContainer width="99%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid stroke={GRID_STROKE} vertical={false} />
                    <XAxis dataKey="date" axisLine={false} style={{ textTransform: "uppercase" }} tickFormatter={(label) => formatDate(label, SHORT_MONTH_FORMAT)} />
                    <YAxis axisLine={false} tickFormatter={(label) => `$${abbrNum(label)}`} />
                    <Tooltip content={<DateCurrencyTooltip />} contentStyle={{ border: "2px solid #de653a", backgroundColor: COLOR_BASE }} />
                    <Line type="monotone" dataKey="billed" stroke={CHART_DEFAULT_COLORS[0]} strokeWidth={4} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ProjectBilling;
