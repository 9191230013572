import React from "react";
import { format } from "date-fns";
import { buildDateFromString, SLASH_DATE_FORMAT } from "$utils/date-helpers";

const DateCell = ({ value, noValue = "-" }) => {
    if (!value) {
        return <div>{noValue}</div>;
    }
    return <div>{format(buildDateFromString(value), SLASH_DATE_FORMAT)}</div>;
};

export default DateCell;
