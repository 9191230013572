import _ from "lodash";

import AddDefaultCards from "../cards/add-default-cards";
import BarCard from "../cards/bar";
import ContactCard from "src/pages/dashboard/cards/contact";
import DataRefreshCard from "src/pages/dashboard/components/data-refresh";
import Top5Card from "src/pages/dashboard/cards/top-5";
import NoData from "$components/charts/no-content";
import SemiCircleCard from "src/pages/dashboard/cards/semi-circle-card";
import SingleStatCard from "../cards/single-stat";
import MultiSelect from "$components/form-inputs/select/multi";
import MultiRangeSlider from "$components/form-inputs/multi-range-slider";

import { abbrNum } from "$utils/number-helper";
import { getTerm } from "$stores/dictionary";
import { mapParentTree } from "$components/form-inputs/select/lib/helper";

export const getKeyMetaData = (key) => {
    switch (key) {
        case "averageRevenue":
            return {
                text: getTerm("averageRevenue"),
            };
        case "client":
            return {
                text: getTerm("parentClient"),
            };
        case "clientGroupName":
            return {
                text: `${getTerm("parentClient")} Name`,
            };
        case "clientPerson":
            const dictCM = getTerm("cm");
            const textCM = dictCM ? `/${dictCM}` : "";
            return {
                text: `${getTerm("parentCrm")}${textCM}`,
            };
        case "country":
            return {
                text: getTerm("geography"),
            };
        case "practice":
        case "serviceLine":
            return {
                text: getTerm("serviceLine"),
                renderData: (data) => {
                    return data
                        .map((d) => {
                            return d.text;
                        })
                        .join(", ");
                },
            };
        case "scoreRange":
            return {
                text: getTerm("scoreRange"),
            };
        case "practices":
            return {
                text: getTerm("serviceLines"),
                renderData: (data) => {
                    return data
                        .map((d) => {
                            return d.text;
                        })
                        .join(", ");
                },
            };
        case "healthScore":
            return {
                text: "Health Score",
            };
        case "industry":
            return {
                text: getTerm("industry"),
                renderData: (data) => {
                    return data.map((v) => v.text).join(", ");
                },
            };
        case "industries":
            return {
                text: getTerm("industry"),
                renderData: (data) => {
                    return data;
                },
            };
        case "name":
            return {
                text: "Name",
            };
        case "probability":
            return {
                text: "Probability",
            };
        case "projectPic":
            return {
                text: getTerm("projectPIC"),
            };
        case "sviScore":
            return {
                text: "SVI Score",
                renderData: (data) => {
                    return data?.min || data?.max ? <span className="txt -white">{`${abbrNum(Number(data.min || 1))} - ${abbrNum(Number(data.max || 10))}`}</span> : "Set Range";
                },
            };
        case "topProjectAmount":
            return {
                text: `Top ${getTerm("potentialProject")} Amount`,
            };
        case "topProjectType":
            return {
                text: `Top ${getTerm("potentialProject")} Type`,
            };
        case "crm":
            return {
                text: getTerm("parentCrm"),
                renderData: (data) => {
                    return data?.text;
                },
            };
        case "sviRange":
            return {
                text: "SVI Range",
            };
        case "t12Revenue":
        case "revenueHistoryTotal":
            return {
                text: getTerm("t12Revenue"),
                tooltip: getTerm("tooltip12MonthRevenue"),
            };
        case "growthPotential":
            return {
                text: getTerm("growthPotential"),
                tooltip: getTerm("tooltipGrowthPotential"),
            };
        case "revenueAtRisk":
            return {
                text: getTerm("revenueAtRisk"),
            };
        default:
            return {
                text: <span className="txt -error">Unknown ({key})</span>,
                renderData: () => {
                    return "Unparsable";
                },
            };
    }
};

export const getPersonalizedFilterByTemplate = (template) => {
    switch (template) {
        case "templateBarClientsByGrowthPotential":
        case "templateBarClientsByRevenue":
        case "templateBarProjectsByGrowth":
        case "templateBarHighestProbabilityProjects":
        case "templateBarMoneyMakerProbabilityProjects":
        case "templateClientsByRevenue":
        case "templateClientsByGrowthPotential":
        case "templateClientsAtRisk":
        case "templateProjectsByGrowth":
        case "templateHighestProbabilityProjects":
        case "templateMoneyMakerProbabilityProjects":
        case "templateGrowthPotentialByServiceLine":
        case "templateRevenue":
        case "templateGrowthPotential":
            return {
                apiKey: "crm",
                text: `Only ${getTerm("parentClients")} Where I Am The ${getTerm("parentCrm")}`,
                employeeTypes: [1],
                disableCustomFilters: false,
            };
        case "templateContactCard":
            return false; /*{
                apiKey: "crm",
                text: "My Personal Info",
                employeeTypes: [1],
                disableCustomFilters: true,
            };*/
        default:
            return false;
    }
};

export const getFiltersByTemplate = (template, filterMenu) => {
    switch (template) {
        case "templateBarClientsByGrowthPotential":
        case "templateBarClientsByRevenue":
        case "templateBarProjectsByGrowth":
        case "templateBarHighestProbabilityProjects":
        case "templateBarMoneyMakerProbabilityProjects":
        case "templateClientsByRevenue":
        case "templateClientsByGrowthPotential":
        case "templateHighestProbabilityProjects":
        case "templateMoneyMakerProbabilityProjects":
        case "templateProjectsByGrowth":
            return [
                {
                    label: getTerm("industry"),
                    key: "industry",
                    apiKey: "tableFilter.industry",
                    defaultValue: [],
                    Component: MultiSelect,
                    filterSettings: {
                        options: [...(filterMenu?.industry?.values || [])],
                    },
                    modifyData: (data) => {
                        return mapParentTree(data);
                    },
                },
                {
                    label: getTerm("serviceLine"),
                    key: "practice",
                    apiKey: "tableFilter.practice",
                    defaultValue: [],
                    Component: MultiSelect,
                    filterSettings: {
                        options: [...(filterMenu?.practice?.values || [])],
                    },
                },
            ];
        case "templateClientsAtRisk":
            return [
                {
                    label: getTerm("industry"),
                    key: "industry",
                    apiKey: "tableFilter.industry",
                    defaultValue: [],
                    Component: MultiSelect,
                    filterSettings: {
                        options: [...(filterMenu?.industry?.values || [])],
                    },
                    modifyData: (data) => {
                        return mapParentTree(data);
                    },
                },
                {
                    label: getTerm("serviceLine"),
                    key: "practice",
                    apiKey: "tableFilter.practice",
                    defaultValue: [],
                    Component: MultiSelect,
                    filterSettings: {
                        options: [...(filterMenu?.practice?.values || [])],
                    },
                },
                {
                    label: "SVI Score",
                    key: "sviScore",
                    apiKey: "tableFilter.sviScore",
                    defaultValue: {},
                    Component: MultiRangeSlider,
                    filterSettings: {
                        min: 1,
                        max: 10,
                        format: { isCurrency: false },
                        cssClasses: ["-center"],
                    },
                },
            ];
        case "templateContactCard":
            return false; /*[
                {
                    label: "User",
                    singular: "Users",
                    key: "user",
                    apiKey: "query",
                    defaultValue: [],
                    Component: MultiSelect,
                    filterSettings: {
                        optionLookup: () => {
                            return [];
                        },
                    },
                },
            ];*/
        case "templateRevenue":
        case "templateGrowthPotential":
            return false; /*[
                {
                    label: getTerm("parentClients"),
                    singular: getTerm("parentClient"),
                    key: "clientGroup",
                    apiKey: "tableFilter.clientGroup",
                    defaultValue: [],
                    Component: MultiSelect,
                    filterSettings: {},
                },
            ];*/
        default:
            return false;
    }
};

export const getLinkTmeplate = (template) => {
    switch (template) {
        case "templateClientsByGrowthPotential":
        case "templateClientsByRevenue":
        case "templateClientsAtRisk":
        case "templateBarClientsByGrowthPotential":
        case "templateBarClientsByRevenue":
            return "/client/#{id}";
        case "templateHighestProbabilityProjects":
        case "templateMoneyMakerProbabilityProjects":
        case "templateProjectsByGrowth":
        case "templateBarProjectsByGrowth":
        case "templateBarHighestProbabilityProjects":
        case "templateBarMoneyMakerProbabilityProjects":
            return "/project/#{id}/potential";
        case "templateGrowthPotentialByServiceLine":
            return "";
        default:
            return "";
    }
};

export const getTemplateDataFormatter = (template) => {
    switch (template) {
        case "templateBarClientsByGrowthPotential":
            return (data) => {
                return data?.data
                    ? data.data.map((d) => {
                          return { text: d.name, id: d.id, value: d.growthPotential, preSup: "$" };
                      })
                    : data;
            };
        case "templateBarClientsByRevenue":
            return (data) => {
                return data?.data
                    ? data.data.map((d) => {
                          return { text: d.name, id: d.id, value: d.revenueHistoryTotal, preSup: "$" };
                      })
                    : data;
            };
        case "templateBarProjectsByGrowth":
            return (data) => {
                return data?.data
                    ? data.data.map((d) => {
                          return { text: d.name, id: d.id, value: d.averageRevenue, preSup: "$" };
                      })
                    : data;
            };
        case "templateBarHighestProbabilityProjects":
            return (data) => {
                return data?.data
                    ? data.data.map((d) => {
                          return { text: d.name, id: d.id, value: d.probability };
                      })
                    : data;
            };
        case "templateBarMoneyMakerProbabilityProjects":
            return (data) => {
                return data?.data
                    ? data.data.map((d) => {
                          return { text: d.name, id: d.id, value: d.averageRevenue, preSup: "$" };
                      })
                    : data;
            };
        case "templateClientsByGrowthPotential":
        case "templateClientsByRevenue":
        case "templateClientsAtRisk":
        case "templateHighestProbabilityProjects":
        case "templateMoneyMakerProbabilityProjects":
        case "templateProjectsByGrowth":
            return (data) => {
                return data.data;
            };

        case "templateGrowthPotentialByServiceLine":
        case "templateRevenue":
        case "templateGrowthPotential":
            return (data) => {
                return {
                    chart: data?.growthPotential?.firmGrowthPotential?.serviceLines,
                    myChart: data?.growthPotential?.myGrowthPotential?.serviceLines,
                    totalValue: data?.growthPotential?.firmGrowthPotential.growthPotential,
                    growthPotential: data?.growthPotential?.firmGrowthPotential.growthPotential,
                    myGrowthPotential: data?.growthPotential?.myGrowthPotential.growthPotential,
                    t12Revenue: data?.growthPotential?.firmGrowthPotential.revenue,
                    myT12Revenue: data?.growthPotential?.myGrowthPotential.revenue,
                };
            };
        default:
            return null;
    }
};

export const getComponentByCardType = (key) => {
    switch (key) {
        case "top5":
            return Top5Card;
        /*case "pie":
            return Top5Card;*/
        case "barComparison":
            return BarCard;
        case "halfCircle":
            return SemiCircleCard;
        case "refresh":
            return DataRefreshCard;
        case "contact":
            return ContactCard;
        case "stats":
            return SingleStatCard;
        case "addDefaultCards":
            return AddDefaultCards;
        default:
            return NoData;
    }
};

export const hasEditableTitle = (type) => {
    switch (type) {
        case "contact":
        case "refresh":
            return false;
        default:
            return true;
    }
};

export const editDefaultTitle = (defaultTitle, data) => {
    const { chartType, filters, userId } = data;
    const isCrm = filters["crm"] && filters["crm"]?.value === userId;
    const showFirm = (chartType === "halfCircle" || chartType === "stats") && _.isEmpty(filters);
    return isCrm ? `${defaultTitle} For ${getTerm("parentClients")} Where I Am The ${getTerm("parentCrm")}` : showFirm ? `Firm ${defaultTitle}` : defaultTitle;
};
