import React from "react";

const NoData = ({ message = "No Data Available" }) => {
    return (
        <div className="no-data">
            <h4 className="txt -center">{message}</h4>
        </div>
    );
};

export default NoData;
