import { React, useState } from "react";
import classNames from "classnames";
import _, { isEmpty, isNull } from "lodash";

import DateInput from "$components/form-inputs/date-picker";
import MultiRangeSlider from "$components/form-inputs/multi-range-slider";
import MultiSelect from "$components/form-inputs/select/multi";

import { AppContextStore } from "$stores/app-context";
import { abbrNum, formatNumberWithCommas } from "$utils/number-helper";
import { getProbabilityRanges } from "$utils/probability-text";
import { Input, Select } from "$components/form-inputs/inputs";
import { stringMatch } from "$utils/check-match";

import "./filters.scss";

var timerFilterTooltip = null;

// Component for Default Column Filter
export function DefaultFilterForColumn(props) {
    const { id, setFilterValue, value } = props;

    return (
        <div className="table-filter">
            <Input
                value={value || ""}
                updateOnChange={(name, value) => {
                    // Set undefined to remove the filter entirely
                    if (setFilterValue) {
                        setFilterValue(id, value);
                    }
                }}
                name={id}
                placeholder={"Filter..."}
                style={{ marginTop: "10px" }}
            />
        </div>
    );
}

// Component for Custom Select Filter
export const SelectColumnFilter = (props) => {
    const { id, setFilterValue, value, options = [], label } = props;
    // UI for Multi-Select box
    return (
        <div className="table-filter">
            <MultiSelect
                name={id}
                label={label}
                value={value || []}
                updateOnChange={(name, value) => {
                    if (setFilterValue) {
                        setFilterValue(id, value || undefined);
                    }
                }}
                options={options.map((v) => {
                    return { text: v.text || v, value: v.value || v };
                })}
                cssClasses={["-small"]}
            />
        </div>
    );
};

// Component for Custom Select Filter
export const FlagColumnFilter = (props) => {
    const { id, setFilterValue, value, options = [], label } = props;
    // UI for Multi-Select box
    return (
        <div className="table-filter">
            <MultiSelect
                name={id}
                label={label}
                value={value || []}
                updateOnChange={(name, value) => {
                    if (setFilterValue) {
                        setFilterValue(id, value || undefined);
                    }
                }}
                options={options.map((v) => {
                    return { text: v.text || v, value: v.value || v };
                })}
                cssClasses={["-small"]}
            />
        </div>
    );
};

export const MultiRangeFilter = (props) => {
    const { cssClass, filters, id, setFilterValue, value } = props;

    const [showHover, setShowHover] = useState(false);
    const onChange = (name, newValue) => {
        if (setFilterValue) {
            setFilterValue(id, newValue);
        }
    };

    const useFilters = {};
    if (filters?.min) {
        useFilters.min = filters?.min;
    }
    if (filters?.max) {
        useFilters.max = filters?.max;
    }
    const buttonText =
        value?.min || value?.max ? (
            <span className="txt -white">
                {filters?.format?.isCurrency && <sup>$</sup>}
                {`${abbrNum(Number(value.min || useFilters.min || 0))} - ${abbrNum(Number(value.max || useFilters.max))}`}
            </span>
        ) : (
            "Set Range"
        );

    return (
        <div className="table-filter">
            <button
                className="button -micro -border -dark"
                onClick={() => {
                    setShowHover(!showHover);
                }}
            >
                {buttonText}
            </button>
            <div
                className={classNames("filter-tooltip", { "-active": showHover, [cssClass]: !!cssClass })}
                onMouseEnter={() => {
                    clearTimeout(timerFilterTooltip);
                }}
                onMouseLeave={() => {
                    timerFilterTooltip = setTimeout(() => {
                        setShowHover(false);
                    }, 1000);
                }}
            >
                <MultiRangeSlider
                    name={id}
                    defaultValue={value}
                    {...useFilters}
                    updateOnChange={onChange}
                    closeMenu={() => {
                        setShowHover(false);
                    }}
                    format={{ isCurrency: filters?.format?.isCurrency }}
                />
            </div>
        </div>
    );
};

export const ProbabilityRange = ({ id, setFilterValue, value = {} }) => {
    const { probabilityMatrix } = AppContextStore.useState((a) => {
        return { probabilityMatrix: a.siteConfig?.probabilityMatrix };
    });
    const probabilityRanges = getProbabilityRanges(probabilityMatrix);
    return (
        <div className="table-filter">
            <button
                className={classNames("button -micro -border -dark", { "-active": value.hasOwnProperty("low") })}
                onClick={() => {
                    const newValue = { ...value };
                    if (newValue.hasOwnProperty("low")) {
                        delete newValue.low;
                    } else {
                        newValue.low = probabilityRanges.low;
                    }

                    setFilterValue(id, isEmpty(newValue) ? "" : newValue);
                }}
            >
                Low
            </button>
            <button
                className={classNames("button -micro -border -dark", { "-active": value.hasOwnProperty("medium") })}
                onClick={() => {
                    const newValue = { ...value };
                    if (newValue.hasOwnProperty("medium")) {
                        delete newValue.medium;
                    } else {
                        newValue.medium = probabilityRanges.medium;
                    }

                    setFilterValue(id, isEmpty(newValue) ? "" : newValue);
                }}
            >
                Medium
            </button>
            <button
                className={classNames("button -micro -border -dark", { "-active": value.hasOwnProperty("high") })}
                onClick={() => {
                    const newValue = { ...value };
                    if (newValue.hasOwnProperty("high")) {
                        delete newValue.high;
                    } else {
                        newValue.high = probabilityRanges.high;
                    }

                    setFilterValue(id, isEmpty(newValue) ? "" : newValue);
                }}
            >
                High
            </button>
        </div>
    );
};

export const DateFilter = ({ id, setFilterValue, value, label }) => {
    const onChange = (name, newValue) => {
        if (setFilterValue && newValue && !isNaN(new Date(newValue))) {
            setFilterValue(id, new Date(newValue).toISOString());
        }
        if (isNull(newValue)) {
            setFilterValue(id, null);
        }
    };
    return (
        <div className="table-filter">
            <DateInput
                id={`filter_data_${id}`}
                label={label}
                defaultValue={value}
                updateOnChange={onChange}
                popperModifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [-65, 14],
                        },
                    },
                ]}
            />
        </div>
    );
};
