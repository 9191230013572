import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { AppContextStore, updateGlobalError } from "../../stores/app-context";

const GlobalError = () => {
    const globalError = AppContextStore.useState((ac) => ac.globalError);
    const [message, setMessage] = useState(null);
    // useEffect(() => {

    useEffect(() => {
        if (globalError) {
            setTimeout(() => {
                updateGlobalError(null);
            }, 5000);

            setTimeout(() => {
                setMessage(globalError);
            }, 500);
        } else {
            setMessage(null);
        }
        // eslint-disable-next-line
    }, [globalError]);
    return <div className={classNames("global-error", { "-visible": !!globalError })}>{message}</div>;
};

export default GlobalError;
