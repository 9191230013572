import { abbrNum } from "$utils/number-helper";
import React from "react";

export const SVIScoreRevenueLabelTooltip = ({ active, payload }) => {
    if (active) {
        const activePayload = payload[0] || {};
        return (
            <div className="chart-tooltip -dark">
                <h4>
                    <em>{activePayload?.payload.label || activePayload.label}</em>
                </h4>
                <br />
                <div>
                    T12 Revenue: <b>${abbrNum(activePayload?.payload?.revenue)}</b>
                </div>
                <br />
                <div>
                    Health Score: <b>{activePayload?.payload?.healthScore}</b>
                </div>
                <br />
                <div>
                    SVI Score: <b>{Math.round(activePayload?.payload?.svi)}</b>
                </div>
            </div>
        );
    }
    return null;
};
