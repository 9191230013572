import classNames from "classnames";
import React from "react";

const LegendLabel = ({ color, label, labelId, handleClick }) => {
    return (
        <span
            className={classNames("recharts-legend-item-text", { "txt -cursor": !!handleClick })}
            style={{ color: color }}
            onClick={() => {
                handleClick({ id: labelId, text: label });
            }}
        >
            {label}
        </span>
    );
};

export default LegendLabel;
