import React from "react";

import Select from "$components/form-inputs/select";
import { useNavigate } from "react-router-dom";

const MobileClientNav = ({ basepath, hasSubClients, navLinks }) => {
    const navigate = useNavigate();
    const navigateTo = (text, value) => {
        navigate(value.url);
    };

    const mobileNavigation = navLinks.reduce((arr, n) => {
        if (n.requiresSubClients && !hasSubClients) {
            return arr;
        }
        arr.push({ ...n, url: `${basepath}${n.url}`, value: n.text });
        return arr;
    }, []);
    const currentNavItem = mobileNavigation.find((l) => l.url === window.location.pathname);

    return (
        <div className="mobile-client-nav">
            <Select name="mobile-navigation" label="Navigation" defaultValue={currentNavItem} options={mobileNavigation} updateOnChange={navigateTo} />
        </div>
    );
};

export default MobileClientNav;
