import React from "react";
import _ from "lodash";

import CollapsingPills from "$components/table/cells/collapsing-pills";

//👇 This default export determines where your story goes in the story list
export default {
    title: "Table Cells",
};

const testData = _.times(8, (i) => {
    return { id: i, text: `Test ${i + 1}` };
});

export const CollapsingPillList = () => {
    return (
        <div>
            <CollapsingPills data={testData} maxVisible={3} />
        </div>
    );
};
