import React from "react";
import { getTerm } from "$stores/dictionary";

const HelpTeamManaging = () => {
    return (
        <div className="help-section">
            <p>
                A listing of all the {getTerm("parentClients")} or {getTerm("subClients")} where the individual is a{!!getTerm("cm") ? ` ${getTerm("cm")} or` : ""} {getTerm("parentCrm")} along with any{" "}
                {getTerm("existingProjects")} where the individual is the {getTerm("projectPIC")}.
            </p>
        </div>
    );
};

export default HelpTeamManaging;
