import React, { useEffect, useMemo, useState } from "react";
import { getDigitsAndTemplate, runTemplate } from "./utils";

const COUNT_SPEED = 60; //ms

const getIncremeneter = (num) => {
    let incrementer = 1;

    if (num >= 10) {
        return Math.ceil(num / 10);
    }
    return incrementer;
};

const CountUp = ({ num }) => {
    const [display, setDisplay] = useState(0);
    // eslint-disable-next-line
    let activeTimeouts = useMemo(() => [], [num]);

    const counter = (incrementer, template) => (current, target, countUp) => {
        if (current <= target) {
            setDisplay(runTemplate(current, template));
            const timeout = setTimeout(() => {
                countUp(current + incrementer, target, countUp);
            }, COUNT_SPEED);
            activeTimeouts.push(timeout);
            return;
        }
        setDisplay(runTemplate(target, template));
    };

    useEffect(() => {
        if (num) {
            const cleanNumber = getDigitsAndTemplate(num);
            const incrementer = getIncremeneter(cleanNumber.num);
            const counterFunction = counter(incrementer, cleanNumber.template);
            counterFunction(0, cleanNumber.num, counterFunction);
        } else {
            setDisplay(0);
        }

        return () => {
            activeTimeouts.forEach((timeout) => clearTimeout(timeout));
        };
        // eslint-disable-next-line
    }, [num]);
    return <>{display}</>;
};

export default CountUp;
