import React from "react";
import MDEditor from "@uiw/react-md-editor";

const NotificationDefinitionEdit = ({ definition }) => {
    const [subject, setSubject] = React.useState(definition.subjectTemplate);
    const [message, setMessage] = React.useState(definition.messageTemplate);
    const [messageLayout, setMessageLayout] = React.useState(definition.messageHtmlLayout);

    return (
        <div>
            <h2>
                {definition.eventType}: {definition.type}{" "}
            </h2>

            <div>
                <label>Subject</label>
                <textarea
                    value={subject}
                    onChange={(e) => {
                        setSubject(e.currentTarget.value);
                    }}
                ></textarea>
            </div>
            <div data-color-mode="light">
                <label>Message</label>
                <MDEditor
                    height={200}
                    value={message}
                    onChange={(v) => {
                        setMessage(v);
                    }}
                />
            </div>
            <div data-color-mode="light">
                <label>HTML</label>
                <input
                    type="text"
                    value={messageLayout}
                    onChange={(e) => {
                        setMessageLayout(e.currentTarget.value);
                    }}
                />
            </div>
        </div>
    );
};

export default NotificationDefinitionEdit;
