import React, { useEffect, useRef } from "react";

import RadarPolarChart from "$components/charts/radar-chart";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import useOnScreen from "$utils/hooks/use-onscreen";
import { COLOR_BASE_DARK, COLOR_FONT, COLOR_POTENTIAL } from "$utils/colors";

const InsightPanel = ({ office, data }) => {
    const appInsights = useAppInsightsContext();
    const [chartData, setChartData] = React.useState();
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    useEffect(() => {
        // Track Event
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: "Insights",
                section: "Office Panel",
            }
        );
    }, []);

    useEffect(() => {
        if (data?.chart);
        {
            setChartData(data.chart);
        }
    }, [data]);

    return (
        <div ref={ref} className="insight-panel">
            <h3>Key Drivers</h3>
            <p className="office-info">
                {office.officeName} | {office.officeManagingPartner}
            </p>
            {isVisible && !chartData && <p>No data available</p>}
            {isVisible && chartData && (
                <>
                    <div>
                        <ul className="legend">
                            <li className="selected">Selected Office</li>
                            <li className="average">Average of Offices</li>
                        </ul>
                    </div>
                    <div className="chart-item">
                        <RadarPolarChart
                            data={chartData}
                            radarKeys={[
                                { dataKey: "averageValue", color: COLOR_FONT },
                                { dataKey: "officeValue", color: COLOR_POTENTIAL },
                            ]}
                            gridStroke={COLOR_BASE_DARK}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default InsightPanel;
