import React from "react";

import InitialsCell from "$components/table/cells/initials";
import LogoCell from "$components/table/cells/logo";

import { getTerm } from "$stores/dictionary";
import { SelectColumnFilter } from "$components/table/components/filters/filter";

export const buildEmployeeProjectsColumns = ({ filterOptions }) => {
    return [
        {
            Header: () => getTerm("existingProject"),
            accessorKey: "name", // accessor is the "key" in the data
            id: "name", // id is used as a sort key (i.e., actual api name)
        },
        {
            Header: () => getTerm("projectType"),
            accessorKey: "projectType", // accessor is the "key" in the data
            id: "projectType", // id is used as a sort key (i.e., actual api name)
        },
        {
            Header: () => getTerm("parentClient"),
            accessorKey: "clientGroupName",
            id: "clientGroupName",
            Cell: ({ data, value }) => <LogoCell value={value} logoUrl={""} />, // TODO: Logourl
        },
        {
            Header: () => getTerm("subClient"),
            accessorKey: "clientName",
            id: "clientName",
            className: "",
        },
        {
            Header: () => getTerm("industry"),
            accessorKey: "industry",
            size: 120,
        },
        {
            Header: () => getTerm("serviceLine"),
            accessorKey: "serviceLine",
            size: 120,
            Filter: SelectColumnFilter,
            filterOptions: { options: filterOptions?.practice?.values || [] },
        },
        {
            Header: () => getTerm("projectPIC"),
            accessorKey: "projectPic", // accessor is the "key" in the data
            id: "projectPicName", // id is used as a sort key (i.e., actual api name)
            Cell: ({ data, value }) => <InitialsCell isActive={data.projectPicIsActive} value={value} totalWidth={2} />, // TODO: getSize?
        },
    ];
};
