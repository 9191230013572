import React from "react";

import StatWithChange from "./stat";

import "./revenue-stats.scss";
import { getTerm } from "$stores/dictionary";

const RevenueStats = ({ data }) => {
    return (
        <div className="revenue-stat-list">
            <StatWithChange label={getTerm("t12Revenue")} tooltip={getTerm("tooltip12MonthRevenue")} value={data?.twelveMonthRevenue} increase={Math.round(data?.twelveMonthRevenueChangePercent || 0)} isHealthy={true} />
            <StatWithChange label={getTerm("growthPotential")} tooltip={getTerm("tooltipGrowthPotential")} value={data?.growthPotential} increase={Math.round(data?.growthPotentialChangePercent || 0)} />
            <StatWithChange label={getTerm("revenueAtRisk")} value={data?.revenueAtRisk} increase={data?.revenueAtRiskChangePercent} />
        </div>
    );
};

export default RevenueStats;
