import React, { useEffect, useState } from "react";
import debounce from "debounce";
import { useSearchParams } from "react-router-dom";

import EmployeeList from "./list";

import { EmployeeStore, fetchEmployees, setLoading, reset, setPagingInfo } from "$stores/employee-list";
import { useDocumentTitle } from "$utils/document-title";
import { AppContextStore } from "$stores/app-context";
import { useEffectOnce } from "$utils/hooks/useEffectOnce";

const debouncedFetchEmployees = debounce(async (updatedFilters, pagingInfo) => {
    fetchEmployees("", { keyPrefix: "tableFilter.", ...pagingInfo });
}, 800);

const Employees = () => {
    useDocumentTitle("Employee List");
    const [searchParams] = useSearchParams();
    const [initialLoad, setInitialLoad] = useState(true);
    const { hasLoadedFilters, currentFilters } = AppContextStore.useState((f) => {
        return { hasLoadedFilters: f.hasLoadedFilters, currentFilters: f.currentFilters || {} };
    });

    const pagingInfo = EmployeeStore.useState((f) => {
        return { filters: f.filters, page: f.pageIndex, limit: f.pageSize, sort: f.sort, filters: f.filters, isSortAsc: f.isSortAsc };
    });

    useEffectOnce(() => {
        return () => {
            // Resets Pagination
            reset();
        };
    }, []);

    useEffect(() => {
        if (currentFilters && pagingInfo) {
            setLoading();
            debouncedFetchEmployees(currentFilters, pagingInfo);
        }
    }, [hasLoadedFilters, currentFilters, pagingInfo]);

    useEffect(() => {
        if (initialLoad && hasLoadedFilters) {
            const updatables = {};
            const sort = JSON.parse(searchParams.get("sort"));
            //const filter = JSON.parse(searchParams.get("filter"));
            const page = searchParams.get("pageIndex");

            if (sort) updatables.sort = sort;
            //if (filter) updatables.filter = filter;
            if (page) updatables.pageIndex = page;

            if (!_.isEmpty(updatables)) {
                setPagingInfo(updatables);
            }
            setInitialLoad(false);
        }
    }, [initialLoad, hasLoadedFilters]);

    return (
        <div>
            <EmployeeList />
        </div>
    );
};

export default Employees;
