import React from "react";

const PercentLegend = ({ data, color }) => {
    const showValue = data.value ? (
        <span>
            :&nbsp;<span className="txt -gray">{data.value.toFixed(1)}%</span>
        </span>
    ) : null;
    return (
        <div className="external-label">
            <span className="color-swatch" style={{ backgroundColor: color }}></span> {data.label}
            {showValue}
        </div>
    );
};

export default PercentLegend;
