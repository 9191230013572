export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
}

export const extractValue = (value, key = "value") => {
    return typeof value === "string" || !isNaN(value) ? value : value && value.hasOwnProperty(key) ? value[key] : "";
};

function getElementValue(e) {
    if (!e.currentTarget) {
        return "";
    }
    const type = e.currentTarget.type;
    switch (type) {
        case "checkbox":
            return e.currentTarget.checked;
        default:
            return e.currentTarget.value;
    }
}

export const updateFn = (fn) => {
    if (!fn || !isFunction(fn)) {
        return null;
    }

    return (e) => {
        const value = getElementValue(e);
        const name = e.currentTarget.name;
        return fn(name, value);
    };
};
