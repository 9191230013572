import { compact } from "lodash";

const excludeSuffixes = ["jr", "jr.", "sr", "sr.", "i", "ii", "iii", "iv", "phd", "ph.d.", "md", "m.d.", "esq", "esq."];

export const getInitials = (name) => {
    if (!name) {
        return "";
    }
    const splitName = compact(
        name
            .replace(/\([^)]*\)/g, "")
            .trim("")
            .split(" ")
    );

    if (excludeSuffixes.includes(splitName[splitName.length - 1].toLowerCase())) {
        splitName.pop();
    }

    return splitName.length === 1 ? splitName[0].charAt(0) : `${splitName[0].charAt(0)}${splitName.reverse()[0].charAt(0)}`;
};
