import { REACT_APP_FEATURE_TEAMS } from "$services/env";
import { getTerm } from "$stores/dictionary";

export const buildMainLinks = (settings = {}) => {
    const mainLinks = [
        {
            path: "/",
            label: "Dashboard",
        },
        {
            path: "/segments",
            label: "Segments",
        },
        {
            key: "offices",
            path: "/offices",
            label: "Offices",
        },
        {
            path: "/clients",
            label: getTerm("parentClients"),
        },
        {
            path: "/projects",
            label: getTerm("projects"),
        },
        {
            path: "/team",
            label: "Team",
        },
    ];

    return mainLinks.reduce((arr, l) => {
        if (l.key && settings.hasOwnProperty(l.key) && settings[l.key] === false) {
            return arr;
        }

        arr.push(l);
        return arr;
    }, []);
};

export const PAGES_WITH_FILTER = ["/segments", "/offices", "/clients", "/projects/potential", "/projects/existing", "/shared-filters"];
