import { Store } from "pullstate";
import { persistValue, retrieveValue } from "$services/local";
import api from "../services/api";
import { clearValue } from "$services/local";
import logger from "$services/logger";

export const DICTIONARY_DATA_KEY = "dictionary-data";
const DICTIONARY_DATA_TTL = 60 * 60 * 24 * 7; // 7 days

export const DictionaryLoadingState = new Store(false);

function dictionaryGetter(target, name) {
    return target[name] === undefined ? name : target[name].term;
}

function defaultDictionary() {
    return new Proxy(
        {},
        {
            get: dictionaryGetter,
        }
    );
}

export let dictionary = defaultDictionary();

export const getTerm = (key) => {
    return dictionary[key];
};

export const updateDictionary = async (dictionaryChanges) => {
    await api.put("/api/admin/dictionary", dictionaryChanges);
    return true;
};

export const resetDictionary = async () => {
    return clearValue(DICTIONARY_DATA_KEY);
};

export const initDictionary = async () => {
    try {
        let dictionaryData = await retrieveValue(DICTIONARY_DATA_KEY);
        if (!dictionaryData) {
            dictionaryData = await api.get("/api/dictionary");
            if (!!dictionaryData && Object.keys(dictionaryData).length > 0) {
                await persistValue(DICTIONARY_DATA_KEY, dictionaryData, DICTIONARY_DATA_TTL);
            }
        }

        dictionary = new Proxy(dictionaryData, {
            get: dictionaryGetter,
        });

        DictionaryLoadingState.update((s) => true);
    } catch (e) {
        DictionaryLoadingState.update((s) => true);
        clearValue(DICTIONARY_DATA_KEY);
        logger.error(e);
    }
    return;
};
