import "./styles/main.scss";

import React, { useEffect, useState } from "react";

import classnames from "classnames";
import logger from "./services/logger";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { appInsights, reactPlugin } from "$utils/app-insights";
import { ToastContainer } from "react-toastify";
import { SVGDefinitions } from "$components/charts/svg-definitions";
import { LayoutStore } from "./stores/layout";
import { DictionaryLoadingState } from "./stores/dictionary";
import { UserStore, retrieveExistingUser, testLoginAction } from "./stores/user";

import HaltAndCatchFire from "./components/critical-error";
import AppLoader from "./components/loaders/app-loader";
import RouteMap from "./RouteMap";

import { useIdleTimer } from "react-idle-timer";
import { AppContextStore, fetchFilters, fetchVersions, initFilterMenu, initSiteSettings, setHaltAndCatchFire } from "./stores/app-context";
import { useMsal } from "@azure/msal-react";
import { handleAbort } from "$services/api";
import { initDictionary } from "$stores/dictionary";
import { buildResetRedirectPath } from "$utils/api-helpers";

const TIMEOUT = 3600000; // 1 hour
const TIMEOUT_DEBOUNCE = 10000; // 10 seconds

// Override Auth Token for Testing
if (process.env.NODE_ENV === "development" || process.env.REACT_APP_ENV === "test") {
    const querystring = window.location.search;
    if (querystring) {
        const urlParams = new URLSearchParams(querystring);
        const ingestToken = urlParams.get("token-override");
        if (ingestToken) {
            testLoginAction(ingestToken).then(() => {
                window.location = "/";
            });
        }
    }
}

const App = () => {
    const layoutProperties = LayoutStore.useState((s) => s);
    const { isCriticalError, isVersionsLoading } = AppContextStore.useState((s) => {
        return { isVersionsLoading: s.versionsLoading, isCriticalError: s.isHaltAndCatchFire };
    });
    const [isLoading, setIsLoading] = useState(true);
    const { inProgress, accounts, instance } = useMsal();
    const user = UserStore.useState((s) => s);
    const dictionaryLoaded = DictionaryLoadingState.useState((s) => s);
    const { start } = useIdleTimer({
        timeout: TIMEOUT,
        onIdle: () => {
            window.location = buildResetRedirectPath(window.location.pathname);
        },
        debounce: TIMEOUT_DEBOUNCE,
        startManually: true,
    });
    // only start the timer when we're logged in
    // this prevents the timer from running on the login page
    if (user && user.isAuthed) {
        start();
    }

    useEffect(() => {
        const versionsController = fetchVersions();
        initDictionary().then(() => {
            initFilterMenu();
        });

        return () => {
            handleAbort([versionsController]);
        };
    }, []);

    useEffect(() => {
        retrieveExistingUser(inProgress, accounts, instance);
    }, [inProgress, accounts, instance]);

    useEffect(() => {
        let filtersController;
        if (user.id) {
            appInsights.setAuthenticatedUserContext(user.id.toString());
        }
        if (user.hasLoaded && user.isAuthed) {
            try {
                filtersController = fetchFilters();
                initSiteSettings();
            } catch (e) {
                logger.error(e);
                setHaltAndCatchFire(true);
            }
        }
        setIsLoading(!user.hasLoaded || !dictionaryLoaded);

        return () => {
            handleAbort([filtersController]);
        };
    }, [user, dictionaryLoaded]);

    if (isCriticalError) {
        return <HaltAndCatchFire />;
    }

    return (
        <div className={classnames("App", { "-stowed": layoutProperties.isLeftPanelStowed })}>
            <AppInsightsContext.Provider value={reactPlugin}>
                <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                <SVGDefinitions />
                <div id="panel-root"></div>
                {isLoading && isVersionsLoading && <AppLoader />}
                {!isLoading && !isVersionsLoading && <RouteMap />}
            </AppInsightsContext.Provider>
        </div>
    );
};

export default App;
