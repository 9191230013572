import React from "react";

import HealthArrow from "$components/health-arrow";
import LargeNumber from "$components/info-list/content/large-number";

import { checkIsHealthyByChange } from "$utils/health-helper";

import "./amount-with-change.scss";
import { formatUIValue } from "$utils/number-helper";

const AmountWithChange = ({ value, changeValue, changePercent, isHealthy }) => {
    const arrowIncrease = typeof isIncrease == "boolean" ? isIncrease : checkIsHealthyByChange(value);
    return (
        <div className="amount-with-change">
            <LargeNumber value={value} isHealthy={isHealthy} />
            <div className="amount-change-group">
                <h5>Monthly Change</h5>
                <div className="amount-change">
                    <h4 className="txt -white">{formatUIValue(changeValue)}</h4>
                    <HealthArrow isIncrease={arrowIncrease} />
                    <h4>
                        {formatUIValue(changePercent)}
                        <sup>%</sup>
                    </h4>
                </div>
            </div>
        </div>
    );
};

export default AmountWithChange;
