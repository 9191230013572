import React, { useEffect, useState } from "react";
import debounce from "debounce";
import { useNavigate } from "react-router-dom";

import TableLoader from "$components/table/table-loader.js";

import { ExistingProjectsStore, setFilters, setPageIndex, setPagingInfo, setPerPage, setSort } from "../../../stores/existingProjects.js";
import { buildDefaultColumns } from "./libs/columns.js";
import { setHelpContent } from "$stores/help.js";
import { HELP_EXISTING_PROJECT_LIST } from "src/pages/help/lib/help-contents.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";

export const mapToTableData = (c) => {
    return {
        key: c.id,
        ...c,
    };
};

const ExistingProjectList = ({ visibleColumns }) => {
    const navigate = useNavigate();
    const filterMenu = AppContextStore.useState((f) => mapByKey(f.filterMenu, "apiKey") || {});
    const { filters, globalFilterRanges, projects, pageIndex, pageSize, pageCount, isLoading, sort, isSortAsc } = ExistingProjectsStore.useState((c) => {
        return {
            globalFilterRanges: c.globalFilterRanges,
            projects: c.existingProjects || [],
            filters: c.filters,
            pageSize: c.pageSize,
            pageIndex: c.pageIndex,
            pageCount: c.pageCount,
            isLoading: c.isLoading,
            sort: c.sort,
            isSortAsc: c.isSortAsc,
        };
    });
    const [defaultColumns, setDefaultColumns] = useState(buildDefaultColumns({ visibleColumns, filterOptions: filterMenu, globalFilterRanges }));

    useEffect(() => {
        setHelpContent(HELP_EXISTING_PROJECT_LIST);
    }, []);

    useEffect(() => {
        setDefaultColumns(buildDefaultColumns({ visibleColumns, filterOptions: filterMenu, globalFilterRanges }));
    }, [filterMenu, globalFilterRanges]);

    const rowClick = (row) => {
        navigate(`/project/${row.key}/existing`);
    };

    const data = projects.map(mapToTableData);

    const debouncedSetPagingInfo = debounce(setPagingInfo, 500);

    return (
        <TableLoader
            allowFilter={true}
            columns={defaultColumns}
            containerClass="project-list"
            data={data}
            filters={filters}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPagingInfo={debouncedSetPagingInfo}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default ExistingProjectList;
