import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { UserStore } from "$stores/user";
import { buildRedirectWithPath } from "$utils/api-helpers";

const AuthRoute = ({ component: Component, ...rest }) => {
    const auth = UserStore.useState((s) => s.isAuthed);

    let location = useLocation();
    const redirect = buildRedirectWithPath("/", `${location.pathname}${location.search}`);
    if (!auth) {
        return <Navigate to={redirect} state={{ from: location }} />;
    }

    return <Outlet />;
};
export default AuthRoute;
