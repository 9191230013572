import React from "react";

import HelpNavigation from "./components/navigation";

import { AppContextStore } from "$stores/app-context";
import { getTerm } from "$stores/dictionary";
import { useDocumentTitle } from "$utils/document-title";

import "./help.scss";

const HelpIndex = () => {
    const { siteKey } = AppContextStore.useState((a) => {
        return { siteKey: a.siteKey };
    });

    useDocumentTitle("Help");
    return (
        <div id="help" className="page">
            <div className="main">
                <aside>
                    <HelpNavigation />
                </aside>
                <main>
                    <h1>Help</h1>

                    <h2 name="overview">
                        <em>Overview of Orgaimi</em>
                    </h2>
                    <div className="help-images">
                        <div>
                            <a href="https://www.linkedin.com/posts/orgaimi_orgaimi-platform-activity-6919659681120411648-QD4u?utm_source=linkedin_share&utm_medium=member_help_web" target="_blank">
                                <img src="/images/help/overview-video.jpg" alt="Overview Video" />
                            </a>
                        </div>
                    </div>
                    <div className="help-section">
                        <ol>
                            <li>Simplify your firm's approach to expanding client relationships with key predictions, intelligent insights, and timely recommendations from Orgaimi.</li>
                            <li>
                                Orgaimi is your firm's own predictive model that learns from past successes with similar clients based on Finance and Client Relationship Management (CRM) data, and helps you identify
                                where clients are underserved and opportunities are untapped.
                            </li>
                            <li>Orgaimi creates the following primary outputs to help partners, business development executives, marketing teams, and leaders achieve greater value for their clients:</li>
                            <ol>
                                <li>
                                    <b>{getTerm("growthPotential")}:</b> Quantification of how much value has yet to be untapped within each client account, based on how many {getTerm("potentialProjects").toLowerCase()}{" "}
                                    are projected in the next year.
                                </li>
                                <li>
                                    <b>{getTerm("potentialProjects")}:</b> Untapped service opportunities for each client based on the client's pattern of services purchased with your firm, lifetime value of the client,
                                    and industry grouping.
                                </li>
                                <li>
                                    <b>Client Management Recommendations:</b> Validated trends over time that when implemented will increase likelihood of growth.
                                </li>
                                <li>
                                    <b>Client Health Scores:</b> Indication of how well your historical client management actions suggest {getTerm("growthPotential").toLowerCase()} for the future.
                                </li>
                            </ol>
                        </ol>
                    </div>

                    <h2 name="use-cases" className="txt -primary">
                        Primary Use Cases
                    </h2>
                    <div className="help-section">
                        <ol>
                            <li>
                                Orgaimi's primary use case is to make it as easy as possible for partners and business developers to identify untapped {getTerm("growthPotential").toLowerCase()} and{" "}
                                {getTerm("parentClients").toLowerCase()} and help them start the journey to capture {getTerm("potentialProjects").toLowerCase()} and improve client health.
                            </li>
                            <li>Each user persona has a unique way of achieving this goal through Orgaimi:</li>
                            <ol>
                                <li>
                                    <b>
                                        {getTerm("parentCustomerRelationsManager")}
                                        {getTerm("clientManager") ? ` & ${getTerm("clientManager")}` : ""}:
                                    </b>{" "}
                                    Orgaimi helps {getTerm("parentCustomerRelationsManager")} {getTerm("clientManagers") ? ` & ${getTerm("clientManagers")}` : ""} to focus on the accounts with the most{" "}
                                    {getTerm("growthPotential").toLowerCase()}, connect them to the {getTerm("potentialProjects").toLowerCase()} and firm {getTerm("teamMembers")} that will drive expansion, and alert them
                                    to potential trends that could indicate the client is being underserved.
                                </li>
                                <li>
                                    <b>{getTerm("projectPersonInCharge")} :</b> Orgaimi helps the {getTerm("projectPersonInCharge")} to identify new {getTerm("potentialProjects").toLowerCase()} for clients they are
                                    currently supporting through {getTerm("existingProjects").toLowerCase()} and alert them to potential trends that could indicate the cleint is being underserved.
                                </li>
                                <li>
                                    <b>Business Development/Marketing Executive:</b> Orgaimi helps Business Development Executives identify {getTerm("potentialProjects").toLowerCase()} with the most{" "}
                                    {getTerm("growthPotential").toLowerCase()} and probability, with the ability to aggregate to spot overall trends for sub-segments of the firm.
                                </li>
                                <li>
                                    <b>Firm Leader:</b> Orgaimi helps Firm Leaders understand aggregate trends in performance across {getTerm("industries").toLowerCase()}, {getTerm("serviceLines").toLowerCase()}, and
                                    geographies, identifying how to best support the {getTerm("parentCustomerRelationsManager")} and the {getTerm("projectPersonInCharge")}.
                                </li>
                            </ol>
                        </ol>
                    </div>

                    <h2 name="potential-projects-core">
                        <em>{getTerm("potentialProjects")}:</em> Core Concepts
                    </h2>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/potential-projects-core.jpg" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            <b>{getTerm("potentialProjects")}</b> are untapped service opportunities for a client based on the client's pattern of services purchased with your firm, lifetime value of the client, and
                            industry grouping.
                        </p>
                        <h4>How are {getTerm("potentialProjects")} Identified?</h4>
                        <ul>
                            <li>
                                Every existing client has a journey with you as a firm: what type of work they initially engaged with you on, how well you performed at the beginning of the relationship, and how that
                                relationship transformed over time.
                            </li>
                            <li>Orgaimi's AI processors measure the hundreds of thousands of unique journeys to spot patterns in the data of outcomes that have led to sustained, strategic growth.</li>
                            <li>
                                Orgaimi compares journeys of "like clients" using the following segmentation:
                                <ul>
                                    <li>Service History</li>
                                    <li>{getTerm("industry")} Grouping</li>
                                    <li>Client Tenure</li>
                                    <li>Client Location</li>
                                    <li>Practice Team Penetration</li>
                                    <li>Total Number of Matters</li>
                                    <li>
                                        {getTerm("revenue")} Total and {getTerm("growthPotential")} of the Client over 12, 24 and 36 months
                                    </li>
                                </ul>
                            </li>
                            <li>Orgaimi's models are trained and updated on a monthly basis, so as new data emerges, the model adjusts over time.</li>
                        </ul>
                    </div>

                    <h2 name="client-health-scores-core">
                        <em>Client Health Scores:</em> Core Concepts
                    </h2>
                    <p>
                        <b>{getTerm("parentClient")} Health Scores</b> indicate how well your historical {getTerm("parentClient").toLowerCase()} management actions suggest {getTerm("growthPotential").toLowerCase()} for
                        the future.
                    </p>
                    <div className="table-grid">
                        <div className="cell">
                            <h5>Delivery Process</h5>
                        </div>
                        <div className="cell">{getTerm("tooltipDeliveryProcess")}</div>

                        <div className="cell">
                            <h5>Team Composition</h5>
                        </div>
                        <div className="cell">{getTerm("tooltipTeamComposition")}</div>

                        <div className="cell">
                            <h5>Cross Collaboration</h5>
                        </div>
                        <div className="cell">{getTerm("tooltipCrossCollaboration")}</div>

                        <div className="cell">
                            <h5>{getTerm("parentClient")} Demographics</h5>
                        </div>
                        <div className="cell">{getTerm("tooltipClientDemographics")}</div>

                        <div className="cell">
                            <h5>External Market</h5>
                        </div>
                        <div className="cell">{getTerm("tooltipExternalMarket")}</div>

                        <div className="cell">
                            <h5>Business Administration</h5>
                        </div>
                        <div className="cell">{getTerm("tooltipBusinessAdministration")}</div>
                    </div>
                    <div className="help-section">
                        <h4>How Are Client Health Scores Created</h4>
                        <ul>
                            <li>Orgaimi measures leading indicators of growth across Delivery Process, Client Relations, Team Composition, Cross Collaboration, Business Administration.</li>
                            <li>
                                Orgaimi's AI processors measure which leading indicators have historically led to the most growth for similar types of clients and prioritizes which combination of indicators are most
                                associated with growth.
                            </li>
                            <li>Orgaimi's models are trained and updated on a monthly basis, so as new data emerges, the model adjusts over time.</li>
                        </ul>
                    </div>

                    {/* Strategic Value -- Start */}
                    <h2 name="strategic-value-core">
                        <em>Strategic Value Index:</em> Core Concepts
                    </h2>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/strategic-value-core.jpg" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            <b>Strategic Value Index</b> indicates how valuable the client is to the firm based on {getTerm("revenue")} total/share of wallet, service penetration, and profitability. The SVI can be used
                            to differentiate clients based on importance, rather than just {getTerm("growthPotential").toLowerCase()} or client health.
                        </p>
                    </div>
                    {/* Strategic Value -- End */}

                    {/* Revenue at Risk -- Start */}
                    <h2 name="revenue-at-risk-core">
                        <em>{getTerm("revenueAtRisk")}:</em> Core Concepts
                    </h2>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/revenue-at-risk-core.jpg" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            <b>{getTerm("revenueAtRisk")}</b> represents portion of client's {getTerm("t12Revenue").toLowerCase()} that is at risk based on the {getTerm("parentClient")}'s probability of churn.{" "}
                            {getTerm("revenueAtRisk")} can be used to help prioritize which clients to focus on based on how much {getTerm("revenue")} is at risk: clients with lower client health scores and high{" "}
                            {getTerm("t12Revenue").toLowerCase()} will have the most {getTerm("revenueAtRisk")}.
                        </p>
                    </div>
                    {/* Revenue at Risk -- End */}

                    <h2 name="login">
                        <em>Accessing</em> Orgaimi
                    </h2>

                    <div className="help-images">
                        <div>
                            <img src="/images/help/login-screen.jpg" alt="Login Screen" />
                        </div>
                        <div>
                            <img src="/images/help/login-microsoft.jpg" alt="Login Microsoft" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>Congratulations! You have been granted access to Orgaimi. Here is how you log-in.</p>
                        <ol>
                            <li>Navigate to your organization's Orgaimi link provided by your Orgaimi administrator or Customer Success Consultant.</li>
                            <li>Click the "Login with Microsoft" button.</li>
                            <li>
                                If you are not logged into your organization's Microsoft 365 account, you will automatically be prompted to enter your organization's Microsoft 365 email address and password. Complete the
                                log-in process.
                            </li>
                            <li>Once you have completed your log-in process, you will be directed to the Orgaimi home page.</li>
                            <li>If you are already logged in to your organization's Microsoft 365 account, you will be directed to the home page.</li>
                        </ol>
                    </div>

                    <h2 name="navigation">Navigating the Dashboard</h2>
                    <div className="help-images">
                        <div>
                            <img src="images/help/header.jpg" alt="header" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>At the top of each Orgaimi page are tabs and links for navigating to major {getTerm("features").toLowerCase()}.</p>
                        <ul>
                            <li data-number="1">Navigate to other pages and log-out</li>
                            <li data-number="2">Return to Dashboard Page</li>
                            <li data-number="3">
                                Access your personal dashboard to compare your {getTerm("parentClient").toLowerCase()} account's {getTerm("growthPotential").toLowerCase()} to the firm's{" "}
                                {getTerm("growthPotential").toLowerCase()}
                            </li>
                            <li data-number="4">
                                View {getTerm("growthPotential").toLowerCase()} for {getTerm("serviceLine")} and {getTerm("industry")} Segments
                            </li>
                            <li data-number="5">Compare Offices based on {getTerm("growthPotential")}</li>
                            <li data-number="6">Access a client list to compare client based on growth opportunity</li>
                            <li data-number="7">
                                Access a {getTerm("project").toLowerCase()} list to view {getTerm("potential").toLowerCase()} and {getTerm("existingProjects").toLowerCase()} in the context of{" "}
                                {getTerm("parentClient").toLowerCase()} accounts
                            </li>
                            <li data-number="8">
                                Apply client-level filters to create custom views
                                <ul>
                                    <li>Save custom filters by naming and saving</li>
                                    <li>Invoke the filter by selecting the name from the list</li>
                                </ul>
                            </li>
                            <li data-number="9">Find the top clients to focus on based on growth opportunity</li>
                        </ul>
                    </div>

                    <h3 name="dashboard">Dashboard</h3>
                    <h4 name="dashboard-homepage">Home Page</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/dashboard.jpg" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>Your Dashboard provides a personalized view of {getTerm("growthPotential").toLowerCase()} for your portfolio of clients and how it compares to the rest of the firm.</p>
                        <ul>
                            <li data-number="1">
                                <b>
                                    {getTerm("my")} {getTerm("growthPotential")}
                                </b>
                                <ul>
                                    <li>
                                        {getTerm("my")} total {getTerm("growthPotential")} is the sum of {getTerm("growthPotential")} for all {getTerm("parentClients").toLowerCase()} in which you are a clients in which
                                        you are a {getTerm("parentCustomerRelationsManager")} {getTerm("clientManager") ? ` or ${getTerm("clientManager")}` : ""} and who have actively worked with the firm in the past 3
                                        years.
                                    </li>
                                    <li>
                                        {getTerm("growthPotential")} is split based on the {getTerm("serviceLine").toLowerCase()} of {getTerm("potentialProjects")}.
                                    </li>
                                </ul>
                            </li>
                            <li data-number="2">
                                <b>
                                    {getTerm("t12Revenue")} of {getTerm("myParentClients")}
                                </b>
                                <ul>
                                    <li>
                                        {getTerm("t12Revenue")} of all {getTerm("existingProjects")} in the last 12 months where you are a {getTerm("parentCustomerRelationsManager")}{" "}
                                        {getTerm("clientManager") ? ` or ${getTerm("clientManager")}` : ""}
                                    </li>
                                </ul>
                            </li>
                            <li data-number="3">
                                <b>{getTerm("growthPotential")}</b>
                                <ul>
                                    <li>
                                        {getTerm("growthPotential")} is the sum of {getTerm("growthPotential")} for all clients who have actively worked with the firm in the past 3 years.
                                    </li>
                                </ul>
                            </li>
                            <li data-number="4">
                                <b>{getTerm("t12Revenue")} of The Firm</b>
                                <ul>
                                    <li>{getTerm("t12Revenue")} for the entire firm</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h4 name="growth-potential-panel">{getTerm("growthPotential")} Panel</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/growth-potential-panel.jpg" alt={`${getTerm("growthPotential")} Panel`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>Your Dashboard also shows your {getTerm("growthPotential")} panel. This can be accessed at anytime in the upper right corner.</p>
                        <ul>
                            <li data-number="1">
                                Access "{`${getTerm("parentCrm")}${getTerm("cm") ? `/${getTerm("cm")}` : ""}`}" to see the top 5 clients by {getTerm("growthPotential")} in which you are a{" "}
                                {getTerm("clientManager") ? ` or ${getTerm("clientManager")}` : ""}
                            </li>
                            <li data-number="2">
                                Access "{getTerm("myProjects")}" to see the top 5 clients by {getTerm("growthPotential")} where you are the {getTerm("projectPersonInCharge")}
                            </li>
                            <li data-number="3">
                                Access "{getTerm("allFirm")}" to see the top 5 clients by {getTerm("growthPotential")} for the firm
                            </li>
                            <li data-number="4">Access "Expert" to see the top 5 clients by {getTerm("growthPotential")} where you are experienced</li>
                            <li data-number="4">Access "Search" to find a different {getTerm("teamMember")}'s client accounts</li>
                        </ul>
                    </div>

                    <h3 name="segments">
                        <em>Segments</em>
                    </h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/segments.jpg" alt="Segments Page" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Segment view provides a summary of {getTerm("growthPotential")} and {getTerm("revenueAtRisk")} for the following segments of the firm:{" "}
                            <b>
                                {getTerm("serviceLine")} and {getTerm("industry")}
                            </b>
                            .
                        </p>
                        <p>
                            <i>This view helps you identify “hotspots” of clients where you may want to focus on for growth or risk mitigation.</i>
                        </p>
                        <ul>
                            <li data-number="1">
                                The top of the page is a Bar Chart that compares a segment's {getTerm("growthPotential")} to its {getTerm("revenueAtRisk")}.
                            </li>
                            <li>
                                Within the Segment Bar Chart, you will see:
                                <ul>
                                    <li data-number="2">
                                        The {getTerm("growthPotential")} for that segment based on the clients who are in that {getTerm("serviceLine")} or {getTerm("industry")}
                                    </li>
                                    <li data-number="3">
                                        The {getTerm("revenueAtRisk")} for that segment based on the clients who are in that {getTerm("serviceLine")} or {getTerm("industry")}
                                    </li>
                                </ul>
                            </li>
                            <li data-number="4">The bottom of the page is a series of Top 5 cards that indicate the top performing categories to act as a "leaderboard".</li>
                            <li data-number="5">
                                Use the toggle to change between {getTerm("industry")} and {getTerm("serviceLine").toLowerCase()} selections
                            </li>
                            <li data-number="6">
                                Use the Client Filters functionality to filter the chart further to drive more specificity in the {getTerm("industry")}/{getTerm("serviceLine")} charts
                            </li>
                        </ul>
                    </div>

                    <h4 name="segment-summary" className="txt -white">
                        <em>Top</em> Segment Summary
                    </h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/segments-2.jpg" alt="Segment Summary" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>The bottom of the page has Top 5 cards that indicate the top performing categories:</p>

                        <ul>
                            <li data-number="1">
                                Top 5 {getTerm("parentClients")} by Total {getTerm("growthPotential")} (the sum of all {getTerm("potentialProjects").toLowerCase()} within that {getTerm("parentClients")})
                            </li>
                            <li data-number="2">
                                Top 5 {getTerm("projectTypes")} by Total {getTerm("growthPotential")} (the sum of all {getTerm("potentialProjects").toLowerCase()} within that {getTerm("projectType")})
                            </li>
                            <li data-number="3">
                                Top 5 {getTerm("projectTypes")} by Average {getTerm("growthPotential")} (the average value of all {getTerm("potentialProjects").toLowerCase()} within that {getTerm("projectType")})
                            </li>
                            <li data-number="4">
                                Top 5 {getTerm("potentialProjects")} by Total {getTerm("growthPotential")} (the {getTerm("potentialProjects").toLowerCase()} with the highest revenue range for individual clients)
                            </li>
                            <li data-number="5">
                                Top 5 Partners by Total {getTerm("growthPotential")} (the sum of all {getTerm("potentialProjects").toLowerCase()} where partners are {getTerm("parentCrm")})
                            </li>
                        </ul>
                    </div>

                    {siteKey !== "faegredrinker" && (
                        <>
                            <h2 name="offices">
                                <em>Offices</em>
                            </h2>
                            <h3 name="offices-map">Offices Map</h3>
                            <div className="help-images">
                                <div>
                                    <img src="/images/help/offices-map.jpg" alt="Offices Map" />
                                </div>
                            </div>
                            <div className="help-section">
                                <p>
                                    The Offices view provides a summary of {getTerm("parentClient")} Health and {getTerm("growthPotential")} for Offices. {getTerm("parentClients")} are allocated to Offices based on the
                                    GL location of the {getTerm("parentClient")} Partner. This view helps you identify "hotspots" of offices where you may want to focus on for growth or risk mitigation.
                                </p>
                                <ul>
                                    <li data-number="1">
                                        Each bubble represents one of the firm's offices
                                        <ul>
                                            <li data-number="2">The size of the bubble represents the Total {getTerm("growthPotential")} per Office</li>
                                            <li data-number="2">
                                                The color of the bubble represents the average {getTerm("parentClient")} Health Score, where light blue indicates Excellent or Good {getTerm("parentClient")} Health and
                                                bright gold indicate {getTerm("parentClient")} Health to Review
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Hover over one of the offices to see summary statistics for the office
                                        <ul>
                                            <li data-number="3">Average Health Score for the Office</li>
                                            <li data-number="4">Percentage change in Health Score over the past month</li>
                                            <li data-number="5">Office name and Office Managing Partner</li>
                                            <li data-number="6">Link to Detailed View</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                            <h3 name="office-details">Office Details</h3>
                            <div className="help-images">
                                <div>
                                    <img src="/images/help/office-details.jpg" alt="Office Details" />
                                </div>
                            </div>
                            <div className="help-section">
                                <p>The Offices Detail view provides detailed statistics on the Office:</p>
                                <ul>
                                    <li data-number="1">
                                        <b>Scores Tab</b>
                                        <ul>
                                            <li data-number="2">Office name and Office Managing Partner</li>
                                            <li data-number="3">Average Health Score for the Office</li>
                                            <li data-number="4">Percentage change in Health Score over the past month</li>
                                            <li data-number="5">Ranking of Offices Shown on the Map based on Average Health Score</li>
                                        </ul>
                                    </li>
                                    <li data-number="6">
                                        <b>Financials Tab</b>
                                        <ul>
                                            <li data-number="7">Office name and Office Managing Partner</li>
                                            <li data-number="8">
                                                Statistics on Total Revenue of the Office, {getTerm("averageRevenue")} per {getTerm("parentClient")} and {getTerm("averageRevenue")} per Partner, where gold indicates
                                                selected office and white indicates average of {getTerm("allFirm").toLowerCase()} offices
                                            </li>
                                            <li data-number="9">Six-month revenue history of selected office</li>
                                        </ul>
                                    </li>
                                    <li data-number="10">
                                        <b>Drivers Tab</b>
                                        <ul>
                                            <li data-number="11">Office name and Office Managing Partner</li>
                                            <li data-number="12">
                                                Spider chart indicating performance against drivers, where gold indicates selected office and white indicates average of {getTerm("allFirm").toLowerCase()} offices
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </>
                    )}

                    <h2 name="clients">
                        <em>{getTerm("parentClients")}</em>
                    </h2>
                    <h3 name="client-list">{getTerm("parentClient")} List</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-list.jpg" alt={`${getTerm("parentClient")} List`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>The Client List provides a tabular view of all existing client accounts where there has been billing activity in the last 3 years. The Client List includes the following fields:</p>
                        <ul>
                            <li data-number="1">Client Name: the {getTerm("parentClient")} or highest level of aggregation of accounts</li>
                            <li data-number="2">
                                {getTerm("parentCustomerRelationsManager")}: the {getTerm("parentClient")} Partner who owns the account for the firm
                            </li>
                            <li data-number="3">
                                {getTerm("industry")}: {getTerm("industries").toLowerCase()} that the {getTerm("parentClient")} represents &ndash; can have multiple due to multiple {getTerm("subClients")}
                            </li>
                            <li data-number="4">
                                {getTerm("serviceLine")}: {getTerm("serviceLine").toLowerCase()} where the {getTerm("parentClient")} has had billings in the last 12-months
                            </li>
                            <li data-number="5">
                                {getTerm("parentClient")} Health Score: Current {getTerm("parentClient")} Health Score for the account
                            </li>
                            <li data-number="6">SVI Score: Current Strategic Value Index for the account</li>
                            <li data-number="7">{getTerm("t12Revenue")}: total net revenue less expenses in the last 12-months, showing a trend over the past 12 months</li>
                            <li data-number="8">
                                {getTerm("revenueAtRisk")}: Current {getTerm("revenueAtRisk")} for the {getTerm("parentClient")} Account
                            </li>
                            <li data-number="9">
                                {getTerm("growthPotential")}: the {getTerm("growthPotential")} for the {getTerm("parentClient").toLowerCase()} in the next 12 months
                            </li>
                            <li data-number="10">Top Potential: the {getTerm("potentialProject")} with the highest revenue amount</li>
                            <li data-number="11">
                                Click on a {getTerm("parentClient")}'s row to navigate to the {getTerm("parentClient")} Page
                            </li>
                            <li data-number="12">Utilize the page navigation at the bottom to see more {getTerm("parentClients").toLowerCase()}</li>
                        </ul>
                    </div>

                    <h2 name="client-page">
                        <em>{getTerm("parentClient")}</em> Detail Pages
                    </h2>

                    <h3 name="client-nav-header">Nav &amp; Header</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-nav-header.jpg" alt={`${getTerm("parentClient")} Page"`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The {getTerm("parentClient")} <b>Nav</b> helps you navigate to find more detail on a specific {getTerm("parentClient")} account
                        </p>

                        <ul>
                            <li data-number="1">
                                Access home dashboard for information about {getTerm("parentClient")} account ownership, last {getTerm("t12Revenue").toLowerCase()} trends, and summary of{" "}
                                {getTerm("growthPotential").toLowerCase()}
                            </li>
                            <li data-number="2">
                                Access information about {getTerm("subClients")} within the {getTerm("parentClient")} account
                            </li>
                            <li data-number="3">
                                Access {getTerm("growthPotential")} detail for {getTerm("parentClient")} account
                            </li>
                            <li data-number="4">Access Recommendations to improve {getTerm("parentClient")} Health</li>
                            <li data-number="5">Access detailed information regarding {getTerm("parentClient")} Health Scores</li>
                            <li data-number="6">Access detailed information about existing work with the Parent {getTerm("parentClient")} account</li>
                        </ul>

                        <p>
                            The {getTerm("parentClient")} <b>Header</b> provides detail on key metrics for a {getTerm("parentClient")} account.
                        </p>

                        <ul>
                            <li data-number="7">{getTerm("parentClient")} Name</li>
                            <li data-number="8">Date of latest Orgaimi update</li>
                            <li data-number="9">Revenue in the Last 12 Months (Net Revenue less Expenses) and percentage change in last month</li>
                            <li data-number="10">Total {getTerm("growthPotential")} in the next 12 months and percentage change in last month</li>
                            <li data-number="11">{getTerm("revenueAtRisk")} based on Churn Probability</li>
                            <li data-number="12">{getTerm("parentClient")} Health Score</li>
                            <li data-number="13">Strategic Value Index</li>
                        </ul>
                    </div>

                    <h3 name="client-dashboard-overview">Dashboard General Info</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-dashboard-overview.jpg" alt={`${getTerm("parentClient")} Dashboard Page`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The {getTerm("parentClient")} <b>Dashboard Page</b> provides further detail on the parent {getTerm("parentClient").toLowerCase()} account. The {getTerm("parentClient")} Sub-Header section
                            provides detail, including
                        </p>

                        <ul>
                            <li data-number="1">{getTerm("parentCrm")}</li>
                            <li data-number="2">Primary Office Location</li>
                            <li data-number="3">{getTerm("serviceLines")} Currently Active</li>
                            <li data-number="4">{getTerm("industries")} represented by Parent Client</li>
                            <li data-number="5">Range of Strategic Value Index</li>
                            <li data-number="6">Range of {getTerm("parentClient")} Health Score</li>
                        </ul>
                    </div>

                    <h3 name="client-dashboard-t12-revenue">
                        <em>Dashboard</em> 12-Month Revenue
                    </h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-dashboard-12-month-revenue.jpg" alt={`${getTerm("parentClient")} Dashboard 12-Month Revenue`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            <b>12-Month Revenue</b> section provides information on the historical revenues over the last 12 months for the parent {getTerm("parentClient").toLowerCase()} account, including
                        </p>

                        <ul>
                            <li data-number="1">12-Month Revenue by {getTerm("subClient")}</li>
                            <li data-number="2">12-Month Revenue by {getTerm("serviceLine")}</li>
                            <li data-number="3">12-Month Revenue by {getTerm("industry")}</li>
                            <li data-number="4">12-Month Revenue by {getTerm("projectTypes")}</li>
                        </ul>

                        <p>
                            The bar charts provide the top 4 revenue categories. If a {getTerm("parentClient")} has more than 4 of a given category, then the 5th bar is displayed as “Other”, providing a count of how many
                            additional categories are included.
                        </p>
                    </div>

                    <h3 name="client-dashboard-growth-potential">
                        <em>Dashboard</em> {getTerm("growthPotential")}
                    </h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-dashboard-growth-potential.jpg" alt={`${getTerm("parentClient")} Dashboard ${getTerm("growthPotential")}`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            {getTerm("growthPotential")} section provides information on the future {getTerm("growthPotential").toLowerCase()} of the {getTerm("parentClient").toLowerCase()} account
                        </p>

                        <b>
                            {getTerm("growthPotential")} by {getTerm("industry")} & {getTerm("subClient")}
                        </b>
                        <ul>
                            <li data-number="1">Inner pie chart: {getTerm("industry")} categories</li>
                            <li data-number="2">Outer pie chart: {getTerm("subClient")} categories</li>
                        </ul>
                        <p>
                            A {getTerm("parentClient").toLowerCase()} may have multiple {getTerm("subClient")} categories within a single {getTerm("industry")} category
                        </p>
                        <br />
                        <b>
                            {getTerm("growthPotential")} by {getTerm("serviceLine")} & {getTerm("projectType")}
                        </b>
                        <ul>
                            <li data-number="3">Inner pie chart: {getTerm("serviceLine")}</li>
                            <li data-number="4">Outer pie chart: {getTerm("projectType")}</li>
                        </ul>
                        <p>
                            A {getTerm("parentClient").toLowerCase()} may have multiple {getTerm("projectTypes")} within a single {getTerm("serviceLine")} category
                        </p>
                    </div>

                    <h3 name="client-subclients">
                        <em>{getTerm("subClients")}</em>
                    </h3>
                    <h4 name="client-subclient-charts" className="txt -white">
                        Charts
                    </h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/clients-subclients-charts.jpg" alt={`${getTerm("subClient")} Charts`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            {getTerm("subClients")} page provides detail on the {getTerm("subClients")} related to the {getTerm("parentClient")}. If a {getTerm("parentClient")} has no {getTerm("subClients")}, this page
                            is not displayed.{" "}
                        </p>

                        <b>12-Month Revenue by {getTerm("subClient")}</b>
                        <ul>
                            <li data-number="1">
                                Details the last {getTerm("t12Revenue").toLowerCase()} for each {getTerm("subClient")} as a proportion of the total {getTerm("parentClient")} revenues. The pie chart will display the top 9
                                categories by revenue. If more than 10, pie section is displayed as "Other", providing a count of how many additional categories are included.
                            </li>
                        </ul>

                        <b>
                            {getTerm("growthPotential")} by {getTerm("subClient")}
                        </b>
                        <ul>
                            <li data-number="2">
                                Details the future {getTerm("growthPotential")} for each {getTerm("subClient")} as a proportion of the total {getTerm("parentClient")} {getTerm("growthPotential")}. The pie chart will
                                display the top 9 categories by revenue. If more than 10, pie section is displayed as “Other”, providing a count of how many additional categories are included.
                            </li>
                        </ul>
                    </div>

                    <h4 name="client-subclient-top5" className="txt -white">
                        Top 5
                    </h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/clients-subclients-top5.jpg" alt={`${getTerm("subClient")} Top 5`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            Top {getTerm("subClients")} section provides the top 5 {getTerm("subClients")} by the following categories
                        </p>

                        <ul>
                            <li data-number="1">Top 5 {getTerm("subClients")} based on Strategic Value Index (SVI)</li>
                            <li data-number="1">
                                Top 5 {getTerm("subClients")} based on {getTerm("revenueAtRisk")}
                            </li>
                        </ul>
                    </div>

                    <h4 name="client-subclient-health-vs-importance" className="txt -white">
                        Health Score vs Strategic Importance
                    </h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-subclient-health-vs-importance.jpg" alt={`${getTerm("subClients")} Top 5`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>Health Score vs Strategic Importance section provides a 2x2 matrix to display the relationship between Health Score and Strategic Value Index for a {getTerm("parentClients")} account.</p>

                        <ul>
                            <li data-number="1">X-axis: Health Score</li>
                            <li data-number="2">Y-axis: SVI Score</li>
                            <li data-number="3">Size of Bubble: {getTerm("t12Revenue").toLowerCase()} </li>
                            <li data-number="4">
                                Upper Left Quadrant: High strategic importance and low {getTerm("parentClient").toLowerCase()} health 🡪 most critical to improve {getTerm("parentClient").toLowerCase()} relationship
                            </li>
                            <li data-number="5">
                                Lower Left Quadrant: Low strategic importance and low {getTerm("parentClient").toLowerCase()} health 🡪 {getTerm("parentClients").toLowerCase()} to consider culling
                            </li>
                            <li data-number="6">Upper Right Quadrant: High strategic importance and high {getTerm("parentClient").toLowerCase()} health 🡪 maintain health</li>
                            <li data-number="7">Lower Right Quadrant: Low strategic importance and high {getTerm("parentClient").toLowerCase()} health 🡪 increase strategic alignment</li>
                        </ul>
                    </div>

                    <h4 name="client-subclient-all" className="txt -white">
                        <em>All</em> {getTerm("subClients")}
                    </h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-subclient-all.jpg" alt={`All ${getTerm("subClients")}`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            All {getTerm("subClients")} section provides a list of all {getTerm("subClients")} underneath the {getTerm("parentClient")} account. For each {getTerm("subClient")}, the list provides
                            information, including:
                        </p>

                        <ul>
                            <li data-number="1">{getTerm("subClient")} Name</li>
                            <li data-number="2">{getTerm("revenueAtRisk")}</li>
                            <li data-number="3">{getTerm("parentClient")} Health Score</li>
                            <li data-number="4">Strategic Value Index</li>
                            <li data-number="5">
                                Portion of Revenue represented by the {getTerm("subClient")} within the {getTerm("parentClient")}
                            </li>
                            <li data-number="6">
                                The list can also be sorted by Portion of Revenue, {getTerm("subClient")} Name, {getTerm("parentClient")} Health Score, or {getTerm("revenueAtRisk")}
                            </li>
                        </ul>
                    </div>

                    <h3 name="client-growth-potential">{getTerm("growthPotential")}</h3>
                    <h4 name="client-growth-potential-charts" className="txt -white">
                        Charts
                    </h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-growth-potential-charts.jpg" alt={getTerm("growthPotential")} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            {getTerm("growthPotential")} page provides information on the future {getTerm("growthPotential").toLowerCase()} of the {getTerm("parentClient").toLowerCase()} account
                        </p>

                        <b>
                            {getTerm("growthPotential")} by {getTerm("industry")} &amp; {getTerm("subClient")}
                        </b>
                        <ul>
                            <li data-number="1">Inner pie chart: {getTerm("industry")} categories</li>
                            <li data-number="2">Outer pie chart: {getTerm("subClient")} categories</li>
                        </ul>
                        <p>
                            A {getTerm("parentClient").toLowerCase()} may have multiple {getTerm("subClient")} categories within a single {getTerm("industry")} category
                        </p>

                        <b>
                            {getTerm("growthPotential")} by {getTerm("serviceLine")} &amp; {getTerm("projectType")}
                        </b>
                        <ul>
                            <li data-number="3">Inner pie chart: {getTerm("serviceLine")}</li>
                            <li data-number="4">Outer pie chart: {getTerm("projectType")}</li>
                        </ul>
                        <p>
                            A {getTerm("parentClient").toLowerCase()} may have multiple {getTerm("projectTypes")} within a single {getTerm("serviceLine")} category
                        </p>
                    </div>

                    <h4 name="client-growth-potential-top-projects" className="txt -white">
                        <em>Top 3</em> {getTerm("potentialProjects")}
                    </h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-growth-potential-top-projects.jpg" alt={getTerm("potentialProjects")} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            <b>Top 3 {getTerm("potentialProjects")}</b> section provides detailed cards for the top 3 potential expansion opportunities based on the average revenue range of the potential{" "}
                            {getTerm("projectType").toLowerCase()} OR the expansion probability.
                        </p>
                        <ul>
                            <li data-number="1">The toggle in the right corner can be used to switch views</li>
                            <li data-number="2">
                                {getTerm("potential")} {getTerm("projectType")}: The {getTerm("projectType").toLowerCase()} recommended for growth expansion{" "}
                            </li>
                            <li data-number="3">
                                {getTerm("subClient")} Name: The {getTerm("subClient")} recommended to target with {getTerm("potential")} {getTerm("projectType")}
                            </li>
                            <li data-number="4">
                                Independence Flag: A white line bar will appear next to the {getTerm("potentialProject").toLowerCase()} when the {getTerm("projectType")} might violate independence requirements. The user
                                should consider the role of independence on the
                                {getTerm("parentClient").toLowerCase()} account before they act on this {getTerm("potentialProject").toLowerCase()}/client
                            </li>
                            <li data-number="5">
                                {getTerm("averageRevenue")} Range: A recommended revenue range based on historical data of {getTerm("parentClients").toLowerCase()} who have purchased this {getTerm("projectType")} who
                                have similar lifetime fees and are in the same
                                {getTerm("industry").toLowerCase()}
                            </li>
                            <li data-number="6">
                                Expansion Probability indicates how frequently this {getTerm("projectType")} has occurred in the past for {getTerm("parentClients").toLowerCase()} with a similar pattern to the selected
                                client
                            </li>
                            <li data-number="7">
                                {getTerm("serviceLine")} for the {getTerm("projectType")}
                            </li>
                            <li data-number="8">{getTerm("industry")} of the Sub-Client</li>
                            <li data-number="9">{getTerm("subCustomerRelationsManager")}</li>
                            <li data-numbar="10">
                                Experienced {getTerm("teamMembers")} : qualified professionals who have significant experience in this {getTerm("projectType").toLowerCase()}, {getTerm("parentClient").toLowerCase()}, or{" "}
                                {getTerm("industry").toLowerCase()}.
                                <ul>
                                    <li>Portfolio Icon = {getTerm("projectType")}</li>
                                    <li>Experience Building Icon = {getTerm("industry")} Experience</li>
                                    <li>Briefcase Icon = {getTerm("parentClient")} Experience</li>
                                </ul>
                            </li>
                        </ul>
                        <p>There are several ways to interact with a {getTerm("potentialProject")}:</p>
                        <ul>
                            <li data-number="11">
                                Email Experienced {getTerm("teamMembers")} : Hover over each experienced team member to open an email to discuss the {getTerm("potentialProject")}
                            </li>
                            <li data-number="12">
                                Chat Experienced {getTerm("teamMembers")} : Hover over each experienced team member to open a Teams Chat to discuss the {getTerm("potentialProject")}
                            </li>
                            <li data-number="13">
                                Flag an Experienced {getTerm("teamMember")}: Indicate using the dropdown list or another reason why you would recommend another experienced team member, rather than the individual
                                currently selected
                            </li>
                            <li data-number="14">Comment: Leave a comment on the {getTerm("potentialProject")} for other Orgaimi users to see and collaborate</li>
                        </ul>

                        <ul>
                            <li data-number="15">
                                Flag: Flag {getTerm("potentialProjects").toLowerCase()} that you believe are not applicable or not a good fit for the {getTerm("parentClients").toLowerCase()} account.
                                <ul>
                                    <li>The flag option opens an entry form to provide a feedback reason for flagging the {getTerm("potentialProject")} . Select a pre-populated reason or enter your own.</li>
                                </ul>
                            </li>
                            <li data-number="16">
                                Push to Salesforce: Select the Salesforce icon to move a {getTerm("potentialProject")} into an active opportunity in Salesforce.
                                <ul>
                                    <li>
                                        The Salesforce options open an entry form to make selections including Name of Opportunity, Close Date, Stage, Total Contract Value and {getTerm("serviceLine")}. Leave the Lead
                                        Source as “Orgaimi”.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h3 name="client-recommendations">Recommendations</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-recommendations.jpg" alt={`${getTerm("parentClient")} Recommendations`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Recommendations View provides three {getTerm("parentClient").toLowerCase()} trends that can be optimized to drive further growth and prevent {getTerm("parentClient").toLowerCase()} churn.
                            These trends have been selected based on similar {getTerm("parentClients").toLowerCase()} to the selected {getTerm("parentClient").toLowerCase()} who have improved their growth. Each
                            Recommendation card provides the following information:
                        </p>
                        <ul>
                            <li data-number="1">{getTerm("parentClient")} management trend that should be increased, decreased, or maintained to improve likelihood of growth</li>
                            <li data-number="2">Justification for why Orgaimi is recommending a certain {getTerm("parentClient").toLowerCase()} management behavior change</li>
                            <li data-number="3">
                                Statistics on this {getTerm("parentClient").toLowerCase()}'s trends vs. {getTerm("parentClients").toLowerCase()} like them who have improved their growth
                            </li>
                        </ul>
                    </div>

                    <h3 name="client-scores">Scores</h3>
                    <h4 name="client-recommendations">Overall</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-scores-overall.jpg" alt={`${getTerm("parentClient")} Scores Overall`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>Scores page provides detailed information about the {getTerm("parentClient")}'s Health Score and where there is good performance or opportunities for improvement.</p>
                        <ul>
                            <li data-number="1">Health Score Time Series Chart: Historical trend over the last 12 months of the {getTerm("parentClient")}'s Health Score</li>
                            <li data-number="2">
                                Overall Theme Performance Radar Chart: {getTerm("parentClient")}'s performance against the behavioral Themes. The closer a node is to the edge, the higher the score (higher performance).
                                The closer a node is to the center, the lower the score (lower performance).
                            </li>
                        </ul>
                    </div>

                    <h4 name="client-scores-subclients">{`${getTerm("parentClient")} / ${getTerm("subClient")}`} Themes &amp; Scores</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-scores-subclients.jpg" alt={`${getTerm("parentClient")} Scores ${getTerm("subClients")}`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            {getTerm("subClient")} Themes & Scores section provides an opportunity to look at the performance of {getTerm("subClients")}' within each theme, to see which {getTerm("subClient")} is
                            contributing positively or negatively to the total overall {getTerm("parentClient")} Health Score
                        </p>
                        <ul>
                            <li data-number="1">{getTerm("subClient")} Name</li>
                            <li data-number="2">Bar Chart with Bricks: More bricks indicates higher scores (higher performance). Fewer bricks indicates lower scores (lower performance).</li>
                            <li data-number="3">
                                Click to Expand: Expand each {getTerm("subClient")} to learn more about the {getTerm("parentClient")} Health Score and the {getTerm("subClient")}'s performance vs. the{" "}
                                {getTerm("parentClient")}
                            </li>
                            <li data-number="4">Theme Definitions: Description of behaviors contributing to the theme</li>
                            <li data-number="5">
                                {getTerm("parentClient")} vs. {getTerm("subClient")} Radar Chart: Comparison of {getTerm("parentClient")}'s Scores (white) against the {getTerm("subClient")}'s scores (orange).{" "}
                            </li>
                        </ul>
                    </div>

                    <h4 name="client-scores-themes">{`${getTerm("parentClient")} / ${getTerm("subClient")}`} Themes</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-scores-themes.jpg" alt={`${getTerm("parentClient")} Scores ${getTerm("subClients")} Themes`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            Within the {getTerm("subClient")} Themes & Scores section, each {getTerm("subClient")} has detailed information on theme performance and performance against specific behaviors.
                        </p>
                        <ul>
                            <li data-number="1">
                                Theme Name: Groups of {getTerm("features")} that behave similarly to each other and target a similar positive {getTerm("parentClient")} outcome.
                            </li>
                            <li data-number="2">
                                Theme Performance: The {getTerm("subClient")}'s current score for the theme. More bricks indicates higher scores (higher performance). Fewer bricks indicates lower scores (lower
                                performance).
                            </li>
                            <li data-number="3">
                                {getTerm("feature")} Name: {getTerm("features")} are specific behavioral trends of {getTerm("parentClients")} that suggest growth or risk.
                            </li>
                            <li data-number="4">
                                {getTerm("feature")} Performance: The {getTerm("subClient")}'s current score for the behavioral trend. More bricks indicates higher scores (higher performance). Fewer bricks indicates
                                lower scores (lower performance).
                            </li>
                        </ul>
                    </div>

                    <h3 name="client-existing-projects">{getTerm("existingProjects")}</h3>
                    <h4 name="client-existing-projects-charts">{getTerm("t12Revenue")} Chart</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-existing-projects-charts.jpg" alt={`${getTerm("parentClient")} ${getTerm("existingProjects")} Chart`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The {getTerm("existingProjects")} page provides additional information about the {getTerm("parentClient").toLowerCase()}'s current and recent {getTerm("existingProjects").toLowerCase()}{" "}
                            portfolio to put Orgaimi's {getTerm("potentialProjects")} and recommendations into context.
                        </p>
                        <ul>
                            <li data-number="1">
                                Last {getTerm("t12Revenue")} by {getTerm("serviceLine")}
                            </li>
                            <li data-number="2">
                                Last {getTerm("t12Revenue")} of {getTerm("parentClient")} vs. Like {getTerm("parentClients")}
                            </li>
                            <li data-number="3">Chart changes based on toggle options</li>
                        </ul>
                    </div>

                    <h4 name="client-existing-projects-counts">{getTerm("existingProject")} Counts Chart</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-existing-projects-project-count.jpg" alt={`${getTerm("parentClient")} ${getTerm("existingProjects")} Chart`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The {getTerm("existingProject")} Counts by {getTerm("projectType")} section provides a bar chart of how many unique {getTerm("existingProjects").toLowerCase()} have occurred for the{" "}
                            {getTerm("parentClient").toLowerCase()} in different service areas
                        </p>
                        <ul>
                            <li data-number="1">X-axis: {getTerm("projectType")}</li>
                            <li data-number="2">Y-axis: {getTerm("existingProject")} Count</li>
                        </ul>
                    </div>

                    <h4 name="client-existing-projects-list">{getTerm("existingProjects")} List</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/client-existing-projects-list.jpg" alt={`${getTerm("parentClient")} ${getTerm("existingProjects")} List`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The {getTerm("existingProjects")} section provides a list of historical {getTerm("existingProjects").toLowerCase()} for the {getTerm("parentClient")}, including:
                        </p>
                        <ul>
                            <li data-number="1">
                                {getTerm("existingProject")} Name & {getTerm("projectType")}{" "}
                            </li>
                            <li data-number="2">
                                {getTerm("subClient")} affiliated with the {getTerm("existingProjects").toLowerCase()}
                            </li>
                            <li data-number="3">{getTerm("projectPersonInCharge")} </li>
                            <li data-number="4">Start/open date of {getTerm("existingProject").toLowerCase()}</li>
                            <li data-number="5">End/closed date of {getTerm("existingProject").toLowerCase()}</li>
                            <li data-number="6">Net revenue less expenses of the {getTerm("existingProject").toLowerCase()} to date</li>
                        </ul>
                    </div>

                    <h3 name="potential-projects">{getTerm("potentialProjects")}</h3>
                    <h4 name="potential-projects-list">List</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/potential-project-list.jpg" alt={`${getTerm("potentialProject")} List`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The {getTerm("potentialProject")} List View displays data about {getTerm("potentialProjects").toLowerCase()} and {getTerm("existingProjects").toLowerCase()} (both closed and active{" "}
                            {getTerm("existingProjects").toLowerCase()}) to provide a more tabular view of identifying {getTerm("project").toLowerCase()} opportunities. The {getTerm("project").toLowerCase()} List for{" "}
                            {getTerm("potentialProjects")} includes:
                        </p>
                        <ul>
                            <li data-number="1">
                                {getTerm("projectType")} for the {getTerm("potentialProjects").toLowerCase()}
                            </li>
                            <li data-number="2">{getTerm("parentClient")} Name</li>
                            <li data-number="3">
                                {getTerm("subClient")} Name to target the {getTerm("potentialProjects").toLowerCase()}
                            </li>
                            <li data-number="4">
                                {getTerm("serviceLine")} of the {getTerm("projectType")}
                            </li>
                            <li data-number="5">{getTerm("parentClient")} Size</li>
                            <li data-number="6">
                                {getTerm("averageRevenue")} Range for the {getTerm("projectType")} based on {getTerm("parentClient")} Size and {getTerm("industry")}
                            </li>
                            <li data-number="7">Expansion Probability</li>
                            <li data-number="8">
                                Toggle to switch between {getTerm("potential")} and {getTerm("existingProjects")}
                            </li>
                        </ul>
                    </div>

                    <h4 name="potential-projects-detail">Detail</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/potential-project-detail.jpg" alt={getTerm("potentialProject")} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The {getTerm("potentialProject")} Detail view gives additional information on the {getTerm("potentialProject").toLowerCase()} to help decision makers, including:
                        </p>
                        <ul>
                            <li>
                                {getTerm("projectType")} for the {getTerm("potentialProject").toLowerCase()} (1)
                            </li>
                            <li>{getTerm("parentClient")} Name (2)</li>
                            <li>
                                {getTerm("subClient")} Name to target the {getTerm("potentialProject").toLowerCase()} (3)
                            </li>
                            <li>{getTerm("parentClient")} Partner (4)</li>
                            <li>
                                {getTerm("serviceLine")} of the {getTerm("projectType")} (5)
                            </li>
                            <li>
                                {getTerm("industry")} of the {getTerm("parentClient")} (6)
                            </li>
                            <li>
                                {getTerm("averageRevenue")} Range for the {getTerm("projectType")} based on {getTerm("parentClient")} Size and {getTerm("industry")} (7)
                            </li>
                            <li>Expansion Probability (8)</li>
                            <li>
                                Experienced {getTerm("teamMembers")} , based on team members who have significant experience in this {getTerm("projectType").toLowerCase()}, {getTerm("parentClient").toLowerCase()}, or{" "}
                                {getTerm("industry").toLowerCase()} (9)
                            </li>
                            <li>
                                Distribution chart of previous {getTerm("existingProjects")} like this that have occurred in the past, based on {getTerm("projectType").toLowerCase()}, {getTerm("industry").toLowerCase()},
                                and {getTerm("parentClient").toLowerCase()} size (10)
                            </li>
                        </ul>
                    </div>

                    <h3 name="existing-projects">{getTerm("existingProjects")}</h3>
                    <h4 name="existing-projects-list">List</h4>
                    <div className="help-images">
                        <div>
                            <img src="images/help/existing-projects-list.jpg" alt={`${getTerm("existingProject")} List`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The {getTerm("existingProject")} List View displays data about {getTerm("potentialProjects").toLowerCase()} and {getTerm("existingProjects").toLowerCase()} (both closed and active{" "}
                            {getTerm("existingProjects").toLowerCase()}) to provide a more tabular view of identifying {getTerm("existingProject").toLowerCase()} opportunities. The {getTerm("existingProject")} List for{" "}
                            {getTerm("existingProjects")} includes:
                        </p>
                        <ul>
                            <li data-number="1">
                                {getTerm("projectType")} of the {getTerm("existingProject").toLowerCase()}
                            </li>
                            <li data-number="2">{getTerm("parentClient")} Name</li>
                            <li data-number="3">
                                {getTerm("subClient")} Name of the {getTerm("existingProject").toLowerCase()}
                            </li>
                            <li data-number="4">
                                {getTerm("projectPersonInCharge")} of the {getTerm("existingProject").toLowerCase()}
                            </li>
                            <li data-number="5">Start/open date of the {getTerm("existingProject").toLowerCase()}</li>
                            <li data-number="6">
                                End/closed date of the {getTerm("existingProject").toLowerCase()}: if the {getTerm("existingProject").toLowerCase()} is open, this will show as "ongoing"
                            </li>
                            <li data-number="7">Status of {getTerm("existingProjects").toLowerCase()}: open, completed/closed, suspended</li>
                            <li data-number="8">Revenue to date</li>
                        </ul>
                    </div>

                    <h4 name="existing-projects-detail">Detail</h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/existing-project-detail.jpg" alt={`${getTerm("existingProject")} Detail`} />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The {getTerm("existingProject")} Detail view gives additional information on {getTerm("existingProjects").toLowerCase()} to help contextualize future opportunities.
                        </p>
                        <h5>Summary</h5>
                        <ul>
                            <li data-number="1">
                                {getTerm("projectType")} of the {getTerm("existingProject").toLowerCase()}
                            </li>
                            <li data-number="2">{getTerm("parentClient")} Name</li>
                            <li data-number="3">{getTerm("parentClient")} Partner</li>
                            <li data-number="4">Start/open date of the {getTerm("existingProjects").toLowerCase()}</li>
                            <li data-number="5">
                                End/closed date of the {getTerm("existingProjects").toLowerCase()}: if the {getTerm("existingProjects").toLowerCase()} does not have an end/closed date, this will show as "ongoing"
                            </li>
                            <li data-number="6">
                                {getTerm("subClient")} of the {getTerm("existingProject").toLowerCase()}
                            </li>
                            <li data-number="7">
                                {getTerm("projectPersonInCharge")} of the {getTerm("existingProject").toLowerCase()}
                            </li>
                            <li data-number="8">Status of {getTerm("existingProjects").toLowerCase()}: open, completed/closed, suspended</li>
                            <li data-number="9">{getTerm("serviceLine")}</li>
                            <li data-number="10">{getTerm("industry")} of the Client</li>
                        </ul>
                        <h5 data-number="11">
                            {getTerm("t12Revenue")} Comparison to Like {getTerm("existingProjects")}
                        </h5>
                        <ul>
                            <li data-number="12">Chart of last 12 months of revenue for similar {getTerm("existingProjects").toLowerCase()}</li>
                        </ul>
                        <h5 data-number="13">Billing By {getTerm("teamMember")}</h5>
                        <ul>
                            <li data-number="14">{getTerm("teamMember")} Name</li>
                            <li data-number="15">Role on the {getTerm("existingProject")}</li>
                            <li data-number="16">{getTerm("hoursBilled")}</li>
                            <li data-number="17">Total {getTerm("hoursBilled")}</li>
                            <li data-number="18">Total {getTerm("revenue")}</li>
                        </ul>
                    </div>

                    <h3 name="team-dashboard">Team</h3>
                    <h4 name="team-dashboard-info">
                        <em>Dashboard</em> Info
                    </h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/team-detail-info.jpg" alt="Team Info" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Team Detail view provides additional information about a {getTerm("teamMember")}'s specific experience across {getTerm("industries")}, {getTerm("serviceLines")}, {getTerm("projectTypes")}{" "}
                            and {getTerm("parentClients")}.
                        </p>
                        <ul>
                            <li data-number="1">{getTerm("teamMember")} Name</li>
                            <li data-number="2">
                                Number of {getTerm("parentClients")} Managed as {getTerm("parentCustomerRelationsManager")}
                            </li>
                            <li data-number="3">
                                Number of {getTerm("subClients")} Managed as {getTerm("subCustomerRelationsManager")}
                            </li>
                            <li data-number="4">
                                Number of {getTerm("existingProjects")} Managed as {getTerm("projectPIC")}
                            </li>
                            <li data-number="5">
                                Average {getTerm("parentClients")} Health Score of {getTerm("parentClients").toLowerCase()} where {getTerm("teamMember")} has billable hours
                            </li>
                            <li data-number="6">Email Address</li>
                            <li data-number="7">Teams Message</li>
                            <li data-number="8">Primary Office</li>
                            <li data-number="9">Roles (Business Unit & Teams)</li>
                        </ul>
                    </div>

                    <h4 name="team-dashboard-charts" className="txt -white">
                        <em>Dashboard</em> Charts
                    </h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/team-detail-charts.jpg" alt="Team Charts" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Team Detail view provides additional information about a {getTerm("teamMember")}'s specific experience across {getTerm("industries")}, {getTerm("serviceLines")}, {getTerm("projectTypes")}{" "}
                            and {getTerm("parentClients")}.
                        </p>
                        <ul>
                            <li data-number="1">
                                Time Spent by {getTerm("parentClient")} and {getTerm("subClient")}
                            </li>
                            <li data-number="2">Time Spent by {getTerm("serviceLines")}</li>
                            <li data-number="3">Time Spent by {getTerm("industries")}</li>
                            <li data-number="4">Time Spent by {getTerm("projectTypes")}</li>
                        </ul>
                    </div>

                    <h4 name="team-past-work" className="txt -white">
                        <em>Past</em> Work
                    </h4>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/team-detail-past-work.jpg" alt="Team Past Work" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Team Detail view provides additional information about a {getTerm("teamMember")}'s specific experience across {getTerm("industries")}, {getTerm("serviceLines")}, {getTerm("projectTypes")}{" "}
                            and {getTerm("parentClients")}.
                        </p>
                        <ul>
                            <li data-number="1">
                                Time Spent by {getTerm("parentClient")} and {getTerm("subClient")}
                            </li>
                            <li data-number="2">Time Spent by {getTerm("serviceLines")}</li>
                            <li data-number="3">Time Spent by {getTerm("industries")}</li>
                            <li data-number="4">Time Spent by {getTerm("projectTypes")}</li>
                            <li data-number="5">
                                Associated {getTerm("existingProjects")} ({getTerm("existingProject").toLowerCase()} with billable hours by the {getTerm("teamMember")})
                                <ul>
                                    <li data-number="6">{getTerm("existingProjects")} Name</li>
                                    <li data-number="7">{getTerm("projectType")}</li>
                                    <li data-number="8">{getTerm("parentClient")}</li>
                                    <li data-number="9">{getTerm("subClient")}</li>
                                    <li data-number="10">{getTerm("projectPIC")}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h4 name="team-managing">
                        <em>Managing</em>
                    </h4>
                    <div className="help-images -stacked">
                        <div>
                            <img src="/images/help/team-detail-managing-clients.jpg" alt={`Team Managing ${getTerm("parentClients")}`} />
                        </div>
                        <div>
                            <img src="/images/help/team-detail-managing-subclients.jpg" alt={`Team Managing ${getTerm("subClients")}`} />
                        </div>
                        <div>
                            <img src="/images/help/team-detail-managing-projects.jpg" alt="Team Managing Projects" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Team Detail view provides additional information about a {getTerm("teamMember")}'s specific experience across {getTerm("industries")}, {getTerm("serviceLines")}, {getTerm("projectTypes")}{" "}
                            and {getTerm("parentClients")}.
                        </p>
                        <p>
                            <b>
                                Managing (only visible for {getTerm("teamMember")} who manage {getTerm("parentClients")}, {getTerm("subClients")}, or {getTerm("existingProjects")})
                            </b>
                        </p>
                        <ul>
                            <li data-number="1">{getTerm("parentClients")} Managed</li>
                            <li data-number="2">{getTerm("subClients")} Managed</li>
                            <li data-number="3">{getTerm("existingProjects")} Managed</li>
                        </ul>
                    </div>

                    <h4 name="team-experience">
                        <em>Experience</em>
                    </h4>
                    <div className="help-images -stacked">
                        <div>
                            <img src="/images/help/team-detail-experience.jpg" alt="Team Managing Experience" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>
                            The Team Detail view provides additional information about a {getTerm("teamMember")}'s specific experience across {getTerm("industries")}, {getTerm("serviceLines")}, {getTerm("projectTypes")}{" "}
                            and {getTerm("parentClients")}. The Experience tab summarized the
                            {getTerm("potentialProjects").toLowerCase()} that an individual is recommended for based on their experience in the {getTerm("parentClient")}, {getTerm("industry")}, or{" "}
                            {getTerm("projectType")}{" "}
                        </p>
                        <ul>
                            <li data-number="1">{getTerm("projectType")}</li>
                            <li data-number="2">{getTerm("parentClient")}</li>
                            <li data-number="3">{getTerm("subClient")}</li>
                            <li data-number="4">{getTerm("serviceLine")}</li>
                            <li data-number="5">
                                Experience: Indicates whether team member is recommended for the potential {getTerm("projectType").toLowerCase()} based on their {getTerm("parentClient")}, {getTerm("industry")} or{" "}
                                {getTerm("projectType")} Experience
                            </li>
                            <li data-number="6">{getTerm("parentClient")} Size</li>
                            <li data-number="7">{getTerm("averageRevenue")} Range</li>
                            <li data-number="8">Probability</li>
                        </ul>
                    </div>

                    <h3 name="overview-panel">Overview Panel</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/overview-panel.jpg" alt="Overview Panel" />
                        </div>
                    </div>
                    <div className="help-section">
                        <ul>
                            <li data-number="1">
                                Summary Panel
                                <ul>
                                    <li data-number="2">{getTerm("parentClient")} Name</li>
                                    <li data-number="3">{getTerm("parentClient")} Partner and Description</li>
                                    <li data-number="4">
                                        {getTerm("parentClient")}'s {getTerm("t12Revenue")}
                                    </li>
                                    <li data-number="5">Percentage Change of {getTerm("t12Revenue")} in last month</li>
                                    <li data-number="6">
                                        {getTerm("parentClient")}'s {getTerm("growthPotential")}
                                    </li>
                                    <li data-number="7">Percentage Change in {getTerm("growthPotential")} since last month</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h3 name="filters">Filters</h3>
                    <div className="help-images">
                        <div>
                            <img src="/images/help/filters.jpg" alt="Filters" />
                        </div>
                    </div>
                    <div className="help-section">
                        <p>Filter Options Include:</p>
                        <ul>
                            <li data-number="1">Search by {getTerm("parentClient")}</li>
                            <li data-number="2">Search by {getTerm("parentCustomerRelationsManager")}</li>
                            <li data-number="3">Search by {getTerm("projectPIC")}</li>
                            <li data-number="4">
                                Filter by {getTerm("industry")} of the {getTerm("parentClient")}
                                <ul>
                                    <li>
                                        Note: There may be more than one {getTerm("industry").toLowerCase()} represented by {getTerm("parentClient")}
                                    </li>
                                </ul>
                            </li>
                            <li data-number="5">
                                Filter by {getTerm("serviceLine")} Involvement of the {getTerm("parentClient")}
                                <ul>
                                    <li>
                                        Notes: There may be more than one {getTerm("serviceLine").toLowerCase()} represented by {getTerm("parentClient")}
                                    </li>
                                </ul>
                            </li>
                            <li data-number="6">
                                Filter by the Geographic Location of the {getTerm("parentCustomerRelationsManager")} for the {getTerm("parentClient")}
                            </li>
                        </ul>
                    </div>

                    <h3 name="key-terms">Key Terms</h3>
                    <div className="help-section">
                        <ol>
                            <li>
                                <b>{getTerm("potentialProjects")}:</b> Untapped service opportunities for each {getTerm("parentClient").toLowerCase()} based on the {getTerm("parentClient").toLowerCase()}'s pattern of
                                services purchased with your firm, lifetime value of the {getTerm("parentClient").toLowerCase()}, and
                                {getTerm("industry").toLowerCase()} grouping.
                            </li>
                            <li>
                                <b>{getTerm("growthPotential")}:</b> Quantification of how much value has yet to be untapped within each {getTerm("parentClient").toLowerCase()} account, based on how many{" "}
                                {getTerm("potentialProjects").toLowerCase()} are projected in the next year.
                            </li>
                            <li>
                                <b>{getTerm("parentClient")} Health Scores:</b> Indication of how well your historical {getTerm("parentClient").toLowerCase()} management actions suggest{" "}
                                {getTerm("growthPotential").toLowerCase()} for the future.
                                <ol>
                                    <li>
                                        <b>Themes:</b> Groups of {getTerm("features")} that behave similarly to each other and target a similar positive {getTerm("parentClient").toLowerCase()} outcome.
                                        <br />
                                        <br />
                                        <div className="table-grid">
                                            <div className="cell">
                                                <h5>Delivery Process</h5>
                                            </div>
                                            <div className="cell">{getTerm("tooltipDeliveryProcess")}</div>

                                            <div className="cell">
                                                <h5>Team Composition</h5>
                                            </div>
                                            <div className="cell">{getTerm("tooltipTeamComposition")}</div>

                                            <div className="cell">
                                                <h5>Cross Collaboration</h5>
                                            </div>
                                            <div className="cell">{getTerm("tooltipCrossCollaboration")}</div>

                                            <div className="cell">
                                                <h5>{getTerm("parentClient")} Demographics</h5>
                                            </div>
                                            <div className="cell">{getTerm("tooltipClientDemographics")}</div>

                                            <div className="cell">
                                                <h5>External Market</h5>
                                            </div>
                                            <div className="cell">{getTerm("tooltipExternalMarket")}</div>

                                            <div className="cell">
                                                <h5>Business Administration</h5>
                                            </div>
                                            <div className="cell">{getTerm("tooltipBusinessAdministration")}</div>
                                        </div>
                                        <br />
                                    </li>
                                    <li>
                                        <b>{getTerm("features")}:</b> {getTerm("features")} are specific behavioral trends of {getTerm("parentClients").toLowerCase()} that suggest growth or risk.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>Recommendations:</b> Validated trends over time that when implemented will increase likelihood of growth.
                            </li>
                            <li>
                                <b>Like {getTerm("parentClients")}:</b> {getTerm("parentClients").toLowerCase()} with a similar history of services purchased with your firm, lifetime value of the{" "}
                                {getTerm("parentClient").toLowerCase()}, and {getTerm("industry").toLowerCase()} grouping.
                            </li>
                            <li>
                                <b>Like {getTerm("existingProjects")}:</b> {getTerm("existingProjects").toLowerCase()} that have followed a similar {getTerm("parentClient").toLowerCase()} journey of services purchased
                                with your firm, lifetime value of the {getTerm("parentClient").toLowerCase()}, and {getTerm("industry").toLowerCase()} grouping.
                            </li>
                            <li>
                                <b>{getTerm("t12Revenue")}:</b> Last 12 months of net {getTerm("revenue")} from all existing {getTerm("parentClients").toLowerCase()} in the firm with at least one month of billing
                                activity in the last 3 years.
                            </li>
                            <li>
                                <b>
                                    Average {getTerm("existingProject")} {getTerm("revenue")}:
                                </b>{" "}
                                {getTerm("averageRevenue")} of {getTerm("existingProjects").toLowerCase()} with this {getTerm("projectType")} for like {getTerm("parentClients").toLowerCase()}.
                            </li>
                            <li>
                                <b>Expansion Probability:</b> How frequently like {getTerm("parentClients").toLowerCase()} successfully expanded the {getTerm("parentClient").toLowerCase()} account with this{" "}
                                {getTerm("projectType")}.
                            </li>
                            <li>
                                <b>Segments:</b> Portions of the firm based on {getTerm("industry")}, {getTerm("geography")}, or {getTerm("serviceLine")}.
                            </li>
                        </ol>
                    </div>

                    <h3 name="key-contacts">Key Contacts</h3>
                    <div className="help-section">
                        <h4 className="txt -white">Help Desk</h4>
                        <p>
                            <a href="mailto:BTsupport@orgaimi.com">BTsupport@orgaimi.com</a>
                        </p>
                        <h4 className="txt -white">Derek Jett, Customer Success Manager</h4>
                        <p>
                            <a href="mailto:djett@axiomcp.com ">djett@axiomcp.com</a>
                        </p>
                        <h4 className="txt -white">Maggie Miller, Chief {getTerm("parentClient")} Officer</h4>
                        <p>
                            <a href="mailto:mmiller@orgaimi.com">mmiller@orgaimi.com</a>
                        </p>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default HelpIndex;
