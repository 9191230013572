import React from "react";
import { getTerm } from "$stores/dictionary";

const HelpTeamPastWork = () => {
    return (
        <div className="help-section">
            <p>
                This view allows for the examination of an individual's past work over {getTerm("industries")}, {getTerm("serviceLines")}, {getTerm("projectTypes")} and {getTerm("parentClients")} as well as a list of all
                projects where the user has spent at least 10 hours.
            </p>
        </div>
    );
};

export default HelpTeamPastWork;
