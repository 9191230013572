import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from "recharts";

import CustomLegend from "$components/charts/legend";

import { DateCurrencyTooltip } from "$components/charts/tooltips/currency";
import { calclulateDataMax, calclulateDataMin } from "$components/charts/lib/constants";
import { formatDate, SHORT_MONTH_FORMAT } from "$utils/date-helpers";
import { abbrNum } from "$utils/number-helper";
import { GRID_STROKE } from "$components/charts/svg-definitions";
import { COLOR_FONT } from "$utils/colors";
import { buildColorsWithOpacity } from "$components/charts/lib/helpers";

const RevenueChart = ({ data = [], highlightFn = null, yDomain = [(dataMin) => (dataMin > 0 ? 0 : calclulateDataMin(dataMin)), (dataMax) => (dataMax < 0 ? 0 : calclulateDataMax(dataMax))], handleClick }) => {
    const colors = buildColorsWithOpacity(data.length);
    return (
        <ResponsiveContainer width="99%" height="100%">
            <LineChart
                width={500}
                height={300}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke={GRID_STROKE} vertical={false} />
                <ReferenceLine y={0} stroke={COLOR_FONT} />
                <XAxis dataKey="date" axisLine={false} tickFormatter={(label) => formatDate(label, SHORT_MONTH_FORMAT)} allowDuplicatedCategory={false} />
                <YAxis axisLine={false} tickFormatter={(label) => `$${abbrNum(label)}`} domain={yDomain} />
                <Tooltip content={<DateCurrencyTooltip />} />
                <Legend wrapperStyle={{ top: -30, left: 0 }} content={CustomLegend} handleClick={handleClick} />
                {data.map((l, i) => {
                    return (
                        <Line
                            key={`line_${i}_${data[i].key}`}
                            url={data[i].url}
                            labelId={data[i].labelId}
                            data={data[i].data}
                            type="monotone"
                            dataKey="value"
                            name={data[i].label}
                            stroke={colors[i]}
                            strokeWidth={highlightFn ? highlightFn(l) : 4}
                            dot={false}
                        />
                    );
                })}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default RevenueChart;
