import React from "react";

const DictionaryItem = ({ item, onChange }) => {
    return (
        <>
            <label>{item.key}: </label>
            <input type="text" value={item.value} onChange={(e) => onChange(item.key, e.target.value)} />
        </>
    );
};

export default DictionaryItem;
