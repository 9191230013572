import React, { useEffect } from "react";

import InfoList from "../../info-list";
import PicSearch from "../components/pic-search";

import { OpportunityStore, searchOpportunities } from "$stores/opportunities";
import { CrmStore } from "$stores/crm-filter";
import { buildContent } from "../lib/helpers";

const SearchPanel = () => {
    const revenueData = OpportunityStore.useState((s) => s.search);
    const selectedOptions = CrmStore.useState((f) => f.selectedResults);

    useEffect(() => {
        searchOpportunities(selectedOptions);
    }, [selectedOptions]);

    return (
        <>
            <header className="subheader">
                <PicSearch />
            </header>
            {selectedOptions.length > 0 && revenueData && (
                <InfoList
                    data={revenueData.map((s) => {
                        return buildContent(s);
                    })}
                />
            )}
        </>
    );
};

export default SearchPanel;
