import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPersonWalkingArrowRight } from "@fortawesome/free-solid-svg-icons";

import { getInitials } from "../../utils/initials";
import { getTerm } from "$stores/dictionary";

import "./profile-icon.scss";

const ProfileIcon = ({ imageUrl, name, isActive = true, modifierClassNames = [], icon = null }) => {
    const initials = isActive ? getInitials(name) || <FontAwesomeIcon icon={icon || faUser} /> : <FontAwesomeIcon icon={faPersonWalkingArrowRight} />;

    return (
        <div className={classnames("profile-icon", modifierClassNames, { "-faded": !isActive })} title={isActive ? name : getTerm("tooltipNoLongerEmployed")}>
            <span>{initials}</span>
            {imageUrl && <div className="image-override" style={{ backgroundImage: `url(${imageUrl})` }}></div>}
        </div>
    );
};

export default ProfileIcon;
