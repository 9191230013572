import axios from "axios";
import { toast } from "react-toastify";
import { persistValue, retrieveValue } from "./local";
import { acquireFreshToken, UserStore } from "$stores/user";

const CANCEL_ERROR_KEY = "canceled";
let userToken;

export const convertStringToHash = (str) => {
    return str.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
    }, 0);
};

export const buildUrlKey = (url) => {
    let baseUrl;
    const splitUrl = url.split("?");
    if (splitUrl.length === 0) {
        baseUrl = url;
    } else {
        baseUrl = splitUrl[0];
    }
    return baseUrl;
};

export const handleAbort = (abortList = []) => {
    abortList.forEach((controller) => {
        if (controller?.abort) {
            controller?.abort();
        }
    });
};

let axiosClient;

const buildAxiosClient = async () => {
    const auth = userToken ? { Authorization: `Bearer ${userToken}` } : {};
    axiosClient = axios.create({
        baseURL: "/",
        headers: { ...auth },
    });
};

const globalCatch = (error) => {
    if (axios.isCancel(error)) {
        throw new Error("canceled");
    }
    if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
            if (!window.location.pathname.startsWith("/reset")) {
                window.location = `/reset?path=${window.location.pathname}`;
            }
            throw new Error(`unauthorized_${error.response.status}`);
        }
        if (error.response.status === 404) {
            return error?.response;
        }
        if (error.response?.data?.message) {
            toast(error.response?.data?.message);
        }
    }
    throw error;
};

export default {
    requestWasCanceled(e) {
        return e.message === CANCEL_ERROR_KEY;
    },
    async getAxiosClient() {
        const { account, instance } = UserStore.getRawState();
        userToken = await acquireFreshToken(account, instance);

        await buildAxiosClient();

        return axiosClient;
    },
    async resetAxiosClient() {
        axiosClient = null;
    },
    async getCancellationToken() {
        let cancel;
        const token = new CancelToken(function executor(c) {
            cancel = c;
        });
        return {
            cancel: cancel,
            token: token,
        };
    },
    async get(url, request, settings) {
        try {
            const client = await this.getAxiosClient();
            const response = await client.get(url, {
                request,
                ...settings,
            });
            if (response.status !== 200) {
                return null;
            }
            return response.data || "OK";
        } catch (error) {
            globalCatch({ ...error });
        }
    },
    async post(url, request) {
        try {
            const client = await this.getAxiosClient();
            const response = await client.post(url, request);
            if (response.status !== 200) {
                return null;
            }
            return response.data || "OK";
        } catch (error) {
            globalCatch({ ...error });
        }
    },
    async put(url, request) {
        try {
            const client = await this.getAxiosClient();
            const response = await client.put(url, request);
            if (response.status !== 200) {
                return null;
            }
            return response.data || "OK";
        } catch (error) {
            globalCatch({ ...error });
        }
    },
    async delete(url) {
        try {
            const client = await this.getAxiosClient();
            const response = await client.delete(url);
            if (response.status !== 200) {
                return null;
            }
            return response.data || "OK";
        } catch (error) {
            globalCatch({ ...error });
        }
    },
    async getFromCache(url, request) {
        const urlKey = convertStringToHash(url);
        const storedValue = await retrieveValue(urlKey);
        if (storedValue) {
            return storedValue;
        }
        const response = await this.get(url, request);
        if (response) {
            await persistValue(urlKey, response);
        }
        return response;
    },
};
