import React from "react";
import { Area, ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { format } from "date-fns";

import EngineLoader from "$components/loaders/engine-loader";
import NoData from "./no-content";

import { ScoreTooltip } from "$components/charts/tooltips/score";
import { useChartColor, useChartUv, GRID_STROKE } from "./svg-definitions";

const ScoreLineChart = ({ scoreHistory, isHealthy }) => {
    const chartColor = useChartColor(isHealthy);
    const chartUv = useChartUv(isHealthy);
    
    if (!scoreHistory) {
        return <EngineLoader />;
    }

    if (scoreHistory.length <= 0) {
        return <NoData />;
    }
    
    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                width={500}
                height={300}
                data={scoreHistory}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke={GRID_STROKE} />
                <XAxis axisLine={false} dataKey="date" interval={0} tickFormatter={(date) => format(new Date(date), "LLL yy")} />
                <YAxis axisLine={false} domain={[0, 700]} tickCount={16} interval={1} />
                <Tooltip content={<ScoreTooltip />} />
                <Line type="monotone" dataKey="score" stroke={chartColor} dot={false} strokeLinecap="round" strokeWidth={2} />
                <Area type="monotone" dataKey="score" stroke="false" strokeWidth={2} fillOpacity={1} fill={chartUv} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default ScoreLineChart;
