import React, { Component, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag, faHandHoldingDollar, faShareNodes } from "@fortawesome/free-solid-svg-icons";

import Comments from "$components/comments";
import Feedback from "$components/feedback";
import FeedbackUndo from "$components/feedback/feedback-undo";
import InfoTable from "$components/info-table";
import LargeNumberRange from "$components/table/cells/large-number-range";
import LinkInfoCell from "$components/info-table/cells/link-info-cell";
import NotificationBar from "$components/notification-bar";
import PotentialProjects from "../components/potential-projects";
import ProbabilityBubble from "$components/probability";
import SalesforceButton from "$components/button/salesforce-export";
import SalesforceOpportunityForm from "$components/salesforce/form";
import ShareModal from "$components/modal/share-modal";
import TeamInfoCell from "$components/info-table/cells/team-info-cell";

import { fetchProject, ProjectDetailsStore } from "$stores/projectDetails";
import { useDocumentTitle } from "$utils/document-title";
import { getTerm } from "$stores/dictionary";
import { AppContextStore } from "$stores/app-context";
import { closeModal, initiateModal } from "$stores/modal";
import { setHelpContent } from "$stores/help";
import { HELP_POTENTIAL_PROJECT_DETAIL } from "src/pages/help/lib/help-contents";

const buildInfoPropertiesMap = (showExperiencedTeamMembers, showProjectTypeGroup) => {
    return {
        clientName: { label: `${getTerm("parentClient")} Name`, Component: LinkInfoCell, additionalProps: { url: "/client/#{clientId}" }, colSpan: 2 },
        subClientName: { label: getTerm("subClient"), colSpan: 2 },
        clientPIC: { label: getTerm("subCrm"), Component: LinkInfoCell, colSpan: 2, additionalProps: { url: "/team/#{crmId}", crmId: "#{crmId}", isActive: "#{crmIsActive}", email: "#{crmEmail}" } },
        ...(showProjectTypeGroup && { projectTypeGroup: { label: getTerm("projectTypeGroup"), colSpan: 2 } }),
        serviceLine: { label: getTerm("serviceLine"), colSpan: showProjectTypeGroup ? 2 : 3 },
        industry: { label: getTerm("industry"), colSpan: showProjectTypeGroup ? 2 : 3 },
        averageRevenue: {
            label: <span data-tooltip={getTerm("tooltipAverageProjectRevenue")}>{getTerm("averageRevenue")} Size</span>,
            Component: LargeNumberRange,
            additionalProps: { url: "/client/#{clientId}", decimalPrecision: 0 },
            colSpan: 2,
        },
        probability: { label: <span data-tooltip={getTerm("tooltipExpansionProbability")}>Deal Probability</span>, Component: ProbabilityBubble, colSpan: 2 },
        ...(showExperiencedTeamMembers && {
            team: {
                label: <span data-tooltip={getTerm("tooltipExperiencedTeam")}>Experienced {getTerm("teamMembers")}</span>,
                Component: TeamInfoCell,
                colSpan: 2,
                additionalProps: { projectId: "#{projectId}", projectKey: "#{projectKey}", allowFeedback: true },
            },
        }),
    };
};

const PotentialProject = () => {
    useDocumentTitle("Potential Project");
    const { enableSalesForce, enableShare, enableExperienceTeamMembers } = AppContextStore.useState((a) => {
        return { enableSalesForce: a.settings?.externalConnections?.salesforce, enableShare: a.settings?.externalConnections?.share, enableExperienceTeamMembers: a.settings?.sections?.experiencedTeamMembers || false };
    });
    const { comparableProjects, currentProject } = ProjectDetailsStore.useState((s) => {
        return { comparableProjects: s.comparables, currentProject: s.projectDetails };
    });
    const [infoPropertiesMap] = useState(buildInfoPropertiesMap(enableExperienceTeamMembers, !!currentProject.projectTypeGroup));

    useEffect(() => {
        setHelpContent(HELP_POTENTIAL_PROJECT_DETAIL);
    }, []);

    const triggerModal = (type) => {
        if (type === "salesforce") {
            initiateModal(
                SalesforceOpportunityForm,
                {
                    clientId: currentProject.subClientId,
                    currentProject: currentProject,
                    projectKey: currentProject.projectKey,
                    afterSubmit: onSalesforceSubmit,
                },
                { analyticsLabel: "Salesforce" }
            );
        }
        if (type === "feedback") {
            initiateModal(
                Feedback,
                {
                    id: currentProject.projectId,
                    projectKey: currentProject.projectKey,
                    onSubmit: () => {
                        closeModal();
                        resetProject();
                    },
                },
                { analyticsLabel: "Feedback" }
            );
        }
        if (type === "share") {
            initiateModal(
                ShareModal,
                {
                    type: "project/potential",
                },
                { analyticsLabel: `${getTerm("potentialProject")} Share` }
            );
        }
    };

    const resetProject = () => {
        closeModal();
        fetchProject(currentProject.projectId, "potential");
    };

    const onSalesforceSubmit = () => {
        closeModal();
        resetProject();
    };

    return (
        <div id="potential-project" className="main-wrapper">
            <div className="page-content -shadow">
                <div className="content">
                    <div className="flex-header">
                        <h1 className="txt -uppercase -no-margin -tablet-center" style={{ display: "flex", position: "relative" }}>
                            <em>{getTerm("potential")}:&nbsp;</em>
                            <span>{currentProject.name}</span>
                        </h1>
                        <div className="project-links">
                            {enableSalesForce && (
                                <SalesforceButton
                                    disabled={currentProject.hasSalesforceOpportunityCreated}
                                    onClick={() => {
                                        triggerModal("salesforce");
                                    }}
                                />
                            )}
                            {!currentProject.userFlagged && (
                                <button
                                    className="button -text"
                                    onClick={() => {
                                        triggerModal("feedback");
                                    }}
                                    title="Feedback"
                                >
                                    <FontAwesomeIcon icon={faFlag} />
                                </button>
                            )}
                            {enableShare && (
                                <button
                                    className="button -text"
                                    onClick={() => {
                                        triggerModal("share");
                                    }}
                                    title="Share"
                                >
                                    <FontAwesomeIcon icon={faShareNodes} />
                                </button>
                            )}
                        </div>
                    </div>
                    {currentProject.isCrossSell && <NotificationBar message="Cross-Sell Opportunity" type="success" icon={faHandHoldingDollar} />}
                    {currentProject.userFlagged && (
                        <NotificationBar type="warning">
                            <FeedbackUndo id={currentProject.projectId} projectKey={currentProject.projectKey} onSubmit={resetProject} />
                        </NotificationBar>
                    )}
                    {currentProject.requiresIndependence && <NotificationBar message="Check Independence." type="warning" />}
                    <InfoTable data={currentProject} dataMap={infoPropertiesMap} colCount={6} />

                    <div className="bg -dark">
                        <Comments projectKey={currentProject.projectKey} />
                    </div>

                    {comparableProjects && comparableProjects.length > 0 && (
                        <>
                            <h3>
                                Similar <span data-tooltip={getTerm("tooltipTopPotentialProjects")}>{getTerm("potentialProjects")}</span>
                            </h3>

                            <div className="similar-projects">
                                <PotentialProjects data={comparableProjects} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PotentialProject;
