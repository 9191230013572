import TileBar from "$components/charts/tile-bar";
import React from "react";

const FeatureScore = ({ name, value }) => {
    return (
        <>
            <h4 className="feature-name">{name}</h4>
            <div className="feature-content">
                <TileBar value={value} />
            </div>
        </>
    );
};

const FeatureScores = ({ data }) => {
    return (
        <>
            {data.map((f, i) => (
                <FeatureScore key={`feature-score${i}`} {...f} />
            ))}
        </>
    );
};

export default FeatureScores;
