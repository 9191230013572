import classNames from "classnames";
import React from "react";

const NotificationTemplateList = ({ selectedId, templates, onClick }) => {
    return (
        <div className="template-list">
            {templates.map((template) => {
                return (
                    <button
                        key={`${template.id}_template`}
                        onClick={() => {
                            onClick(template);
                        }}
                        className={classNames("button -default -border -small", { "-inactive": selectedId !== template.id })}
                    >
                        {template.templateKey}
                    </button>
                );
            })}
        </div>
    );
};

export default NotificationTemplateList;
