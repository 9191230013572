import React from "react";

import NoData from "$components/charts/no-content";
import ScoreLineChart from "$components/charts/score-line";

import { checkIsHealthy } from "$utils/health-helper";
import { ClientDetailsStore } from "$stores/client-details";

import "./health-history.scss";
import { getTerm } from "$stores/dictionary";

const HealthHistory = () => {
    const { clientScoreHistory } = ClientDetailsStore.useState((s) => s.clientDetailsCharts);

    return (
        <div className="health-history">
            <div className="chart">
                <ScoreLineChart scoreHistory={clientScoreHistory} isHealthy={clientScoreHistory ? checkIsHealthy(clientScoreHistory[clientScoreHistory.length - 1]?.score) : false} />
            </div>
            <div className="content">
                <div className="intro">
                    Your {getTerm("parentClient")} Health Scores indicate whether your {getTerm("parentClient").toLowerCase()} is healthy or at risk based on leading indicators of {getTerm("parentClient").toLowerCase()}{" "}
                    relationship health. Orgaimi measures monthly behavior changes across Themes to highlight trends of healthy, growing {getTerm("parentClients").toLowerCase()} or at risk, declining{" "}
                    {getTerm("parentClients").toLowerCase()}.
                </div>
                <ul className="definition-list">
                    <li>
                        <b>Scores:</b> Scores are on a 100-700 scale: lower scores indicate more risk, where higher scores predict more growth. Your overall {getTerm("parentClient")} Health Score compiles your{" "}
                        {getTerm("parentClient").toLowerCase()}'s performance across all Themes and considers seasonality trends to provide a score. Each Theme has a sub-score to represent your{" "}
                        {getTerm("parentClient").toLowerCase()}'s performance in this particular area.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default HealthHistory;
