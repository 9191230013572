import React from "react";

import ToggleSwitch from "$components/toggle-switch";

const NotificationsSettings = () => {
    return (
        <div className="settings">
            <div className="form-fields">
                <h5 className="txt -uppercase -condensed -normal">Email Notifications:</h5>
                <ToggleSwitch
                    options={[
                        { label: "On", value: true },
                        { label: "Off", value: false },
                    ]}
                    selectedIndex={0}
                />
            </div>
        </div>
    );
};

export default NotificationsSettings;
