import { faBriefcase, faBuilding, faFolder, faFolderTree, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export const getExpertInIcon = (key) => {
    switch (key) {
        case 1:
            return { icon: faFolder };
        case 2:
            return { icon: faBuilding };
        case 3:
            return { icon: faBriefcase };
        case 4:
            return { icon: faFolderTree };
        default:
            return { icon: faQuestionCircle };
    }
};
