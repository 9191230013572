import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { updateFn } from "../libs/helpers";

const Textarea = (props) => {
    const [value, setValue] = useState((props.defaultValue ? props.defaultValue : props.value) || "");

    useEffect(() => {
        const useValue = (props.defaultValue ? props.defaultValue : props.value) || "";
        if (useValue !== value) {
            setValue(useValue);
        }
    }, [props.defaultValue, props.value, value]);

    const onChange = (e) => {
        setValue(e.currentTarget.value);

        if (props.onChange) {
            props.onChange(e);
        } else if (props.updateOnChange) {
            updateFn(props.updateOnChange)(e);
        }
    };

    return (
        <textarea
            type="text"
            key={`${props.id || props.name || ""}`}
            id={`${props.id || props.name || ""}`}
            name={props.name}
            autoComplete={props.autoComplete}
            autoFocus={props.autoFocus}
            min={props.min}
            max={props.max}
            maxLength={props.maxLength}
            minLength={props.minLength}
            multiple={props.multiple}
            pattern={props.pattern}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            required={props.required}
            step={props.step}
            value={value}
            onKeyUp={props.onKeyUp}
            onKeyDown={props.onKeyDown}
            onClick={props.onClick}
            onChange={onChange}
            onBlur={props.onBlur || updateFn(props.updateOnBlur)}
            onFocus={props.onFocus}
            disabled={props.disabled}
            tabIndex={props.tabIndex}
            className={classnames({ "-error": props.invalid })}
        ></textarea>
    );
};

export default Textarea;
