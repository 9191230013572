import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import GenericLegend from "./components/generic-legend";

import { CHART_DEFAULT_COLORS } from "../lib/constants";
import { stringTemplateReplace } from "$utils/template-replace";

const ExternalLegend = ({ cssClasses, data = [], colors = CHART_DEFAULT_COLORS, linkFormat, onClick, title, Component = GenericLegend }) => {
    const navigate = useNavigate();
    if (!data || data.length <= 0) {
        return null;
    }
    return (
        <div className={classNames("external-legend", cssClasses)}>
            {title && <h4>{title}</h4>}
            {data.map((entry, index) => {
                const isLink = (linkFormat || !!onClick) && entry.label !== "Other";
                return (
                    <div
                        key={`color-swatch_${index}`}
                        className={classNames({ "-link": isLink })}
                        onClick={() => {
                            if (isLink) {
                                if (onClick) {
                                    onClick(entry);
                                }
                                if (linkFormat) {
                                    navigate(stringTemplateReplace(linkFormat, entry));
                                }
                            }
                        }}
                    >
                        <Component color={entry.color || colors[index]} data={entry} />
                    </div>
                );
            })}
        </div>
    );
};

export default ExternalLegend;
