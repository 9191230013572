import React from "react";

import { COLOR_BASE, COLOR_HEALTHY, COLOR_UNHEALTHY } from "$utils/colors";

export const GRID_STROKE = COLOR_BASE;
export const HEALTHY_ID = "#healthyUv";
export const UNHEALTHY_ID = "#unhealthyUv";

export const useChartColor = (isHealthy) => {
    return isHealthy ? COLOR_HEALTHY : COLOR_UNHEALTHY;
};

export const useChartUv = (isHealthy) => {
    return isHealthy ? `url(${HEALTHY_ID})` : `url(${UNHEALTHY_ID})`;
};

export const SVGDefinitions = () => {
    return (
        <svg className="hide-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient id="healthyUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={COLOR_HEALTHY} stopOpacity={1} />
                    <stop offset="95%" stopColor={COLOR_HEALTHY} stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="unhealthyUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={COLOR_UNHEALTHY} stopOpacity={1} />
                    <stop offset="95%" stopColor={COLOR_UNHEALTHY} stopOpacity={0.1} />
                </linearGradient>
            </defs>
        </svg>
    );
};
