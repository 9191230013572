import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowDown, faLongArrowUp } from "@fortawesome/free-solid-svg-icons";

const HealthArrow = ({ isHealthy, isIncrease }) => {
    const healthClass = isHealthy === false ? "-unhealthy" : isHealthy ? "-healthy" : "";
    return (
        <span className={classnames("arrow", healthClass)}>
            <FontAwesomeIcon icon={isIncrease === false ? faLongArrowDown : faLongArrowUp} />
        </span>
    );
};

export default HealthArrow;
