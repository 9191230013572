import React, { useCallback, useEffect, useState, useRef } from "react";
import classnames from "classnames";

import { abbrNum, formatNumberWithCommas } from "$utils/number-helper";
import { stringMatch } from "$utils/check-match";

import "./multi-range-slider.scss";
import classNames from "classnames";

let updateRangeTimer = null;

const MultiRangeSlider = ({
    name = "",
    label = "",
    defaultValue,
    min = 0,
    max = 100,
    updateOnChange,
    format = {
        isCurrency: false,
        isPercent: false,
    },
    closeMenu,
    cssClasses = [],
}) => {
    const [minVal, setMinVal] = useState(defaultValue?.min || min);
    const [maxVal, setMaxVal] = useState(defaultValue?.max || max);
    const [inUse, setInUse] = useState(false);
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);

    // Convert to percentage
    const getPercent = useCallback(
        (value) => {
            return Math.round(((value - min) / (max - min)) * 100);
        },
        [min, max]
    );

    useEffect(() => {
        if ((defaultValue?.min || min) !== minVal || (defaultValue?.max || max) !== maxVal) {
            setMinVal(defaultValue?.min || min);
            setMaxVal(defaultValue?.max || max);
        }
    }, [defaultValue, min, max]);

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal, getPercent, min]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent, max]);

    // Get min and max values when their state changes
    useEffect(() => {
        clearTimeout(updateRangeTimer);
        if (inUse) {
            return;
        }

        if (updateOnChange) {
            let newValue = {};

            if (!isNaN(minVal) && min !== minVal) {
                newValue.min = minVal;
            }
            if (!isNaN(maxVal) && max !== maxVal) {
                newValue.max = maxVal;
            }
            if ((newValue.min === min && newValue.max === max) || (!newValue.min && !newValue.max) || Object.keys(newValue).length === 0) {
                newValue = "";
            }
            if (!stringMatch(newValue, defaultValue)) {
                updateRangeTimer = setTimeout(() => {
                    updateOnChange(name, newValue);
                }, 800);
            }
        }
    }, [minVal, maxVal, inUse]);

    if (isNaN(min) || !max) {
        return (
            <div className={classNames("multi-range-slider", cssClasses)}>
                <p>No Available Min/Max Values</p>
                {defaultValue && Object.keys(defaultValue).length > 0 && (
                    <p>
                        <button
                            className="button -micro"
                            onClick={() => {
                                updateOnChange(name, "");
                                if (closeMenu) {
                                    closeMenu();
                                }
                            }}
                        >
                            Clear Filter
                        </button>
                    </p>
                )}
            </div>
        );
    }

    return (
        <div className={classNames("multi-range-slider", cssClasses)}>
            {label && (
                <label htmlFor="rangeMin">
                    <h4 className="txt -secondary">{label}:</h4>
                </label>
            )}
            <div className="slider">
                <input
                    id="rangeMin"
                    type="range"
                    min={min}
                    max={max}
                    value={minVal || min}
                    ref={minValRef}
                    onChange={(event) => {
                        const value = Math.min(+event.target.value, +maxVal - 1);
                        setMinVal(value);
                        event.target.value = value.toString();
                    }}
                    onMouseDown={() => {
                        setInUse(true);
                    }}
                    onMouseUp={() => {
                        setInUse(false);
                    }}
                    className={classnames("thumb thumb--zindex-3", {
                        "thumb--zindex-5": minVal > max - 100,
                    })}
                />
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={maxVal || max}
                    ref={maxValRef}
                    onChange={(event) => {
                        clearTimeout(updateRangeTimer);
                        const value = Math.max(+event.target.value, +minVal + 1);
                        setMaxVal(value);
                        event.target.value = value.toString();
                    }}
                    onMouseDown={() => {
                        setInUse(true);
                    }}
                    onMouseUp={() => {
                        setInUse(false);
                    }}
                    className="thumb thumb--zindex-4"
                />

                <div className="slider-track" />
                <div ref={range} className="slider-range" />
                <div className="slider-value">
                    <div
                        className="slider-min-value"
                        onMouseDown={() => {
                            setInUse(true);
                        }}
                    >
                        {format?.isCurrency ? <sup>$</sup> : ""}
                        {abbrNum(minVal) || 0}
                    </div>
                    &nbsp;-&nbsp;
                    <div className="slider-max-value">
                        {abbrNum(maxVal)}
                        {format?.isPercent ? <sup>%</sup> : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultiRangeSlider;
