import React from "react";
import { getTerm } from "$stores/dictionary";

const HelpExistingProjects = () => {
    return (
        <div className="help-section">
            <p>
                The {getTerm("existingProject")} List View displays data about {getTerm("potentialProjects").toLowerCase()} and {getTerm("existingProjects").toLowerCase()} (both closed and active projects) to provide a
                more tabular view of identifying project opportunities. The Project List for {getTerm("existingProjects")} includes:
            </p>
            <ul>
                <li data-number="1">
                    {getTerm("projectType")} of the {getTerm("existingProject").toLowerCase()}
                </li>
                <li data-number="2">{getTerm("parentClient")} Name</li>
                <li data-number="3">
                    {getTerm("subClient")} Name of the {getTerm("existingProject").toLowerCase()}
                </li>
                <li data-number="4">
                    {getTerm("projectPersonInCharge")} of the {getTerm("existingProject").toLowerCase()}
                </li>
                <li data-number="5">Start/open date of the project</li>
                <li data-number="6">End/closed date of the project: if the project is open, this will show as "ongoing"</li>
                <li data-number="7">Status of project: open, completed/closed, suspended</li>
                <li data-number="8">Revenue to date</li>
            </ul>
        </div>
    );
};

export default HelpExistingProjects;
