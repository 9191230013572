import React, { useEffect, useState } from "react";
import debounce from "debounce";
import { useSearchParams } from "react-router-dom";
1;

import ClientList from "./list";

import { AppContextStore } from "$stores/app-context";
import { ClientStore, fetchClients, watchPagination, reset, setLoading, setPagingInfo } from "$stores/clients";
import { useDocumentTitle } from "$utils/document-title";
import { setHelpContent } from "$stores/help";
import { HELP_CLIENT_LIST } from "../help/lib/help-contents";
import { useEffectOnce } from "$utils/hooks/useEffectOnce";

const debouncedFetchClients = debounce(async (updatedFilters, pagingInfo) => {
    fetchClients(updatedFilters, { keyPrefix: "tableFilter.", ...pagingInfo });
}, 800);

const ClientIndex = () => {
    useDocumentTitle("Client List");
    const [searchParams] = useSearchParams();
    const [initialLoad, setInitialLoad] = useState(true);

    const { hasLoadedFilters, globalFilters } = AppContextStore.useState((f) => {
        return { hasLoadedFilters: f.hasLoadedFilters, globalFilters: f.currentFilters || {}, overviewLoading: f.overviewLoading };
    });

    const pagingInfo = ClientStore.useState((f) => {
        return { page: f.pageIndex, limit: f.pageSize, filters: f.filters, sort: f.sort, isSortAsc: f.isSortAsc };
    });

    useEffect(() => {
        const unsubs = watchPagination();
        setHelpContent(HELP_CLIENT_LIST);

        return () => {
            if (unsubs && unsubs.length > 0) {
                unsubs.forEach((unsub) => unsub());
            }
        };
    }, []);

    useEffectOnce(() => {
        return () => {
            // Resets Pagination
            reset();
        };
    }, []);

    useEffect(() => {
        if (hasLoadedFilters) {
            const updatables = {};
            const sort = JSON.parse(searchParams.get("sort"));
            const filter = JSON.parse(searchParams.get("filter"));
            const page = searchParams.get("pageIndex");
            if (sort) updatables.sort = sort;
            if (filter) updatables.filter = filter;
            if (page) updatables.pageIndex = page;

            if (!_.isEmpty(updatables)) {
                setPagingInfo(updatables);
            }
            setInitialLoad(false);
        }
    }, [hasLoadedFilters]);

    useEffect(() => {
        if (!initialLoad && hasLoadedFilters) {
            setLoading();
            debouncedFetchClients({ ...globalFilters }, { ...pagingInfo });
        }
    }, [initialLoad, hasLoadedFilters, globalFilters, pagingInfo]);

    return (
        <div id="client-list" className="page">
            <div className="main -spacer">
                <main>
                    <div className="main-wrapper">
                        <h1>
                            <em>Client</em> List
                        </h1>
                        <ClientList />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ClientIndex;
