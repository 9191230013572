import React from "react";
import { getTerm } from "$stores/dictionary";

const HelpExistingProjectDetail = () => {
    return (
        <div className="help-section">
            <p>
                The {getTerm("existingProject")} Detail view gives additional information on {getTerm("existingProjects").toLowerCase()} to help contextualize future opportunities.
            </p>
        </div>
    );
};

export default HelpExistingProjectDetail;
