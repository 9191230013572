import React from "react";

import { abbrNum } from "../../../utils/number-helper";
import { getTerm } from "$stores/dictionary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";

export const buildContent = (s) => {
    return {
        content: (
            <div>
                <h3>{s.companyName}</h3>
                <p>
                    {getTerm("growthPotential")} =&nbsp;
                    <b>
                        <sup>{s.scoreBubble.preSup}</sup>
                        {s.scoreBubble.value}
                    </b>
                    <br />
                    Top {getTerm("potentialProject")}: {s.topProject} =&nbsp;
                    <b>
                        <sup>$</sup>
                        {abbrNum(s.topProjectPotentialRevenue)}
                    </b>
                    {s.topProjectIsCrossSell && (
                        <span className="txt -importance">
                            {" "}
                            <FontAwesomeIcon icon={faHandHoldingDollar} />
                        </span>
                    )}
                </p>
            </div>
        ),
        ...s,
    };
};

export const buildProjectContent = (s) => {
    return {
        content: (
            <div>
                <h3>{s.topProject}</h3>
                <p>
                    {getTerm("growthPotential")} =&nbsp;
                    <b>
                        <sup>{s.scoreBubble.preSup}</sup>
                        {s.scoreBubble.value}
                    </b>
                    <br />
                    {getTerm("parentClient")}: {s.companyName}
                </p>
            </div>
        ),
        ...s,
    };
};
