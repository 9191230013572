import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpPotentialProjectsDetail = () => {
    return (
        <div className="help-section">
            <p>
                The {getTerm("potentialProject")} Detail view gives additional information on the {getTerm("potentialProject").toLowerCase()} to help decision makers.
            </p>
        </div>
    );
};

export default HelpPotentialProjectsDetail;
