import React from "react";
import { PieChart, Pie, Cell, Sector, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { CHART_PIE_COLORS } from "../lib/constants";
import { LabelCurrencyTooltip } from "../tooltips/currency";
import { LabelValueTooltip } from "../tooltips/label-value";

import EngineLoader from "$components/loaders/engine-loader";

import "./pie.scss";
import NotificationBar from "$components/notification-bar";
import NoData from "../no-content";
import { isFunction } from "lodash";

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
            <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const ChartPie = ({ charts = [], colors = CHART_PIE_COLORS, TooltipComponent = LabelCurrencyTooltip }) => {
    const onPieEnter = (_, index) => {
        //this.setState({
        //    activeIndex: index,
        //});
    };

    if (charts.length === 0 || !charts[0].data) {
        return <EngineLoader />;
    }
    if (charts[0].data.length === 0) {
        return <NoData message="No Parsable Data Available" />;
    }

    const allNegatives = charts[0]?.data.every((v) => v.value <= 0);

    if (allNegatives) {
        return (
            <>
                <NotificationBar message="Can not display chart due to all negative numbers" type="error" />
            </>
        );
    }

    const largestDiameter = charts.reduce((num, c) => {
        return Math.max(num, c.outerRadius * 2 + 50);
    }, 0);

    return (
        <div className="chart-pie" style={{ width: largestDiameter + "px", height: largestDiameter + "px" }}>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={largestDiameter} height={largestDiameter}>
                    <Tooltip content={<TooltipComponent />} />
                    {charts.map(({ data, onClick, innerRadius, outerRadius }, i) => {
                        const overrideOnClick =
                            onClick && isFunction(onClick)
                                ? (d, i) => {
                                      const useName = d.label || d.name || "";
                                      if (!useName.toLowerCase().startsWith("other")) {
                                          onClick(d, i);
                                      }
                                  }
                                : null;
                        return (
                            <Pie
                                key={`chart_${i}`}
                                data={data}
                                cx="50%"
                                cy="50%"
                                innerRadius={innerRadius}
                                outerRadius={outerRadius}
                                fill="#8884d8"
                                nameKey={"text"}
                                dataKey="value"
                                onClick={overrideOnClick}
                                onMouseEnter={onPieEnter}
                                cursor={!!onClick ? "pointer" : ""}
                            >
                                {data.map((entry, index) => {
                                    return <Cell key={`cell-${index}`} fill={entry.color || colors[index % colors.length]} />;
                                })}
                            </Pie>
                        );
                    })}
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ChartPie;
