import React, { useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { getTypeDefaults } from "./lib/helpers";

const NotificationBar = ({ children, isClosable, link, linkText, message = "", type = "info", cssClass, icon }) => {
    const [visible, setVisible] = useState(true);
    const typeDefault = getTypeDefaults(type);
    const useLink = link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
            {linkText}
        </a>
    ) : null;

    const body = children ? (
        children
    ) : (
        <strong>
            {message} {useLink}
        </strong>
    );

    if (!visible) {
        return null;
    }
    return (
        <div className={classNames(typeDefault.baseCssClass, cssClass)}>
            {body}

            <div className="icon">
                <FontAwesomeIcon icon={icon || typeDefault.icon} />
            </div>
            {isClosable && (
                <button
                    className="button -text btn-close"
                    onClick={(e) => {
                        e.preventDefault();
                        setVisible(false);
                    }}
                    title="Close"
                >
                    <FontAwesomeIcon icon={faTimesCircle} />
                </button>
            )}
        </div>
    );
};

export default NotificationBar;
