import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import api from "$services/api.js";

import FeebackDialog from "./libs/feedback-dialog.js";
import TableLoader from "$components/table/table-loader.js";

import { PotentialProjectsStore, setFilters, setFlagged, setPageIndex, setPagingInfo, setPerPage, setSort } from "$stores/potentialProjects.js";
import { buildDefaultColumns } from "./libs/columns.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";
import { setHelpContent } from "$stores/help.js";
import { HELP_POTENTIAL_PROJECT_LIST } from "src/pages/help/lib/help-contents.js";
import { dialogConfirm } from "$stores/modal.js";

export const mapToTableData = (c) => {
    return {
        key: c.id,
        ...c,
    };
};

const PotentialProjectList = ({ buildColumns, columns, settings, initialFilters }) => {
    const navigate = useNavigate();
    const { filterMenu } = AppContextStore.useState((a) => {
        return {
            filterMenu: mapByKey(a.filterMenu, "apiKey") || {},
        };
    });
    const [showFeedback, setShowFeedback] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const { filters, filterRanges, globalFilterRanges, projects, pageIndex, pageSize, pageCount, isLoading, sort, isSortAsc } = PotentialProjectsStore.useState((c) => {
        return {
            projects: c.potentialProjects,
            pageSize: c.pageSize,
            pageIndex: c.pageIndex,
            pageCount: c.pageCount,
            isLoading: c.isLoading,
            sort: c.sort,
            isSortAsc: c.isSortAsc,
            filters: c.filters,
            filterRanges: c.filterRanges,
            globalFilterRanges: c.globalFilterRanges,
        };
    });

    useEffect(() => {
        setHelpContent(HELP_POTENTIAL_PROJECT_LIST);
    }, []);

    const rowClick = (row) => {
        navigate(`/project/${row.key}/potential`);
    };

    const updateSelectedProjects = (project, action) => {
        if (action === "clear") {
            setSelectedProjects([]);
        } else if (action === "remove") {
            setSelectedProjects(selectedProjects.filter((i) => project.id !== i.id));
        } else {
            setSelectedProjects([...selectedProjects, { ...project }]);
        }
    };

    const updateAll = (isAdd) => {
        if (isAdd) {
            setSelectedProjects(_.uniqBy([...selectedProjects, ...projects], (p) => p.id));
        } else {
            const removeProjects = projects.map((p) => p.id);
            const filterVisible = selectedProjects.filter((s) => !removeProjects.includes(s.id));
            setSelectedProjects(filterVisible);
        }
    };

    const sendFlags = async () => {
        if (selectedProjects.length <= 0) {
            return false;
        }

        const confirmUpdate = await dialogConfirm({
            data: {},
            Component: FeebackDialog,
            settings: { type: "confirm" },
        });

        const data = {
            projects: selectedProjects.map((s) => {
                return {
                    key: s.projectKey,
                    id: s.id,
                };
            }),
            ...confirmUpdate,
        };

        if (confirmUpdate) {
            const result = await api.put("/api/projects/potential/bulk/user-flag", data);
            if (result) {
                // Update Flagged
                setFlagged(selectedProjects.map((s) => s.id));
                // Clear selected
                setSelectedProjects([]);
            }
        }
    };

    const data = projects.map(mapToTableData);
    const selectedList = selectedProjects.map((i) => i.id);

    return (
        <div>
            <TableLoader
                allowFilter={true}
                columns={
                    !!columns
                        ? columns
                        : (buildColumns &&
                              buildColumns({ settings, showFeedback, selectedList, updateSelectedList: updateSelectedProjects, updateAll, filterOptions: filterMenu, filterRanges, globalFilterRanges, sendFlags })) ||
                          buildDefaultColumns({
                              settings,
                              showFeedback,
                              selectedList,
                              updateSelectedList: updateSelectedProjects,
                              updateAll,
                              filterOptions: filterMenu,
                              filterRanges,
                              globalFilterRanges,
                              sendFlags,
                          })
                }
                containerClass="project-list"
                data={data}
                filters={filters}
                initialFilters={initialFilters}
                isLoading={isLoading}
                isSortAsc={isSortAsc}
                onRowClick={rowClick}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageCount={pageCount}
                setPageIndex={setPageIndex}
                setFilters={setFilters}
                setPagingInfo={setPagingInfo}
                setPerPage={setPerPage}
                setSort={setSort}
                sort={sort}
            />
        </div>
    );
};

export default PotentialProjectList;
