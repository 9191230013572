import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { REACT_APP_APPINSIGHTS_KEY, REACT_APP_DISABLE_TELEMETRY } from "$services/env";

const browserHistory = createBrowserHistory({ basename: "" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: REACT_APP_APPINSIGHTS_KEY || "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx",
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
        disableTelemetry: REACT_APP_DISABLE_TELEMETRY,
    },
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
