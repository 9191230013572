import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { OpportunityStore } from "../../../stores/opportunities";

const NavItem = ({ panel, isActive }) => {
    return (
        <NavLink className={() => (isActive ? "-active" : undefined)} to={panel.key}>
            {panel.label}
        </NavLink>
    );
};

const PanelNav = ({ currentHash, setFirstHash }) => {
    const visiblePanels = OpportunityStore.useState((s) => s.visiblePanels);

    useEffect(() => {
        if (visiblePanels.length > 0) {
            setFirstHash(visiblePanels.filter((v) => v.isVisible)[0].key);
        }
    }, [visiblePanels]);

    return (
        <div className="button-list">
            {visiblePanels.reduce((arr, p) => {
                if (p.isVisible) {
                    arr.push(<NavItem key={p.key} panel={p} isActive={currentHash === p.key} />);
                }
                return arr;
            }, [])}
        </div>
    );
};

export default PanelNav;
