import { Store } from "pullstate";
import logger from "$services/logger";
import api from "../services/api";

import * as signalR from "@microsoft/signalr";
import { sortByKey } from "$utils/mapping";
import { acquireFreshToken, UserStore } from "./user";

const initialState = {
    active: [],
    archived: [],
};

export const NOTIFICATION_STATES = {
    active: [0, 1, 2, 5],
    archived: [3],
    unread: [0, 1],
    viewed: [2],
};

export const NotificationStore = new Store(initialState);

let connection = null;

var OneMinuteReconnectPolicy = (function () {
    function OneMinuteReconnectPolicy() {}

    OneMinuteReconnectPolicy.prototype.nextRetryDelayInMilliseconds = function (retryContext) {
        return retryContext.elapsedMilliseconds < 60000 ? 3000 : null;
    };

    return OneMinuteReconnectPolicy;
})();

async function startWwebsocketConnection() {
    if (!connection) {
        return;
    }

    await connection.start();

    connection.invoke("GetNotifications");

    logger.info("SignalR Connected.");
}

const beforeUnloadListener = async (event) => {
    await unwatchNotifications();
    return true;
};

const sortMessages = (messages = [], states = []) => {
    return messages.filter((m) => states.includes(m.status));
};

const sortByTimestamp = (a, b) => sortByKey(a, b, "timestamp", false);
const getToken = () => UserStore.getRawState().userToken;

export const watchNotifications = async () => {
    try {
        logger.info("SignalR Connection Initiated.");

        var reconnectPolicy = new OneMinuteReconnectPolicy();

        connection = new signalR.HubConnectionBuilder()
            .withAutomaticReconnect(reconnectPolicy)
            .withUrl("/api/notifications/hub", {
                accessTokenFactory: getToken,
            })
            .configureLogging(signalR.LogLevel.Debug)
            .build();

        connection.on("NotificationEvent", (data) => {
            //update store
            logger.debug("Notification received", data);
            NotificationStore.update((n) => {
                n.active = [data, ...n.active].sort(sortByTimestamp);
                return n;
            });
        });

        connection.on("ReceiveNotifications", (data) => {
            //update store
            logger.debug("Notification received", data);
            NotificationStore.update((n) => {
                n.active = sortMessages(data, NOTIFICATION_STATES["active"]).sort(sortByTimestamp);
                n.archived = sortMessages(data, NOTIFICATION_STATES["archived"]).sort(sortByTimestamp);
                return n;
            });
        });

        connection.onclose(async (error) => {
            if (error && error.statusCode === 401) {
                // Refresh the token here
                await acquireFreshToken();
                // Reconnect with the new token
                await connection.start();
            }
        });

        setTimeout(() => {
            startWwebsocketConnection();
            addEventListener("beforeunload", beforeUnloadListener, { capture: true });
        }, 500);
    } catch (err) {
        logger.error("Error Connecting to SignalR", err);
    }
};

export const unwatchNotifications = () => {
    if (connection && connection.stop) {
        logger.info("Stopping SignalR Connection");
        connection.stop().then(function () {
            connection = null;
        });
    }
};

export const markAsRead = (id) => {
    toggleNotificationState(2, id);
};

export const markAsArchived = (id) => {
    toggleNotificationState(3, id);
};
const toggleNotificationState = async (state, id) => {
    try {
        await connection.invoke("UpdateMessageStatus", [id], state);
        setTimeout(() => {
            connection.invoke("GetNotifications");
        }, 500);
    } catch (err) {
        logger.error("Error Updating Message Status", err);
    }
};

export const sendSystemNotificationToAll = (subject, message, appMessage, notificationMessageType) => {
    logger.log("Posting", subject, message, appMessage, notificationMessageType);
    return api.post("/api/notification-messages/admin/publish-system-event", {
        subject,
        message,
        appMessage,
        MessageType: notificationMessageType || null,
    });
};

export const sendDataRefreshNotificationToAll = () => {
    return api.post("/api/notification-messages/admin/publish-datarefresh-event", {});
};
