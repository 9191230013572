import { roundAboveOne } from "$utils/number-helper";
import React from "react";

export const LabelPercentTooltip = ({ active, payload }) => {
    if (active) {
        const activePayload = payload[0] || {};
        return (
            <div className="chart-tooltip -dark -potential txt -center">
                <h4 className="txt -white">{activePayload.label || activePayload.name}</h4>
                <span>{roundAboveOne(activePayload.value)}%</span>
            </div>
        );
    }
    return null;
};
