import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList, ResponsiveContainer, Cell, Tooltip } from "recharts";

import NoData from "./no-content";

import { BarBubbleTooltip } from "$components/charts/tooltips/bar-bubble";
import { GRID_STROKE } from "$components/charts/svg-definitions";
import { getTerm } from "$stores/dictionary";
import { calculateMaxSpace } from "./lib/helpers";
import { HoverTooltip } from "./tooltips/currency";

const ChartBarBubble = ({ data, yDomain = [(dataMin) => 0, (dataMax) => calculateMaxSpace(dataMax)], itemClick }) => {
    if (!data || data.length <= 0) {
        return <NoData />;
    }
    return (
        <div className="chart-container">
            <ResponsiveContainer width="99%" height="100%">
                <BarChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 5,
                        bottom: 5,
                    }}
                    onClick={(data) => {
                        if (data && data.activeLabel) {
                            itemClick({ value: data.activeLabel });
                        }
                    }}
                    cursor={itemClick ? "pointer" : null}
                >
                    <CartesianGrid stroke={GRID_STROKE} vertical={false} />
                    <XAxis
                        dataKey="text"
                        onClick={(data) => {
                            if (data && data.value) {
                                itemClick({ value: data.value });
                            }
                        }}
                        textAnchor="start"
                        angle={45}
                        height={140}
                    />
                    <YAxis
                        allowDataOverflow={true}
                        tickCount={5}
                        domain={yDomain}
                        label={{ value: `${getTerm("existingProject")} Count`, angle: -90, fill: "#f3f3f3", position: "insideLeft", offset: 16, style: { textAnchor: "middle" } }}
                        style={{ fill: "#f3f3f3" }}
                    />
                    {!!itemClick && <Tooltip cursor={{ fill: "rgba(28, 54, 66, 0.25)" }} content={<HoverTooltip />} />}
                    <Bar dataKey="value" fill="#de653a" minPointSize={5} barSize={10} cursor={itemClick ? "pointer" : null}>
                        <LabelList dataKey="value" content={BarBubbleTooltip} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ChartBarBubble;
