import React from "react";

const ContentItem = ({ title, Content }) => {
    return (
        <div>
            <h3>
                <span>{title}</span>
            </h3>
            <div>
                <Content />
            </div>
        </div>
    );
};

const HelpContent = ({ data = [] }) => {
    return (
        <div className="help-accordion">
            {data.map((a, i) => {
                return <ContentItem key={`help-content_${i}`} title={a.title} Content={a.Content} />;
            })}
        </div>
    );
};

export default HelpContent;
