import React from "react";
import classNames from "classnames";

import { abbrNum } from "$utils/number-helper";

const ChartSummary = ({ data, handleClick }) => {
    return (
        <div className="chart-summary">
            {data.map((d, index) => {
                return (
                    <div
                        key={`summary_${index}`}
                        className={classNames({ "txt -cursor": !!handleClick })}
                        onClick={() => {
                            if (handleClick) {
                                handleClick(d);
                            }
                        }}
                    >
                        {d.label || d.text}: <em>${abbrNum(d.value)}</em>
                    </div>
                );
            })}
        </div>
    );
};

export default ChartSummary;
