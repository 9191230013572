import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpClientScores = () => {
    return (
        <div className="help-section">
            <p>Scores page provides detailed information about the {getTerm("parentClient")}'s Health Score and where there is good performance or opportunities for improvement.</p>
            <ul>
                <li data-number="1">Health Score Time Series Chart: Historical trend over the last 12 months of the {getTerm("parentClient")}'s Health Score</li>
                <li data-number="2">
                    Overall Theme Performance Radar Chart: {getTerm("parentClient")}'s performance against the behavioral Themes. The closer a node is to the edge, the higher the score (higher performance). The closer a
                    node is to the center, the lower the score (lower performance).
                </li>
                <li>If there are any {getTerm("subClients")}, the score breakdown of each will also be available.</li>
            </ul>
        </div>
    );
};

export default HelpClientScores;
