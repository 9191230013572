import devLogger from "./logger-transports/devLogger";
//import insightLogger from "./logger-transports/applicationInsights";

export const debug = (...logs) => {
    //insightLogger.debug(...logs);
    devLogger.debug(...logs);
};

export const log = (...logs) => {
    //insightLogger.info(...logs);
    devLogger.info(...logs);
};

export const error = (...logs) => {
    //insightLogger.error(...logs);
    devLogger.error(...logs);
};

export default {
    log,
    error,
    info: log,
    debug,
};
