import PaginatedStore from "./base/PaginatedStore";
import endpoints from "../services/endpoint";

export const DEFAULT_SORT = { name: "asc" };
//TODO: Endpoint
const CLIENT_GROUPS_MANAGED_CM_STORAGE_KEY = `/api/client-groups${endpoints("cm_managed_list")}`;

const { store, fetchList, reset, setFilters, setLoading, setPageIndex, setPerPage, setSort, watchPagination } = PaginatedStore({
    key: "clientGroupsManaged",
    label: "Client Groups Managed",
    apiListUrl: CLIENT_GROUPS_MANAGED_CM_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    persistPagingInfo: false,
    storageKey: CLIENT_GROUPS_MANAGED_CM_STORAGE_KEY,
});

export const ClientGroupsCMManagedStore = store;

export const fetchClientGroupsCMManaged = fetchList;

export { reset, setFilters, setLoading, setPageIndex, setPerPage, setSort, watchPagination };
