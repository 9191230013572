import React from "react";
import classNames from "classnames";

import { AppContextStore } from "../../../stores/app-context";

const FilterCount = ({ updateFilterSummary }) => {
    const { filterCount } = AppContextStore.useState((f) => {
        return { filterCount: f.currentFiltersCount };
    });
    const label = filterCount > 1 ? "Filters" : "Filter";
    return (
        <div className="filter-count">
            <img src="/images/icon-filter.svg" className="svg -detail" alt="Filters" width="20" />
            <span
                className={classNames("txt", { "-cursor": filterCount > 0 })}
                onMouseLeave={() => {
                    updateFilterSummary(false);
                }}
                onMouseEnter={() => {
                    updateFilterSummary(true);
                }}
            >
                <b>{filterCount}</b>
                <span>{label}</span>
            </span>
        </div>
    );
};

export default FilterCount;
