import React from "react";
import { getTerm } from "$stores/dictionary";

const HelpTeamList = () => {
    return (
        <div className="help-section">
            <p>
                The Team Detail view provides additional information about a {getTerm("teamMember")}'s specific experience across {getTerm("industries")}, {getTerm("serviceLines")}, {getTerm("projectTypes")} and{" "}
                {getTerm("parentClients")}.
            </p>
            <p>Note that, for each row, only the top 5 respective elements are displayed and clicking into each row will provide additional values.</p>
        </div>
    );
};

export default HelpTeamList;
