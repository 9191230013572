import { faCheckCircle, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export const getTypeDefaults = (type) => {
    switch (type) {
        case "error":
            return {
                baseCssClass: "notification-bar -error",
                icon: faExclamationTriangle,
            };
        case "warning":
            return {
                baseCssClass: "notification-bar -warning",
                icon: faExclamationTriangle,
            };
        case "success":
            return {
                baseCssClass: "notification-bar -success",
                icon: faCheckCircle,
            };
        default:
            return {
                baseCssClass: "notification-bar -info",
                icon: faInfoCircle,
            };
    }
};
