import React from "react";

import { getTerm } from "$stores/dictionary";

const HelpClientDashboard = () => {
    return (
        <div className="help-section">
            <p>
                The {getTerm("parentClient")} <b>Nav</b> helps you navigate to find more detail on a specific {getTerm("parentClient")} account.
            </p>
            <br />
            <h4>Dashboard Header</h4>
            <p>
                The {getTerm("parentClient")} <b>Header</b> provides detail on key metrics for a {getTerm("parentClient")} account.
            </p>
            <div>
                <img src="/images/help/client-dashboard.png" />
            </div>
            <br />
            <h4>{getTerm("t12Revenue")}</h4>
            <p>
                <b>{getTerm("t12Revenue")}</b> section provides information on the historical revenues over the last 12 months for the parent {getTerm("parentClient").toLowerCase()} account.
            </p>
            <p>
                The bar charts provide the top 4 revenue categories. If a {getTerm("parentClient")} has more than 4 of a given category, then the 5th bar is displayed as “Other”, providing a count of how many additional
                categories are included.
            </p>
            <h4>Dashboard {getTerm("growthPotential")}</h4>
            <p>
                {getTerm("growthPotential")} section provides information on the future {getTerm("growthPotential").toLowerCase()} of the {getTerm("parentClient").toLowerCase()} account
            </p>

            <b>
                {getTerm("growthPotential")} by {getTerm("industry")} & {getTerm("subClient")}
            </b>
            <ul>
                <li data-number="1">Inner pie chart: {getTerm("industry")} categories</li>
                <li data-number="2">Outer pie chart: {getTerm("subClient")} categories</li>
            </ul>
            <p>
                A {getTerm("parentClient").toLowerCase()} may have multiple {getTerm("subClient")} categories within a single {getTerm("industry")} category
            </p>
            <br />
            <b>
                {getTerm("growthPotential")} by {getTerm("serviceLine")} & {getTerm("projectType")}
            </b>
            <ul>
                <li data-number="3">Inner pie chart: {getTerm("serviceLine")}</li>
                <li data-number="4">Outer pie chart: {getTerm("projectType")}</li>
            </ul>
            <p>
                A {getTerm("parentClient").toLowerCase()} may have multiple {getTerm("projectTypes")} within a single {getTerm("serviceLine")} category
            </p>
        </div>
    );
};

export default HelpClientDashboard;
