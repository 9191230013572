import React from "react";
import classNames from "classnames";

import "./tile-bar.scss";

const Bar = ({ isActive }) => {
    return <div className={classNames("bar", { "-active": isActive })}></div>;
};

const TileBar = ({ barCount = 5, barGap = 10, value, cssClass = [] }) => {
    const styles = { gridTemplateColumns: `repeat(${barCount}, 1fr)`, gridGap: `${barGap}px` };
    let bars = [];
    for (let i = 0; i < barCount; ++i) {
        bars.push(<Bar key={`bar_${i}`} isActive={value >= i + 1} />);
    }
    return (
        <div className={classNames("tile-bar", cssClass)} style={styles}>
            {bars}
        </div>
    );
};

export default TileBar;
