export const validateField = (validate) => (e) => {
    validate(e.currentTarget.name);
};

export const setField = (setValue) => (e) => {
    setValue(e.currentTarget.name, e.currentTarget.value);
};

export const isBoolean = (value) => {
    return value === true || value === false;
};
