import React, { useState } from "react";
import { convertCardToPayload, createCards } from "$stores/dashboard";
import { UserStore } from "$stores/user";
import { buildDefaultCards, buildCrmCards } from "../lib/constants";
import { debounce } from "lodash";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import EngineLoader from "$components/loaders/engine-loader";

const AddDefaultCards = () => {
    const appInsights = useAppInsightsContext();
    const [loading, setLoading] = useState(false);
    const user = UserStore.useState((s) => s);
    const handleClick = debounce(() => {
        setLoading(true);
        const newCards = buildDefaultCards().map(convertCardToPayload);
        const crmCards = buildCrmCards(user);
        if (user.employeeType === 1) {
            crmCards.map((crmCard) => {
                newCards.push(convertCardToPayload(crmCard));
            });
        }

        createCards(newCards, user.id);

        if (appInsights) {
            appInsights.trackEvent(
                { name: "Dashboard Card Defaults" },
                {
                    label: "Default Cards",
                    action: "Add",
                }
            );
        }
    }, 100);

    if (loading) {
        return <EngineLoader />;
    }

    return (
        <div className="txt -center">
            <h3>
                <em>Welcome</em> {user.displayName}
            </h3>
            <div>
                <br />
                <h4>Your dashboard is currently empty.</h4>
                <br />
                <hr />
                <br />
                <p>Would you like us to make some cards for you?</p>
                <div className="button-group -center">
                    <button className="button -secondary -small" onClick={handleClick}>
                        Add Default Cards
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddDefaultCards;
