import api from "../../../services/api";
import logger from "../../../services/logger";
export const getFeebackOptions = async () => {
    try {
        const results = await api.getFromCache("/api/settings/feedbackoptions/project", null, {});

        if (results) {
            return results.map((r) => {
                return { value: r.sortOrder, text: r.text };
            });
        } else {
            return [];
        }
    } catch (e) {
        logger.error(e);
        return [];
    }
};

export const getTeamFeebackOptions = async () => {
    try {
        const results = await api.getFromCache("/api/settings/feedbackoptions/team", null, {});

        if (results) {
            return results.map((r) => {
                return { value: r.sortOrder, text: r.text };
            });
        } else {
            return [];
        }
    } catch (e) {
        logger.error(e);
        return [];
    }
};
