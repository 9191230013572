import DateInput from "../date-picker";
import Select from "../select";
import MultiSelect from "../select/multi";

import { RadioGroup, Checkbox, Input } from "$components/form-inputs/inputs";

export const getFieldType = (type) => {
    switch (type.toLowerCase()) {
        case "boolean":
            return { type: "checkbox", Component: Checkbox };
        case "radio":
            return { type: "radio", Component: RadioGroup };
        case "picklist":
            return { type: "number", Component: Select };
        case "multipicklist":
            return { type: "number", Component: MultiSelect };
        case "date":
            return { type: "date", Component: DateInput };
        default:
            return { type: "text", Component: Input };
    }
};
