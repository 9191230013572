import { Store } from "pullstate";
import api from "../services/api";
import logger from "../services/logger";

import { buildFilters, buildSort } from "$utils/api-helpers";
import EmptyDrop from "src/pages/dashboard/components/empty-drop";
import * as signalR from "@microsoft/signalr";
import { ADD_DEFAULT_CARDS } from "src/pages/dashboard/lib/constants";
import { UserStore } from "./user";

let connection = null;

const initialState = {
    cards: [],
    cardData: {},
    myGrowthPotential: null,
};

const emptyData = { data: {}, Component: EmptyDrop, cssClass: ["-empty"], notDraggable: true };
const COLUMN_COUNT = 8;

export const convertCardToPayload = (a) => {
    return {
        id: a.id,
        cardId: a.cardId,
        columnPosition: a.columnPosition,
        columnSize: a.columnSize,
        rowPosition: a.rowPosition,
        rowSize: a.rowSize,
        sort: JSON.stringify(a.sort),
        filters: JSON.stringify(a.filters),
        metrics: JSON.stringify(a.metrics),
        rootEndpoint: a.rootEndpoint,
        title: a.title,
        chartType: a.type,
        template: a.template,
    };
};

const getToken = () => UserStore.getRawState().userToken;

var OneMinuteReconnectPolicy = (function () {
    function OneMinuteReconnectPolicy() {}

    OneMinuteReconnectPolicy.prototype.nextRetryDelayInMilliseconds = function (retryContext) {
        return retryContext.elapsedMilliseconds < 60000 ? 3000 : null;
    };

    return OneMinuteReconnectPolicy;
})();

async function startWwebsocketConnection() {
    if (!connection) {
        return;
    }

    await connection.start();

    connection.invoke("GetDashboardCards");

    logger.info("SignalR Connected.");
}

const beforeUnloadListener = async (event) => {
    await unwatchDashboardCards();
    return true;
};

export const watchDashboardCards = async () => {
    try {
        logger.info("SignalR Dashboard onnection Initiated.");

        var reconnectPolicy = new OneMinuteReconnectPolicy();

        connection = new signalR.HubConnectionBuilder()
            .withAutomaticReconnect(reconnectPolicy)
            .withUrl("/api/dashboard/hub", {
                accessTokenFactory: getToken,
            })
            .configureLogging(signalR.LogLevel.Debug)
            .build();

        connection.on("DashboardCardUpsertEvent", (data) => {
            //update store
            logger.debug("Dashboard Card(s) received", data);
            mapCardsFromApiResponse(data);
        });

        connection.on("DashboardCardDeleteEvent", (data) => {
            //update store
            logger.debug("Dashboard Card(s) delete", data);
            mapCardsFromApiResponse(data);
        });

        connection.onclose(async (error) => {
            if (error && error.statusCode === 401) {
                // Refresh the token here
                await acquireFreshToken();
                // Reconnect with the new token
                await connection.start();
            }
        });

        setTimeout(() => {
            startWwebsocketConnection();
            addEventListener("beforeunload", beforeUnloadListener, { capture: true });
        }, 500);
    } catch (err) {
        logger.error("Error Connecting to SignalR", err);
    }
};

export const unwatchDashboardCards = () => {
    if (connection && connection.stop) {
        logger.info("Stopping SignalR Connection");
        connection.stop().then(function () {
            connection = null;
        });
    }
};

export const getCardMatrix = (columnPosition, columnSize, rowPosition, rowSize, callback) => {
    const cardMatrix = [];

    // for each columnSize loop through each rowSize
    for (let row = rowPosition; row < rowPosition + rowSize; row++) {
        for (let col = columnPosition; col < columnPosition + columnSize; col++) {
            cardMatrix.push(row + col / 10);
            if (callback) {
                callback((row - 1) * COLUMN_COUNT + col - 1);
            }
        }
    }

    return cardMatrix;
};

export const getTallestRow = (cards) => {
    return cards.reduce((count, card) => {
        const cardMaxRow = card.rowPosition + card.rowSize;
        return Math.max(count, cardMaxRow);
    }, 1);
};

export const buildCurrentMatrix = (cards) => {
    const onlyRealCards = cards.filter((a) => !a.emptyCard);
    // Loop cards to find max matrix, rowPos + rowSize = totalRow find max from all cards
    const tallestRow = getTallestRow(onlyRealCards);
    // Create matrix
    const matrix = Array(tallestRow * 8).fill(false);

    // Loop through cards
    onlyRealCards.forEach((card) => {
        getCardMatrix(card.columnPosition, card.columnSize, card.rowPosition, card.rowSize, (fillIndex) => {
            matrix[Math.floor(fillIndex)] = true;
        });
    });

    return matrix;
};

export const translateIndexToPosition = (index) => {
    const row = Math.floor(index / COLUMN_COUNT) + 1;
    return {
        column: index + 1 - (row - 1) * COLUMN_COUNT,
        row,
    };
};
export const translateIndexToDecimalPosition = (index) => {
    const row = Math.floor(index / COLUMN_COUNT) + 1;
    const column = index + 1 - (row - 1) * COLUMN_COUNT;
    return row + column / 10;
};

const putIntoPosition = (arr) => {
    const newArr = arr.filter((c) => !c.emptyCard);
    const matrix = buildCurrentMatrix(newArr);
    matrix.forEach((m, i) => {
        if (m === false) {
            const position = translateIndexToPosition(i);
            newArr.push({ ...emptyData, rowPosition: position.row, columnPosition: position.column, rowSize: 1, columnSize: 1, emptyCard: true });
        }
    });
    return newArr;
};

export const createCards = async (newCards, userId) => {
    // TODO use id from endpoint
    try {
        await api.post(`/api/reporting/cards/dashboard/${userId || 0}`, newCards, {});
    } catch (err) {
        logger.error("Unable to create dashboard card", err);
    }
};

export const updateCardFilters = async ({ card, filters, userId }) => {
    const updatedCard = { ...card };
    updatedCard.filters = { ...filters };

    const payload = convertCardToPayload(updatedCard);
    const response = await api.post(`/api/reporting/cards/dashboard/${userId || 0}`, [payload], {});
    try {
        DashboardStore.update((s) => {
            const updatedCards = s.cards.map((c) => {
                if (c.id === card.id) {
                    return updatedCard;
                }
                return c;
            });
            s.cards = updatedCards;
            if (card.id && card.rootEndpoint && s.cardData.hasOwnProperty(card.id)) {
                // TODO: Default value
                s.cardData[card.id] = [];
            }
        });
    } catch (err) {
        logger.error("Unable to create dashboard card", err);
    }
};

export const swapCard = async (dropData, dragData, userId) => {
    const saveCards = [];
    try {
        DashboardStore.update((s) => {
            const newCards = s.cards.map((c) => {
                return { ...c };
            });

            const dragCard = newCards.find((c) => {
                return c.id === dragData.id;
            });
            saveCards.push(dragCard);
            if (dragCard) {
                if (dropData.id) {
                    const dropCard = newCards.find((c) => {
                        return c.id === dropData.id;
                    });
                    // Swapping with a card
                    const dragColumnPosition = dragData.columnPosition;
                    const dragRowPosition = dragData.rowPosition;

                    dragCard["rowPosition"] = dropData.rowPosition;
                    dragCard["columnPosition"] = dropData.columnPosition;
                    dropCard["rowPosition"] = dragRowPosition;
                    dropCard["columnPosition"] = dragColumnPosition;

                    saveCards.push(dropCard);
                } else {
                    // Swaps with empty space
                    dragCard["rowPosition"] = dropData.rowPosition;
                    dragCard["columnPosition"] = dropData.columnPosition;
                }
                s.cards = putIntoPosition(newCards);
            }
        });
        const payload = saveCards.map(convertCardToPayload);
        const response = await api.post(`/api/reporting/cards/dashboard/${userId || 0}`, payload, {});
    } catch (err) {
        logger.error("Unable to create dashboard card", err);
    }
};

export const removeCard = async (id, userId) => {
    try {
        await api.delete(`/api/reporting/cards/dashboard/${userId || 0}/${id}`, null, {});
    } catch (err) {
        logger.error("Unable to remove dashboard card", err);
    }
};

function mapCardsFromApiResponse(dashCardResponse) {
    const jsonResponse = dashCardResponse?.map((a) => {
        const sort = JSON.parse(a.sort);
        const filters = JSON.parse(a.filters);
        const metrics = JSON.parse(a.metrics);
        return { ...a, sort, filters, metrics };
    });
    DashboardStore.update((s) => {
        if (jsonResponse.length <= 0) {
            s.cards = [{ ...ADD_DEFAULT_CARDS }];
        } else {
            s.cards = putIntoPosition(
                jsonResponse.map((a) => {
                    return { ...a, cssClass: ["-center -no-padding"] };
                })
            );
        }
    });
}

const apiUserCards = async (userId, apiSettings) => {
    try {
        const response = await api.get(`/api/dashboard?crm=${userId || 0}`, null, apiSettings);
        DashboardStore.update((s) => {
            s.myGrowthPotential = response?.growthPotential?.myGrowthPotential;
        });
        const dashCardResponse = await api.get(`/api/reporting/cards/dashboard/${userId || 0}`, null, apiSettings);
        mapCardsFromApiResponse(dashCardResponse);
    } catch (err) {
        logger.error("Unable to fetch dashboard cards", err);
    }
};
export const fetchUserCards = (userId) => {
    const abortController = new AbortController();
    apiUserCards(userId, { signal: abortController.signal });

    return abortController;
};

const apiCardData = async (id, rootEndpoint, filters, sort, formatData, apiSettings, keyPrefix = "") => {
    const sortParamString = buildSort(sort);
    const filterParamString = buildFilters(filters, keyPrefix);

    let paramString = "";

    if (sortParamString) {
        paramString = `&sort=${sortParamString}`;
    }
    if (filterParamString) {
        paramString = paramString + `&${filterParamString}`;
    }
    const hasQuestion = rootEndpoint.indexOf("?") > 0;
    const apiUrl = rootEndpoint + `${paramString ? `${hasQuestion ? "" : "?"}${paramString}` : ""}`;
    try {
        const response = await api.get(apiUrl, null, apiSettings);
        DashboardStore.update((s) => {
            const newCardData = { ...s.cardData };
            newCardData[id] = formatData ? formatData(response) : response;
            s.cardData = newCardData;

            /*const newCards = [...s.cards];
            const cardIndex = newCards.findIndex((c) => c?.id === id);
            newCards[cardIndex].data = formatData ? formatData(response) : response;
            s.cards = newCards;*/
        });
    } catch (err) {
        logger.error("Unable to fetch dashboard card data", err);
    }
};

export const fetchCardData = (id, rootEndpoint, filters, sort, formatData) => {
    const abortController = new AbortController();
    apiCardData(id, rootEndpoint, filters, sort, formatData, { signal: abortController.signal });

    return abortController;
};

export const DashboardStore = new Store(initialState);
