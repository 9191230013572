import React from "react";

import Select from "$components/form-inputs/select";
import { useNavigate } from "react-router-dom";

const MobileTeamNav = ({ basepath, navLinks }) => {
    const navigate = useNavigate();
    const navigateTo = (text, value) => {
        navigate(value.url);
    };

    const mobileNavigation = navLinks.reduce((arr, n) => {
        arr.push({ ...n, url: `${basepath}${n.url}`, value: n.text });
        return arr;
    }, []);
    const currentNavItem = mobileNavigation.find((l) => l.url === window.location.pathname);

    return (
        <div className="mobile-team-nav">
            <Select name="mobile-navigation" label="Navigation" defaultValue={currentNavItem} options={mobileNavigation} updateOnChange={navigateTo} />
        </div>
    );
};

export default MobileTeamNav;
