import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";

import { updateFn } from "../libs/helpers";

export const Checkbox = ({ id, disabled, label, name, defaultValue, hidden, invalid, value = false, onChange, updateOnChange, onBlur, updateOnBlur, required, onClick }) => {
    const [useValue, setUseValue] = useState((defaultValue ? defaultValue : value) || false);

    useEffect(() => {
        setUseValue((defaultValue ? defaultValue : value) || false);
    }, [defaultValue, value]);

    const handleClick = (e) => {
        setUseValue(!e.currentTarget.value);
    };

    const handleEnter = (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            if (updateOnChange) {
                updateOnChange(name, !e.currentTarget.value);
            } else {
                setUseValue(!e.currentTarget.value);
            }
        }
    };

    const handleOnChange = (e) => {
        const newValue = !useValue;
        setUseValue(newValue);

        if (onChange) {
            onChange(e);
        } else if (updateOnChange) {
            updateOnChange(name, newValue);
        }
    };

    const icon = useValue ? faSquareCheck : faSquare;

    return (
        <div className={classNames("form-field -checkbox", { "-error": !!invalid })}>
            <div className={classNames("check", { "-selected": useValue })}>
                <input
                    type="checkbox"
                    key={`${id || name || ""}`}
                    id={`${id || name || ""}`}
                    disabled={disabled}
                    hidden={hidden}
                    required={required}
                    value={useValue}
                    onChange={handleOnChange}
                    onBlur={onBlur || updateFn(updateOnBlur)}
                    onClick={onClick || handleClick}
                    onKeyDown={handleEnter}
                />
                <FontAwesomeIcon icon={icon} />
            </div>
            <label htmlFor={id || name}>{label}</label>
        </div>
    );
};
