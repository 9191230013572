import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import TableLoader from "$components/table/table-loader.js";

import { buildClientProjectsColumns, buildSubclientProjectsColumns } from "./lib/columns.js";
import { ClientExistingProjectsStore, setPageIndex, setPerPage, setSort, setFilters } from "$stores/clientExistingProjects.js";
import { mapToTableData } from "src/pages/projects/existing/list.js";
import { ClientDetailsStore } from "$stores/client-details.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";

const ExistingProjects = () => {
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const { filterMenu, visibleColumns } = AppContextStore.useState((f) => {
        return { filterMenu: mapByKey(f.filterMenu, "apiKey") || {}, visibleColumns: f.siteConfig?.existingProjectListColumns || null };
    });
    const { filters, globalFilterRanges, isLoading, isSortAsc, pageCount, pageIndex, pageSize, projects, sort } = ClientExistingProjectsStore.useState((c) => {
        return {
            filters: c.filters,
            globalFilterRanges: c.globalFilterRanges,
            isLoading: c.isLoading,
            isSortAsc: c.isSortAsc,
            pageCount: c.pageCount,
            pageIndex: c.pageIndex,
            pageSize: c.pageSize,
            projects: c.clientExistingProjects || [],
            sort: c.sort,
        };
    });
    const [clientProjectsColumns, setClientProjectsColumns] = useState([]);
    const [subclientProjectsColumns, setSubclientProjectsColumns] = useState([]);

    const hasSubClients = ClientDetailsStore.useState((s) => s.hasSubClients);
    const rowClick = (row) => {
        navigate(`/project/${row.id}/existing`);
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: "Existing Project",
                section: "Client Detail",
                projectId: row.id,
            }
        );
    };

    useEffect(() => {
        setClientProjectsColumns(buildClientProjectsColumns({ visibleColumns, globalFilterRanges, filterOptions: filterMenu }));
        setSubclientProjectsColumns(buildSubclientProjectsColumns(buildClientProjectsColumns({ visibleColumns, globalFilterRanges, filterOptions: filterMenu })));
    }, [filterMenu, globalFilterRanges, visibleColumns]);

    const data = projects.map(mapToTableData);
    return (
        <TableLoader
            allowFilter={true}
            columns={hasSubClients ? subclientProjectsColumns : clientProjectsColumns}
            containerClass="project-list"
            data={data}
            filters={filters}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default ExistingProjects;
