import React from "react";

import LegendLabel from "./label";
import LegendSvg from "./svg";

const CustomLegend = ({ payload, handleClick }) => {
    return (
        <ul className="recharts-default-legend" style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
            {payload.map((entry, index) => (
                <li key={`item-${index}`} className={`recharts-legend-item legend-item-${index}`} style={{ display: "inline-block", marginRight: "10px" }}>
                    <LegendSvg color={entry.color} />
                    <LegendLabel color={entry.color} label={entry.value} labelId={entry.payload.labelId} handleClick={handleClick} />
                </li>
            ))}
        </ul>
    );
};

export default CustomLegend;
