import React from "react";

const GenericInfoCell = ({ value, label, subHeader = null }) => {
    return (
        <>
            <h5>{label}</h5>
            <h4>{value || "-"}</h4>
            {subHeader && <h6>{subHeader}</h6>}
        </>
    );
};

export default GenericInfoCell;
