import React from "react";
import _ from "lodash";

import EngineLoader from "$components/loaders/engine-loader";
import StatWithChange from "$components/dashboard/components/stat";

import { capitalizeFirstLetter } from "$utils/text-helpers";
import { getKeyMetaData } from "../lib/helpers";

const SingleStatCard = ({ data, metrics, filters, title }) => {
    if (!data || _.isEmpty(data)) {
        return <EngineLoader />;
    }
    const metric = filters["crm"] ? `my${capitalizeFirstLetter(metrics[0])}` : metrics[0];
    const metricMeta = getKeyMetaData(metric);
    return (
        <div className="dashboard-info-card -stat">
            <StatWithChange tooltip={metricMeta?.tooltip ? metricMeta.tooltip : null} label={title} value={data[metric]} isHealthy={metrics[0] === "t12Revenue" ? true : null} />
        </div>
    );
};

export default SingleStatCard;
