import React from "react";
import classNames from "classnames";

const ChartTypes = ({ activeIndex, answer, options, questionIndex, handleSelect, title }) => {
    const hasFollowUp = options && options[0].hasOwnProperty("followUp");
    return (
        <div
            className={classNames("wizard-widget -template", {
                "-selected": answer,
                "-active": questionIndex === activeIndex || (!hasFollowUp && questionIndex === activeIndex - 1),
                "-previous": activeIndex >= questionIndex && hasFollowUp,
                "-skip": hasFollowUp && options.length === 1,
            })}
        >
            <h3>{title}</h3>
            <p>Select a chart type to add.</p>
            <div className="wizard-options">
                {options.map((o, i) => {
                    return (
                        <div
                            key={`option_${questionIndex}_${i}}`}
                            className={classNames("option", { "-selected": answer?.id === o.id })}
                            onClick={() => {
                                handleSelect(o, questionIndex);
                            }}
                        >
                            <h4>{o.text}</h4>
                            <img src={o.url} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ChartTypes;
