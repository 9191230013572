import React from "react";
import classNames from "classnames";

import { checkIsHealthy } from "$utils/health-helper";

export const ScoreTooltip = ({ active, payload }) => {
    const activePayload = payload[0]?.payload || {};
    if (active) {
        const isHealthy = checkIsHealthy(activePayload.score);
        const healthClass = isHealthy === false ? "-unhealthy" : isHealthy ? "-healthy" : "";
        return (
            <div className={classNames("chart-tooltip -dark -center", healthClass)}>
                <h5>{activePayload.date}</h5>
                <h2 className={classNames("txt -no-margin", healthClass)}>{activePayload.score}</h2>
            </div>
        );
    }
    return null;
};
