import * as input from "./plain-inputs";

import { WithLabel } from "./with-label";

export const Input = WithLabel(input.Input);
export const Select = WithLabel(input.Select);
export const Textarea = WithLabel(input.Textarea);
export const Checkbox = input.Checkbox;
export const Radio = input.Radio;
export const RadioGroup = input.RadioGroup;
