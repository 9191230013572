/* eslint no-console: "off" */
import { IS_PROD } from "../../../src/services/env";

const isDev = !IS_PROD;
const devLogger = {
    info: (...args) => {
        if (isDev) {
            console.log(...args);
        }
    },
    error: (...args) => {
        if (isDev) {
            console.log(...args);
        }
    },
    debug: (...args) => {
        if (isDev) {
            console.log(...args);
        }
    },
};

export default devLogger;
