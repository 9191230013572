import React from "react";
import Typeahead from "../../form-inputs/typeahead";
import { CrmStore, search, updateSelectedResults } from "../../../stores/crm-filter";
import { getTerm } from "$stores/dictionary";

const PicSearch = () => {
    const options = CrmStore.useState((f) => f.results);
    const { isLoading, selectedOptions } = CrmStore.useState((f) => {
        return { selectedOptions: f.selectedResults, isLoading: f.isLoading };
    });

    return (
        <Typeahead
            name="revenueCRM"
            label={getTerm("opportunitySearch")}
            cssClass="-full"
            apiLoading={isLoading}
            options={options}
            value={selectedOptions}
            optionLookup={search}
            updateOnChange={(name, newValues) => {
                updateSelectedResults(newValues);
            }}
        />
    );
};

export default PicSearch;
