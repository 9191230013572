import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import MultiRangeSlider from ".";

import { KEYCODE } from "$utils/keycodes";
import { buildRangeText } from "./lib/range-helper";

const MultiRangeSliderDropdown = ({ id, label, name, defaultValue, min, max, updateOnChange, invalid }) => {
    var hideTimer = null;
    const dropdownRef = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);

    const mouseEnter = (e) => {
        clearTimeout(hideTimer);
    };

    const mouseLeave = (e) => {
        clearTimeout(hideTimer);
        hideTimer = setTimeout(() => {
            setIsOpen(false);
        }, 400);
    };

    const handleKeyDown = useCallback(
        (event) => {
            switch (event.keyCode) {
                case KEYCODE.TAB:
                    setIsOpen(false);
                    break;
                case KEYCODE.ESC:
                    setIsOpen(false);
                    setActiveIndex(-1);
                    break;
                default:
                    break;
            }
        },
        [activeIndex]
    );

    const icon = isOpen ? faCaretUp : faCaretDown;

    return (
        <div className={classNames("multi-range-slider-dropdown", { "-error": !!invalid })} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onKeyDown={handleKeyDown} tabIndex="0">
            <div
                className="label"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <span className="label-span">{label}:</span>
                <span>&nbsp;{buildRangeText(defaultValue?.min, defaultValue?.max)}</span>
                <div className="arrow">
                    <FontAwesomeIcon icon={icon} />
                </div>
            </div>
            <div ref={dropdownRef} className={classNames("dropdown", { "-open": isOpen })}>
                <MultiRangeSlider updateOnChange={updateOnChange} defaultValue={defaultValue} min={min} max={max} />
            </div>
        </div>
    );
};

export default MultiRangeSliderDropdown;
