import React from "react";

export const LabelValueTooltip = ({ active, payload }) => {
    if (active) {
        const activePayload = payload[0] || {};
        return (
            <div className="chart-tooltip -dark -potential">
                <h4>{activePayload.label || activePayload.name}</h4>
                {activePayload.value}
            </div>
        );
    }
    return null;
};
