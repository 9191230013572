import TypeaheadStore from "./base/TypeaheadStore";

const { store, search, updateSelectedResults } = TypeaheadStore({
    key: "projectpic",
    searchApiEndpoint: "/api/employees/projectpic",
    responseHandler: (response) => {
        return response && response["employees"] && response["employees"].length > 0
            ? response["employees"].map((e) => {
                  return {
                      value: e.employeeId,
                      text: e.fullName,
                  };
              })
            : null;
    },
});

const ProjectPicFilterStore = store;

export { ProjectPicFilterStore, search, updateSelectedResults };
