import React, { useEffect } from "react";
import { NotificationDefintionStore, fetchNotificationDefinitions, setActiveEventTypeId } from "./store.js";
import DefintionSelection from "./components/DefinitionSelection/index.js";
import NotificationDefinitionEdit from "./components/DefinitionEdit/index.js";

import "./notification.css";

const NotificationAdmin = () => {
    const notificationDefinitions = NotificationDefintionStore.useState((s) => s.notificationDefinitions);
    const activeMessages = NotificationDefintionStore.useState((s) => s.activeMessages);

    useEffect(() => {
        fetchNotificationDefinitions();
    }, []);

    useEffect(() => {}, [notificationDefinitions]);
    return (
        <div className="notification-admin">
            <h1>Notifications</h1>
            {!activeMessages && (
                <DefintionSelection
                    list={_.uniqBy(notificationDefinitions, "eventTypeId").map((d) => {
                        return { id: d.eventTypeId, name: d.eventType };
                    })}
                />
            )}
            {activeMessages && (
                <div>
                    <button
                        onClick={(e) => {
                            setActiveEventTypeId(null);
                        }}
                    >
                        Back
                    </button>
                </div>
            )}
            {activeMessages &&
                activeMessages.map((m) => (
                    <div key={m.id}>
                        <NotificationDefinitionEdit definition={m} />
                    </div>
                ))}
        </div>
    );
};

export default NotificationAdmin;
