import { useRef, useEffect } from "react";

export const useDocumentTitle = (title, prevailOnUnmount = false) => {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        const appendedTitle = title ? `${title} - Orgaimi` : "Orgaimi";
        document.title = appendedTitle;
    }, [title]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
            }
        },
        []
    );
};
