import { Store } from "pullstate";
import api from "$services/api";

const initialState = {
    notificationDefinitions: [],
    activeEventTypeId: null,
    activeMessages: null,
};

export const NotificationDefintionStore = new Store(initialState);

NotificationDefintionStore.createReaction(
    (s) => s.activeEventTypeId,
    (activeEventTypeId, draft) => {
        if (!activeEventTypeId) {
            draft.activeMessages = null;
        } else {
            draft.activeMessages = draft.notificationDefinitions.filter((n) => n.eventTypeId === activeEventTypeId);
        }
    }
);

export const fetchNotificationDefinitions = async () => {
    const response = await api.get("/api/admin/notification-definitions");
    NotificationDefintionStore.update((s) => {
        s.notificationDefinitions = response;
    });
};

export const setActiveEventTypeId = (eventTypeId) => {
    NotificationDefintionStore.update((s) => {
        s.activeEventTypeId = eventTypeId;
    });
};
