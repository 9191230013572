import React, { useState } from "react";
import debounce from "debounce";
import { useNavigate } from "react-router-dom";

import TableLoader from "$components/table/table-loader.js";

import { buildDefaultColumns } from "./libs/columns.js";
import { EmployeeStore, setPageIndex, setSort, setPagingInfo, setPerPage, setFilters } from "$stores/employee-list.js";

export const mapToTableData = (c) => {
    return {
        key: c.id,
        ...c,
    };
};

const EmployeeList = () => {
    const navigate = useNavigate();
    const [defaultColumns] = useState(buildDefaultColumns());
    const { employees, filters, pageIndex, pageSize, pageCount, isLoading, sort, isSortAsc } = EmployeeStore.useState((c) => {
        return { employees: c.employees || [], filters: c.filters, pageSize: c.pageSize, pageIndex: c.pageIndex, pageCount: c.pageCount, isLoading: c.isLoading, sort: c.sort, isSortAsc: c.isSortAsc };
    });

    const rowClick = (row) => {
        navigate(`/team/${row.key}`);
    };

    const data = employees.map(mapToTableData);

    const debouncedSetPagingInfo = debounce(setPagingInfo, 500);

    return (
        <TableLoader
            allowFilter={true}
            columns={defaultColumns}
            containerClass="team-list"
            data={data}
            filters={filters}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageCount={pageCount}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPagingInfo={debouncedSetPagingInfo}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default EmployeeList;
