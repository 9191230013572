import React from "react";
import classNames from "classnames";

import "./dashboard.scss";

const GlobalDashboard = ({ children, isLoading }) => {
    return (
        <div id="global-dashboard" className={classNames({ "-loading": isLoading })}>
            {children}
        </div>
    );
};

export default GlobalDashboard;
