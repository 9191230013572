import React from "react";
import { format } from "date-fns";

import { SHORT_DATE_FORMAT, STANDARD_DATE_FORMAT } from "$utils/date-helpers";
import { AppContextStore } from "$stores/app-context";
import { UserStore } from "$stores/user";

const DataRefreshCard = () => {
    const user = UserStore.useState((s) => s);
    const { dataDate, lastDataUpdate } = AppContextStore.useState((f) => ({ dataDate: f.versions.latestPeriodDate, lastDataUpdate: f.versions.lastModelRun }));

    return (
        <div className="dashboard-info-card">
            <h4 className="txt -white">Data Refresh</h4>
            {lastDataUpdate && (
                <h4>
                    <br />
                    <span>{format(lastDataUpdate, STANDARD_DATE_FORMAT)}</span>
                </h4>
            )}
            <h4 className="txt">
                {dataDate && <div className="txt -gray -small -uppercase -compressed">For the month ending</div>}
                <b className="txt -healthy">{dataDate ? format(dataDate, SHORT_DATE_FORMAT) : format(new Date(), SHORT_DATE_FORMAT)} </b>
            </h4>
        </div>
    );
};

export default DataRefreshCard;
