import React from "react";
import FilterSummary from "./filter-summary";
import { NavLink } from "react-router-dom";

const BrowseSharedFilters = ({ sharedFilters }) => {
    return (
        <div>
            <h1>
                <em>Browse</em> Shared Filters
            </h1>

            <div className="bucket-links">
                <NavLink to="/segments">
                    <img src="/images/icon-external-market.svg" />
                    <h5>Segments</h5>
                </NavLink>
                <NavLink to="/offices">
                    <img src="/images/icon-geography.svg" />
                    <h5>Offices</h5>
                </NavLink>
                <NavLink to="/clients">
                    <img src="/images/icon-cross-collaboration.svg" />
                    <h5>Clients</h5>
                </NavLink>
                <NavLink to="/projects">
                    <img src="/images/icon-projects.svg" className="svg -detail" />
                    <h5>Projects</h5>
                </NavLink>
            </div>

            <h2>
                <em>Filter</em> Summary
            </h2>
            <p>You are viewing the site through following shared filters:</p>

            <FilterSummary filters={sharedFilters} />
        </div>
    );
};

export default BrowseSharedFilters;
