import React, { useEffect } from "react";

import Employees from "./components/employee-list";

import { useDocumentTitle } from "$utils/document-title";
import { setHelpContent } from "$stores/help";
import { HELP_TEAM_LIST } from "../help/lib/help-contents";

const TeamIndex = () => {
    useDocumentTitle("Team List");

    useEffect(() => {
        setHelpContent(HELP_TEAM_LIST);
    }, []);

    return (
        <div id="team-list" className="page">
            <div className="main -spacer">
                <main>
                    <div className="main-wrapper">
                        <h1>
                            <em>Team</em> List
                        </h1>
                        <Employees />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default TeamIndex;
