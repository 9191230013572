import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/free-regular-svg-icons";

import { updateFn } from "../libs/helpers";

export const Radio = ({ defaultValue, disabled, hidden, id, invalid, label, name, required, onBlur, onClick, onChange, updateOnBlur, updateOnChange, value }) => {
    const handleEnter = (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            if (updateOnChange) {
                updateOnChange(id || name, value);
            }
        }
    };

    const handleOnChange = (e) => {
        if (onChange) {
            onChange(e);
        } else if (updateOnChange) {
            updateOnChange(id || name, value);
        }
    };

    const icon = defaultValue ? faCircleCheck : faCircle;

    return (
        <div className={classNames("form-field -radio", { "-error": !!invalid })}>
            <div className={classNames("check", { "-selected": defaultValue })}>
                <input
                    type="radio"
                    id={`${id || name || ""}`}
                    name={`${id || name || ""}`}
                    disabled={disabled}
                    hidden={hidden}
                    required={required}
                    value={defaultValue}
                    onBlur={onBlur || updateFn(updateOnBlur)}
                    onClick={handleOnChange}
                    onKeyDown={handleEnter}
                />
                <FontAwesomeIcon icon={icon} />
            </div>
            <label htmlFor={id || name}>{label}</label>
        </div>
    );
};
