import { Store } from "pullstate";
import api from "$services/api";

const initialState = {
    notificationTemplates: [],
};

export const NotificationTemplateStore = new Store(initialState);

export const fetchNotificationTemplates = async () => {
    const response = await api.get("/api/admin/notification-templates");
    NotificationTemplateStore.update((s) => {
        s.notificationTemplates = response;
    });
};

export const saveNotificationTemplates = async (data) => {
    const response = await api.put("/api/admin/notification-templates", data);

    fetchNotificationTemplates();
};
