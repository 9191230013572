import { Store } from "pullstate";
import api from "../services/api";
import differenceInDays from "date-fns/differenceInDays";
import { format } from "date-fns";
import { abbrNum } from "$utils/number-helper";
import { buildDateFromString, SHORT_DATE_FORMAT } from "../utils/date-helpers";
import { getTerm } from "./dictionary";

const initialState = {
    opportunities: null,
    myClients: null,
    myProjects: null,
    allFirm: null,
    expert: null,
    potentialRevenue: [],
    featured: null,
    lastDataUpdate: null,
    visiblePanels: [],
};

export const OpportunityStore = new Store(initialState);

export const fetchOpportunitiesByPractice = async (practice) => {
    const result = await api.get(`/api/opportunities?Practice=${practice}`);
    OpportunityStore.update((s) => {
        s.opportunities = result.opportunities;
        return s;
    });
};

//Client Map
const mapOpportunityToPotentialRevenueItem = (data) => {
    const date = buildDateFromString(data.date);
    const compareDate = new Date();

    return {
        isNew: differenceInDays(compareDate, date) <= 2,
        activeDate: format(date, SHORT_DATE_FORMAT),
        companyName: data.family,
        highestImpactDriver: data.theme,
        topProject: data.topProject,
        topProjectPotentialRevenue: data.topProjectPotentialRevenue,
        topProjectIsCrossSell: data.topProjectIsCrossSell,
        scoreBubble: {
            healthy: true,
            arrow: false,
            preSup: "$",
            value: abbrNum(data.amount),
        },
        reverse: true,
        url: `/client/${data.clientId}`,
    };
};

const mapOpportunitiesResults = (opportunities) => {
    if (!opportunities || opportunities.length === 0) {
        return [];
    }
    return opportunities.slice(0, 5).map(mapOpportunityToPotentialRevenueItem);
};

//Project map
const mapProjectOpportunityToPotentialRevenueItem = (data, type) => {
    const date = buildDateFromString(data.date);
    const compareDate = new Date();
    return {
        isNew: differenceInDays(compareDate, date) <= 2,
        activeDate: format(date, SHORT_DATE_FORMAT),
        amount: data.amount,
        companyName: data.clientGroupName,
        highestImpactDriver: data.theme,
        topProject: data.topProject,
        topProjectPotentialRevenue: data.topProjectPotentialRevenue,
        scoreBubble: {
            healthy: true,
            arrow: false,
            preSup: "$",
            value: abbrNum(data.topProjectPotentialRevenue),
        },
        reverse: true,
        url: `/project/${data.topProjectId}/${type}`,
    };
};

const mapProjectOpportunitiesResults = (opportunities, type = "potential") => {
    if (!opportunities || opportunities.length === 0) {
        return [];
    }
    return opportunities
        .sort((a, b) => (a.topProjectPotentialRevenue < b.topProjectPotentialRevenue ? 1 : -1))
        .slice(0, 5)
        .map((data) => mapProjectOpportunityToPotentialRevenueItem(data, type));
};

export const searchOpportunities = async (crmList) => {
    if (crmList.length === 0) {
        OpportunityStore.update((s) => {
            s.search = [];
            return s;
        });
        return;
    }
    const queryString = crmList.map((crm) => `crm=${crm.value}`).join("&");
    const result = await api.get(`/api/opportunities/search?${queryString}`);
    OpportunityStore.update((s) => {
        s.search = mapOpportunitiesResults(result.opportunities);
        return s;
    });
};

const apiPotentialRevenue = async ({ crm, enableExperiencedTeamMembers }) => {
    const result = await api.get(`/api/opportunities?crm=${crm || -1}`); // Passing -1 if the user profile is undefined, which can happen
    OpportunityStore.update((s) => {
        s.allFirm = mapOpportunitiesResults(result.opportunities);
        s.myClients = mapOpportunitiesResults(result.clientOpportunities);
        s.myProjects = mapProjectOpportunitiesResults(result.projectOpportunities, "potential"); // TODO: This should change to existing
        s.expert = mapProjectOpportunitiesResults(result.expertOpportunities, "potential");
        s.lastDataUpdate = buildDateFromString(result.lastDataUpdate);
        s.visiblePanels = [
            { key: "#myclients", label: getTerm("myParentClients"), isVisible: result.clientOpportunities.length > 0 },
            { key: "#myprojects", label: `${getTerm("myProjects")}`, isVisible: result.projectOpportunities.length > 0 },
            { key: "#allfirm", label: getTerm("allFirm"), isVisible: true },
            { key: "#expert", label: "Expert", isVisible: result.expertOpportunities.length > 0 && enableExperiencedTeamMembers },
            { key: "#search", label: "Search", isVisible: true },
        ];
        return s;
    });
};

export const fetchPotentialRevenue = (params) => {
    const abortController = new AbortController();
    apiPotentialRevenue(params, { signal: abortController.signal });
    return abortController;
};

export const reset = () => {
    OpportunityStore.update((s) => {
        return initialState;
    });
};
