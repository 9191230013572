import React from "react";

import { abbrNum, getNumberRange } from "$utils/number-helper";

const LargeNumberRange = ({ label, value, isHealthy, decimalPrecision = null }) => {
    const healthClass = isHealthy === false ? "txt-unhealthy" : isHealthy ? "txt-healthy" : "txt -potential";
    const numberVariance = getNumberRange(value);
    return (
        <div className="large-number">
            <div>
                <h3 className={healthClass}>
                    <sup>$</sup>
                    {abbrNum(numberVariance.low, { showSuffix: true, decimalPrecision })}&ndash;{abbrNum(numberVariance.high, { decimalPrecision })}
                </h3>
            </div>
            {label && <h5>{label}</h5>}
        </div>
    );
};

export default LargeNumberRange;
