import PercentLegend from "$components/charts/legend/components/percent-legend";
import { LabelPercentTooltip } from "$components/charts/tooltips/currency";
import { getTerm } from "$stores/dictionary";

export const buildTeamTop5Sections = () => {
    return {
        topServiceLines: {
            header: () => (
                <h4 className="txt -center -white">
                    <em>Time Spent</em> By {getTerm("serviceLines")}
                </h4>
            ),
            type: "percent",
            chartKey: "employeeHoursByServiceLine",
            accessorKey: "serviceLine",
            ChartTooltipComponent: LabelPercentTooltip,
            LegendComponent: PercentLegend,
        },
        topIndustries: {
            header: () => (
                <h4 className="txt -center -white">
                    <em>Time Spent</em> By {getTerm("industries")}
                </h4>
            ),
            type: "percent",
            chartKey: "employeeHoursByIndustry",
            accessorKey: "industry",
            ChartTooltipComponent: LabelPercentTooltip,
            LegendComponent: PercentLegend,
        },
        topProjectTypes: {
            header: () => (
                <h4 className="txt -center -white">
                    <em>Time Spent</em> By {getTerm("projectTypes")}
                </h4>
            ),
            type: "percent",
            chartKey: "employeeHoursByProjectType",
            accessorKey: "projectType",
            ChartTooltipComponent: LabelPercentTooltip,
            LegendComponent: PercentLegend,
        },
    };
};
