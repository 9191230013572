import React from "react";
import { setActiveEventTypeId } from "../../store.js";

const DefintionSelection = ({ list }) => {
    return (
        <ul>
            {list.map((item, index) => {
                return (
                    <li key={index}>
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveEventTypeId(item.id);
                            }}
                        >
                            {item.name}
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};

export default DefintionSelection;
