import React, { useState } from "react";
import classNames from "classnames";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";

import { deleteSavedFilter } from "$stores/app-context";
import { dialogConfirm } from "$stores/modal";
import { getKeyMetaData } from "src/pages/dashboard/lib/helpers";
import { buildRangeText } from "$components/form-inputs/multi-range-slider/lib/range-helper";

const customOrder = ["client", "clientPerson", "projectPic", "industry", "practice", "country", "scoreRange", "clientSize", "sviRange"];

const sortByArrayKeys = (a, b) => {
    return customOrder.indexOf(a) - customOrder.indexOf(b);
};

const FilterSummary = ({ id, name, filters, modifierClassNames, TitleComp }) => {
    const [isUpdating, setIsUpdating] = useState("false");
    const modifiedFilters = { ...filters };
    // Handle SVIRange
    if (modifiedFilters["sviRangeMin"] || modifiedFilters["sviRangeMax"]) {
        modifiedFilters["sviRange"] = buildRangeText(modifiedFilters["sviRangeMin"], modifiedFilters["sviRangeMax"]);
        delete modifiedFilters.sviRangeMin;
        delete modifiedFilters.sviRangeMax;
    }

    const handleUpdateItem = () => {
        setIsUpdating(!isUpdating);
    };

    const handleDelete = async () => {
        const confirmSave = await dialogConfirm({
            data: {
                message: (
                    <p className="txt -center">
                        Are you sure you want to remove the filter:
                        <br />
                        <br />
                        <span className="txt -white">{name}</span>
                    </p>
                ),
            },
            settings: {
                type: "confirm",
            },
        });
        if (confirmSave) {
            await deleteSavedFilter(id);
        }
    };

    return (
        <div className="saved-filter">
            {id && (
                <div className="flex-header">
                    <h3>{name}</h3>
                    <div className="button-group">
                        <button className="button -text -right" onClick={handleUpdateItem}>
                            <FontAwesomeIcon icon={faPencil} />
                        </button>
                        <button className="button -text -right" onClick={handleDelete}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                </div>
            )}
            {TitleComp && <TitleComp />}
            <div className={classNames("filter-summary", modifierClassNames)}>
                {Object.keys(modifiedFilters)
                    .sort(sortByArrayKeys)
                    .map((key) => {
                        return (
                            <div key={key} className={classNames("filter-item", { "-columns": modifiedFilters[key].length > 10 })}>
                                <h4>{getKeyMetaData(key)?.text}</h4>
                                <div className="values">
                                    {_.isArray(modifiedFilters[key])
                                        ? modifiedFilters[key].map((f) => <div className="value-item">{f.text}</div>)
                                        : typeof modifiedFilters[key] === "string"
                                        ? modifiedFilters[key]
                                        : "Unreadable"}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default FilterSummary;
