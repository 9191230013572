import PaginatedStore from "./base/PaginatedStore";
import { getTerm } from "./dictionary";

export const DEFAULT_SORT = { name: "asc" };
const CLIENT_STORAGE_KEY = "/api/projects/potential";

const { store, fetchList, reset, setFilters, setFlagged, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort, watchPagination } = PaginatedStore({
    key: "potentialProjects",
    label: getTerm("potentialProjects"),
    apiListUrl: CLIENT_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    persistPagingInfo: true,
    storageKey: CLIENT_STORAGE_KEY,
});

export const PotentialProjectsStore = store;

export const fetchPotentialProjects = fetchList;

export { reset, setFilters, setFlagged, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort, watchPagination };
