import React, { useEffect, useState } from "react";
import debounce from "debounce";
import { useSearchParams } from "react-router-dom";

import ExistingProjectList from "./list";

import { ExistingProjectsStore, fetchExistingProjects, setLoading, reset, setPagingInfo } from "$stores/existingProjects";
import { AppContextStore, fetchOverview } from "$stores/app-context";
import { useDocumentTitle } from "$utils/document-title";
import { useEffectOnce } from "$utils/hooks/useEffectOnce";

const debouncedFetchProjects = debounce(async (updatedFilters, pagingInfo) => {
    fetchExistingProjects(updatedFilters, { keyPrefix: "tableFilter.", ...pagingInfo });
    //fetchOverview(updatedFilters); //TODO: This still needed?
}, 800);

const ExistingProjects = ({ overrideFilters = {} }) => {
    useDocumentTitle("Existing Project List");
    const [searchParams] = useSearchParams();
    const [initialLoad, setInitialLoad] = useState(true);
    const { hasLoadedFilters, globalFilters, visibleColumns } = AppContextStore.useState((f) => {
        return { hasLoadedFilters: f.hasLoadedFilters, globalFilters: f.currentFilters || {}, visibleColumns: f.siteConfig?.existingProjectListColumns || null };
    });

    const pagingInfo = ExistingProjectsStore.useState((f) => {
        return { page: f.pageIndex, limit: f.pageSize, filters: f.filters, sort: f.sort, isSortAsc: f.isSortAsc };
    });

    useEffectOnce(() => {
        return () => {
            // Resets Pagination
            reset();
        };
    }, []);

    useEffect(() => {
        if (hasLoadedFilters) {
            const updatables = {};
            const sort = JSON.parse(searchParams.get("sort"));
            const filter = JSON.parse(searchParams.get("filter"));
            const page = searchParams.get("pageIndex");
            if (sort) updatables.sort = sort;
            if (filter) updatables.filter = filter;
            if (page) updatables.pageIndex = page;

            if (!_.isEmpty(updatables)) {
                setPagingInfo(updatables);
            }
            setInitialLoad(false);
        }
    }, [hasLoadedFilters]);

    useEffect(() => {
        if (!initialLoad && hasLoadedFilters) {
            setLoading();
            debouncedFetchProjects({ ...overrideFilters, ...globalFilters }, { ...pagingInfo });
        }
    }, [initialLoad, hasLoadedFilters, globalFilters, pagingInfo, overrideFilters]);

    return (
        <div>
            <ExistingProjectList visibleColumns={visibleColumns} />
        </div>
    );
};

export default ExistingProjects;
