import _, { uniqBy } from "lodash";
import api from "../services/api";
import logger from "../services/logger";
import { Store } from "pullstate";
import { updateGlobalError } from "./app-context";
import { formatDate, SHORT_DATE_FORMAT } from "$utils/date-helpers";
import { getTerm } from "./dictionary";

const initialState = {
    clientDetails: {},
    projects: {
        existing: [],
        averageRevenueProjects: null,
        expansionProbabilityProjects: null,
    },
    chartScoreSviReveune: [],
    clientDetailsCharts: { serviceLineChart: null, likeClientChart: null, themeOfficeChart: null },
    subClients: [],
    top5SubClients: {},
    hasSubClients: false,
    totalPercentOfRevenue: 100,
};

export const ClientDetailsStore = new Store(initialState);

const appendUrlProperty = (data) => {
    return data.map((c) => {
        return {
            ...c,
            labelId: c.key,
        };
    });
};

export const evenOutChartDates = (data) => {
    const allDates = _.uniq(data.map((d) => (d?.data || []).map((i) => i.date)).flat());

    const newData = data.map((d) => {
        const newData = allDates.map((date) => {
            const found = d.data.find((i) => i.date === date);
            return {
                date,
                value: found ? found.value : 0,
            };
        });
        return {
            ...d,
            data: newData,
        };
    });
    return newData;
};

const fetchClientDetailsCharts = async (clientId) => {
    const response = await api.get(`/api/clients/${clientId}/charts`);

    ClientDetailsStore.update((s) => {
        s.clientDetailsCharts = {
            serviceLineData: evenOutChartDates(response?.serviceLineChart),
            themeOfficeChart: response?.themeOfficeChart, // TODO: Is this deprecated now?
            likeClientData: appendUrlProperty(evenOutChartDates(response?.likeClientChart) || []),
            clientScoreHistory:
                response?.clientScoreHistory.map((i) => {
                    return {
                        ...i,
                        date: formatDate(i.date, SHORT_DATE_FORMAT),
                    };
                }) || [],
        };
    });
};

const apiExistingProjects = async ({ client }, apiSettings) => {
    try {
        // TODO: This should not be limit 3, breaks without the limit
        const response = await api.get(`/api/projects/existing?client=${client}&page=0&limit=100`, null, apiSettings);

        ClientDetailsStore.update((s) => {
            s.projects.existing = response.data;
        });
    } catch (e) {
        logger.error("Unable to fetch scorecard summary", e);
    }
};

export const fetchExistingProjects = (params) => {
    const abortController = new AbortController();
    apiExistingProjects(params, { signal: abortController.signal });
    return abortController;
};

const apiPotentialProjects = async ({ client }, apiSettings) => {
    try {
        const response = await api.get(`/api/projects/potential/client/${client}`, null, apiSettings);

        ClientDetailsStore.update((s) => {
            s.projects.averageRevenueProjects = response.averageRevenue;
            s.projects.expansionProbabilityProjects = response.expansionProbability;
        });
    } catch (e) {
        logger.error("Unable to fetch scorecard summary", e);
    }
};

export const fetchPotentialProjects = (params) => {
    const abortController = new AbortController();
    apiPotentialProjects(params, { signal: abortController.signal });
    return abortController;
};

const mapSubclientDataForChart = (key) => (s) => ({
    labelId: s.id,
    name: s.name,
    value: s[key],
});

const apiGetscatterChartSubClients = async ({ client }, apiSettings) => {
    const scatterResponse = await api.getFromCache(`/api/client-groups/clients?client=${client}`, null, apiSettings);
    ClientDetailsStore.update((s) => {
        s.chartScoreSviReveune = scatterResponse.data.map((s) => {
            return { id: s.id, label: s.name, svi: s.sviScore, healthScore: s.healthScore, revenue: s.twelveMonthRevenue };
        });
    });
};

const apiGetTop5SubClients = async ({ client }, apiSettings) => {
    try {
        const sviResponse = await api.get(`/api/client-groups/clients?client=${client}&page=0&limit=5&sort=SviScore desc`, null, apiSettings);
        const rarResponse = await api.get(`/api/client-groups/clients?client=${client}&page=0&limit=5&sort=revenueAtRisk desc`, null, apiSettings);
        ClientDetailsStore.update((s) => {
            s.top5SubClients = {
                ClientsSVIScore: sviResponse?.data.map(mapSubclientDataForChart("sviScore")) || [],
                ClientsRevenueAtRisk: rarResponse?.data.map(mapSubclientDataForChart("revenueAtRisk")) || [],
            };
        });
    } catch (e) {
        logger.error(`Unable to fetch top 5 ${getTerm("subClients")}`, e);
    }
};

const apiGetChurnedPortionOfRevenue = async ({ client }, apiSettings) => {
    try {
        const churnedRevenue = await api.get(`/api/client-groups/clients?client=${client}`, null, apiSettings);
        const subClients = churnedRevenue?.data || [];
        ClientDetailsStore.update((s) => {
            s.totalPercentOfRevenue = subClients.reduce((t, c) => {
                t = isNaN(c.portionOfRevenue) ? t : t + c.portionOfRevenue;
                return t;
            }, 0);
        });
    } catch (e) {
        logger.error("Unable to fetch churned portion of revenue", e);
    }
};

const apiGetSubClients = async ({ client, page = 0, limit = 10, sort = "Name", sortDirection = "ASC" }, apiSettings) => {
    try {
        const response = await api.getFromCache(`/api/client-groups/clients?client=${client}`, null, apiSettings);

        ClientDetailsStore.update((s) => {
            const subClients = response.data.map((s) => {
                // const crmIds = s.crms.map((crm) => crm.id);
                return {
                    ...s,
                    clientManagers: uniqBy(s.clientManagers, "id"), // Dedupes this list
                    /*uniqBy(
                        s.clientManagers.filter((cm) => !crmIds.includes(cm.id)),
                        "id"
                    ),*/ revenueAtRisk: Math.abs(s.revenueAtRisk) || 0,
                };
            });
            s.subClients = subClients;
        });
    } catch (e) {
        logger.error("Unable to fetch scorecard summary", e);
    }
};

export const fetchSubClients = (params) => {
    const abortController = new AbortController();
    apiGetSubClients(params, { signal: abortController.signal });
    apiGetTop5SubClients(params, { signal: abortController.signal });
    apiGetscatterChartSubClients(params, { signal: abortController.signal });
    apiGetChurnedPortionOfRevenue(params, { signal: abortController.signal });
    return abortController;
};

const apiClient = async (clientId, apiSettings) => {
    try {
        const response = await api.get(`/api/client-groups?client=${clientId}&page=0&limit=1`, null, apiSettings);
        const clientData = response.data && response.data.length > 0 ? response.data[0] : null;
        //clientData.crmFullName = clientData.crms ? clientData.crms[0]?.fullName || "" : clientData.crmFullName;
        //clientData.crmId = clientData.crms ? clientData.crms[0]?.id || "" : clientData.crmId;
        //clientData.teamByTypeId = clientData.crms ? _.groupBy(clientData.crms, "personTypeId") : {};
        logger.debug("ClientDetailsStore: fetchClient", clientData);

        // async retrieve chart data
        fetchClientDetailsCharts(clientId);
        ClientDetailsStore.update((s) => {
            const subclientName = s.subClients[0]?.name || "";
            s.clientDetails = clientData;
            s.hasSubClients = clientData.clientCount > 1 || (clientData.clientCount === 1 && clientData.name !== subclientName) || false;
            // const crmIds = clientData.crms.map((crm) => crm.id);
            s.clientDetails.clientManagers = uniqBy(clientData.clientManagers, "id"); // Dedupes this list
            /*uniqBy(
                clientData.clientManagers.filter((cm) => !crmIds.includes(cm.id)),
                "id"
            );*/
        });
    } catch (e) {
        if (api.requestWasCanceled(e)) {
            return;
        }
        updateGlobalError(`Unable to fetch ${getTerm("parentClient")}`);
        logger.error(`Unable to fetch ${getTerm("parentClient")}`, e);
    }
};

export const fetchClient = (clientId) => {
    const abortController = new AbortController();
    apiClient(clientId, { signal: abortController.signal });
    return abortController;
};

export const resetClient = () => {
    ClientDetailsStore.update((s) => {
        return initialState;
    });
};
