import NoData from "$components/charts/no-content";
import { Store } from "pullstate";
import HelpAccordion from "src/pages/help/components/help-accordion";
import HelpContent from "src/pages/help/components/help-content";

const initialState = {
    showHelp: false,
    Content: NoData,
    data: [],
};

export const HelpStore = new Store(initialState);

export const toggleHelp = (bool) => {
    HelpStore.update((s) => {
        s.showHelp = bool || false;
    });
};

export const setHelpContent = (dataFn) => {
    const data = dataFn();
    HelpStore.update((s) => {
        s.Content = !data ? NoData : data.length > 1 ? HelpAccordion : HelpContent;
        s.data = data || [];
    });
};
