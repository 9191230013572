export const CHART_HEALTHY_COLORS = ["#18b2dc", "#1395b9", "#0f7796"];

export const CHART_UNHEALTHY_COLORS = ["#e06537", "#b55735", "#884933"];

export const CHART_DEFAULT_COLORS = ["#18B2DC", "#6793DC", "#A367DC", "#DC67AB", "#DCAF66", "#C3DC67"];

export const CHART_PIE_COLORS = ["#18B2DC", "#DC67AB", "#A367DC", "#DCAF66", "#6793DC", "#C3DC67"];

export const calclulateDataMin = (dataMin) => {
    return dataMin;
};

export const calclulateDataMax = (dataMax) => {
    if (dataMax < 0) {
        return 0;
    }

    return dataMax;
};
