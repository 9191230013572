import React from "react";
import { Link } from "react-router-dom";

import { AppContextStore } from "$stores/app-context";
import { buildChatUrl } from "$utils/chat-type";
import { faEnvelope, faMessage, faPersonWalkingArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { getTerm } from "$stores/dictionary";
import classNames from "classnames";

const LinkInfoCell = ({ value, label, url, email, isActive = true }) => {
    const appInsights = useAppInsightsContext();
    const useIsActive = isActive === "false" || isActive === false ? false : true;
    const { enableTeams } = AppContextStore.useState((a) => {
        return { enableTeams: a.settings?.externalConnections?.teams && useIsActive };
    });

    return (
        <>
            <h5>{label}</h5>
            <div className={classNames("link-info-content", { "-faded": !useIsActive })}>
                <h4>
                    {value ? (
                        url ? (
                            <Link
                                to={url}
                                onClick={() => {
                                    appInsights.trackEvent(
                                        { name: "Potential Projects" },
                                        {
                                            label: label,
                                            section: "Table Info",
                                        }
                                    );
                                }}
                            >
                                {value}
                            </Link>
                        ) : (
                            value
                        )
                    ) : (
                        "-"
                    )}
                </h4>
                {!useIsActive && (
                    <div className="txt" data-tooltip={getTerm("tooltipNoLongerEmployed")}>
                        <FontAwesomeIcon icon={faPersonWalkingArrowRight} />
                    </div>
                )}
                {email && useIsActive && (
                    <a
                        href={buildChatUrl("email", email)}
                        target="_blank"
                        onClick={() => {
                            appInsights.trackEvent(
                                { name: "Potential Project" },
                                {
                                    label: email,
                                    action: "Email",
                                }
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                )}
                {enableTeams && email && (
                    <a
                        href={buildChatUrl("teams", email)}
                        target="_blank"
                        onClick={() => {
                            appInsights.trackEvent(
                                { name: "Potential Project" },
                                {
                                    label: email,
                                    action: "Teams",
                                }
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faMessage} />
                    </a>
                )}
            </div>
        </>
    );
};

export default LinkInfoCell;
