import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faCloudArrowUp, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { getInitials } from "$utils/initials";
import { formatRecentDate } from "$utils/date-helpers";

import NoData from "$components/charts/no-content";

import { NOTIFICATION_STATES, markAsArchived, markAsRead } from "$stores/notifications";

const NotificationMessageList = ({ messages = [] }) => {
    if (!messages || messages.length <= 0) {
        return (
            <div className="message-list">
                <NoData message="No Notifications" />
            </div>
        );
    }

    return (
        <div className="message-list">
            {messages.map((m, i) => (
                <div
                    className={classNames("message", { "-unread": NOTIFICATION_STATES["unread"].includes(m.status), "-archive": NOTIFICATION_STATES["viewed"].includes(m.status) })}
                    key={`message_${m.id}`}
                    onClick={() => {
                        if (NOTIFICATION_STATES["unread"].includes(m.status)) {
                            markAsRead(m.id);
                        }
                    }}
                >
                    <div className="profile-icon -small" title={m?.fromUser?.displayName}>
                        {getInitials(m?.fromUser?.displayName)}
                    </div>
                    <div className="content">
                        <div className="name-date">
                            <b>{m?.fromUser?.displayName}</b>
                        </div>
                        <p>
                            <span dangerouslySetInnerHTML={{ __html: m.message }}></span>
                            {m.link && (
                                <a href={m.link} target="_blank" className="button -micro -icon">
                                    <FontAwesomeIcon icon={faCloudArrowUp} />
                                </a>
                            )}
                        </p>
                        <span className="time txt -xsmall -light">{formatRecentDate(new Date(`${m?.timestamp}Z`))}</span>
                    </div>
                    {m.status === 0 || m.status === 1}
                    <div
                        className="state"
                        onClick={() => {
                            if (NOTIFICATION_STATES["active"].includes(m.status)) {
                                markAsArchived(m.id);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={m.status === 0 ? faEnvelope : faArchive} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default NotificationMessageList;
