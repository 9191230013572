import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { updateFn } from "../libs/helpers";

const Input = (props) => {
    const incomingNumber = typeof props.defaultValue !== "undefined" ? props.defaultValue : props.value;
    const [value, setValue] = useState(incomingNumber !== "undefined" ? incomingNumber : "");

    useEffect(() => {
        const useValue = typeof props.defaultValue !== "undefined" ? props.defaultValue : props.value;
        if (useValue !== value) {
            setValue(useValue !== "undefined" ? useValue : "");
        }
    }, [props.defaultValue, props.value, value]);

    const onChange = (e) => {
        setValue(e.currentTarget.value);

        if (props.onChange) {
            props.onChange(e);
        } else if (props.updateOnChange) {
            updateFn(props.updateOnChange)(e);
        }
    };

    return (
        <input
            type="text"
            key={`${props.id || props.name || ""}`}
            id={`${props.id || props.name || ""}`}
            name={props.name}
            autoComplete={props.autoComplete || "off"}
            autoFocus={props.autoFocus}
            min={props.min}
            max={props.max}
            maxLength={props.maxLength}
            minLength={props.minLength}
            multiple={props.multiple}
            pattern={props.pattern}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            required={props.required}
            step={props.step}
            value={value}
            onKeyUp={props.onKeyUp}
            onKeyDown={props.onKeyDown}
            onClick={props.onClick}
            onChange={onChange}
            onBlur={props.onBlur || updateFn(props.updateOnBlur)}
            onFocus={props.onFocus}
            disabled={props.disabled}
            tabIndex={props.tabIndex}
            className={classnames({ "-error": props.invalid })}
        />
    );
};

export default Input;
