import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import SelectItem from "./components/select-item";

import { checkSingleMatch } from "$utils/check-match";
import { KEYCODE } from "$utils/keycodes";
import { extractValue } from "../libs/helpers";

import "./select.scss";

const Select = ({ id, label, name, defaultValue = "", options = [], updateOnChange, invalid, updateOption, EditComponent = null }) => {
    var hideTimer = null;
    const dropdownRef = useRef();
    const [value, setValue] = useState(defaultValue);

    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);

    useEffect(() => {
        const isMatch = checkSingleMatch(defaultValue, value);
        if (!isMatch) {
            setValue(defaultValue);
        }
    }, [defaultValue, options]);

    useEffect(() => {
        const isMatch = checkSingleMatch(defaultValue, value);
        if (defaultValue && !isMatch) {
            setValue(defaultValue);
        }
    }, [options]);

    const updateValues = (changeValue, isAdd) => {
        clearTimeout(hideTimer);
        var newValue = isAdd ? changeValue : null;
        setValue(newValue);
        setIsOpen(false);
        setActiveIndex(-1);
        if (updateOnChange) {
            updateOnChange(id || name, newValue);
        }
    };

    const mouseEnter = (e) => {
        clearTimeout(hideTimer);
    };

    const mouseLeave = (e) => {
        clearTimeout(hideTimer);
        hideTimer = setTimeout(() => {
            setIsOpen(false);
        }, 400);
    };

    const handleKeyDown = useCallback(
        (event) => {
            switch (event.keyCode) {
                case KEYCODE.TAB:
                    setIsOpen(false);
                    break;
                case KEYCODE.RETURN:
                    const option = options[activeIndex];
                    if (option && dropdownRef && dropdownRef.current) {
                        const isSelected = extractValue(value) === extractValue(option) ? true : false;
                        updateValues(option, !isSelected);
                    }
                    break;
                case KEYCODE.ESC:
                    setIsOpen(false);
                    setActiveIndex(-1);
                    break;
                case KEYCODE.UP:
                    setIsOpen(true);
                    if (activeIndex > 0) {
                        setActiveIndex(activeIndex - 1);
                    } else {
                        setActiveIndex(options.length - 1);
                    }
                    break;
                case KEYCODE.DOWN:
                    setIsOpen(true);

                    if (activeIndex < options.length - 1) {
                        setActiveIndex(activeIndex + 1);
                    } else {
                        setActiveIndex(0);
                    }
                    break;
                default:
                    break;
            }
        },
        [options, activeIndex]
    );

    const items = Array.isArray(options)
        ? options.map((option, index) => {
              const item = option;
              const isActiveIndex = activeIndex === index ? true : false;
              return <SelectItem key={`select_${item.value || index}`} item={item} selected={value} isActiveIndex={isActiveIndex} updateValues={updateValues} updateOption={updateOption} EditComponent={EditComponent} />;
          })
        : Object.keys(options).map((key, index) => {
              const item = options[key];
              const isActiveIndex = activeIndex === index ? true : false;
              return <SelectItem key={`select_${key}`} item={item} selected={value} isActiveIndex={isActiveIndex} updateValues={updateValues} updateOption={updateOption} EditComponent={EditComponent} />;
          });

    const icon = isOpen ? faCaretUp : faCaretDown;

    return (
        <div className={classNames("multi-select", { "-error": !!invalid })} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onKeyDown={handleKeyDown} tabIndex="0">
            <div
                className="label"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <span className="label-span">{label}:</span> {extractValue(value, "text")}
                <div className="arrow">
                    <FontAwesomeIcon icon={icon} />
                </div>
            </div>
            <div ref={dropdownRef} className={classNames("dropdown", { "-open": isOpen })}>
                <ul>{items}</ul>
            </div>
        </div>
    );
};

export default Select;
