import _ from "lodash";
import api from "../services/api";
import logger from "../services/logger";

import { CHART_PIE_COLORS } from "$components/charts/lib/constants";
import { buildFilters, objectToParamString } from "$utils/api-helpers";
import { sortByKey } from "$utils/mapping";
import { Store } from "pullstate";
import { hexToRgb } from "$components/charts/lib/helpers";

const initialState = {
    charts: {},
};

export const ChartsStore = new Store(initialState);

const apiChartKeys = async (apiSettings) => {
    try {
        const response = await api.get("/api/reporting/charts", null, apiSettings);

        ChartsStore.update((s) => {
            s.keys = response.data;
        });
    } catch (e) {
        logger.error("Unable to fetch chart keys", e);
    }
};

export const fetchChartKeys = () => {
    const abortController = new AbortController();
    apiChartKeys({ signal: abortController.signal });
    return abortController;
};

const mapDimensionsTextValue = (dimensions = [], parentColors = []) => {
    let useIndex = 0;
    return dimensions.reduce((arr, d, i) => {
        useIndex = i >= CHART_PIE_COLORS.length - 1 ? 0 : useIndex + 1;

        (d?.data || []).forEach((data, ii) => {
            const useColor = !_.isEmpty(parentColors) && data?.parentId && parentColors[data?.parentId] ? parentColors[data?.parentId] : CHART_PIE_COLORS[useIndex];
            const opacityGap = 60 / Math.max(d?.data.length || 3, 3);
            const opacity = !_.isEmpty(parentColors) ? (100 - ii * opacityGap) / 100 : 1;
            arr.push({ text: data.label, value: data.value, color: `rgba(${hexToRgb(useColor)}, ${opacity})`, rgb: hexToRgb(useColor) });
        });
        return arr;
    }, []);
};

const mapChartSimpleFormat = (charts, chartKeysToObj, colors = CHART_PIE_COLORS) => {
    return Object.keys(charts).reduce((obj, chartKey) => {
        const chart = charts[chartKey];
        const sortKey = chartKeysToObj[chartKey]?.sortBy || "value";
        if (chart.dimensions[0]) {
            let colorIndex = 0;
            let opacity = 1;
            const opacityGap = 60 / Math.max(chart.dimensions[0].length || 3, 3) / 100;
            const parentLabel = chart.dimensions[0].label;
            const chartData = chart.dimensions[0].data
                .sort((a, b) => sortByKey(a, b, sortKey, false))
                .reduce((arr, d, i) => {
                    if (d.value > 0) {
                        if (colorIndex >= colors.length - 1) {
                            colorIndex = 0;
                            opacity = opacity - opacityGap;
                        } else {
                            colorIndex++;
                        }
                        arr.push({ ...d, text: d.label, parentLabel, color: `rgba(${hexToRgb(colors[colorIndex])}, ${opacity})`, rgb: hexToRgb(colors[colorIndex]) });
                    }
                    return arr;
                }, []);

            obj[chartKey] = chartData.length > 0 ? chartData : [];
        } else {
            obj[chartKey] = [];
        }
        return obj;
    }, {});
};

export const fetchCharts = async (params, chartKeys, apiSettings = null) => {
    try {
        const useChartKeys = [];
        const chartKeysToObj = chartKeys.reduce((obj, c) => {
            const key = c?.key || c;
            useChartKeys.push(key);

            obj[key] = typeof c === "string" ? { key: key } : { ...c };
            return obj;
        }, {});

        const chartGroups = await api.get(`/api/reporting/chart-groups?${objectToParamString(params)}&chartKey=${useChartKeys.join("&chartKey=")}`, null, apiSettings);

        const mappedCharts = mapChartSimpleFormat(chartGroups.charts, chartKeysToObj);

        // Maps Chart Children if necissary
        Object.keys(chartKeysToObj).forEach((k) => {
            if (chartKeysToObj[k].groupChildrenFrom) {
                // Object for Children by parentId
                const groupChildrenByParentId = _.groupBy(mappedCharts[chartKeysToObj[k].groupChildrenFrom], "parentId");

                // Iterate through parent and add children to each data point
                if (mappedCharts[k]) {
                    mappedCharts[k] = mappedCharts[k].map((c) => {
                        const childrenColorSynced = (groupChildrenByParentId[c.labelId] || []).map((g, i) => {
                            const opacityGap = 60 / Math.max(groupChildrenByParentId[c.labelId].length, 3);
                            const opacity = (100 - i * opacityGap) / 100;
                            return { ...g, color: `rgba(${c.rgb}, ${opacity})` };
                        });
                        return { ...c, children: childrenColorSynced };
                    });
                }
            }
        });

        return mappedCharts;
    } catch (e) {
        logger.error("Unable to fetch chart keys", e);
    }
};

const apiCharts = async (params, chartKeys, apiSettings) => {
    try {
        const charts = await fetchCharts(params, chartKeys, apiSettings);

        ChartsStore.update((s) => {
            s.charts = {
                ...charts,
            };
        });
    } catch (e) {
        logger.error("Unable to fetch chart keys", e);
    }
};

export const fetchChartsStore = (params, chartKeys) => {
    const abortController = new AbortController();
    apiCharts(params, chartKeys, { signal: abortController.signal });
    return abortController;
};

const apiChart = async (params, chartKey, apiSettings) => {
    try {
        const result = await api.get(`/api/reporting/charts/${chartKey}?${buildFilters(params)}`, null, apiSettings);
        const chart = mapDimensionsTextValue(result?.dimensions);

        ChartsStore.update((s) => {
            s.charts = { ...s.charts, chart };
        });
    } catch (e) {
        logger.error("Unable to fetch chart keys", e);
    }
};

export const fetchChart = (params, chartKeys) => {
    const abortController = new AbortController();
    apiChart(params, chartKeys, { signal: abortController.signal });
    return abortController;
};

export const resetCharts = () => {
    ChartsStore.update((s) => {
        return initialState;
    });
};
