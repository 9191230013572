import React from "react";

import "./corner-ribbon.scss";

const CornerRibbon = ({ text }) => {
    return (
        <div className="corner-ribbon">
            <span className="txt -bold -condensed">{text}</span>
        </div>
    );
};

export default CornerRibbon;
