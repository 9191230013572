import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSalesforce } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-toastify";

const SalesforceButton = ({ disabled, onClick }) => {
    const handleOnClick = () => {
        if (!disabled && onClick) {
            onClick();
        } else {
            toast("This project has already been sent to Salesforce");
        }
    };
    const disabledText = disabled ? "This project has already been sent to Salesforce" : "";
    return (
        <button className={classNames("button -export-icon", { "-disabled": disabled })} onClick={handleOnClick} title={disabledText} title="Salesforce">
            <FontAwesomeIcon icon={faSalesforce} />
        </button>
    );
};

export default SalesforceButton;
