import React, { useEffect, useState } from "react";
import classNames from "classnames";

import StackedBarChart from "$components/charts/stacked-bar";
import Top5 from "$components/top5";

import { AppContextStore } from "$stores/app-context";
import { fetchClients } from "$stores/clients";
import { fetchOffices } from "$stores/offices";
import { useDocumentTitle } from "$utils/document-title";
import { fetchSegmentChart, fetchSegmentGroups, fetchTop5, resetTop5, SegmentStore } from "$stores/segments";
import { buildTop5Titles } from "./lib/constants";
import { handleAbort } from "$services/api";
import { debounce } from "lodash";
import { getTerm } from "$stores/dictionary";
import { setHelpContent } from "$stores/help";
import { HELP_SEGMENTS } from "../help/lib/help-contents";

import "./segments.scss";

let controller;
let controllerTop5;

const debouncedFetchData = debounce((segmentGroup, segment, filters) => {
    if (segment) {
        controllerTop5 = fetchTop5(segmentGroup, segment, filters);
    } else {
        controller = fetchSegmentChart(segmentGroup, filters);
    }
}, 800);

const SegmentsIndex = () => {
    const title = useDocumentTitle("Segments");
    const { filters, siteKey } = AppContextStore.useState((s) => {
        return { filters: s.currentFilters, siteKey: s.siteKey };
    });
    const [top5Titles] = useState(buildTop5Titles(siteKey));
    const { segmentGroups, top5, chart, chartYDomain } = SegmentStore.useState((s) => {
        return { segmentGroups: s.segmentGroups, top5: s.top5, chart: s.chart, chartYDomain: s.chartYDomain };
    });
    const [segmentGroup, setSegmentGroup] = useState("1");
    const [segment, setSegment] = useState("");
    const [segmentIndex, setSegmentIndex] = useState("");

    useEffect(() => {
        setHelpContent(HELP_SEGMENTS);
    }, []);

    useEffect(() => {
        const controllerClients = fetchClients(filters);
        const controllerOffices = fetchOffices(filters);
        resetTop5();
        setSegment("");
        setSegmentIndex("");
        fetchSegmentGroups();

        return () => {
            handleAbort([controllerClients, controllerOffices]);
        };
    }, [filters]);

    useEffect(() => {
        controller = debouncedFetchData(segmentGroup, segment, filters);

        return () => {
            handleAbort([controller, controllerTop5]);
        };
    }, [segment, segmentGroup, filters]);

    const changeSection = (key) => {
        resetTop5();
        setSegment("");
        setSegmentIndex("");
        setSegmentGroup(key);
    };

    const changeSegment = (index) => {
        const segmentId = chart[index]?.id || "";
        handleAbort([controller, controllerTop5]);
        resetTop5();
        setSegment(segmentId);
        setSegmentIndex(index);
    };

    return (
        <div id="segments" className="page">
            <div className="main -spacer">
                <main>
                    <div className="main-wrapper">
                        <div className="button-list -underline">
                            {segmentGroups.map((s) => (
                                <button key={s.key} className={classNames("button -text", { "-active": segmentGroup === s.key })} onClick={() => changeSection(s.key)}>
                                    {s.label}
                                </button>
                            ))}
                        </div>
                        <div className="chart-container">
                            {chart && (
                                <StackedBarChart
                                    data={chart}
                                    selectedIndex={segmentIndex}
                                    onSelect={changeSegment}
                                    keysToLabel={{ growth: getTerm("growthPotential"), revenueAtRisk: getTerm("revenueAtRisk") }}
                                    yDomain={chartYDomain}
                                />
                            )}
                        </div>
                        <br />
                        <div className="flex-header">
                            <h1>
                                <em>Top</em> Segment Summary
                            </h1>
                        </div>
                        <div className="segment-summary">
                            {Object.keys(top5Titles).map((key) => {
                                if (!top5[key]) {
                                    return null;
                                }
                                return (
                                    <Top5
                                        key={key}
                                        data={top5[key].map((t) => {
                                            const useKey = top5Titles[key].metrics && top5Titles[key].metrics.length > 0 ? top5Titles[key]?.metrics[0] : "value";
                                            return { ...t, [useKey]: t?.value };
                                        })}
                                        type={top5Titles[key]?.type}
                                        LabelComponent={top5Titles[key]?.LabelComponent}
                                        title={top5Titles[key]?.header()}
                                        linkTemplate={top5Titles[key]?.linkTemplate}
                                        metrics={top5Titles[key]?.metrics}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default SegmentsIndex;
