import React from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import ChartSummary from "$components/charts/chart-summary";
import NoData from "$components/charts/no-content";
import EngineLoader from "$components/loaders/engine-loader";
import SemiCirclePie from "$components/charts/semi-circle-pie";

import { setFilters as setPotentialFilters } from "$stores/potentialProjects";
import { LabelCurrencyTooltip } from "$components/charts/tooltips/currency";
import { abbrNum, formatUIValue } from "$utils/number-helper";
import { getTerm } from "$stores/dictionary";
import { capitalizeFirstLetter } from "$utils/text-helpers";

const SemiCircleCard = ({ data, metrics, filters, title }) => {
    const navigate = useNavigate();
    if (!data || _.isEmpty(data)) {
        return <EngineLoader />;
    }
    if (!data.chart) {
        return <NoData />;
    }

    const setPotentialProjectsFilters = (key, value) => {
        setPotentialFilters({ [key]: value });
        navigate("/projects/potential");
    };

    const metric = filters["crm"] ? `my${capitalizeFirstLetter(metrics[0])}` : metrics[0];
    const chartMetric = filters["crm"] ? "myChart" : "chart";
    return (
        <div className="growth-potential-chart">
            <h4 data-tooltip={getTerm("tooltipGrowthPotential")} className="tooltip--up" dangerouslySetInnerHTML={{ __html: title }}></h4>
            <SemiCirclePie
                TooltipComponent={LabelCurrencyTooltip}
                data={data[chartMetric]}
                totalValue={formatUIValue("$" + abbrNum(data[metric]))}
                onClick={(data) => {
                    setPotentialProjectsFilters("serviceLine", [{ text: data.text, value: data.id }]);
                }}
            />
            <ChartSummary
                data={data[chartMetric]}
                handleClick={(data) => {
                    setPotentialProjectsFilters("serviceLine", [{ text: data.text, value: data.id }]);
                }}
            />
            {/*<h3 className="txt -healthy -compressed -black">
                <sup>$</sup>
                {abbrNum(data.revenue)}
                <span data-tooltip={getTerm("tooltip12MonthRevenue")} className="tooltip--up">
                    {getTerm("t12Revenue")} of {getTerm("myParentClients")}
                </span>
            </h3>*/}
        </div>
    );
};

export default SemiCircleCard;
