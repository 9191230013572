import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../services/azure-auth/config";
import { UserStore } from "../../stores/user";

import AppVersion from "../header/components/app-version";
import Button from "../button";
import NotificationBar from "$components/notification-bar";

import "./login.scss";

const LoginIndex = () => {
    const { instance } = useMsal();

    const { displayName, hasLoaded, hasNoAccess } = UserStore.useState((s) => s);

    const onLogin = (e) => {
        e.preventDefault();
        instance.loginRedirect(loginRequest);
    };

    return (
        <div className="login-page">
            <aside>
                <div className="logo">
                    <img src="/images/logo-orgaimi.svg" alt="Orgaimi" />
                </div>
                <div className="login-button">
                    <h3 className="txt -center">Log In</h3>
                    <Button onClick={onLogin} className="button -small -full -icon -primary -right">
                        <img src="/images/icon-microsoft.png" alt="Microsoft Logo" width="20" />
                        Login with Microsoft
                    </Button>
                    {/*<div className="background-line -center -gray"><span className="txt -compressed -uppercase">or</span></div>
                    <LoginForm />*/}
                    {hasLoaded && hasNoAccess && (
                        <NotificationBar type="error">
<div className="txt -center">
                            You do not have access to this application
                            {displayName && <p className="txt -xsmall">
                                (Not {displayName}? <a href="/logout" className="txt -white">Logout)</a>
                            </p>}
                        </div>
                        </NotificationBar>
                        
                    )}
                </div>
                <div className="aside-footer">
                    <AppVersion />
                </div>
            </aside>
            <main>
                <div className="container txt -center">
                    <img src="/images/logo-orgaimi.svg" alt="Orgaimi" width="300" />
                    <h1>
                        Organizational Intelligence
                        <br />
                        <em>for the Enterprise</em>
                    </h1>
                </div>
            </main>
        </div>
    );
};

export default LoginIndex;
