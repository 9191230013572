import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import TableLoader from "$components/table/table-loader.js";

import { buildEmployeeProjectsColumns } from "./lib/columns.js";
import { EmployeeExistingProjectsStore, setFilters, setPageIndex, setPerPage, setSort } from "$stores/employeeExistingProjects.js";
import { mapToTableData } from "src/pages/projects/existing/list.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";

const ExistingProjects = () => {
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const filterMenu = AppContextStore.useState((f) => mapByKey(f.filterMenu, "apiKey") || {});
    const { filters, isLoading, isSortAsc, pageCount, pageIndex, pageSize, projects, sort } = EmployeeExistingProjectsStore.useState((c) => {
        return {
            filters: c.filters,
            isLoading: c.isLoading,
            isSortAsc: c.isSortAsc,
            pageCount: c.pageCount,
            pageIndex: c.pageIndex,
            pageSize: c.pageSize,
            projects: c.employeeExistingProjects || [],
            sort: c.sort,
        };
    });
    const [employeeProjectsColumns, setEmployeeProjectColumns] = useState([]);

    useEffect(() => {
        setEmployeeProjectColumns(buildEmployeeProjectsColumns({ filterOptions: filterMenu }));
    }, [filterMenu]);

    const rowClick = (row) => {
        navigate(`/project/${row.id}/existing`);
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: "Existing Project",
                section: "Client Detail",
                projectId: row.id,
            }
        );
    };

    const data = projects.map(mapToTableData);

    return (
        <TableLoader
            allowFilter={true}
            columns={employeeProjectsColumns}
            containerClass="project-list"
            data={data}
            filters={filters}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setFilters={setFilters}
            setPageIndex={setPageIndex}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default ExistingProjects;
