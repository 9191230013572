import { CHART_DEFAULT_COLORS } from "./constants";

export const calculateMaxSpace = (max) => {
    if (max < 20) {
        return Math.floor(max + 2);
    }

    return Math.floor(max * 1.21);
};

export const hexToRgb = (hex) => {
    var arrBuff = new ArrayBuffer(4);
    var vw = new DataView(arrBuff);
    vw.setUint32(0, parseInt(hex.replace("#", ""), 16), false);
    var arrByte = new Uint8Array(arrBuff);

    return arrByte[1] + "," + arrByte[2] + "," + arrByte[3];
};

export const buildColorsWithOpacity = (count, colors = CHART_DEFAULT_COLORS) => {
    const adjustedColors = [];
    const opacityGap = 60 / Math.max(Math.ceil(count / colors.length) || 3, 3);
    var opacityStep = -1;

    for (let i = 0; i < count; i++) {
        const colorIndex = i % colors.length;
        const originalColor = colors[colorIndex];
        if (i % colors.length === 0) {
            opacityStep = opacityStep + 1;
        }
        const opacity = (100 - opacityStep * opacityGap) / 100;

        // Convert originalColor to rgba format
        const rgbaColor = `rgba(${hexToRgb(originalColor)}, ${opacity})`;

        adjustedColors.push(rgbaColor);
    }
    return adjustedColors;
};
